import { FormStorage } from "../../../views/formView/FormStorage";
import { IPrWorkingPatternEntity } from "@odata/GeneratedEntityTypes";
import { IWorkingPatternCustomData } from "./WorkingPatternsFormView";
import { formatDateToDateString } from "@components/inputs/date/utils";
import { Dayjs } from "dayjs";

export const clearDayError = (storage: FormStorage<IPrWorkingPatternEntity, IWorkingPatternCustomData>, day: Dayjs): void => {
    let daysWithError = storage.getCustomData().daysWithError;
    daysWithError = daysWithError?.filter(d => d !== formatDateToDateString(day));
    storage.setCustomData({
        daysWithError
    });
};