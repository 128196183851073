import { getHourDifference, longDateFormat } from "@components/inputs/date/utils";
import { SwitchType } from "@components/inputs/switch/Switch";
import { getDaysUnit, IFieldDefFn, IGetValueArgs } from "@components/smart/FieldInfo";
import { getCommonFilterDefs, withDisplayName } from "@components/smart/GeneralFieldDefinition";
import { FilterBarGroup, getDefaultFilterGroupDef } from "@components/smart/smartFilterBar/SmartFilterBar.types";
import {
    EntitySetName,
    EntityTypeName,
    IPrWorkingPatternDayEntity,
    IPrWorkingPatternDayIntervalEntity,
    IPrWorkingPatternEntity,
    PrWorkingPatternDayEntity,
    PrWorkingPatternDayIntervalEntity,
    PrWorkingPatternEntity
} from "@odata/GeneratedEntityTypes";
import { PrWorkingPatternTypeCode, PrWorkIntervalTypeCode } from "@odata/GeneratedEnums";
import { isNotDefined } from "@utils/general";
import i18next from "i18next";
import React from "react";

import { DASH_CHARACTER } from "../../../constants";
import { BasicInputSizes, CacheStrategy, FieldType, LabelStatus, Sort, TextAlign, ValidatorType } from "../../../enums";
import BindingContext, { createPath } from "../../../odata/BindingContext";
import DateType, { getDateFormat, getUtcDate, getUtcDayjs } from "../../../types/Date";
import { IFormDef } from "../../../views/formView/Form";
import { FormStorage } from "../../../views/formView/FormStorage";
import { ISplitPageTableDef } from "../../../views/table/TableView.utils";
import { activeFormatter } from "../../chartOfAccounts/ChartOfAccountsDef";
import { setDefByEntityType } from "../../getDefByEntityType";
import { IDefinition, IGetDefinition } from "../../PageUtils";
import { SmartCalendarWrapper } from "./SmartCalendarWrapper";
import WorkDayIntervalsFastEntry from "./WorkDayIntervalsFastEntry";
import WorkingPatternsFormView, { IWorkingPatternCustomData } from "./WorkingPatternsFormView";

export const calendarPath = BindingContext.localContext("Calendar");
export const rotationLengthPath = BindingContext.localContext("RotationLength");
export const dateStartPath = BindingContext.localContext("DateStart");
export const workDayIntervalsFastEntryPath = BindingContext.localContext("WorkDayIntervalsFastEntry");
export const timeDiffPath = BindingContext.localContext("TimeDiff");

const isRotation = (args: IGetValueArgs): boolean => {
    return args.storage.getValueByPath(PrWorkingPatternEntity.TypeCode) === PrWorkingPatternTypeCode.Rotation;
};

export const getDefinitions: IGetDefinition = (): IDefinition => {
    const table: ISplitPageTableDef = {
        id: `${EntityTypeName.PrWorkingPattern}Table`,
        filterBarDef: [{
            ...getDefaultFilterGroupDef(FilterBarGroup.Filters),
            defaultFilters: [
                PrWorkingPatternEntity.Name,
                PrWorkingPatternEntity.Type,
                PrWorkingPatternEntity.IsActive
            ],
            filterDefinition: {
                ...getCommonFilterDefs(),
                Name: {},
                IsActive: {},
                ...withDisplayName(PrWorkingPatternEntity.Type)
            },
            isValueHelp: true
        }],
        initialSortBy: [{ id: PrWorkingPatternEntity.Name, sort: Sort.Desc }],
        columns: [
            PrWorkingPatternEntity.Name,
            PrWorkingPatternEntity.Type,
            PrWorkingPatternEntity.IsActive
        ],
        columnDefinition: {
            Name: {},
            IsActive: {
                id: PrWorkingPatternEntity.IsActive,
                textAlign: TextAlign.Center,
                formatter: activeFormatter
            },
            ...withDisplayName(PrWorkingPatternEntity.Type)
        },
        title: i18next.t("WorkingPatterns:Title")
    };

    const form: IFormDef = {
        id: `${EntityTypeName.PrWorkingPattern}Form`,
        translationFiles: getDefinitions.translationFiles,
        isDeletable: true,
        formControl: WorkingPatternsFormView,
        additionalProperties: [{
            id: PrWorkingPatternEntity.DateLastModified
        },
            {
                id: PrWorkingPatternEntity.Days,
                additionalProperties: [{
                    id: PrWorkingPatternDayEntity.WorkingHours
                }, {
                    id: PrWorkingPatternDayEntity.Date
                }, {
                    id: PrWorkingPatternDayEntity.Intervals,
                    additionalProperties: [{
                        id: PrWorkingPatternDayIntervalEntity.Type
                    }, {
                        id: PrWorkingPatternDayIntervalEntity.TimeStart
                    }, {
                        id: PrWorkingPatternDayIntervalEntity.TimeEnd
                    }, {
                        id: timeDiffPath
                    }]
                }]
            }],
        fieldDefinition: {
            Name: {
                width: BasicInputSizes.L
            },
            IsActive: {
                type: FieldType.Switch,
                fieldSettings: {
                    type: SwitchType.Icons
                },
                defaultValue: true
            },
            TypeCode: {
                type: FieldType.SegmentedButton,
                defaultValue: PrWorkingPatternTypeCode.Weekly,
                labelStatus: LabelStatus.Hidden,
                fieldSettings: {
                    items: [
                        {
                            id: PrWorkingPatternTypeCode.Weekly,
                            label: i18next.t("WorkingPatterns:Type.Weekly")
                        }, {
                            id: PrWorkingPatternTypeCode.Monthly,
                            label: i18next.t("WorkingPatterns:Type.Monthly")
                        }, {
                            id: PrWorkingPatternTypeCode.Rotation,
                            label: i18next.t("WorkingPatterns:Type.Rotation")
                        }
                    ]
                }
            },
            IsDifferentOddAndEvenWeek: {
                type: FieldType.Checkbox,
                labelStatus: LabelStatus.Removed,
                isVisible: args => {
                    return args.storage.getValueByPath(PrWorkingPatternEntity.TypeCode) === PrWorkingPatternTypeCode.Weekly;
                }
            },
            DefaultDayStart: {
                type: FieldType.Time,
                width: BasicInputSizes.XS,
                label: i18next.t("WorkingPatterns:DefaultTimeStartLabel"),
                tooltip: i18next.t("WorkingPatterns:DefaultTimeStart"),
                defaultValue: () => {
                    return getUtcDayjs().startOf("day").add(8, "hours").toDate();
                },
                fieldSettings: {
                    showSteppers: false
                }
            },
            [calendarPath]: {
                type: FieldType.Custom,
                render: (args: IFieldDefFn) => {
                    const storage = args.storage as FormStorage<IPrWorkingPatternEntity, IWorkingPatternCustomData>;
                    return <SmartCalendarWrapper storage={storage}/>;
                }
            },
            [workDayIntervalsFastEntryPath]: {
                type: FieldType.Custom,
                isVisible: (args: IGetValueArgs) => {
                    const storage = args.storage as FormStorage<IPrWorkingPatternEntity, IWorkingPatternCustomData>;
                    return !!storage.getCustomData().selectedDay;
                },
                render: (args: IFieldDefFn) => {
                    const storage = args.storage as FormStorage<IPrWorkingPatternEntity, IWorkingPatternCustomData>;
                    return <WorkDayIntervalsFastEntry storage={storage}/>;
                }
            },
            [dateStartPath]: {
                type: FieldType.Date,
                label: i18next.t("WorkingPatterns:RotationStart"),
                isRequired: true,
                width: BasicInputSizes.M,
                isVisible: isRotation,
                defaultValue: () => getUtcDate()
            },
            [rotationLengthPath]: {
                type: FieldType.NumberInput,
                label: i18next.t("WorkingPatterns:RotationLength"),
                isRequired: true,
                width: BasicInputSizes.S,
                isVisible: isRotation,
                validator: {
                    type: ValidatorType.Number,
                    settings: {
                        min: 0,
                        max: 365
                    }
                },
                fieldSettings: {
                    unit: getDaysUnit
                }
            },
            [createPath(PrWorkingPatternEntity.Days, PrWorkingPatternDayEntity.Intervals, PrWorkingPatternDayIntervalEntity.Type)]: {
                type: FieldType.ComboBox,
                cacheStrategy: CacheStrategy.EndOfTime,
                fieldSettings: {
                    displayName: "Name"
                },
                defaultValue: (args: IGetValueArgs) => {
                    const dayBc = args.bindingContext.getParentCollection().getParent();
                    const day = args.storage.getValue(dayBc) as IPrWorkingPatternDayEntity;
                    const lastInterval = day.Intervals?.at(-1);
                    return lastInterval?.Type?.Code === PrWorkIntervalTypeCode.Work ? PrWorkIntervalTypeCode.MealBreak : PrWorkIntervalTypeCode.Work;
                }
            },
            [createPath(PrWorkingPatternEntity.Days, PrWorkingPatternDayEntity.Intervals, PrWorkingPatternDayIntervalEntity.TimeStart)]: {
                type: FieldType.Time,
                width: BasicInputSizes.XS,
                fieldSettings: {
                    showSteppers: false
                }
            },
            [createPath(PrWorkingPatternEntity.Days, PrWorkingPatternDayEntity.Intervals, PrWorkingPatternDayIntervalEntity.TimeEnd)]: {
                type: FieldType.Time,
                width: BasicInputSizes.XS,
                fieldSettings: {
                    showSteppers: false
                }
            },
            [createPath(PrWorkingPatternEntity.Days, PrWorkingPatternDayEntity.Intervals, timeDiffPath)]: {
                isReadOnly: true,
                width: "50px",
                label: i18next.t("WorkingPatterns:Length"),
                formatter: (val, args) => {
                    const intervalEntity = args.storage.getValue(args.bindingContext.getParent()) as IPrWorkingPatternDayIntervalEntity;
                    const diff = getHourDifference(getUtcDayjs(intervalEntity.TimeStart), getUtcDayjs(intervalEntity.TimeEnd));
                    if (isNotDefined(intervalEntity.TimeStart) || isNotDefined(intervalEntity.TimeEnd) || isNaN(diff)) {
                        return DASH_CHARACTER;
                    }
                    const dateDiff = getUtcDayjs().startOf("day").add(diff, "hours");
                    const formattedValue = DateType.format(dateDiff, longDateFormat(getDateFormat(DateType.defaultTimeFormat)));
                    return `${formattedValue} ${i18next.t("Components:Calendar.HourPlaceholder")}`;
                }
            }
        },
        groups: [
            {
                id: "main",
                rows: [
                    [{ id: PrWorkingPatternEntity.Name }, { id: PrWorkingPatternEntity.IsActive }],
                    [{ id: PrWorkingPatternEntity.TypeCode }, { id: PrWorkingPatternEntity.DefaultDayStart }]
                ]
            }, {
                id: "shifts",
                title: i18next.t("WorkingPatterns:Shifts"),
                rows: [
                    [{ id: PrWorkingPatternEntity.IsDifferentOddAndEvenWeek }, { id: rotationLengthPath }, { id: dateStartPath }],
                    [{ id: calendarPath }],
                    [{ id: workDayIntervalsFastEntryPath }]
                ]
            }
        ],
        querySettings: {
            "Days/Intervals": {
                sort: [{ id: "TimeStart", sort: Sort.Asc }]
            }
        }
    };

    return {
        entitySet: EntitySetName.PrWorkingPatterns,
        table,
        form
    };
};

getDefinitions.translationFiles = ["WorkingPatterns", "Common"];
setDefByEntityType(EntityTypeName.PrWorkingPattern, getDefinitions);