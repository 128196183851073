import { WithConfirmationDialog, withConfirmationDialog } from "@components/dialog/withConfirmationDialog";
import { CopyIcon } from "@components/icon";
import { isValidDate } from "@components/inputs/date/utils";
import { IFieldDef } from "@components/smart/FieldInfo";
import { ISmartFieldChange } from "@components/smart/smartField/SmartField";
import {
    IPrWorkingPatternDayEntity,
    IPrWorkingPatternDayIntervalEntity,
    IPrWorkingPatternEntity,
    PrWorkingPatternDayEntity,
    PrWorkingPatternDayIntervalEntity
} from "@odata/GeneratedEntityTypes";
import { cloneDeep } from "lodash";
import React, { Component } from "react";

import { Button } from "../../../components/button";
import SmartFastEntryList, { ISmartFastEntriesActionEvent } from "../../../components/smart/smartFastEntryList";
import BindingContext from "../../../odata/BindingContext";
import { getUtcDayjs } from "../../../types/Date";
import NumberType from "../../../types/Number";
import { FormStorage } from "../../../views/formView/FormStorage";
import { WorkDayIntervalsFastEntryHeader, WorkDayIntervalsFastEntryWrapper } from "./WorkingPattern.styles";
import { clearDayError } from "./WorkingPatterns.utils";
import { timeDiffPath } from "./WorkingPatternsDef";
import { IWorkingPatternCustomData } from "./WorkingPatternsFormView";

interface IProps extends WithConfirmationDialog {
    storage: FormStorage<IPrWorkingPatternEntity, IWorkingPatternCustomData>
}

class WorkDayIntervalsFastEntry extends Component<IProps> {
    get selectedDay(): IPrWorkingPatternDayEntity {
        const selectedDay = this.props.storage.getCustomData().selectedDay;
        return selectedDay ? this.props.storage.getValue(selectedDay) : null;
    }

    get selectedDayBc(): BindingContext {
        const selectedDay = this.props.storage.getCustomData().selectedDay;
        return selectedDay;
    }

    get columns(): IFieldDef[] {
        return [{
            id: PrWorkingPatternDayIntervalEntity.Type
        }, {
            id: PrWorkingPatternDayIntervalEntity.TimeStart
        }, {
            id: PrWorkingPatternDayIntervalEntity.TimeEnd
        }, {
            id: timeDiffPath
        }];
    }

    handleChange = (e: ISmartFieldChange): void => {
        this.props.storage.handleLineItemsChange(e);
        this.props.storage.refreshFields();
        clearDayError(this.props.storage, getUtcDayjs(this.selectedDay.Date));

        if (isValidDate(e.value as Date) || e.bindingContext.getPath() === PrWorkingPatternDayIntervalEntity.Type) {
            // refresh local context field with hours diff
            this.props.storage.refresh();
        }
    };

    handleClone = async (): Promise<void> => {
        const isConfirmed = await this.props.confirmationDialog.open({
            content: this.props.storage.t("WorkingPatterns:ConfirmationText", { hours: this.selectedDay.WorkingHours })
        });

        if (isConfirmed) {
            const entity = this.props.storage.data.entity as IPrWorkingPatternEntity;
            const daysWithSameWH = entity.Days.filter(d => d.WorkingHours === this.selectedDay.WorkingHours);
            for (const day of daysWithSameWH) {
                day.Intervals = cloneDeep(this.selectedDay.Intervals);
                clearDayError(this.props.storage, getUtcDayjs(day.Date));
            }
            this.props.storage.refresh();
        }
    };

    get isCloningDisabled(): boolean {
        return false;
        // return !this.selectedDay.Intervals.length;
    }

    handleAction = (e: ISmartFastEntriesActionEvent<IPrWorkingPatternDayIntervalEntity>) => {
        this.props.storage.handleLineItemsAction(e);
        this.props.storage.refresh();
    };

    render() {
        if (!this.selectedDay) {
            return null;
        }
        return <WorkDayIntervalsFastEntryWrapper>
            <WorkDayIntervalsFastEntryHeader>
                <b>{`${this.props.storage.t("WorkingPatterns:Interval")} ${NumberType.format(this.selectedDay.WorkingHours ?? 0)} ${this.props.storage.t("Components:Calendar.HourPlaceholder")}`}</b>
                <Button
                        title={this.props.storage.t("WorkingPatterns:UseForAll")}
                        isDisabled={this.isCloningDisabled}
                        isDecorative
                        onClick={this.handleClone}
                        icon={<CopyIcon/>}
                />
            </WorkDayIntervalsFastEntryHeader>
            <SmartFastEntryList
                storage={this.props.storage}
                bindingContext={this.selectedDayBc.navigate(PrWorkingPatternDayEntity.Intervals)}
                columns={this.columns}
                onChange={this.handleChange}
                useLabelWrapping={true}
                canAdd={true}
                isItemCloneable={false}
                isAddButtonTransparent={true}
                onAction={this.handleAction}
                order={"Days/Intervals/TimeStart"}
            />
        </WorkDayIntervalsFastEntryWrapper>;
    }
}

export default withConfirmationDialog(WorkDayIntervalsFastEntry);