import { VisibleIcon } from "@components/icon";
import { formatHoursToTimeFormat, getHourDifference, longDateFormat } from "@components/inputs/date/utils";
import { SwitchType } from "@components/inputs/switch/Switch";
import { IFieldDefFn, IGetValueArgs } from "@components/smart/FieldInfo";
import { getCommonFilterDefs, withDisplayName } from "@components/smart/GeneralFieldDefinition";
import { FilterBarGroup, getDefaultFilterGroupDef } from "@components/smart/smartFilterBar/SmartFilterBar.types";
import { ISummaryItem, ISummaryItemEditProps } from "@components/smart/smartSummaryItem/SmartSummaryItem";
import {
    EntitySetName,
    EntityTypeName,
    IPrAttendanceDayEntity,
    IPrAttendanceDayIntervalEntity,
    IPrAttendanceEntity,
    IPrAttendanceStatusEntity,
    IPrEmployeeEntity,
    IPrEmploymentEntity,
    PrAbsenceCategoryEntity,
    PrAbsenceCategoryTemporalCurrentEntity,
    PrAttendanceDayEntity,
    PrAttendanceDayIntervalEntity,
    PrAttendanceEntity,
    PrAttendanceStatusEntity,
    PrEmployeeEntity,
    PrEmploymentEntity,
    PrEmploymentTemplateEntity,
    PrEmploymentTemplateTemporalEntity,
    PrEmploymentTemporalEntity,
    PrWorkingPatternDayEntity,
    PrWorkingPatternEntity
} from "@odata/GeneratedEntityTypes";
import { PrAttendanceStatusCode, PrWorkIntervalTypeCode } from "@odata/GeneratedEnums";
import { getEnumDisplayValue } from "@odata/GeneratedEnums.utils";
import { userNameWithAvatarTableFormatter } from "@pages/admin/users/UsersDef";
import { isNotDefined } from "@utils/general";
import i18next from "i18next";
import React from "react";
import { DefaultTheme } from "styled-components";

import Calendar from "../../../components/calendar";
import { DASH_CHARACTER } from "../../../constants";
import {
    BasicInputSizes,
    CacheStrategy,
    FieldType,
    LabelStatus,
    NavigationSource,
    Sort,
    TextAlign
} from "../../../enums";
import { ColoredText } from "../../../global.style";
import { Model } from "../../../model/Model";
import BindingContext, { createPath } from "../../../odata/BindingContext";
import DateType, { getDateFormat, getUtcDayjs } from "../../../types/Date";
import { IFormDef } from "../../../views/formView/Form";
import { FormStorage } from "../../../views/formView/FormStorage";
import { ISplitPageTableDef } from "../../../views/table/TableView.utils";
import { setDefByEntityType } from "../../getDefByEntityType";
import { getItemBreadCrumbsText, IDefinition, IGetDefinition } from "../../PageUtils";
import {
    balanceFormatter,
    getWorkingPattern,
    handleDaySelect,
    hasFullDayAbsence,
    isAbsenceInWorkFEList
} from "./Attendance.utils";
import AttendanceDayEditEntries from "./AttendanceDayEditEntries";
import AttendanceDaysList from "./AttendanceDaysList";
import AttendanceFormView, { IAttendanceCustomData } from "./AttendanceFormView";
import UsedWorkingPatternOverview from "./UsedWorkingPatternOverview";

function getThemeColorForPaymentStatus(statusCode: PrAttendanceStatusCode): keyof DefaultTheme {
    return statusCode === PrAttendanceStatusCode.ToProcess ? "C_SEM_text_bad" : "C_SEM_text_good";
}

export const viewSwitchPath = BindingContext.localContext("ViewSwitch");
export const dayFastEntriesPath = BindingContext.localContext("DayFastEntries");
export const DayFundPath = BindingContext.localContext("DayFund");
export const WorkedHoursPath = BindingContext.localContext("WorkedHours");
export const SaldoPath = BindingContext.localContext("Saldo");
export const IsFullDayAbsencePath = BindingContext.localContext("IsFullDayAbsence");
export const HasAbsencePath = BindingContext.localContext("HasAbsence");
export const timeDiffPath = BindingContext.localContext("TimeDiff");

export enum AttendanceViewMode {
    Calendar = "Calendar",
    Table = "Table"
}

export const getDefinitions: IGetDefinition = (): IDefinition => {
    const table: ISplitPageTableDef = {
        id: `${EntityTypeName.PrAttendance}Table`,
        filterBarDef: [{
            ...getDefaultFilterGroupDef(FilterBarGroup.Filters),
            defaultFilters: [
                createPath(PrAttendanceEntity.Employment, PrEmploymentEntity.Employee, PrEmployeeEntity.FirstName),
                createPath(PrAttendanceEntity.Employment, PrEmploymentEntity.Employee, PrEmployeeEntity.LastName),
                createPath(PrAttendanceEntity.Employment, PrEmploymentEntity.NumberOurs),
                PrAttendanceEntity.Status
            ],
            filterDefinition: {
                [createPath(PrAttendanceEntity.Employment, PrEmploymentEntity.Employee, PrEmployeeEntity.FirstName)]: {},
                [createPath(PrAttendanceEntity.Employment, PrEmploymentEntity.Employee, PrEmployeeEntity.LastName)]: {},
                [createPath(PrAttendanceEntity.Employment, PrEmploymentEntity.NumberOurs)]: {},
                ...withDisplayName(PrAttendanceEntity.Status),
                ...getCommonFilterDefs()
            },
            isValueHelp: true
        }],
        initialSortBy: [{
            id: createPath(PrAttendanceEntity.Employment, PrEmploymentEntity.Employee, PrEmployeeEntity.LastName),
            sort: Sort.Desc
        }],
        columns: [
            createPath(PrAttendanceEntity.Employment, PrEmploymentEntity.Employee, PrEmployeeEntity.LastName),
            createPath(PrAttendanceEntity.Employment, PrEmploymentEntity.NumberOurs),
            PrAttendanceEntity.Status,
            PrAttendanceEntity.Balance,
            PrAttendanceEntity.Overtime,
            PrAttendanceEntity.OvertimeFromPreviousMonth
        ],
        columnDefinition: {
            [createPath(PrAttendanceEntity.Employment, PrEmploymentEntity.Employee, PrEmployeeEntity.LastName)]: {
                label: i18next.t("Attendance:Table.Name"),
                additionalProperties: [{ id: `/${createPath(PrAttendanceEntity.Employment, PrEmploymentEntity.Employee, PrEmployeeEntity.FirstName)}` }],
                formatter: (val, args) => {
                    return userNameWithAvatarTableFormatter(val, {
                        ...args,
                        entity: (args.entity as IPrAttendanceEntity)?.Employment?.Employee
                    });
                }
            },
            [createPath(PrAttendanceEntity.Employment, PrEmploymentEntity.NumberOurs)]: {
                label: i18next.t("Attendance:Table.Employment")
            },
            [PrAttendanceEntity.Status]: {
                formatter: (val, args) => {
                    const entity = args.entity as IPrAttendanceEntity;
                    return {
                        value: <ColoredText
                                color={getThemeColorForPaymentStatus(entity.Status?.Code as PrAttendanceStatusCode)}>
                            {entity.Status?.Name}
                        </ColoredText>,
                        tooltip: entity.Status?.Name
                    };
                }
            },
            [PrAttendanceEntity.Balance]: {
                formatter: balanceFormatter
            },
            [PrAttendanceEntity.Overtime]: {
                formatter: balanceFormatter
            },
            [PrAttendanceEntity.OvertimeFromPreviousMonth]: {
                formatter: balanceFormatter
            }
        },
        title: i18next.t("Attendance:Title"),
        tabs: [],
        tabsSettings: {
            customFilter: (selectedTab: string) => {
                if (!selectedTab) {
                    return "";
                }
                const [year, month] = selectedTab.split("-");
                return `${PrAttendanceEntity.Year} eq ${year} AND ${PrAttendanceEntity.Month} eq ${month}`;
            },
            withoutCounts: true
        }
    };

    const summary: ISummaryItem[] = [{
        id: createPath(PrAttendanceEntity.Employment, PrEmploymentEntity.Employee),
        additionalProperties: [{ id: PrEmployeeEntity.FirstName }, { id: PrEmployeeEntity.LastName }],
        formatter: (val) => {
            const employee = val as IPrEmployeeEntity;
            return `${employee.FirstName} ${employee.LastName}`;
        }
    }, {
        id: PrAttendanceEntity.Balance,
        label: i18next.t("Attendance:Summary.Balance"),
        formatter: (val, args) => {
            const entity = args.entity as IPrAttendanceEntity;
            return balanceFormatter(entity.Balance)?.value;
        }
    }, {
        id: BindingContext.localContext("WorkingPattern"),
        label: i18next.t("Attendance:Summary.WorkingPattern"),
        textAlign: TextAlign.Left,
        formatter: (val, args) => {
            const workingPattern = getWorkingPattern(args.entity as IPrAttendanceEntity);
            return workingPattern?.Name ?? DASH_CHARACTER;
        },
        renderEdit: (args: ISummaryItemEditProps) => (
                <UsedWorkingPatternOverview
                        storage={args.storage as FormStorage<IPrAttendanceEntity, IAttendanceCustomData>}
                        onClose={args.onClose}
                />
        ),
        icon: VisibleIcon
    }, {
        id: PrAttendanceEntity.Employment,
        label: i18next.t("Attendance:Table.Employment"),
        additionalProperties: [{ id: PrEmploymentEntity.NumberOurs }],
        formatter: (val) => {
            const employment = val as IPrEmploymentEntity;
            return employment?.NumberOurs ?? DASH_CHARACTER;
        }
    }, {
        id: PrAttendanceEntity.Status,
        additionalData: [{ id: PrAttendanceStatusEntity.Name }],
        label: i18next.t("Attendance:Summary.Status"),
        formatter: (val) => {
            return (val as IPrAttendanceStatusEntity)?.Name;
        },
        colorFormatter: (val) => {
            return getThemeColorForPaymentStatus((val as IPrAttendanceStatusEntity).Code as PrAttendanceStatusCode);
        }
    }];

    const workingPatternAdditionalProperties = [
        { id: PrWorkingPatternEntity.Name },
        { id: PrWorkingPatternEntity.Type },
        { id: PrWorkingPatternEntity.IsDifferentOddAndEvenWeek },
        {
            id: PrWorkingPatternEntity.Days,
            additionalProperties: [
                    { id: PrWorkingPatternDayEntity.WorkingHours },
                    { id: PrWorkingPatternDayEntity.Date }
            ]
        }
    ];

    const form: IFormDef = {
        id: `${EntityTypeName.PrAttendance}Form`,
        translationFiles: getDefinitions.translationFiles,
        getItemBreadCrumbText: (storage: Model) =>
                getItemBreadCrumbsText(storage, i18next.t("SalaryComponent:New"), i18next.t("SalaryComponent:FormTitle")),
        isDeletable: true,
        formControl: AttendanceFormView,
        summary,
        additionalProperties: [{
            id: PrAttendanceEntity.Month
        }, {
            id: PrAttendanceEntity.Year
        }, {
            id: PrAttendanceEntity.Employment,
            additionalProperties: [{
                id: PrEmploymentEntity.NumberOurs
            }, {
                id: PrEmploymentEntity.Template,
                additionalProperties: [{
                    id: PrEmploymentTemplateEntity.TemporalPropertyBag,
                    additionalProperties: [{
                        id: PrEmploymentTemplateTemporalEntity.DateValidFrom
                    }, {
                        id: PrEmploymentTemplateTemporalEntity.DateValidTo
                    }, {
                        id: PrEmploymentTemplateTemporalEntity.WorkingPattern,
                        additionalProperties: workingPatternAdditionalProperties
                    }]
                }]
            }, {
                id: PrEmploymentEntity.TemporalPropertyBag,
                additionalProperties: [{
                    id: PrEmploymentTemporalEntity.DateValidFrom
                }, {
                    id: PrEmploymentTemporalEntity.DateValidTo
                }, {
                    id: PrEmploymentTemporalEntity.WorkingPattern,
                    additionalProperties: workingPatternAdditionalProperties
                }]
            }]
        }, {
            id: PrAttendanceEntity.Days,
            additionalProperties: [{
                id: DayFundPath // local context, here just for info load
            }, {
                id: WorkedHoursPath // local context, here just for info load
            }, {
                id: SaldoPath // local context, here just for info load
            }, {
                id: HasAbsencePath // local context, here just for info load
            }, {
                id: IsFullDayAbsencePath // local context, here just for info load
            }, {
                id: PrAttendanceDayEntity.Date
            }, {
                id: PrAttendanceDayEntity.Intervals,
                additionalProperties: [{
                    id: timeDiffPath // local context, here just for info load
                }, {
                    id: PrAttendanceDayIntervalEntity.AbsenceCategory,
                    additionalProperties: [{
                        id: PrAbsenceCategoryEntity.Color
                    }, {
                        id: PrAbsenceCategoryEntity.CurrentTemporalPropertyBag,
                        additionalProperties: [{
                            id: PrAbsenceCategoryTemporalCurrentEntity.Name
                        }]
                    }]
                }, {
                    id: PrAttendanceDayIntervalEntity.TimeEnd
                }, {
                    id: PrAttendanceDayIntervalEntity.TimeStart
                }, {
                    id: PrAttendanceDayIntervalEntity.WorkType
                }, {
                    id: PrAttendanceDayIntervalEntity.Order
                }, {
                    id: PrAttendanceDayIntervalEntity.IsWholeDay
                }, {
                    id: PrAttendanceDayIntervalEntity.IsStartNextDay
                }, {
                    id: PrAttendanceDayIntervalEntity.IsEndNextDay
                }]
            }]
        }],
        fieldDefinition: {
            [viewSwitchPath]: {
                type: FieldType.SegmentedButton,
                defaultValue: AttendanceViewMode.Calendar,
                labelStatus: LabelStatus.Removed,
                fieldSettings: {
                    items: [{
                        id: AttendanceViewMode.Calendar,
                        iconName: "Date",
                        title: i18next.t("Attendance:Form.Calendar")
                    }, {
                        id: AttendanceViewMode.Table,
                        iconName: "List",
                        title: i18next.t("Attendance:Form.Table")
                    }]
                }
            },
            [BindingContext.localContext("Table")]: {
                type: FieldType.Custom,
                isVisible: (args: IGetValueArgs) => {
                    return args.storage.getValueByPath(viewSwitchPath) === AttendanceViewMode.Table;
                },
                render: (args: IFieldDefFn) => {
                    const storage = args.storage as FormStorage<IPrAttendanceEntity, IAttendanceCustomData>;
                    const saldoMap = storage.getCustomData()?.saldoMap ?? {};
                    const hoursFundMap = storage.getCustomData()?.hoursFundMap ?? {};

                    return <AttendanceDaysList
                            saldoMap={saldoMap}
                            hoursFundMap={hoursFundMap}
                            storage={storage}/>;
                },
                customizationData: {
                    useForCustomization: false
                }
            },
            [BindingContext.localContext("Calendar")]: {
                type: FieldType.Custom,
                isVisible: (args: IGetValueArgs) => {
                    return args.storage.getValueByPath(viewSwitchPath) !== AttendanceViewMode.Table;
                },
                render: (args: IFieldDefFn) => {
                    const storage = args.storage as FormStorage<IPrAttendanceEntity, IAttendanceCustomData>;
                    const entity = storage.data.entity;
                    const saldoMap = storage.getCustomData()?.saldoMap ?? {};
                    const actions = storage.getCustomData()?.actions ?? {};
                    const holidays = storage.getCustomData()?.holidays ?? [];
                    const month = getUtcDayjs().set("month", entity.Month - 1).set("year", entity.Year);
                    return <Calendar
                            month={month}
                            hoursSaldo={saldoMap}
                            actions={actions}
                            holidays={holidays}
                            onDaySelect={(days) => handleDaySelect(days, storage)}
                    />;
                },
                customizationData: {
                    useForCustomization: false
                }
            },
            [dayFastEntriesPath]: {
                type: FieldType.Custom,
                isVisible: (args: IGetValueArgs) => {
                    const storage = args.storage as FormStorage<IPrAttendanceEntity, IAttendanceCustomData>;
                    const viewType = storage.getValueByPath(viewSwitchPath);
                    return !!storage.getCustomData().selectedDays?.size && viewType !== AttendanceViewMode.Table;
                },
                render: (args: IFieldDefFn) => {
                    const storage = args.storage as FormStorage<IPrAttendanceEntity, IAttendanceCustomData>;
                    const selectedDayIndex = storage.getCustomData().selectedDays?.values().next()?.value;
                    return <AttendanceDayEditEntries onChange={args.events.onChange}
                                                     parentEtag={storage.data?.metadata?.etag}
                                                     storage={storage}
                                                     selectedDay={selectedDayIndex}/>;
                }
            },
            [createPath(PrAttendanceEntity.Days, PrAttendanceDayEntity.Intervals, PrAttendanceDayIntervalEntity.AbsenceCategory)]: {
                type: FieldType.ComboBox,
                cacheStrategy: CacheStrategy.Route,
                fieldSettings: {
                    displayName: "CurrentTemporalPropertyBag/Name",
                    preloadItems: true,
                    additionalProperties: [{ id: PrAbsenceCategoryEntity.Color }],
                    localDependentFields: [{
                        from: { id: PrAbsenceCategoryEntity.Color },
                        to: { id: PrAbsenceCategoryEntity.Color },
                        navigateFrom: NavigationSource.Itself
                    }]
                }
            },
            [createPath(PrAttendanceEntity.Days, PrAttendanceDayEntity.Intervals, PrAttendanceDayIntervalEntity.WorkType)]: {
                type: FieldType.ComboBox,
                cacheStrategy: CacheStrategy.EndOfTime,
                fieldSettings: {
                    displayName: "Name"
                },
                isDisabled: isAbsenceInWorkFEList,
                formatter: (code, args) => {
                    const entity = args.storage.getValue(args.bindingContext.getParent());
                    return entity?.AbsenceCategory?.CurrentTemporalPropertyBag?.Name ?? getEnumDisplayValue(EntityTypeName.PrWorkIntervalType, code as PrWorkIntervalTypeCode);
                },
                defaultValue: (args: IGetValueArgs) => {
                    const dayBc = args.bindingContext.getParentCollection().getParent();
                    const day = args.storage.getValue(dayBc) as IPrAttendanceDayEntity;
                    const lastInterval = day.Intervals?.at(-1);
                    return lastInterval?.WorkType?.Code === PrWorkIntervalTypeCode.Work ? PrWorkIntervalTypeCode.MealBreak : PrWorkIntervalTypeCode.Work;
                }
            },
            [createPath(PrAttendanceEntity.Days, PrAttendanceDayEntity.Intervals, PrAttendanceDayIntervalEntity.TimeStart)]: {
                type: FieldType.Time,
                width: BasicInputSizes.XS,
                isDisabled: isAbsenceInWorkFEList,
                fieldSettings: {
                    showSteppers: false
                }
            },
            [createPath(PrAttendanceEntity.Days, PrAttendanceDayEntity.Intervals, PrAttendanceDayIntervalEntity.TimeEnd)]: {
                type: FieldType.Time,
                width: BasicInputSizes.XS,
                isDisabled: isAbsenceInWorkFEList,
                fieldSettings: {
                    showSteppers: false
                }
            },
            [createPath(PrAttendanceEntity.Days, PrAttendanceDayEntity.Intervals, timeDiffPath)]: {
                isReadOnly: true,
                width: "50px",
                label: i18next.t("Attendance:Form.Length"),
                formatter: (val, args) => {
                    const intervalEntity = args.storage.getValue(args.bindingContext.getParent()) as IPrAttendanceDayIntervalEntity;
                    if (!intervalEntity) {
                        return "";
                    }
                    const diff = getHourDifference(getUtcDayjs(intervalEntity.TimeStart), getUtcDayjs(intervalEntity.TimeEnd));
                    if (isNotDefined(intervalEntity.TimeStart) || isNotDefined(intervalEntity.TimeEnd) || isNaN(diff)) {
                        return DASH_CHARACTER;
                    }
                    const dateDiff = getUtcDayjs().startOf("day").add(diff, "hours");
                    const formattedValue = DateType.format(dateDiff, longDateFormat(getDateFormat(DateType.defaultTimeFormat)));
                    return `${formattedValue} ${i18next.t("Components:Calendar.HourPlaceholder")}`;
                }
            },
            [createPath(PrAttendanceEntity.Days, DayFundPath)]: {
                label: i18next.t("Attendance:DaysList.Fund"),
                isReadOnly: true,
                formatter: (val, args) => {
                    const storage = args.storage as FormStorage<IPrAttendanceEntity, IAttendanceCustomData>;
                    const dayEntity = storage.getValue(args.bindingContext.getParent());
                    if (hasFullDayAbsence(dayEntity)) {
                        return DASH_CHARACTER;
                    }
                    const date = dayEntity[PrAttendanceDayEntity.Date];
                    const dayIndex = getUtcDayjs(date).get("date");
                    const fund = storage.getCustomData().hoursFundMap[dayIndex] ?? 0;
                    return formatHoursToTimeFormat(fund);
                }
            },
            [createPath(PrAttendanceEntity.Days, WorkedHoursPath)]: {
                type: FieldType.Time,
                width: BasicInputSizes.S,
                label: i18next.t("Attendance:DaysList.Worked"),
                isReadOnly: (args: IGetValueArgs): boolean => {
                    return hasFullDayAbsence(args.storage.getValue(args.bindingContext.getParent()));
                },
                formatter: (val, args) => {
                    const storage = args.storage as FormStorage<IPrAttendanceEntity, IAttendanceCustomData>;
                    const dayEntity = storage.getValue(args.bindingContext.getParent());
                    if (hasFullDayAbsence(dayEntity)) {
                        return DASH_CHARACTER;
                    }
                    return DateType.format(val as Date, longDateFormat(getDateFormat(DateType.defaultTimeFormat)));
                },
                fieldSettings: {
                    unit: i18next.t("Components:Calendar.HourPlaceholder")
                }
            },
            [createPath(PrAttendanceEntity.Days, SaldoPath)]: {
                label: i18next.t("Attendance:DaysList.Saldo"),
                isReadOnly: true,
                formatter: (val, args) => {
                    const storage = args.storage as FormStorage<IPrAttendanceEntity, IAttendanceCustomData>;
                    const dayEntity = storage.getValue(args.bindingContext.getParent());
                    if (hasFullDayAbsence(dayEntity)) {
                        return DASH_CHARACTER;
                    }
                    const date = dayEntity[PrAttendanceDayEntity.Date];
                    const dayIndex = getUtcDayjs(date).get("date");
                    const saldo = storage.getCustomData().saldoMap[dayIndex] ?? 0;

                    if (saldo === 0) {
                        return formatHoursToTimeFormat(0);
                    }

                    return (saldo < 0 ? "-" : "+") + formatHoursToTimeFormat(Math.abs(saldo));
                },
            },
            [createPath(PrAttendanceEntity.Days, HasAbsencePath)]: {
                type: FieldType.Switch,
                label: i18next.t("Attendance:Form.Absence"),
                fieldSettings: {
                    type: SwitchType.YesNo
                }
            },
            [createPath(PrAttendanceEntity.Days, IsFullDayAbsencePath)]: {
                type: FieldType.SegmentedButton,
                defaultValue: true,
                isVisible: (args) => {
                    return !!args.storage.getValue(args.bindingContext.getParent().navigate(HasAbsencePath));
                },
                fieldSettings: {
                    items: [{
                        id: false,
                        label: i18next.t("Attendance:AbsenceType.Partial")
                    }, {
                        id: true,
                        label: i18next.t("Attendance:AbsenceType.WholeDay")
                    }]
                }
            }
        },
        groups: [
            {
                id: "main",
                rows: [
                    [
                        { id: viewSwitchPath }
                    ],
                    [
                        { id: BindingContext.localContext("Calendar") },
                        { id: BindingContext.localContext("Table") }
                    ],
                    [
                        { id: dayFastEntriesPath }
                    ]
                ]
            }
        ]
    };
    return {
        entitySet: EntitySetName.PrAttendances,
        table,
        form
    };
};
getDefinitions.translationFiles = ["Attendance", "Common", "Components"];
setDefByEntityType(EntityTypeName.PrAttendance, getDefinitions);