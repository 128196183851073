import { WithConfirmationDialog, withConfirmationDialog } from "@components/dialog/withConfirmationDialog";
import { ISmartFieldBlur, ISmartFieldChange } from "@components/smart/smartField/SmartField";
import {
    CashReceiptEntity,
    EntitySetName,
    IBankTransactionEntity,
    ICashBoxEntity,
    ICashReceiptEntity,
    IDocumentEntity,
    INumberRangeEntity
} from "@odata/GeneratedEntityTypes";
import { CompanyPermissionCode, DocumentTypeCode } from "@odata/GeneratedEnums";
import { BatchRequest } from "@odata/OData";
import { isCashBasisAccountingCompany } from "@utils/CompanyUtils";
import { ICopyEntityArgs } from "@utils/DraftUtils";
import { isNotDefined, isObjectEmpty } from "@utils/general";
import { checkPermissionWithOwnPermission } from "@utils/permissionUtils";
import { cloneDeep } from "lodash";
import React, { ReactElement } from "react";

import BusyIndicator from "../../../components/busyIndicator";
import { Button } from "../../../components/button";
import { AppContext, ContextEvents } from "../../../contexts/appContext/AppContext.types";
import { EmptyObject } from "../../../global.types";
import BindingContext, { IEntity } from "../../../odata/BindingContext";
import DraftFormView from "../../../views/formView/DraftFormView";
import { FormStorage, IFormStorageSaveResult, ISaveArgs } from "../../../views/formView/FormStorage";
import { IFormViewProps } from "../../../views/formView/FormView";
import {
    correctAccountIdAfterChange,
    correctAccountIds,
    handleAccAssignmentChange,
    handleAccAssignmentChildrenChange,
    IAccAssDialogCustomData,
    invalidateAccountAssignments,
    sendAdditionalRequestForAccountAssignment,
    setCorrectSpecialItemsForAccountAssignment
} from "../../accountAssignment/AccountAssignment.utils";
import { AccountAssignmentDialog } from "../../accountAssignment/AccountAssignmentDialog";
import {
    getCashTransactionType,
    getTranBalance,
    handleBeforeSave,
    handleLineItemsChange,
    IBankCustomData,
    refreshExRateAndRefreshItems,
    refreshFormAfterDateChange,
    renderAssignmentSplitDialog,
    renderPairingDialog
} from "../../banks/bankTransactions/BankTransactions.utils";
import { addExpenseGainIfMissing, correctGainAccountAssignment, handleItemAmountBlur } from "../../banks/Pair.utils";
import { createPaymentEntityCopy } from "../../banks/Payments.utils";
import {
    handleCustomCbaCategoryChange,
    loadCategoriesMap,
    setCorrectSpecialItemsForItemCategory
} from "../../cashBasisAccounting/CashBasisAccounting.utils";
import CustomCategoryTemporalDialog from "../../cashBasisAccounting/CustomCategoryTemporalDialog";
import { IDocumentFormViewProps } from "../../documents/DocumentInterfaces";
import { setFiscalYearForNumberRangeWithoutFiscalYear } from "../../documents/proformaInvoices/ProformaInvoice.utils";
import {
    canChangeNumberRange,
    getFiscalDataForNumberRange,
    getRangeByDefinitionId,
    loadAndStoreRanges,
    replaceWildcards
} from "../../numberRange/NumberRange.utils";
import { getDefaultCashBox } from "./CashReceipts.utils";
import { isPairFastEntryDisabled } from "./CashReceiptsDef";
import CashReceiptsPairTableView from "./CashReceiptsPairTableView";

interface IProps extends IFormViewProps<ICashReceiptEntity, IBankCustomData>, WithConfirmationDialog {
}

class CashReceiptsFormView extends DraftFormView<ICashReceiptEntity, IProps> {
    static contextType = AppContext;

    documentTypeCode: DocumentTypeCode;

    get _documentItemDomainType(): string {
        return "PaymentDocumentItem";
    }

    componentDidMount(): void {
        super.componentDidMount();
        this.registerHeader();
    }

    componentDidUpdate(prevProps: Readonly<IDocumentFormViewProps>, prevState: Readonly<EmptyObject>): void {
        super.componentDidUpdate(prevProps, prevState);
        this.registerHeader();
    }

    getAdditionalLoadPromise = (): Promise<boolean | void>[] => {
        const promises: Promise<boolean | void>[] = [
            loadAndStoreRanges(this.props.storage)
        ];
        return promises;
    };

    getDefaultCashBox = (): ICashBoxEntity => {
        const accounts = this.props.storage.context.getCashBoxes(true);
        const entity = this.props.storage.data as IDocumentEntity;
        return getDefaultCashBox(accounts, entity);
    };


    setDefaultCashBox = (setRange: boolean): ICashBoxEntity => {
        const storage = this.props.storage;
        const defaultAccount = this.getDefaultCashBox();
        if (defaultAccount) {
            if (setRange) {
                this.changeNumberRangeBaseByCashBox(defaultAccount, true);
            }
            storage.setValueByPath("TransactionCurrency/Code", defaultAccount.TransactionCurrencyCode);
            storage.setValueByPath("TransactionCurrency/MinorUnit", defaultAccount.TransactionCurrency?.MinorUnit);
            storage.setValueByPath("CashBox/BalanceSheetAccountNumberSuffix", defaultAccount.BalanceSheetAccountNumberSuffix);

            refreshExRateAndRefreshItems(this.props.storage, defaultAccount.TransactionCurrencyCode, this.props.storage.data.entity.DateIssued);
        }

        return defaultAccount;
    };

    setupCashBoxStuff = async (): Promise<void> => {
        const { storage } = this.props;
        const { entity } = storage.data;
        const isNew = storage.data.bindingContext.isNew();

        if (isNew) {
            await loadAndStoreRanges(storage, true);
        }

        if (this.isDraftView()) {
            let defaultAccount = entity.CashBox;
            if (!entity.CashBox?.Id) {
                defaultAccount = this.setDefaultCashBox(false);
                storage.setValueByPath("CashBox", defaultAccount?.Id);
            }

            if (isNotDefined(entity.NumberOurs)) {
                let range = entity.NumberRange;
                if (isObjectEmpty(range)) {
                    range = this.getRangeFromCashBox(defaultAccount);
                }

                this.replaceWildcardsAndSetRange(range);
            }
        } else if (isNew) {
            if (storage.getCustomData()?.isCopy) {
                this.changeNumberRangeBaseByCashBox(this.entity.CashBox, true);
            } else if (!entity.CashBox?.Id || !entity.NumberOurs) {
                this.setDefaultCashBox(true);
            }
        }
    };

    onAfterLoad = async (): Promise<void> => {
        const { storage } = this.props;


        storage.setCustomData({ type: this.documentTypeCode });

        setFiscalYearForNumberRangeWithoutFiscalYear(storage, storage.data.entity.DateIssued);
        setCorrectSpecialItemsForAccountAssignment(storage, CashReceiptEntity.Items);
        await Promise.all([setCorrectSpecialItemsForItemCategory(storage), correctAccountIds(storage, CashReceiptEntity.Items)]);

        await this.setupCashBoxStuff();

        if (isCashBasisAccountingCompany(this.context)) {
            loadCategoriesMap(storage);
        }
        return super.onAfterLoad();
    };

    registerHeader = (): void => {
        if (this.props.storage.data.bindingContext) {
            const numberOursBc = this.props.storage.data.bindingContext.navigate(CashReceiptEntity.NumberOurs);
            this.props.storage.addCustomRef(this._refHeader.current, numberOursBc);
        }
    };

    handleAmountBlur = (args: ISmartFieldBlur): void => {
        if (args.bindingContext.getPath() === CashReceiptEntity.TransactionAmount) {
            this.props.storage.addActiveField(this.props.storage.data.bindingContext.navigate(CashReceiptEntity.Items));
        }
    };

    handleBlur = async (args: ISmartFieldBlur): Promise<void> => {
        if (args.wasChanged) {
            handleItemAmountBlur(this.props.storage, args);
        }

        await this.props.storage.handleBlur(args);

        if (args.wasChanged) {
            this.handleAmountBlur(args);
        }

        this.props.storage.refreshFields();
    };

    canPostDocument = (): boolean => {
        return checkPermissionWithOwnPermission(this.props.storage, this.props.permissionContext, CompanyPermissionCode.CashBox);
    };

    shouldDisableSaveButton = (): boolean => {
        return !this.canPostDocument() || this.props.storage.data.entity[CashReceiptEntity.Locks]?.length > 0 || this.props.storage.isDisabled;
    };

    handleSave = (): void => {
        this.save();
    };

    handlePost = (): void => {
        this.save({
            queryParams: {
                postInvoice: true
            }
        });
    };

    handleSaveAndPost = (): void => {
        this.save({
            queryParams: {
                postInvoice: true,
                clearInvoice: true
            }
        });
    };

    handlePreSave = (): void => {
        const balance = getTranBalance(this.props.storage);
        const hasItems = this.props.storage.data.entity.Items?.length > 0;
        const hadItems = this.props.storage.data.origEntity?.Items?.length > 0;
        if (hasItems || hadItems) {
            if (balance === 0) {
                this.handleSaveAndPost();
            } else {
                this.handlePost();
            }
        } else {
            this.handleSave();
        }
    };

    renderSaveButtons = (): ReactElement => {
        return <Button
            isDisabled={this.props.storage.isDisabled}
            onClick={this.handlePreSave}>
            {this.props.storage.t("Common:General.Save")}
        </Button>;
    };

    replaceWildcardsAndSetRange = (range: INumberRangeEntity): void => {
        const numberOurs = replaceWildcards(range.NextNumber, this.props.storage.data.entity);
        this.props.storage.setValueByPath(CashReceiptEntity.NumberRange, range, true);
        this.props.storage.setValueByPath(CashReceiptEntity.NumberOurs, numberOurs, true);
    };

    getRangeFromCashBox = (record: IEntity): INumberRangeEntity => {
        const fieldName = this.documentTypeCode === DocumentTypeCode.CashReceiptIssued ? "ReceiptIssuedNumberRangeDefinition" : "ReceiptReceivedNumberRangeDefinition";
        if (record?.[fieldName]) {
            return getRangeByDefinitionId(this.props.storage, record[fieldName]?.Id, getFiscalDataForNumberRange(this.props.storage.getEntity())?.FiscalYear?.Id);
        }

        return null;
    };

    changeNumberRangeBaseByCashBox = (record: IEntity, force: boolean): void => {
        const range = this.getRangeFromCashBox(record);
        // we don't change number ours for custom range
        if (range && (force || canChangeNumberRange(this.props.storage))) {
            this.replaceWildcardsAndSetRange(range);
        }
    };

    handleCashBoxChange = (e: ISmartFieldChange): void => {
        if (e.bindingContext.getPath() === CashReceiptEntity.CashBox && e.triggerAdditionalTasks) {
            refreshExRateAndRefreshItems(this.props.storage, e.additionalData?.TransactionCurrencyCode, this.props.storage.data.entity.DateIssued);

            this.changeNumberRangeBaseByCashBox(e.additionalData, false);
        }
    };

    handleDateIssuedChange = async (args: ISmartFieldChange): Promise<void> => {
        if (args.bindingContext.getPath() === CashReceiptEntity.DateIssued) {

            const currency = this.props.storage.data.entity.TransactionCurrency?.Code;
            refreshExRateAndRefreshItems(this.props.storage, currency, args.value as Date);

            if (!this.props.storage.getError(args.bindingContext)) {
                const newDate = args.value as Date;
                // todo sometimes methods use Date to get fiscal year, sometimes they use FiscalYear/FiscalYearForNumberRangePath
                // that they expect in the entity
                // => very confusing, should be all changed just to use Dates that will be passed as parameter
                setFiscalYearForNumberRangeWithoutFiscalYear(this.props.storage, newDate);
                await refreshFormAfterDateChange(this.props.storage, args.bindingContext, newDate);
            }
        }
    };

    handleFieldsForPair = (e: ISmartFieldChange): void => {
        const path = e.bindingContext.getPath();
        if ((path === CashReceiptEntity.DateIssued && (e.triggerAdditionalTasks || !e.value)) ||
            (path === CashReceiptEntity.CashBox && e.triggerAdditionalTasks) || (path === CashReceiptEntity.TransactionAmount) || (path === CashReceiptEntity.ExchangeRatePerUnit)) {
            this.props.storage.addActiveField(this.props.storage.data.bindingContext.navigate(CashReceiptEntity.Items));
        }
    };

    handleNumberRange = (e: ISmartFieldChange): void => {
        if (e.bindingContext.getPath() === CashReceiptEntity.NumberRange) {
            this.props.storage.data.entity.NumberRange = e.value ? e.additionalData : null;
        }
    };

    handleChange = (e: ISmartFieldChange): void => {
        const { storage } = this.props;
        const wasPairDisabled = isPairFastEntryDisabled(storage);

        this.handleCashBoxChange(e);
        this.handleDateIssuedChange(e);

        handleAccAssignmentChildrenChange(storage, e);
        handleAccAssignmentChange({
            storage,
            event: e,
            confirmationDialog: this.props.confirmationDialog,
            documentTypeCode: DocumentTypeCode.BankTransaction,
            hasVat: true
        });

        storage.handleChange(e);

        this.handleNumberRange(e);

        // must be called after change
        correctAccountIdAfterChange(storage, e);

        const isPairDisabled = isPairFastEntryDisabled(storage);
        if (wasPairDisabled !== isPairDisabled) {
            this.handleFieldsForPair(e);
        }

        this.saveDraft();
        storage.refreshFields(e.triggerAdditionalTasks);
    };

    onBeforeSave = (): IEntity => {
        const entity = cloneDeep(this.props.storage.data.entity);
        handleBeforeSave(this.props.storage, entity);

        return entity;
    };

    confirmAssignment = (): void => {
        const storage: FormStorage<unknown, IAccAssDialogCustomData> = this.props.storage;
        const bc = storage.getCustomData().AccountAssignmentDialogBc;
        const item = storage.getValue(bc.getParent().getParent());

        correctGainAccountAssignment(storage, item);
        addExpenseGainIfMissing(storage, item);
    };


    handleLineItemsChange = async (args: ISmartFieldChange): Promise<void> => {
        // if you change something just for this method, keep in mind changes in Bank Transaction !!
        handleLineItemsChange(this.props.storage, args);

        this.saveDraft();
        this.props.storage.refreshFields();
    };

    getCorrectRowBc = (): BindingContext => {
        const { bindingContext } = this.props.storage.data;
        return this.isDraftView() ? this.getDraftBc() : bindingContext.createNewBindingContext(EntitySetName.CashReceipts).addKey(bindingContext.getKey(), bindingContext.isNew());
    };

    save = async (args: ISaveArgs = {}): Promise<IFormStorageSaveResult> => {
        this.saveDraftDebounced.cancel();
        const data = this.onBeforeSave();
        this.props.onBeforeSave?.();

        const isNew = this.props.storage.data.bindingContext.isNew();

        const result = await this.props.storage.save({
            ...args,
            data,
            successSubtitle: this.props.storage.t(`${this.props.storage.data.definition?.translationFiles?.[0]}:Validation.Saved`),
            onBeforeExecute: async (batch: BatchRequest) => {
                await sendAdditionalRequestForAccountAssignment(this.props.storage, data, batch, this.documentTypeCode, "Items");
            }
        });

        if (!result) {
            this.props.onSaveFail?.();
            this.forceUpdate(this.scrollPageUp);
            return result;
        }

        if (isNew && !!data.PaymentDocumentDraft) {
            this.context.eventEmitter.emit(ContextEvents.RecalculateDrafts);
        }

        // we need to remove all cached items in linked pair account assignments
        invalidateAccountAssignments(this.props.storage, "Items");

        this.onAfterSave?.(isNew, false);
        this.forceUpdate(isNew ? this.scrollPageDown : undefined);

        if (args?.queryParams?.postInvoice) {
            await this.context.updateCashBoxes();
            this.props.storage.refresh(true);
        }


        return result;
    };

    handleCustomCbaCategoryChange = (e: ISmartFieldChange): void => {
        handleCustomCbaCategoryChange(e, this.props.storage);
    };

    handleCloseCbaCategoryDialog = (): void => {
        this.props.storage.setCustomData({
            isCbaCategoryDialogOpen: false
        });
        this.forceUpdate();
    };

    createEntityCopy = (args: ICopyEntityArgs = {}): ICashReceiptEntity => {
        const ALLOWED_NAVIGATIONS = [
            "Attachments", "CashBox", "Company", "Currency", "Items", "NumberRange", "PaymentMethod", "TransactionCurrency"
        ];

        if (args.includeAttachments) {
            ALLOWED_NAVIGATIONS.push("Attachments");
        }

        return createPaymentEntityCopy<ICashReceiptEntity>(this.props.storage, args, ALLOWED_NAVIGATIONS);
    };

    prepareDataForSave = async (entity: IBankTransactionEntity, isDraft: boolean): Promise<IBankTransactionEntity> => {
        return this.onBeforeSave();
    };

    render() {
        if (!this.isReady()) {
            return <BusyIndicator isDelayed/>;
        }

        return (
            <>
                {this.renderForm()}
                <AccountAssignmentDialog storage={this.props.storage}
                                         onChange={this.handleChange}
                                         onConfirm={this.confirmAssignment}
                                         onTemporalChange={this.handleTemporalChange}/>
                <CustomCategoryTemporalDialog storage={this.props.storage}
                                              onTemporalChange={this.handleCustomCbaCategoryChange}
                                              onClose={this.handleCloseCbaCategoryDialog}/>
                {renderAssignmentSplitDialog(this.props.storage)}
                {renderPairingDialog(this.props.storage, {
                    type: getCashTransactionType(this.props.storage),
                    view: CashReceiptsPairTableView,
                    saveDraftFn: this.saveDraft.bind(this)
                })}
            </>
        );
    }
}

export default withConfirmationDialog(CashReceiptsFormView);
export { CashReceiptsFormView as CashReceiptsFormViewForExtend };