import Clickable from "@components/clickable/Clickable";
import { ConnectionStatus } from "@components/connectionDialog/ConnectionDialog";
import { DownloadIcon } from "@components/icon";
import { ifAll, IFieldDefFn, IGetValueArgs, isVisibleByPath } from "@components/smart/FieldInfo";
import BindingContext, { createPath } from "@odata/BindingContext";
import {
    BankApiConnectionSettingEntity,
    BankApiStatementImportSettingEntity,
    CompanyBankAccountEntity,
    EntityTypeName
} from "@odata/GeneratedEntityTypes";
import {
    BankApiStatementImportFrequencyCode,
    BankApiStatementImportRangeCode,
    BankCode,
    FiscalYearStatusCode
} from "@odata/GeneratedEnums";
import { getEnumNameSpaceName, getEnumSelectItems } from "@odata/GeneratedEnums.utils";
import { isBankApiConnected } from "@pages/banks/bankAccounts/Api.utils";
import { BankKeyLinkWrapper, StyledAPISettingsDescText } from "@pages/banks/bankAccounts/BankAccounts.styles";
import i18next from "i18next";
import React from "react";
import { ValidationError } from "yup";

import { REST_API_URL } from "../../../../constants";
import { addCompanyIdToUrl } from "../../../../contexts/appContext/AppContext.utils";
import { BasicInputSizes, FieldType, IconSize, ValidatorType } from "../../../../enums";
import { IFormDef } from "../../../../views/formView/Form";
import { FormStorage, IFormStorageDefaultCustomData } from "../../../../views/formView/FormStorage";
import { getActiveFiscalYears, getFiscalYearByDate } from "../../../fiscalYear/FiscalYear.utils";
import { IDefinition, IGetDefinition } from "../../../PageUtils";
import { hasRelatedEntity } from "../BankAccounts.utils";

export interface ICompanyBankAccountApiSettingsCustomData extends IFormStorageDefaultCustomData {
    connectionStatus?: ConnectionStatus;
}

const hasSettings = (args: IGetValueArgs) => {
    return [ConnectionStatus.WaitingForConfiguration, ConnectionStatus.Connected].includes((args.storage.getCustomData() as ICompanyBankAccountApiSettingsCustomData).connectionStatus);
};

export const API_PASSWORD_PATH = BindingContext.localContext("apiPassword");
export const CERTIFICATE_PATH = BindingContext.localContext("certificate");
export const BANK_KEY_DOWNLOAD_PATH = BindingContext.localContext("bankKeyDownLoadPath");

export const getDefinition: IGetDefinition = (): IDefinition => {
    const getSettingsPath = (path: string) => {
        return createPath(CompanyBankAccountEntity.BankApiStatementImportSetting, path);
    };

    const isWithoutRelatedEntity = (args: IGetValueArgs) => {
        return !hasRelatedEntity(args);
    };

    const form: IFormDef = {
        id: "CompanyBankAccountSettingsSpecialForm",
        translationFiles: getDefinition.translationFiles,
        additionalProperties: [
            { id: createPath(CompanyBankAccountEntity.BankApiConnectionSetting, BankApiConnectionSettingEntity.ApiKeyId) },
            { id: createPath(CompanyBankAccountEntity.BankApiConnectionSetting, BankApiConnectionSettingEntity.DateExpirationApiKey) },
            { id: CompanyBankAccountEntity.BankApiStatementImportSetting }
        ],
        fieldDefinition: {
            [createPath(CompanyBankAccountEntity.BankApiConnectionSetting, BankApiConnectionSettingEntity.ApiKeySetter)]: {
                type: FieldType.Password,
                label: i18next.t("Banks:Accounts.Token"),
                width: BasicInputSizes.XL,
                isRequired: true,
                isDisabled: args => {
                    return [ConnectionStatus.WaitingForConfiguration, ConnectionStatus.Connected].includes((args.storage.getCustomData() as ICompanyBankAccountApiSettingsCustomData).connectionStatus);
                },
                isVisible: args => {
                    return args.storage.data.entity.BankCode === BankCode.FioBanka_A_s_;
                },
                fieldSettings: {
                    placeholder: i18next.t("Banks:Accounts.AddTokenPlaceholder")
                },
                additionalProperties: [{
                    id: `/${CompanyBankAccountEntity.BankCode}`
                }]
            },
            [BANK_KEY_DOWNLOAD_PATH]: {
                type: FieldType.Custom,
                isVisible: args => {
                    return args.storage.data.entity.BankCode === BankCode.ČeskoslovenskáObchodníBanka_A_S_ && !hasSettings(args);
                },
                render: (args: IFieldDefFn): React.ReactElement => {
                    const url = addCompanyIdToUrl(`${REST_API_URL}/CompanyBankAccountApi/GetCertificateRequest/${args.storage.data.entity.Id}`, args.storage.context);
                    return <BankKeyLinkWrapper>
                        <Clickable link={url} isLink>
                            {i18next.t("Banks:Accounts.KeyForBank")}
                            <DownloadIcon width={IconSize.S} height={IconSize.S}/>
                        </Clickable>
                    </BankKeyLinkWrapper>;
                }
            },
            [API_PASSWORD_PATH]: {
                type: FieldType.Password,
                isRequired: true,
                label: i18next.t("Banks:Accounts.Password"),
                width: BasicInputSizes.XL,
                isDisabled: args => {
                    return [ConnectionStatus.WaitingForConfiguration, ConnectionStatus.Connected].includes((args.storage.getCustomData() as ICompanyBankAccountApiSettingsCustomData).connectionStatus);
                },
                isVisible: args => {
                    return args.storage.data.entity.BankCode === BankCode.RaiffeisenbankA_s_;
                },
                additionalProperties: [{
                    id: `/${CompanyBankAccountEntity.BankCode}`
                }, {
                    id: createPath(CompanyBankAccountEntity.BankApiConnectionSetting, BankApiConnectionSettingEntity.ApiKeySetter)
                }]
            },
            [CERTIFICATE_PATH]: {
                type: FieldType.FileInput,
                isRequired: true,
                label: i18next.t("Banks:Accounts.Certificate"),
                width: BasicInputSizes.XL,
                isVisible: args => {
                    const entity = args.storage.data.entity;
                    return [BankCode.RaiffeisenbankA_s_, BankCode.ČeskoslovenskáObchodníBanka_A_S_].includes(entity.BankCode) && !isBankApiConnected(args.storage.data.entity);
                },
                additionalProperties: [{
                    id: `/${CompanyBankAccountEntity.BankCode}`
                }, {
                    id: createPath(CompanyBankAccountEntity.BankApiConnectionSetting, BankApiConnectionSettingEntity.ApiKeyId)
                }]
            },
            [createPath(CompanyBankAccountEntity.BankApiConnectionSetting, BankApiConnectionSettingEntity.ContractNumber)]: {
                label: i18next.t("Banks:Accounts.ContractNumber"),
                isRequired: true,
                width: BasicInputSizes.XL,
                isDisabled: hasSettings,
                isVisible: args => {
                    return args.storage.data.entity.BankCode === BankCode.ČeskoslovenskáObchodníBanka_A_S_;
                },
                additionalProperties: [{
                    id: `/${CompanyBankAccountEntity.BankCode}`
                }]
            },
            [getSettingsPath(BankApiStatementImportSettingEntity.BankApiStatementImportFrequency)]: {
                type: FieldType.ComboBox,
                width: BasicInputSizes.XL,
                isRequired: true,
                isVisible: hasSettings,
                defaultValue: BankApiStatementImportFrequencyCode.Monthly,
                fieldSettings: {
                    displayName: "Name",
                    items: getEnumSelectItems(EntityTypeName.BankApiStatementImportFrequency)
                },
                render: (args: IFieldDefFn): React.ReactElement => {
                    const storage = args.storage as FormStorage;
                    const tKey = isBankApiConnected(storage.data.origEntity) ? "BankApiStatementImportFrequencyConnectedDesc" : "BankApiStatementImportFrequencyDesc";
                    return <>
                        <StyledAPISettingsDescText>{i18next.t(`Banks:Accounts.${tKey}`)}</StyledAPISettingsDescText>
                        {args.fieldElement}
                    </>;
                }
            },
            [getSettingsPath(BankApiStatementImportSettingEntity.BankApiStatementImportRange)]: {
                type: FieldType.ComboBox,
                width: BasicInputSizes.XL,
                isVisible: ifAll(isWithoutRelatedEntity, hasSettings),
                clearIfInvisible: false,
                isRequired: true,
                defaultValue: BankApiStatementImportRangeCode.CurrentActiveFiscalYear,
                fieldSettings: {
                    displayName: "Name",
                    items: getEnumSelectItems(EntityTypeName.BankApiStatementImportRange)
                },
                render: (args: IFieldDefFn): React.ReactElement => {
                    const storage = args.storage as FormStorage;
                    const tKey = isBankApiConnected(storage.data.origEntity) ? "BankApiStatementImportRangeConnectedDesc" : "BankApiStatementImportRangeDesc";
                    return <>
                        <StyledAPISettingsDescText>{i18next.t(`Banks:Accounts.${tKey}`)}</StyledAPISettingsDescText>
                        {args.fieldElement}
                    </>;
                }
            },
            [getSettingsPath(BankApiStatementImportSettingEntity.DateFrom)]: {
                type: FieldType.Date,
                isRequired: true,
                clearIfInvisible: false,
                width: BasicInputSizes.M,
                validator: {
                    type: ValidatorType.Date,
                    settings: {
                        customValidator: (value, args) => {
                            const { storage } = args;
                            if (isVisibleByPath(storage as FormStorage, getSettingsPath(BankApiStatementImportSettingEntity.DateFrom))) {
                                const fiscalYear = getFiscalYearByDate(args.storage.context, value as Date);
                                if (fiscalYear?.StatusCode !== FiscalYearStatusCode.Active) {
                                    return new ValidationError(i18next.t("Banks:Validation.ActiveFY"), value, args.bindingContext.getNavigationPath(true));
                                }
                            }
                            return true;
                        }
                    }
                },
                fieldSettings: {
                    minDate: (args: IGetValueArgs) => {
                        const fiscalYears = getActiveFiscalYears(args.storage.context);
                        if (!fiscalYears?.length) {
                            return null;
                        }
                        return fiscalYears[0].DateStart;
                    },
                    maxDate: (args: IGetValueArgs) => {
                        const fiscalYears = getActiveFiscalYears(args.storage.context);
                        if (!fiscalYears?.length) {
                            return null;
                        }
                        return fiscalYears[fiscalYears.length - 1].DateEnd;
                    }
                },
                isVisible: args => {
                    const range = args.storage.getValueByPath(getSettingsPath(BankApiStatementImportSettingEntity.BankApiStatementImportRange));
                    return range?.Code === BankApiStatementImportRangeCode.FromDate && isWithoutRelatedEntity(args) && hasSettings(args);
                }
            }
        },
        groups: [
            {
                id: "Token",
                showGroupDivider: false,
                rows: [
                    [
                        { id: BANK_KEY_DOWNLOAD_PATH },
                        { id: CERTIFICATE_PATH },
                        { id: createPath(CompanyBankAccountEntity.BankApiConnectionSetting, BankApiConnectionSettingEntity.ApiKeySetter) },
                        { id: createPath(CompanyBankAccountEntity.BankApiConnectionSetting, BankApiConnectionSettingEntity.ContractNumber) },
                        { id: API_PASSWORD_PATH }
                    ],
                    [{ id: getSettingsPath(BankApiStatementImportSettingEntity.BankApiStatementImportFrequency) }],
                    [{ id: getSettingsPath(BankApiStatementImportSettingEntity.BankApiStatementImportRange) }, { id: getSettingsPath(BankApiStatementImportSettingEntity.DateFrom) }]
                ]
            }
        ]
    };

    return {
        form
    };
};

getDefinition.translationFiles = [
    "Banks",
    getEnumNameSpaceName(EntityTypeName.BankApiStatementImportFrequency),
    getEnumNameSpaceName(EntityTypeName.BankApiStatementImportRange)
];
