import { ISelectItem } from "@components/inputs/select/Select.types";
import { ifAll, IGetValueArgs } from "@components/smart/FieldInfo";
import { fourDigitsFormatter, withDisplayName } from "@components/smart/GeneralFieldDefinition";
import {
    FilterBarGroup,
    getDefaultFilterGroupDef,
    IFilterGroupDef
} from "@components/smart/smartFilterBar/SmartFilterBar.types";
import { ISummaryItem } from "@components/smart/smartSummaryItem/SmartSummaryItem";
import {
    CompanyFixedExchangeRateEntity,
    CurrencyUsedByCompanyEntity,
    EntitySetName,
    EntityTypeName,
    FiscalPeriodEntity,
    FiscalYearEntity,
    ICompanyFixedExchangeRateEntity,
    ICurrencyUsedByCompanyEntity
} from "@odata/GeneratedEntityTypes";
import { BankExchangeRateFreshnessCode, ExchangeRateTypeCode, FiscalYearStatusCode } from "@odata/GeneratedEnums";
import { IFormatOptions } from "@odata/OData.utils";
import { getCompanyCurrency, isCashBasisAccountingCompany } from "@utils/CompanyUtils";
import { isObjectEmpty } from "@utils/general";
import i18next from "i18next";
import { capitalize } from "lodash";

import { DASH_CHARACTER } from "../../constants";
import {
    BasicInputSizes,
    CacheStrategy,
    FieldType,
    RadioButtonGroupLayout,
    Sort,
    TextAlign,
    ValidatorType,
    ValueType
} from "../../enums";
import { TValue } from "../../global.types";
import { Model } from "../../model/Model";
import BindingContext, { createPath } from "../../odata/BindingContext";
import DateType, { DateFormat, getUtcDayjs } from "../../types/Date";
import NumberType from "../../types/Number";
import memoizeOne from "../../utils/memoizeOne";
import { IFormDef } from "../../views/formView/Form";
import { ISplitPageTableDef } from "../../views/table/TableView.utils";
import { setDefByEntityType } from "../getDefByEntityType";
import { getItemBreadCrumbsText, IDefinition, IGetDefinition } from "../PageUtils";
import CurrencyUsedByCompanyFormView, { ICurrencyUsedByCompanyFormCustomData } from "./CurrencyUsedByCompanyFormView";

export const CURRENT_CNB_RATE_PATH = BindingContext.localContext("CurrentCNBRate");
export const FISCAL_YEAR_PATH = BindingContext.localContext("FiscalYear");


export function getClosingRatePath(FYId: number): string {
    return BindingContext.localContext(`ClosingRate-${FYId}`);
}

export function getPeriodPath(periodId: number): string {
    return BindingContext.localContext(`Period-${periodId}`);
}

const isFixedRate = (args: IGetValueArgs<ICurrencyUsedByCompanyEntity>): boolean => {
    return args.storage.data.entity.ExchangeRateType?.Code === ExchangeRateTypeCode.Fixed;
};

const isBankRate = (args: IGetValueArgs<ICurrencyUsedByCompanyEntity>): boolean => {
    return args.storage.data.entity.ExchangeRateType?.Code === ExchangeRateTypeCode.ByBank;
};

const getAmountItems = memoizeOne(() => {
    const items: ISelectItem[] = [];
    for (let i = 1; i <= 1000000; i *= 10) {
        items.push({
            id: i,
            label: NumberType.format(i)
        });
    }
    return items;
});

export const getDefinitions: IGetDefinition = (context): IDefinition => {
    const filterBarDef: IFilterGroupDef[] = [{
        ...getDefaultFilterGroupDef(FilterBarGroup.Filters),
        createQuery: false,
        allowCustomFilters: false,
        defaultFilters: [
            CurrencyUsedByCompanyEntity.ExchangeRateType,
            CurrencyUsedByCompanyEntity.Country,
            CurrencyUsedByCompanyEntity.FromCurrency,
            CurrencyUsedByCompanyEntity.FromCurrencyCode,
            CurrencyUsedByCompanyEntity.Amount,
            CurrencyUsedByCompanyEntity.ExchangeRateDirect
        ],
        filterDefinition: {
            ...withDisplayName("ExchangeRateType"),
            ...withDisplayName("Country"),
            ...withDisplayName("FromCurrency"),
            "FromCurrencyCode": {},
            "Amount": {},
            "ExchangeRateDirect": {}
        },
        isValueHelp: true
    }];

    const table: ISplitPageTableDef = {
        id: `${EntityTypeName.CurrencyUsedByCompany}Table`,
        initialSortBy: [{
            id: "Country",
            sort: Sort.Asc
        }],
        columns: [
            CurrencyUsedByCompanyEntity.ExchangeRateType,
            CurrencyUsedByCompanyEntity.Country,
            CurrencyUsedByCompanyEntity.FromCurrency,
            CurrencyUsedByCompanyEntity.FromCurrencyCode,
            CurrencyUsedByCompanyEntity.Amount,
            CurrencyUsedByCompanyEntity.ExchangeRateDirect
        ],
        columnDefinition: {
            ...withDisplayName("ExchangeRateType"),
            ...withDisplayName("Country"),
            ...withDisplayName("FromCurrency"),
            FromCurrencyCode: {},
            Amount: {},
            ExchangeRateDirect: {
                formatter: fourDigitsFormatter
            }
        },
        tabs: [],
        title: i18next.t("ExchangeRate:SettingsForm.TableTitle"),
        filterBarDef
    };

    const auditTrailSummary: ISummaryItem[] = [];

    const summary: ISummaryItem[] = [{
        id: "FromCurrency",
        additionalProperties: [{ id: "Name" }],
        formatter: (val: TValue, args: IFormatOptions) => {
            const currency = args.entity.FromCurrency;
            if (currency) {
                return `${currency.Code} ${DASH_CHARACTER} ${currency.Name}`;
            }
            return val;
        }
    }, {
        id: CURRENT_CNB_RATE_PATH,
        label: i18next.t("ExchangeRate:FixedRateForm.TodayRate"),
        textAlign: TextAlign.Right,
        formatter: (val: TValue, args: IFormatOptions) => {
            const storage = args.storage;
            let rate: number = null;
            if (storage.getValueByPath(CurrencyUsedByCompanyEntity.ExchangeRateTypeCode) === ExchangeRateTypeCode.ByBank) {
                rate = args.storage.getValueByPath(CURRENT_CNB_RATE_PATH);
            } else {
                const today = getUtcDayjs();
                const currentFixedRate = storage.getValueByPath(CurrencyUsedByCompanyEntity.FixedExchangeRates)?.find((r: ICompanyFixedExchangeRateEntity) => {
                    return !!r.FiscalPeriod && today.isBetween(r.FiscalPeriod.DateStart, r.FiscalPeriod.DateEnd);
                });
                rate = !isObjectEmpty(currentFixedRate) ? (currentFixedRate.ExchangeRateDirect / currentFixedRate.Amount) : null;
            }
            return rate ? fourDigitsFormatter(rate) : DASH_CHARACTER;
        }
    }];

    const hasFromCurrency = (args: IGetValueArgs) => {
        return !!args.storage.data.entity.FromCurrencyCode;
    };

    const form: IFormDef = {
        id: `${EntityTypeName.CurrencyUsedByCompany}Form`,
        summary,
        translationFiles: getDefinitions.translationFiles,
        formControl: CurrencyUsedByCompanyFormView,
        title: i18next.t("ExchangeRate:FixedRateForm.FixedCurrency"),
        isDeletable: true,
        getItemBreadCrumbText: (storage: Model) =>
                getItemBreadCrumbsText(storage, i18next.t("ExchangeRate:FixedRateForm.NewForeignCurrency"), `${storage.data.entity?.FromCurrencyCode} ${DASH_CHARACTER} ${storage.data.entity?.FromCurrency?.Name}`),
        auditTrailSummary,
        additionalProperties: [
            { id: CurrencyUsedByCompanyEntity.FromCurrencyCode },
            {
                id: CurrencyUsedByCompanyEntity.FixedExchangeRates,
                additionalProperties: [
                    { id: CompanyFixedExchangeRateEntity.ExchangeRateDirect },
                    { id: CompanyFixedExchangeRateEntity.Amount },
                    {
                        id: CompanyFixedExchangeRateEntity.FiscalYear,
                        additionalProperties: [
                            { id: FiscalYearEntity.DateEnd }
                        ]
                    },
                    {
                        id: CompanyFixedExchangeRateEntity.FiscalPeriod,
                        additionalProperties: [
                            { id: FiscalPeriodEntity.Name },
                            { id: FiscalPeriodEntity.DateStart },
                            { id: FiscalPeriodEntity.DateEnd }
                        ]
                    },
                    { id: CompanyFixedExchangeRateEntity.IsClosingFiscalYear }
                ]
            }
        ],
        fieldDefinition: {
            FromCurrency: {
                type: FieldType.ComboBox,
                cacheStrategy: CacheStrategy.Route,
                isReadOnly: (args: IGetValueArgs) => {
                    return !args.storage.data.bindingContext.isNew();
                },
                fieldSettings: {
                    displayName: "Code",
                    shouldDisplayAdditionalColumns: true,
                    localDependentFields: [{
                        from: { id: "Name" },
                        to: { id: "FromCurrency/Name" }
                    }],
                    transformFetchedItems: (items: ISelectItem[], args: IGetValueArgs) => {
                        return items?.filter(item => item.additionalData.Code !== getCompanyCurrency(args.storage?.context));
                    }
                },
                columns: [{ id: "Code" }, { id: "Name" }]
            },
            ExchangeRateType: {
                type: FieldType.SegmentedButton,
                defaultValue: ExchangeRateTypeCode.ByBank,
                isRequired: false,
                isVisible: hasFromCurrency,
                isReadOnly: (args: IGetValueArgs) => {
                    return !(args.storage.getCustomData() as ICurrencyUsedByCompanyFormCustomData).IsCurrencyExchangeRateProvidedByCentralBankDaily && !args.storage.data.bindingContext.isNew();
                },
                isDisabled: (args: IGetValueArgs) => {
                    return !(args.storage.getCustomData() as ICurrencyUsedByCompanyFormCustomData).IsCurrencyExchangeRateProvidedByCentralBankDaily && args.storage.data.bindingContext.isNew();
                },
                fieldSettings: {
                    items: [{
                        id: ExchangeRateTypeCode.ByBank,
                        label: i18next.t("ExchangeRate:SettingsForm.BankRate")
                    }, {
                        id: ExchangeRateTypeCode.Fixed,
                        label: i18next.t("ExchangeRate:SettingsForm.FixedRate")
                    }]
                }
            },
            Amount: {
                isVisible: ifAll(isBankRate, hasFromCurrency),
                isReadOnly: true
            },
            BankExchangeRateFreshness: {
                type: FieldType.RadioButtonGroup,
                label: i18next.t("ExchangeRate:Rate"),
                defaultValue: BankExchangeRateFreshnessCode.Current,
                fieldSettings: {
                    layout: RadioButtonGroupLayout.Column,
                    definition: [{
                        id: BankExchangeRateFreshnessCode.Current,
                        label: i18next.t("ExchangeRate:SettingsForm.Current")
                    }, {
                        id: BankExchangeRateFreshnessCode.PreviousDay,
                        label: i18next.t("ExchangeRate:SettingsForm.PreviousDay")
                    }]
                },
                isVisible: ifAll(hasFromCurrency, isBankRate)
            },
            [FISCAL_YEAR_PATH]: {
                isVisible: ifAll(isFixedRate, hasFromCurrency),
                width: BasicInputSizes.S,
                isRequired: true,
                label: i18next.t("ExchangeRate:FixedRateForm.FiscalYear"),
                type: FieldType.ComboBox,
                comparisonFunction: (entity1: ICurrencyUsedByCompanyEntity, entity2: ICurrencyUsedByCompanyEntity) => {
                    return entity1.ExchangeRateType?.Code === entity2.ExchangeRateType?.Code;
                },
                fieldSettings: {
                    entitySet: EntitySetName.FiscalYears,
                    displayName: "Number",
                    preloadItems: true,
                    additionalProperties: [{ id: FiscalYearEntity.StatusCode }, {
                        id: FiscalYearEntity.Periods,
                        additionalProperties: [{ id: FiscalPeriodEntity.Name }, { id: FiscalPeriodEntity.DateStart }, { id: FiscalPeriodEntity.DateEnd }]
                    }],
                    itemsForRender: items => {
                        return items?.filter(item => item.additionalData.StatusCode === FiscalYearStatusCode.Active);
                    }
                }
            },
            [CurrencyUsedByCompanyEntity.SetFixedExchangeRate]: {
                type: FieldType.Switch,
                defaultValue: true,
                isVisible: ifAll(isFixedRate, hasFromCurrency),
                comparisonFunction: (entity1: ICurrencyUsedByCompanyEntity, entity2: ICurrencyUsedByCompanyEntity) => {
                    return entity1.ExchangeRateType?.Code === entity2.ExchangeRateType?.Code || entity2.SetFixedExchangeRate !== entity1.SetFixedExchangeRate;
                },
                isReadOnly: (args: IGetValueArgs) => {
                    return !(args.storage.getCustomData() as ICurrencyUsedByCompanyFormCustomData).IsCurrencyExchangeRateProvidedByCentralBank && !args.storage.data.bindingContext.isNew();
                },
                isDisabled: (args: IGetValueArgs) => {
                    return !(args.storage.getCustomData() as ICurrencyUsedByCompanyFormCustomData).IsCurrencyExchangeRateProvidedByCentralBank && args.storage.data.bindingContext.isNew();
                },
                label: i18next.t("ExchangeRate:FixedRateForm.SetFixedExchangeRate")
            },
            [createPath(CurrencyUsedByCompanyEntity.FixedExchangeRates, CompanyFixedExchangeRateEntity.FiscalPeriod, FiscalPeriodEntity.Name)]: {
                width: BasicInputSizes.S,
                isReadOnly: true,
                label: i18next.t("ExchangeRate:FixedRateForm.Period"),
                formatter: (val: TValue, args: IFormatOptions) => {
                    const fixedRateEntity = args.storage.getValue(args.bindingContext.getParentCollection()) as ICompanyFixedExchangeRateEntity;
                    if (fixedRateEntity.IsClosingFiscalYear) {
                        return i18next.t("ExchangeRate:FixedRateForm.ClosingRate").toString();
                    }
                    if (isCashBasisAccountingCompany(args.storage.context)) {
                        return capitalize(DateType.format(fixedRateEntity.FiscalPeriod.DateStart, DateFormat.month));
                    }
                    return val?.toString() ?? DASH_CHARACTER;
                }
            },
            [createPath(CurrencyUsedByCompanyEntity.FixedExchangeRates, CompanyFixedExchangeRateEntity.ExchangeRateDirect)]: {
                type: FieldType.NumberInput,
                width: BasicInputSizes.S,
                isRequired: false,
                validator: {
                    type: ValidatorType.Number,
                    settings: {
                        min: 0,
                        excludeMin: true
                    }
                },
                formatter: fourDigitsFormatter
            },
            [createPath(CurrencyUsedByCompanyEntity.FixedExchangeRates, CompanyFixedExchangeRateEntity.Amount)]: {
                width: BasicInputSizes.S,
                type: FieldType.ComboBox,
                defaultValue: 1,
                isRequired: false,
                fieldSettings: {
                    items: getAmountItems(),
                    searchType: ValueType.Number
                }
            }
        },
        groups: [{
            id: "general",
            rows: [
                [{ id: CurrencyUsedByCompanyEntity.FromCurrency }, { id: CurrencyUsedByCompanyEntity.Amount }],
                [{ id: CurrencyUsedByCompanyEntity.ExchangeRateType }],
                [{ id: CurrencyUsedByCompanyEntity.BankExchangeRateFreshness }],
            ]
        }, {
            id: "periods",
            title: i18next.t("ExchangeRate:FixedRateForm.PeriodRate"),
            isVisible: isFixedRate,
            rows: [
                [{ id: FISCAL_YEAR_PATH }, { id: CurrencyUsedByCompanyEntity.SetFixedExchangeRate }]
            ],
            lineItems: {
                collection: CurrencyUsedByCompanyEntity.FixedExchangeRates,
                order: createPath(CompanyFixedExchangeRateEntity.FiscalPeriod, FiscalPeriodEntity.DateStart),
                isItemVisible: (args: IGetValueArgs) => {
                    return args.item?.FiscalYear?.Id === args.storage.getValueByPath(FISCAL_YEAR_PATH);
                },
                columns: [
                    { id: createPath(CompanyFixedExchangeRateEntity.FiscalPeriod, FiscalPeriodEntity.Name) },
                    { id: CompanyFixedExchangeRateEntity.Amount },
                    { id: CompanyFixedExchangeRateEntity.ExchangeRateDirect }],
                canReorder: false,
                isItemCloneable: false,
                isItemRemovable: false,
                canAdd: false
            }
        }]
    };

    return {
        entitySet: EntitySetName.CurrenciesUsedByCompany,
        table,
        form
    };
};

getDefinitions.translationFiles = ["ExchangeRate", "Common"];
setDefByEntityType(EntityTypeName.CurrencyUsedByCompany, getDefinitions);