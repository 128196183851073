import { Button } from "@components/button";
import Dialog, { IDialogProps } from "@components/dialog";
import { ConnectedIcon, ConnectionWarnIcon, DisconnectedIcon } from "@components/icon";
import React, { ReactElement } from "react";
import { WithTranslation, withTranslation } from "react-i18next";

import TestIds from "../../testIds";
import { ContentWrapper, HeaderWrapper, IconWrapper, StyledHeader, StyledSubtitle } from "./ConnectionDialog.styles";

export enum ConnectionStatus {
    Disconnected = "Disconnected",
    WaitingForConfiguration = "WaitingForConfiguration",
    Connected = "Connected",
    ConnectionError = "ConnectionError",
    WaitingForAllow = "WaitingForAllow",
    Expired = "Expired"
}

interface IProps extends WithTranslation, Pick<IDialogProps, "onClose" | "onConfirm" | "onAfterOpen"> {
    onDisconnect?: () => void;
    status: ConnectionStatus;
    title?: string;
    subtitle?: string | ReactElement;
    accountName?: string;
    isBusy?: boolean;
    children?: ReactElement;
    preFormContent?: ReactElement;
}

class ConnectionDialog extends React.Component<IProps> {
    get title(): string {
        let key = null;
        switch (this.props.status) {
            case ConnectionStatus.WaitingForConfiguration:
                key = "WaitingForConfiguration";
                break;
            case ConnectionStatus.WaitingForAllow:
                key = "WaitingForAllow";
                break;
            case ConnectionStatus.Disconnected:
                key = "Disconnected";
                break;
            case ConnectionStatus.Connected:
                key = "Connected";
                break;
            case ConnectionStatus.ConnectionError:
                key = "ConnectionError";
                break;
            case ConnectionStatus.Expired:
                key = "Expired";
                break;
        }
        return key ? this.props.t(`ConnectionDialog:${key}`) : null;
    }

    get subTitle(): string | ReactElement {
        switch (this.props.status) {
            case ConnectionStatus.Expired:
            case ConnectionStatus.WaitingForAllow:
                return <>{this.props.t("ConnectionDialog:AllowSubtitle")}&nbsp;<b>{this.props.accountName}.</b></>;
            case ConnectionStatus.Disconnected:
                return <>{this.props.t("ConnectionDialog:DisconnectedSubtitlePre")}&nbsp;
                    <b>{this.props.accountName}</b> {this.props.t("ConnectionDialog:DisconnectedSubtitlePost")}</>;
            case ConnectionStatus.WaitingForConfiguration:
                return <>{this.props.t("ConnectionDialog:WaitingForConfigurationSubtitlePre")}&nbsp;
                    <b>{this.props.accountName}</b> {this.props.t("ConnectionDialog:WaitingForConfigurationSubtitlePost")}</>;
            case ConnectionStatus.Connected:
                return <>{this.props.t("ConnectionDialog:ConnectedSubtitlePre")}&nbsp;
                    <b>{this.props.accountName}</b> {this.props.t("ConnectionDialog:ConnectedSubtitlePost")}</>;
            case ConnectionStatus.ConnectionError:
                return this.props.t("ConnectionDialog:ConnectionErrorSubtitle");
        }
    }

    renderIcon() {
        switch (this.props.status) {
            case ConnectionStatus.WaitingForAllow:
            case ConnectionStatus.Disconnected:
                return <DisconnectedIcon />;
            case ConnectionStatus.Connected:
            case ConnectionStatus.WaitingForConfiguration:
                return <ConnectedIcon />;
            case ConnectionStatus.ConnectionError:
            case ConnectionStatus.Expired:
                return <ConnectionWarnIcon />;
            default:
                return null;
        }
    }

    getConfirmButtonText = () => {
        switch (this.props.status) {
            case ConnectionStatus.Disconnected:
                return this.props.t("ConnectionDialog:Connect");
            case ConnectionStatus.Connected:
                return this.props.t("Common:General.Save");
            case ConnectionStatus.ConnectionError:
                return this.props.t("ConnectionDialog:TryAgain");
            case ConnectionStatus.WaitingForAllow:
            case ConnectionStatus.Expired:
                return this.props.t("ConnectionDialog:Allow");
            case ConnectionStatus.WaitingForConfiguration:
                return this.props.t("ConnectionDialog:Finish");
        }
    }

    renderFooter() {
        return (
            <>
                <Button isTransparent
                    onClick={this.props.onClose}>
                    {this.props.t("Common:General.Cancel")}
                </Button>
                {[ConnectionStatus.Connected, ConnectionStatus.Expired].includes(this.props.status) && !!this.props.onDisconnect &&
                    <Button isTransparent
                        onClick={this.props.onDisconnect}>
                        {this.props.t("ConnectionDialog:Disconnect")}
                    </Button>}
                <Button onClick={this.props.onConfirm}>
                    {this.getConfirmButtonText()}
                </Button>
            </>
        );
    }

    render() {
        if (!this.props.tReady) {
            return null;
        }

        return (
            <Dialog
                onClose={this.props.onClose}
                busy={this.props.isBusy}
                onConfirm={this.props.onConfirm}
                onAfterOpen={this.props.onAfterOpen}
                width={"338px"} // size of status icons
                footer={this.renderFooter()}
                removePadding
                withoutHeader
            >
                <IconWrapper>
                    {this.renderIcon()}
                </IconWrapper>
                <HeaderWrapper data-testid={TestIds.DialogHeader}>
                    <StyledHeader data-testid={TestIds.DialogTitle}>
                        {this.props.title ?? this.title}
                    </StyledHeader>
                    <StyledSubtitle data-testid={TestIds.DialogSubTitle}>
                        {this.props.subtitle ?? this.subTitle}
                    </StyledSubtitle>
                </HeaderWrapper>
                <ContentWrapper>
                    {this.props.children}
                </ContentWrapper>
            </Dialog>
        );
    }
}

export default withTranslation(["ConnectionDialog"])(ConnectionDialog);