import { FormViewForExtend, IFormViewProps } from "../../../views/formView/FormView";
import { AppContext } from "../../../contexts/appContext/AppContext.types";
import {
    IPrWorkingPatternDayEntity,
    IPrWorkingPatternEntity,
    PrWorkingPatternEntity
} from "@odata/GeneratedEntityTypes";
import { ISmartFieldChange } from "@components/smart/smartField/SmartField";
import { IFormStorageDefaultCustomData } from "../../../views/formView/FormStorage";
import { dateStartPath, rotationLengthPath } from "./WorkingPatternsDef";
import { PrWorkingPatternTypeCode, PrWorkIntervalTypeCode } from "@odata/GeneratedEnums";
import {
    formatDateToDateString,
    getHourDifference,
    getMonthDays,
    isSameDay,
    isSameMonth
} from "@components/inputs/date/utils";
import BindingContext from "../../../odata/BindingContext";
import { isNotDefined } from "@utils/general";
import { transformWorkIntervalsBeforeSave } from "../attendance/Attendance.utils";
import { withPermissionContext } from "../../../contexts/permissionContext/withPermissionContext";
import { getUtcDate, getUtcDayjs } from "../../../types/Date";
import { Dayjs } from "dayjs";


export interface IWorkingPatternCustomData extends IFormStorageDefaultCustomData {
    selectedDay: BindingContext;
    selectedMonth: Dayjs;
    daysWithError: string[];
}

interface IProps extends IFormViewProps<IPrWorkingPatternEntity, IWorkingPatternCustomData> {
}

class WorkingPatternsFormView extends FormViewForExtend<IPrWorkingPatternEntity, IProps> {
    static contextType = AppContext;

    static defaultProps = {
        title: "WorkingPatterns:FormTitle"
    };

    onAfterLoad() {

        if (!this.props.storage.data.bindingContext.isNew()) {
            const sortedDays = this.entity.Days?.sort((day, day2) => getUtcDayjs(day.Date).diff(day2.Date));
            const date = this.entity.TypeCode === PrWorkingPatternTypeCode.Monthly ? sortedDays[sortedDays.length - 1].Date : sortedDays[0].Date;
            this.props.storage.setValueByPath(dateStartPath, date ?? getUtcDate());
            this.props.storage.setCustomData({
                selectedMonth: getUtcDayjs(date)
            });
            if (this.entity.TypeCode === PrWorkingPatternTypeCode.Rotation) {
                this.props.storage.setValueByPath(rotationLengthPath, this.entity.Days?.length ?? 0);
            }
        } else {
            this.props.storage.setCustomData({
                selectedMonth: getUtcDayjs()
            });
        }
        return super.onAfterLoad();
    }

    handleTypeChange = (e: ISmartFieldChange): void => {
        if (e.bindingContext.getPath() === PrWorkingPatternEntity.TypeCode) {
            if (this.entity.TypeCode !== e.value) {
                const isNew = this.props.storage.data.bindingContext.isNew();
                const origEntity = this.props.storage.data.origEntity;
                this.entity.Days = !isNew && e.value === origEntity.TypeCode ? origEntity.Days : [];
                this.props.storage.setCustomData({
                    selectedDay: null,
                    daysWithError: null
                });
            }
        }
    };

    handleDateStartChange = (e: ISmartFieldChange): void => {
        if (e.bindingContext.getPath() === dateStartPath) {
            if (!isSameDay(getUtcDayjs(e.value as Date), getUtcDayjs(this.props.storage.getValueByPath(dateStartPath)))) {
                this.entity.Days = [];
                this.props.storage.setCustomData({
                    selectedDay: null,
                    daysWithError: null
                });
            }
        }
    };

    handleChange = (e: ISmartFieldChange): void => {
        this.handleTypeChange(e);
        this.handleDateStartChange(e);

        this.props.storage.handleChange(e);

        if (e.bindingContext.getPath() === PrWorkingPatternEntity.DefaultDayStart) {
            this.props.storage.refreshFields();
        } else {
            this.props.storage.refresh();
        }
    };

    fillWithDefaultZeros = (data: IPrWorkingPatternEntity): IPrWorkingPatternEntity => {
        const sortedDays = data.Days.sort((day1, day2) => {
            return getUtcDayjs(day1.Date).diff(day2.Date);
        });

        function findDay(d: IPrWorkingPatternDayEntity) {
            return isSameDay(getUtcDayjs(d.Date), date);
        }

        let date: Dayjs;
        let length: number;
        switch (data.TypeCode) {
            case PrWorkingPatternTypeCode.Weekly:
                date = getUtcDayjs(sortedDays[0].Date).clone().startOf("week");
                length = data.IsDifferentOddAndEvenWeek ? 14 : 7;
                break;
            case PrWorkingPatternTypeCode.Monthly:
                date = getUtcDayjs(sortedDays[0].Date).clone().startOf("month");
                length = getMonthDays(getUtcDayjs(sortedDays[0].Date)).length;
                break;
            case PrWorkingPatternTypeCode.Rotation:
                date = getUtcDayjs(this.props.storage.getValueByPath(dateStartPath));
                length = this.props.storage.getValueByPath(rotationLengthPath);
                break;
        }

        const days: IPrWorkingPatternDayEntity[] = [];

        for (let i = 0; i < length; i++) {
            const day = sortedDays.find(findDay);

            days.push(day ?? {
                Date: date.toDate(),
                WorkingHours: 0
            });
            date = date.add(1, "day");
        }

        data.Days = days;
        return data;
    };

    onBeforeSave(): IPrWorkingPatternEntity {
        const data = this.entity;
        // if switched and edited another month, throw away all changes in previous one
        const selectedMonth = this.props.storage.getCustomData().selectedMonth;
        if (data.TypeCode === PrWorkingPatternTypeCode.Monthly && data.Days?.length && selectedMonth) {
            data.Days = data.Days.filter(d => isSameMonth(d.Date, selectedMonth.toDate()));
        }

        const daysWithError = [];

        for (const day of (data.Days ?? [])) {
            if (!day.WorkingHours) {
                day.WorkingHours = 0;
                day.Intervals = [];
            } else {
                const plannedHours = day.Intervals?.filter(i => i.Type?.Code === PrWorkIntervalTypeCode.Work).reduce((hours, interval) => {
                    if (isNotDefined(interval.TimeStart) || isNotDefined(interval.TimeEnd)) {
                        return hours;
                    }
                    const diff = getHourDifference(getUtcDayjs(interval.TimeStart), getUtcDayjs(interval.TimeEnd));
                    return hours + diff;
                }, 0) ?? 0;

                if (plannedHours !== (day.WorkingHours ?? 0)) {
                    daysWithError.push(formatDateToDateString(day.Date));
                }

                day.Intervals = transformWorkIntervalsBeforeSave(day.Intervals);
            }
        }

        if (daysWithError.length) {
            this.props.storage.setCustomData({
                daysWithError
            });
            throw new Error(this.props.storage.t("Error:PrWorkingPatternStructureIsInvalid"));
        }

        const res = this.fillWithDefaultZeros(data);

        if (data.TypeCode === PrWorkingPatternTypeCode.Monthly && !this.props.storage.data.bindingContext.isNew()) {
            // we cannot remove days from other months, so we have to merge it here or in the future maybe handle every month specially
            const origDays = this.props.storage.data.origEntity?.Days?.filter(day => !isSameMonth(day.Date, selectedMonth.toDate())) ?? [];
            res.Days = [...origDays, ...res.Days];
        }

        return res;
    }
}

export default withPermissionContext(WorkingPatternsFormView);