import React, { PureComponent } from "react";
import { IRow, ISort, TId } from "@components/table";
import { FormStorage } from "../../../views/formView/FormStorage";
import { CollapsibleContent } from "../../../components/collapsibleContent";
import memoizeOne from "../../../utils/memoizeOne";
import { EntitySetName, IUserEntity } from "@odata/GeneratedEntityTypes";
import { Sort, TableSizes } from "../../../enums";
import { ModelEvent } from "../../../model/Model";
import { userNameWithAvatarFormatter } from "../users/UsersDef";
import { AssignedUsersTableWrapper } from "./GeneralRoles.styles";
import i18next from "i18next";
import TableWithAutoSizedColumns from "../../../components/table/TableWithAutoSizedColumns";

interface IProps {
    storage: FormStorage;
}

interface IState {
    isCollapsed: boolean;
    loadingUsers: boolean;
    rows: IRow[];
    sort: ISort[];
}

class AssignedUsersTable extends PureComponent<IProps> {
    state: IState = {
        isCollapsed: true,
        loadingUsers: true,
        rows: [],
        sort: [{ id: "FirstName", sort: Sort.Asc }]
    };
    columns = [
        { id: "FirstName", label: i18next.t("GeneralRoles:User") }
    ];

    tableId = "generalRoleAssignedUsers";

    getRows = memoizeOne(async (id: TId, sort?: ISort[]): Promise<IRow[]> => {
        const query = this.props.storage.oData.getEntitySetWrapper(EntitySetName.Users).query()
                .select("Id", "FirstName", "LastName")
                .filter(`GeneralRoles/any(role: role/GeneralRole/Id eq ${id})`)
                .expand("GeneralRoles", g => g.select("Id"));

        (sort ?? this.state.sort).forEach((sort) => {
            query.orderBy(sort.id, sort.sort === Sort.Asc);
        });

        const users = await query.fetchData<IUserEntity[]>();

        return users.value.map((user: IUserEntity) => {
            return {
                id: user.Id,
                values: {
                    FirstName: userNameWithAvatarFormatter(user.FirstName, { entity: user }, {
                        isBold: false,
                        withLink: true
                    })
                }
            };
        });
    });

    componentDidMount = async (): Promise<void> => {
        const id = this.props.storage.data.entity.Id;
        const rows = id ? await this.getRows(id) : [];
        this.setState({ loadingUsers: false, rows });
    };

    handleCollapsed = (): void => {
        this.setState({ isCollapsed: !this.state.isCollapsed });
    };

    handleCollapsingEnd = (): void => {
        this.props.storage.emitter.emit(ModelEvent.RecalculateScrollbar);
    };

    handleSort = async (props: ISort[]): Promise<void> => {
        const rows = await this.getRows(this.props.storage.data.entity.Id, props);
        this.setState({ rows, sort: props });
    };

    render() {
        const tableHeight = this.state.rows?.length ? `${(this.state.rows.length + 1) * TableSizes.RowHeight}px` : "100%"; // +1 for header
        const table = <TableWithAutoSizedColumns busy={this.state.loadingUsers}
                                                 onSortChange={this.handleSort}
                                                 style={{ height: tableHeight }}
                                                 sort={this.state.sort}
                                                 tableId={this.tableId}
                                                 noDataText={this.props.storage.t(`${this.props.storage.data.definition?.translationFiles[0]}:NoAssignedUsers`)}
                                                 columns={this.columns}
                                                 rows={this.state.rows}/>;
        return <AssignedUsersTableWrapper>
            {this.state.rows?.length ? <CollapsibleContent isCollapsed={this.state.isCollapsed}
                                                           topMargin={90}
                                                           onCollapsingEnd={this.handleCollapsingEnd}
                                                           onCollapsed={this.handleCollapsed}>
                {table}
            </CollapsibleContent> : table}
        </AssignedUsersTableWrapper>;
    }
}

export default AssignedUsersTable;