import { IconButton } from "@components/button";
import Clickable from "@components/clickable";
import FileUploadButton from "@components/fileUploadButton/FileUploadButton";
import FileDropArea from "@components/fileUploader/FileDropArea";
import { CloseIcon } from "@components/icon";
import { ErrorWrapper, FileNamesWrapper, StyledFileInput } from "@components/inputs/fileInput/FileInput.styles";
import { WithErrorAndTooltip, withErrorAndTooltip } from "@components/inputs/formError/WithErrorAndTooltip";
import { ISharedInputProps } from "@components/inputs/input";
import { IconDelimiter, IconWrapper } from "@components/inputs/input/Input.styles";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";

import { IconSize } from "../../../enums";

interface IProps extends ISharedInputProps {
    width: string;
    files?: File[];
    multiple?: boolean;
    onChange: (files: File[]) => void;
}

interface IState {
    files: File[];
    fileLinks: string[];
}

class FileInput extends React.PureComponent<IProps & WithErrorAndTooltip & WithTranslation, IState> {
    state: IState = {
        files: null,
        fileLinks: null
    };

    componentDidMount() {
        if (this.props.files) {
            this.setState({ files: this.props.files });
            this.createLinks(this.props.files);
        }
    }

    componentWillUnmount() {
        this.revokeLinks();
    }

    createLinks = (files: File[]): void => {
        const fileLinks: string[] = [];
        for (const file of files) {
            fileLinks.push(URL.createObjectURL(file));
        }
        this.setState({ fileLinks });
    };

    revokeLinks = (): void => {
        if (this.state.fileLinks) {
            for (const fileLink of this.state.fileLinks) {
                URL.revokeObjectURL(fileLink);
            }
        }
    };

    handleNewFiles = (files: File[]): void => {
        if (!this.props.multiple) {
            files = [files[0]];
        }

        this.setState({ files });
        this.props.onChange(files);
        this.createLinks(files);
    };

    handleRemoveButtonClick = (): void => {
        this.revokeLinks();
        this.props.onChange(null);
        this.setState({ files: null, fileLinks: null });
    };

    renderFiles = (): React.ReactElement => {
        return (
                <FileNamesWrapper hasError={this.props.hasError}>
                {this.state.files?.map((file, index) => (
                    <Clickable key={index}
                               link={this.state.fileLinks?.[index]}
                               useEllipsis>
                        {file.name}
                    </Clickable>
                ))}
            </FileNamesWrapper>
        );
    };

    renderIcon = (): React.ReactElement => {
        return (
            <IconWrapper>
                {<IconDelimiter/>}
                {!this.state.files && <FileUploadButton onFileChange={this.handleNewFiles}
                                                        isDecorative
                                                        multiple={this.props.multiple}
                                                        width={IconSize.M}
                                                        hotspotId={"FileInputUploadButton"}/>}
                {this.state.files &&
                    <IconButton title={this.props.t("Components:FileInput.RemoveFile")}
                                hotspotId={"FileInputRemoveButton"}
                                onClick={this.handleRemoveButtonClick}
                                isDecorative>
                        <CloseIcon width={IconSize.M}/>
                    </IconButton>
                }
            </IconWrapper>
        );
    };

    render() {
        return (
            <StyledFileInput _width={this.props.width} _height={"34px"}>
                <ErrorWrapper>{this.props.errorAndTooltip}</ErrorWrapper>
                <FileDropArea isLocalDropArea={false}
                              onNewFiles={this.handleNewFiles}
                              showContent>
                    {this.renderFiles()}
                    {this.renderIcon()}
                </FileDropArea>
            </StyledFileInput>
        );
    }
}

export default withTranslation(["Components"])(withErrorAndTooltip(FileInput, { preventValueTooltip: true }));

export type { IProps as IFileInputProps };