import customFetch from "../../utils/customFetch";
import { REST_API_URL } from "../../constants";
import { ILabelEntity } from "@odata/GeneratedEntityTypes";
import { ODataQueryResult } from "@odata/ODataParser";
import { TEntityKey } from "@odata/BindingContext";

const GET_REFERENCED_LABELS_PATH = `${REST_API_URL}/LabelHierarchies/GetReferencedLabels`;

export const getUsedLabels = async (labelsIds: TEntityKey[]): Promise<ILabelEntity[]> => {
    const result = await customFetch(`${GET_REFERENCED_LABELS_PATH}?${labelsIds.map(id => `labelIds=${id}`).join("&")}`);
    const labels = await result.json() as ODataQueryResult<ILabelEntity[]>;

    return labels?.value;
};