import React from "react";
import { IReportTableDefinition, TGetReportConfigListItemOverride } from "../Report.utils";
import i18next from "i18next";
import { BasicInputSizes, ConfigListItemBoundType, FieldType, Sort } from "../../../enums";
import { assetIntentFormatter, CommonReportProps } from "../CommonDefs";
import { IReportHierarchy, IReportRowDef, IReportVariantDef } from "@components/smart/smartTable";
import { ReportId } from "../ReportIds";
import {
    FilterBarGroup,
    getDefaultFilterGroupDef,
    IFilterGroupDef
} from "@components/smart/smartFilterBar/SmartFilterBar.types";
import { IGetValueArgs } from "@components/smart/FieldInfo";
import { getActiveFiscalYear, getActiveFiscalYears, getFiscalYears } from "../../fiscalYear/FiscalYear.utils";
import { enhanceTotalsWithGroupAssetsData, getAmountDrilldownFormatter } from "./AssetAnalysis.utils";
import { IAppContext } from "../../../contexts/appContext/AppContext.types";
import { isAccountAssignmentCompany } from "@utils/CompanyUtils";
import { ISelectItem } from "@components/inputs/select/Select.types";

export enum AssetAnalysisVariant {
    Inventory = "-78",
    Deprecation = "-77"
}

export const getDefinition = (context: IAppContext): IReportTableDefinition => {
    const tableId = ReportId.AssetAnalysis;
    const title = i18next.t("Reporting:AssetAnalysis.Title");
    const path = "AssetAnalysis";
    const initialSortBy = [{ id: "JournalEntry_DateAccountingTransaction", sort: Sort.Asc }];
    const hasAccounting = isAccountAssignmentCompany(context);
    const filterBarDef: IFilterGroupDef[] = [
        {
            ...getDefaultFilterGroupDef(FilterBarGroup.Parameters),
            defaultFilters: [
                CommonReportProps.fiscalYear
            ],
            filterDefinition: {
                [CommonReportProps.fiscalYear]: {
                    type: FieldType.ComboBox,
                    width: BasicInputSizes.L,
                    label: i18next.t(`Reporting:Parameters.${hasAccounting ? "FiscalYear" : "Year"}`),
                    formatter: (val, args): string => {
                        const fiscalYears = getFiscalYears(args.storage.context);
                        return fiscalYears?.find(fy => fy.Id === val)?.Number;
                    },
                    defaultValue: (args: IGetValueArgs) => {
                        const fiscalYear = getActiveFiscalYear(args.context);
                        return fiscalYear?.Id;
                    },
                    fieldSettings: {
                        itemsFactory: async (args: IGetValueArgs): Promise<ISelectItem[]> => {
                            const fiscalYears = getActiveFiscalYears(args.context, Sort.Desc);

                            return fiscalYears.map(fiscalYear => {
                                return {
                                    id: fiscalYear.Id,
                                    label: fiscalYear.Number,
                                    additionalData: fiscalYear
                                };
                            });
                        }
                    }
                }
            }
        },
        {
            ...getDefaultFilterGroupDef(FilterBarGroup.Filters),
            allowCustomFilters: false,
            defaultFilters: [],
            filterDefinition: {}
        }
    ];

    const parameters: string[] = [CommonReportProps.fiscalYear];

    let deprecationCols = [
        { "ColumnAlias": "Asset_NumberOurs" },
        { "ColumnAlias": "Asset_Name" },
        { "ColumnAlias": "Asset_DateFirstPutInUse" },
        { "ColumnAlias": "Asset_CalculatedPrice" },
        { "ColumnAlias": "TaxDepreciationPolicyItemReportView_AccumulatedDepreciation" },
        { "ColumnAlias": "TaxDepreciationPolicyItemReportView_DepreciationExpense" },
        { "ColumnAlias": "TaxDepreciationPolicyItemReportView_EndValue" }
    ];

    if (hasAccounting) {
        deprecationCols = [
            ...deprecationCols,
            { "ColumnAlias": "AccountingDepreciationPolicyItemReportView_AccumulatedDepreciation" },
            { "ColumnAlias": "AccountingDepreciationPolicyItemReportView_CalculatedExpense" },
            { "ColumnAlias": "AccountingDepreciationPolicyItemReportView_EndValue" },
            { "ColumnAlias": "DepreciationDiff" },
            { "ColumnAlias": "AssetAccountBalanceReportView_Amount" }
        ];
    }

    let inventoryCols = [
        { "ColumnAlias": "Asset_NumberOurs" },
        { "ColumnAlias": "Asset_Name" },
        { "ColumnAlias": "AssetStatus_Name" },
        { "ColumnAlias": "AssetType_Name" },
        { "ColumnAlias": "Asset_DateFirstPutInUse" },
        { "ColumnAlias": "Asset_CalculatedPrice" }
    ];

    if (hasAccounting) {
        inventoryCols = [
            ...inventoryCols,
            { "ColumnAlias": "InUseAccount_Number" },
            { "ColumnAlias": "AccountingDepreciationPolicy_UsefulLife" },
            { "ColumnAlias": "AssetAccountBalanceReportView_Amount" }

        ];
    } else {
        inventoryCols = [
            ...inventoryCols,
            { "ColumnAlias": "TaxDepreciationPolicyItemReportView_OpeningBalance" },
            { "ColumnAlias": "TaxDepreciationPolicyItemReportView_EndValue" }
        ];
    }

    const defaultReportVariants: Record<AssetAnalysisVariant, IReportVariantDef> = {
        [AssetAnalysisVariant.Deprecation]: {
            ReportHierarchy: {
                "Aggregate": true,
                "Groups": [
                    { "ColumnAlias": "TaxDepreciationCategory_Name" }
                ],
                "Columns": deprecationCols,
                "Aggregations": []
            }
        }, [AssetAnalysisVariant.Inventory]: {
            ReportHierarchy: {
                "Aggregate": false,
                "Groups": [],
                "Columns": inventoryCols,
                "Aggregations": []
            }
        }
    };

    const defaultReportHierarchy: IReportHierarchy = defaultReportVariants[AssetAnalysisVariant.Inventory].ReportHierarchy;

    const rowsDataFactory = (rowsData: IReportRowDef[]): IReportRowDef[] => {
        enhanceTotalsWithGroupAssetsData(rowsData);

        return rowsData;
    };

    const configListItemsOverride: TGetReportConfigListItemOverride = {
        Asset_NumberOurs: {
            isRequired: true,
            boundTo: ConfigListItemBoundType.Column
        }
    };

    const columnOverrides = {
        Asset_NumberOurs: {
            formatter: assetIntentFormatter
        },
        Asset_CalculatedPrice: {
            formatter: getAmountDrilldownFormatter()
        },
        AssetAccountBalanceReportView_Amount: {
            formatter: getAmountDrilldownFormatter(true)
        }
    };

    return {
        title, path, id: tableId, initialSortBy, filterBarDef, rowsDataFactory,
        configListItemsOverride, columnOverrides, parameters, defaultReportHierarchy, defaultReportVariants
    };
};