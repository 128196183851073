export interface IEvalaMetadataRule {
    ErrorCode: string;
    Message: string;
    LinkedEntities: {
        DisplayName: string;
        Id: number;
        TypeName: string;
    }[];
    // entity related
    // cannot be deleted - applicable for collection item, e.g. Items(4)
    CannotBeDeleted?: boolean;
    
    // collections related
    // cannot add item in collection - applicable for whole collection, e.g. Items
    CannotAdd?: boolean;
    // cannot remove item from collection - applicable for whole collection, e.g. Items, Attachments
    CannotDelete?: boolean;
}

export interface ODataEntityMetadata {
    count?: number;
    context?: string;
    type?: string;
    etag?: string;
    metadata?: {
        DisabledPropertyRules?: Record<string, IEvalaMetadataRule[]>
        EnabledPropertyRules?: Record<string, IEvalaMetadataRule[]>
    };
}

export interface IEntityBase {
    _metadata?: Record<string, ODataEntityMetadata>;
    "#id"?: number | string;
}

export interface IAcceptableUserPolicyItemEntity extends IEntityBase {
    Id?: number;
    AccountingCode?: string;
    CurrencyCode?: string;
    DateEnd?: Date;
    DateStart?: Date;
    DiscountTypeCode?: string;
    Price?: number;
    PriceNet?: number;
    PriceVat?: number;
    PriceCurrent?: number;
    PriceNetCurrent?: number;
    PriceVatCurrent?: number;
    DocumentsCount?: number;
    TotalDocumentsCount?: number;
    CompanyId?: number;
    TemporaryGuid?: string;
    Accounting?: IAccountingEntity;
    Currency?: ICurrencyEntity;
    DiscountType?: IDiscountTypeEntity;
    ProductPrice?: IProductPriceEntity;
    Subscription?: ISubscriptionEntity;
}

export enum AcceptableUserPolicyItemEntity {
    Id = "Id",
    AccountingCode = "AccountingCode",
    CurrencyCode = "CurrencyCode",
    DateEnd = "DateEnd",
    DateStart = "DateStart",
    DiscountTypeCode = "DiscountTypeCode",
    Price = "Price",
    PriceNet = "PriceNet",
    PriceVat = "PriceVat",
    PriceCurrent = "PriceCurrent",
    PriceNetCurrent = "PriceNetCurrent",
    PriceVatCurrent = "PriceVatCurrent",
    DocumentsCount = "DocumentsCount",
    TotalDocumentsCount = "TotalDocumentsCount",
    CompanyId = "CompanyId",
    TemporaryGuid = "TemporaryGuid",
    Accounting = "Accounting",
    Currency = "Currency",
    DiscountType = "DiscountType",
    ProductPrice = "ProductPrice",
    Subscription = "Subscription",
}

export interface IArchivedCompanyItemEntity extends IEntityBase {
    Id?: number;
    AccountingCode?: string;
    CurrencyCode?: string;
    DateEnd?: Date;
    DateStart?: Date;
    DiscountTypeCode?: string;
    Price?: number;
    PriceNet?: number;
    PriceVat?: number;
    PriceCurrent?: number;
    PriceNetCurrent?: number;
    PriceVatCurrent?: number;
    DocumentsCount?: number;
    TotalDocumentsCount?: number;
    CompanyId?: number;
    TemporaryGuid?: string;
    Accounting?: IAccountingEntity;
    Currency?: ICurrencyEntity;
    DiscountType?: IDiscountTypeEntity;
    ProductPrice?: IProductPriceEntity;
    Subscription?: ISubscriptionEntity;
}

export enum ArchivedCompanyItemEntity {
    Id = "Id",
    AccountingCode = "AccountingCode",
    CurrencyCode = "CurrencyCode",
    DateEnd = "DateEnd",
    DateStart = "DateStart",
    DiscountTypeCode = "DiscountTypeCode",
    Price = "Price",
    PriceNet = "PriceNet",
    PriceVat = "PriceVat",
    PriceCurrent = "PriceCurrent",
    PriceNetCurrent = "PriceNetCurrent",
    PriceVatCurrent = "PriceVatCurrent",
    DocumentsCount = "DocumentsCount",
    TotalDocumentsCount = "TotalDocumentsCount",
    CompanyId = "CompanyId",
    TemporaryGuid = "TemporaryGuid",
    Accounting = "Accounting",
    Currency = "Currency",
    DiscountType = "DiscountType",
    ProductPrice = "ProductPrice",
    Subscription = "Subscription",
}

export interface IBillingAddressEntity extends IEntityBase {
    Id?: number;
    City?: string;
    CountryCode?: string;
    Name?: string;
    PostalCode?: string;
    Street?: string;
    TemporaryGuid?: string;
    Country?: ICountryEntity;
    Tenant?: ITenantEntity;
}

export enum BillingAddressEntity {
    Id = "Id",
    City = "City",
    CountryCode = "CountryCode",
    Name = "Name",
    PostalCode = "PostalCode",
    Street = "Street",
    TemporaryGuid = "TemporaryGuid",
    Country = "Country",
    Tenant = "Tenant",
}

export interface IBillingContactEntity extends IEntityBase {
    Id?: number;
    Email?: string;
    FirstName?: string;
    LastName?: string;
    Order?: number;
    TemporaryGuid?: string;
    Tenant?: ITenantEntity;
}

export enum BillingContactEntity {
    Id = "Id",
    Email = "Email",
    FirstName = "FirstName",
    LastName = "LastName",
    Order = "Order",
    TemporaryGuid = "TemporaryGuid",
    Tenant = "Tenant",
}

export interface IBillingItemEntity extends IEntityBase {
    Id?: number;
    AccountingCode?: string;
    CurrencyCode?: string;
    DateEnd?: Date;
    DateStart?: Date;
    DiscountTypeCode?: string;
    Price?: number;
    PriceNet?: number;
    PriceVat?: number;
    PriceCurrent?: number;
    PriceNetCurrent?: number;
    PriceVatCurrent?: number;
    DocumentsCount?: number;
    TotalDocumentsCount?: number;
    CompanyId?: number;
    TemporaryGuid?: string;
    Accounting?: IAccountingEntity;
    Currency?: ICurrencyEntity;
    DiscountType?: IDiscountTypeEntity;
    ProductPrice?: IProductPriceEntity;
    Subscription?: ISubscriptionEntity;
}

export enum BillingItemEntity {
    Id = "Id",
    AccountingCode = "AccountingCode",
    CurrencyCode = "CurrencyCode",
    DateEnd = "DateEnd",
    DateStart = "DateStart",
    DiscountTypeCode = "DiscountTypeCode",
    Price = "Price",
    PriceNet = "PriceNet",
    PriceVat = "PriceVat",
    PriceCurrent = "PriceCurrent",
    PriceNetCurrent = "PriceNetCurrent",
    PriceVatCurrent = "PriceVatCurrent",
    DocumentsCount = "DocumentsCount",
    TotalDocumentsCount = "TotalDocumentsCount",
    CompanyId = "CompanyId",
    TemporaryGuid = "TemporaryGuid",
    Accounting = "Accounting",
    Currency = "Currency",
    DiscountType = "DiscountType",
    ProductPrice = "ProductPrice",
    Subscription = "Subscription",
}

export interface ICompanyItemEntity extends IEntityBase {
    Id?: number;
    AccountingCode?: string;
    CurrencyCode?: string;
    DateEnd?: Date;
    DateStart?: Date;
    DiscountTypeCode?: string;
    Price?: number;
    PriceNet?: number;
    PriceVat?: number;
    PriceCurrent?: number;
    PriceNetCurrent?: number;
    PriceVatCurrent?: number;
    DocumentsCount?: number;
    TotalDocumentsCount?: number;
    CompanyId?: number;
    TemporaryGuid?: string;
    Accounting?: IAccountingEntity;
    Currency?: ICurrencyEntity;
    DiscountType?: IDiscountTypeEntity;
    ProductPrice?: IProductPriceEntity;
    Subscription?: ISubscriptionEntity;
}

export enum CompanyItemEntity {
    Id = "Id",
    AccountingCode = "AccountingCode",
    CurrencyCode = "CurrencyCode",
    DateEnd = "DateEnd",
    DateStart = "DateStart",
    DiscountTypeCode = "DiscountTypeCode",
    Price = "Price",
    PriceNet = "PriceNet",
    PriceVat = "PriceVat",
    PriceCurrent = "PriceCurrent",
    PriceNetCurrent = "PriceNetCurrent",
    PriceVatCurrent = "PriceVatCurrent",
    DocumentsCount = "DocumentsCount",
    TotalDocumentsCount = "TotalDocumentsCount",
    CompanyId = "CompanyId",
    TemporaryGuid = "TemporaryGuid",
    Accounting = "Accounting",
    Currency = "Currency",
    DiscountType = "DiscountType",
    ProductPrice = "ProductPrice",
    Subscription = "Subscription",
}

export interface IDatabaseEntity extends IEntityBase {
    Id?: string;
    AcceptsNewTenants?: boolean;
    Version?: string;
}

export enum DatabaseEntity {
    Id = "Id",
    AcceptsNewTenants = "AcceptsNewTenants",
    Version = "Version",
}

export interface IDiscountItemEntity extends IEntityBase {
    Id?: number;
    AccountingCode?: string;
    CurrencyCode?: string;
    DateEnd?: Date;
    DateStart?: Date;
    DiscountTypeCode?: string;
    Price?: number;
    PriceNet?: number;
    PriceVat?: number;
    PriceCurrent?: number;
    PriceNetCurrent?: number;
    PriceVatCurrent?: number;
    DocumentsCount?: number;
    TotalDocumentsCount?: number;
    CompanyId?: number;
    TemporaryGuid?: string;
    Accounting?: IAccountingEntity;
    Currency?: ICurrencyEntity;
    DiscountType?: IDiscountTypeEntity;
    ProductPrice?: IProductPriceEntity;
    Subscription?: ISubscriptionEntity;
}

export enum DiscountItemEntity {
    Id = "Id",
    AccountingCode = "AccountingCode",
    CurrencyCode = "CurrencyCode",
    DateEnd = "DateEnd",
    DateStart = "DateStart",
    DiscountTypeCode = "DiscountTypeCode",
    Price = "Price",
    PriceNet = "PriceNet",
    PriceVat = "PriceVat",
    PriceCurrent = "PriceCurrent",
    PriceNetCurrent = "PriceNetCurrent",
    PriceVatCurrent = "PriceVatCurrent",
    DocumentsCount = "DocumentsCount",
    TotalDocumentsCount = "TotalDocumentsCount",
    CompanyId = "CompanyId",
    TemporaryGuid = "TemporaryGuid",
    Accounting = "Accounting",
    Currency = "Currency",
    DiscountType = "DiscountType",
    ProductPrice = "ProductPrice",
    Subscription = "Subscription",
}

export interface IEnabledFeatureEntity extends IEntityBase {
    Id?: number;
    FeatureCode?: string;
    TemporaryGuid?: string;
    Feature?: IFeatureEntity;
    Tenant?: ITenantEntity;
}

export enum EnabledFeatureEntity {
    Id = "Id",
    FeatureCode = "FeatureCode",
    TemporaryGuid = "TemporaryGuid",
    Feature = "Feature",
    Tenant = "Tenant",
}

export interface IInvitationEntity extends IEntityBase {
    Id?: number;
    CustomerCompanies?: string;
    DateCreated?: Date;
    DateEmailSent?: Date;
    Email?: string;
    FirstName?: string;
    TemporaryGuid?: string;
    IsCustomerInvitation?: boolean;
    LastName?: string;
    TenantUserId?: number;
    Tenant?: ITenantEntity;
}

export enum InvitationEntity {
    Id = "Id",
    CustomerCompanies = "CustomerCompanies",
    DateCreated = "DateCreated",
    DateEmailSent = "DateEmailSent",
    Email = "Email",
    FirstName = "FirstName",
    TemporaryGuid = "TemporaryGuid",
    IsCustomerInvitation = "IsCustomerInvitation",
    LastName = "LastName",
    TenantUserId = "TenantUserId",
    Tenant = "Tenant",
}

export interface IInvoiceEntity extends IEntityBase {
    Id?: number;
    Amount?: number;
    AmountNet?: number;
    AmountVat?: number;
    CurrencyCode?: string;
    DateCreated?: Date;
    DateEnd?: Date;
    DateOfPayment?: Date;
    DateStart?: Date;
    Number?: string;
    PaymentAttemptsCount?: number;
    S5InvoiceStateCode?: string;
    SymbolVariable?: number;
    PaymentId?: number;
    FileMetadataId?: number;
    TemporaryGuid?: string;
    Currency?: ICurrencyEntity;
    S5InvoiceState?: IS5InvoiceStateEntity;
    Subscription?: ISubscriptionEntity;
    Items?: IInvoiceItemEntity[];
}

export enum InvoiceEntity {
    Id = "Id",
    Amount = "Amount",
    AmountNet = "AmountNet",
    AmountVat = "AmountVat",
    CurrencyCode = "CurrencyCode",
    DateCreated = "DateCreated",
    DateEnd = "DateEnd",
    DateOfPayment = "DateOfPayment",
    DateStart = "DateStart",
    Number = "Number",
    PaymentAttemptsCount = "PaymentAttemptsCount",
    S5InvoiceStateCode = "S5InvoiceStateCode",
    SymbolVariable = "SymbolVariable",
    PaymentId = "PaymentId",
    FileMetadataId = "FileMetadataId",
    TemporaryGuid = "TemporaryGuid",
    Currency = "Currency",
    S5InvoiceState = "S5InvoiceState",
    Subscription = "Subscription",
    Items = "Items",
}

export interface IInvoiceItemEntity extends IEntityBase {
    Id?: number;
    InvoiceId?: number;
    Name?: string;
    PriceNet?: number;
    S5articleGuid?: string;
    TemporaryGuid?: string;
}

export enum InvoiceItemEntity {
    Id = "Id",
    InvoiceId = "InvoiceId",
    Name = "Name",
    PriceNet = "PriceNet",
    S5articleGuid = "S5articleGuid",
    TemporaryGuid = "TemporaryGuid",
}

export interface IModuleItemEntity extends IEntityBase {
    Id?: number;
    AccountingCode?: string;
    CurrencyCode?: string;
    DateEnd?: Date;
    DateStart?: Date;
    DiscountTypeCode?: string;
    Price?: number;
    PriceNet?: number;
    PriceVat?: number;
    PriceCurrent?: number;
    PriceNetCurrent?: number;
    PriceVatCurrent?: number;
    DocumentsCount?: number;
    TotalDocumentsCount?: number;
    CompanyId?: number;
    TemporaryGuid?: string;
    Accounting?: IAccountingEntity;
    Currency?: ICurrencyEntity;
    DiscountType?: IDiscountTypeEntity;
    ProductPrice?: IProductPriceEntity;
    Subscription?: ISubscriptionEntity;
    ModuleCode?: string;
    ModuleName?: string;
    StorageCapacityIncrease?: number;
    Module?: IModuleEntity;
}

export enum ModuleItemEntity {
    Id = "Id",
    AccountingCode = "AccountingCode",
    CurrencyCode = "CurrencyCode",
    DateEnd = "DateEnd",
    DateStart = "DateStart",
    DiscountTypeCode = "DiscountTypeCode",
    Price = "Price",
    PriceNet = "PriceNet",
    PriceVat = "PriceVat",
    PriceCurrent = "PriceCurrent",
    PriceNetCurrent = "PriceNetCurrent",
    PriceVatCurrent = "PriceVatCurrent",
    DocumentsCount = "DocumentsCount",
    TotalDocumentsCount = "TotalDocumentsCount",
    CompanyId = "CompanyId",
    TemporaryGuid = "TemporaryGuid",
    Accounting = "Accounting",
    Currency = "Currency",
    DiscountType = "DiscountType",
    ProductPrice = "ProductPrice",
    Subscription = "Subscription",
    ModuleCode = "ModuleCode",
    ModuleName = "ModuleName",
    StorageCapacityIncrease = "StorageCapacityIncrease",
    Module = "Module",
}

export interface IModulePriceEntity extends IEntityBase {
    Id?: number;
    CurrencyCode?: string;
    ModuleCode?: string;
    Name?: string;
    Price?: number;
    PriceNet?: number;
    PriceVat?: number;
    ProductCode?: string;
    TemporaryGuid?: string;
    Currency?: ICurrencyEntity;
    Module?: IModuleEntity;
    Product?: IProductEntity;
}

export enum ModulePriceEntity {
    Id = "Id",
    CurrencyCode = "CurrencyCode",
    ModuleCode = "ModuleCode",
    Name = "Name",
    Price = "Price",
    PriceNet = "PriceNet",
    PriceVat = "PriceVat",
    ProductCode = "ProductCode",
    TemporaryGuid = "TemporaryGuid",
    Currency = "Currency",
    Module = "Module",
    Product = "Product",
}

export interface IProductPriceEntity extends IEntityBase {
    Id?: number;
    CurrencyCode?: string;
    Name?: string;
    Price?: number;
    PriceNet?: number;
    PriceVat?: number;
    ProductCode?: string;
    ProductPriceTypeCode?: string;
    TemporaryGuid?: string;
    Currency?: ICurrencyEntity;
    Product?: IProductEntity;
    ProductPriceType?: IProductPriceTypeEntity;
}

export enum ProductPriceEntity {
    Id = "Id",
    CurrencyCode = "CurrencyCode",
    Name = "Name",
    Price = "Price",
    PriceNet = "PriceNet",
    PriceVat = "PriceVat",
    ProductCode = "ProductCode",
    ProductPriceTypeCode = "ProductPriceTypeCode",
    TemporaryGuid = "TemporaryGuid",
    Currency = "Currency",
    Product = "Product",
    ProductPriceType = "ProductPriceType",
}

export interface ISubscriptionEntity extends IEntityBase {
    Id?: number;
    BillingPeriodCode?: string;
    ChargeImmediatelly?: boolean;
    CurrencyCode?: string;
    DateCancellation?: Date;
    DateExpiration?: Date;
    DateFree?: Date;
    DateGracefulPeriodEnd?: Date;
    DatePaidTo?: Date;
    DatePurchase?: Date;
    DateRenewal?: Date;
    DateTrialStart?: Date;
    HasLimitedAccess?: boolean;
    IsGracefulPeriod?: boolean;
    Number?: string;
    PaymentMethodCode?: string;
    PurchaseContact?: string;
    PurchaseStatusCode?: string;
    RecurrentPaymentStateCode?: string;
    StorageCapacity?: number;
    SubscriptionStatusCode?: string;
    SubscriptionTypeCode?: string;
    TenantId?: number;
    CardPaymentLimit?: number;
    TotalPrice?: number;
    TotalPriceNet?: number;
    TotalPriceVat?: number;
    GoPayId?: number;
    StorageUsage?: number;
    HasBillingRequirementsSet?: boolean;
    TrialLength?: number;
    TemporaryGuid?: string;
    BillingPeriod?: IBillingPeriodEntity;
    Currency?: ICurrencyEntity;
    PaymentMethod?: IPaymentMethodEntity;
    PurchaseStatus?: IPurchaseStatusEntity;
    RecurrentPaymentState?: IRecurrentPaymentStateEntity;
    SubscriptionStatus?: ISubscriptionStatusEntity;
    SubscriptionType?: ISubscriptionTypeEntity;
    Tenant?: ITenantEntity;
    AcceptableUserPolicyItems?: IAcceptableUserPolicyItemEntity[];
    ArchivedCompanyItems?: IArchivedCompanyItemEntity[];
    CompanyItems?: ICompanyItemEntity[];
    DiscountItems?: IDiscountItemEntity[];
    Invoices?: IInvoiceEntity[];
    ModuleItems?: IModuleItemEntity[];
    UserItems?: IUserItemEntity[];
}

export enum SubscriptionEntity {
    Id = "Id",
    BillingPeriodCode = "BillingPeriodCode",
    ChargeImmediatelly = "ChargeImmediatelly",
    CurrencyCode = "CurrencyCode",
    DateCancellation = "DateCancellation",
    DateExpiration = "DateExpiration",
    DateFree = "DateFree",
    DateGracefulPeriodEnd = "DateGracefulPeriodEnd",
    DatePaidTo = "DatePaidTo",
    DatePurchase = "DatePurchase",
    DateRenewal = "DateRenewal",
    DateTrialStart = "DateTrialStart",
    HasLimitedAccess = "HasLimitedAccess",
    IsGracefulPeriod = "IsGracefulPeriod",
    Number = "Number",
    PaymentMethodCode = "PaymentMethodCode",
    PurchaseContact = "PurchaseContact",
    PurchaseStatusCode = "PurchaseStatusCode",
    RecurrentPaymentStateCode = "RecurrentPaymentStateCode",
    StorageCapacity = "StorageCapacity",
    SubscriptionStatusCode = "SubscriptionStatusCode",
    SubscriptionTypeCode = "SubscriptionTypeCode",
    TenantId = "TenantId",
    CardPaymentLimit = "CardPaymentLimit",
    TotalPrice = "TotalPrice",
    TotalPriceNet = "TotalPriceNet",
    TotalPriceVat = "TotalPriceVat",
    GoPayId = "GoPayId",
    StorageUsage = "StorageUsage",
    HasBillingRequirementsSet = "HasBillingRequirementsSet",
    TrialLength = "TrialLength",
    TemporaryGuid = "TemporaryGuid",
    BillingPeriod = "BillingPeriod",
    Currency = "Currency",
    PaymentMethod = "PaymentMethod",
    PurchaseStatus = "PurchaseStatus",
    RecurrentPaymentState = "RecurrentPaymentState",
    SubscriptionStatus = "SubscriptionStatus",
    SubscriptionType = "SubscriptionType",
    Tenant = "Tenant",
    AcceptableUserPolicyItems = "AcceptableUserPolicyItems",
    ArchivedCompanyItems = "ArchivedCompanyItems",
    CompanyItems = "CompanyItems",
    DiscountItems = "DiscountItems",
    Invoices = "Invoices",
    ModuleItems = "ModuleItems",
    UserItems = "UserItems",
}

export interface ITenantEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateDataDeletion?: Date;
    DomainName?: string;
    ImpactScore?: number;
    IsActive?: boolean;
    IsCanceled?: boolean;
    IsDemo?: boolean;
    Label?: string;
    LeadScore?: number;
    LegalNumber?: string;
    Name?: string;
    ProductCode?: string;
    SendInvoice?: boolean;
    TaxNumber?: string;
    VatStatusCode?: string;
    SendInvoiceCompanyId?: number;
    CustomerSupportGuid?: string;
    S5CustomerGuid?: string;
    TemporaryGuid?: string;
    BillingAddress?: IBillingAddressEntity;
    Product?: IProductEntity;
    VatStatus?: IVatStatusEntity;
    BillingContacts?: IBillingContactEntity[];
}

export enum TenantEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateDataDeletion = "DateDataDeletion",
    DomainName = "DomainName",
    ImpactScore = "ImpactScore",
    IsActive = "IsActive",
    IsCanceled = "IsCanceled",
    IsDemo = "IsDemo",
    Label = "Label",
    LeadScore = "LeadScore",
    LegalNumber = "LegalNumber",
    Name = "Name",
    ProductCode = "ProductCode",
    SendInvoice = "SendInvoice",
    TaxNumber = "TaxNumber",
    VatStatusCode = "VatStatusCode",
    SendInvoiceCompanyId = "SendInvoiceCompanyId",
    CustomerSupportGuid = "CustomerSupportGuid",
    S5CustomerGuid = "S5CustomerGuid",
    TemporaryGuid = "TemporaryGuid",
    BillingAddress = "BillingAddress",
    Product = "Product",
    VatStatus = "VatStatus",
    BillingContacts = "BillingContacts",
}

export interface IUserItemEntity extends IEntityBase {
    Id?: number;
    AccountingCode?: string;
    CurrencyCode?: string;
    DateEnd?: Date;
    DateStart?: Date;
    DiscountTypeCode?: string;
    Price?: number;
    PriceNet?: number;
    PriceVat?: number;
    PriceCurrent?: number;
    PriceNetCurrent?: number;
    PriceVatCurrent?: number;
    DocumentsCount?: number;
    TotalDocumentsCount?: number;
    CompanyId?: number;
    TemporaryGuid?: string;
    Accounting?: IAccountingEntity;
    Currency?: ICurrencyEntity;
    DiscountType?: IDiscountTypeEntity;
    ProductPrice?: IProductPriceEntity;
    Subscription?: ISubscriptionEntity;
    UserId?: number;
}

export enum UserItemEntity {
    Id = "Id",
    AccountingCode = "AccountingCode",
    CurrencyCode = "CurrencyCode",
    DateEnd = "DateEnd",
    DateStart = "DateStart",
    DiscountTypeCode = "DiscountTypeCode",
    Price = "Price",
    PriceNet = "PriceNet",
    PriceVat = "PriceVat",
    PriceCurrent = "PriceCurrent",
    PriceNetCurrent = "PriceNetCurrent",
    PriceVatCurrent = "PriceVatCurrent",
    DocumentsCount = "DocumentsCount",
    TotalDocumentsCount = "TotalDocumentsCount",
    CompanyId = "CompanyId",
    TemporaryGuid = "TemporaryGuid",
    Accounting = "Accounting",
    Currency = "Currency",
    DiscountType = "DiscountType",
    ProductPrice = "ProductPrice",
    Subscription = "Subscription",
    UserId = "UserId",
}

export interface IAccountEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    AssetAcquisitionTypeCode?: string;
    CategoryCode?: string;
    CurrencyCode?: string;
    IsActive?: boolean;
    IsAssignable?: boolean;
    IsClosed?: boolean;
    IsInvertible?: boolean;
    Name?: string;
    Note?: string;
    Number?: string;
    TaxApplicabilityCode?: string;
    TypeCode?: string;
    AssetAcquisitionType?: IAssetTypeEntity;
    BalanceSheetLayout?: IBalanceSheetLayoutEntity;
    Category?: IAccountCategoryEntity;
    Currency?: ICurrencyEntity;
    IncomeStatementLayout?: IIncomeStatementLayoutEntity;
    Parent?: IAccountEntity;
    TaxApplicability?: ITaxApplicabilityEntity;
    Type?: IAccountTypeEntity;
    Children?: IAccountEntity[];
}

export enum AccountEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    AssetAcquisitionTypeCode = "AssetAcquisitionTypeCode",
    CategoryCode = "CategoryCode",
    CurrencyCode = "CurrencyCode",
    IsActive = "IsActive",
    IsAssignable = "IsAssignable",
    IsClosed = "IsClosed",
    IsInvertible = "IsInvertible",
    Name = "Name",
    Note = "Note",
    Number = "Number",
    TaxApplicabilityCode = "TaxApplicabilityCode",
    TypeCode = "TypeCode",
    AssetAcquisitionType = "AssetAcquisitionType",
    BalanceSheetLayout = "BalanceSheetLayout",
    Category = "Category",
    Currency = "Currency",
    IncomeStatementLayout = "IncomeStatementLayout",
    Parent = "Parent",
    TaxApplicability = "TaxApplicability",
    Type = "Type",
    Children = "Children",
}

export interface IAccountAssignmentEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    CreditAccountName?: string;
    CreditAccountNumber?: string;
    DebitAccountName?: string;
    DebitAccountNumber?: string;
    IsActive?: boolean;
    Name?: string;
    Note?: string;
    ShortName?: string;
    DocumentTypes?: IAccountAssignmentDocumentTypeEntity[];
}

export enum AccountAssignmentEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    CreditAccountName = "CreditAccountName",
    CreditAccountNumber = "CreditAccountNumber",
    DebitAccountName = "DebitAccountName",
    DebitAccountNumber = "DebitAccountNumber",
    IsActive = "IsActive",
    Name = "Name",
    Note = "Note",
    ShortName = "ShortName",
    DocumentTypes = "DocumentTypes",
}

export interface IAccountAssignmentDocumentTypeEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    DocumentTypeCode?: string;
    DocumentType?: IDocumentTypeEntity;
}

export enum AccountAssignmentDocumentTypeEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    DocumentTypeCode = "DocumentTypeCode",
    DocumentType = "DocumentType",
}

export interface IAccountAssignmentDocumentTypeTemplateEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    DocumentTypeCode?: string;
    DocumentType?: IDocumentTypeEntity;
}

export enum AccountAssignmentDocumentTypeTemplateEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    DocumentTypeCode = "DocumentTypeCode",
    DocumentType = "DocumentType",
}

export interface IAccountAssignmentSelectionEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    SelectionCode?: string;
    Selection?: ISelectionEntity;
}

export enum AccountAssignmentSelectionEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    SelectionCode = "SelectionCode",
    Selection = "Selection",
}

export interface IAccountAssignmentSelectionDraftEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    SelectionCode?: string;
    Selection?: ISelectionEntity;
}

export enum AccountAssignmentSelectionDraftEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    SelectionCode = "SelectionCode",
    Selection = "Selection",
}

export interface IAccountAssignmentTemplateEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    CreditAccountName?: string;
    CreditAccountNumber?: string;
    DebitAccountName?: string;
    DebitAccountNumber?: string;
    IsActive?: boolean;
    Name?: string;
    Note?: string;
    ShortName?: string;
    DocumentTypes?: IAccountAssignmentDocumentTypeTemplateEntity[];
}

export enum AccountAssignmentTemplateEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    CreditAccountName = "CreditAccountName",
    CreditAccountNumber = "CreditAccountNumber",
    DebitAccountName = "DebitAccountName",
    DebitAccountNumber = "DebitAccountNumber",
    IsActive = "IsActive",
    Name = "Name",
    Note = "Note",
    ShortName = "ShortName",
    DocumentTypes = "DocumentTypes",
}

export interface IAccountCategoryEntity extends IEntityBase {
    Code?: string;
    Name?: string;
}

export enum AccountCategoryEntity {
    Code = "Code",
    Name = "Name",
}

export interface IAccountRoleEntity extends IEntityBase {
    Code?: string;
    Name?: string;
}

export enum AccountRoleEntity {
    Code = "Code",
    Name = "Name",
}

export interface IAccountTemplateEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    AssetAcquisitionTypeCode?: string;
    CategoryCode?: string;
    CurrencyCode?: string;
    IsActive?: boolean;
    IsInvertible?: boolean;
    Name?: string;
    Note?: string;
    Number?: string;
    TaxApplicabilityCode?: string;
    TypeCode?: string;
    AssetAcquisitionType?: IAssetTypeEntity;
    BalanceSheetLayout?: IBalanceSheetLayoutTemplateEntity;
    Category?: IAccountCategoryEntity;
    Currency?: ICurrencyEntity;
    IncomeStatementLayout?: IIncomeStatementLayoutTemplateEntity;
    Parent?: IAccountTemplateEntity;
    TaxApplicability?: ITaxApplicabilityEntity;
    Type?: IAccountTypeEntity;
    Children?: IAccountTemplateEntity[];
}

export enum AccountTemplateEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    AssetAcquisitionTypeCode = "AssetAcquisitionTypeCode",
    CategoryCode = "CategoryCode",
    CurrencyCode = "CurrencyCode",
    IsActive = "IsActive",
    IsInvertible = "IsInvertible",
    Name = "Name",
    Note = "Note",
    Number = "Number",
    TaxApplicabilityCode = "TaxApplicabilityCode",
    TypeCode = "TypeCode",
    AssetAcquisitionType = "AssetAcquisitionType",
    BalanceSheetLayout = "BalanceSheetLayout",
    Category = "Category",
    Currency = "Currency",
    IncomeStatementLayout = "IncomeStatementLayout",
    Parent = "Parent",
    TaxApplicability = "TaxApplicability",
    Type = "Type",
    Children = "Children",
}

export interface IAccountTypeEntity extends IEntityBase {
    Code?: string;
    Name?: string;
}

export enum AccountTypeEntity {
    Code = "Code",
    Name = "Name",
}

export interface IAccountingEntity extends IEntityBase {
    Code?: string;
    BookkeepingCode?: string;
    CountryCode?: string;
    DefaultLanguageCode?: string;
    Description?: string;
    Name?: string;
    Bookkeeping?: IBookkeepingEntity;
    Country?: ICountryEntity;
    DefaultLanguage?: ILanguageEntity;
}

export enum AccountingEntity {
    Code = "Code",
    BookkeepingCode = "BookkeepingCode",
    CountryCode = "CountryCode",
    DefaultLanguageCode = "DefaultLanguageCode",
    Description = "Description",
    Name = "Name",
    Bookkeeping = "Bookkeeping",
    Country = "Country",
    DefaultLanguage = "DefaultLanguage",
}

export interface IAccountingDepreciationPolicyEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    AccumulatedDepreciation?: number;
    CurrencyCode?: string;
    DateFirstDepreciation?: Date;
    DateLastDepreciation?: Date;
    Currency?: ICurrencyEntity;
    DepreciationTypeCode?: string;
    UsefulLife?: number;
    DepreciationType?: IDepreciationTypeEntity;
    Items?: IAccountingDepreciationPolicyItemEntity[];
}

export enum AccountingDepreciationPolicyEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    AccumulatedDepreciation = "AccumulatedDepreciation",
    CurrencyCode = "CurrencyCode",
    DateFirstDepreciation = "DateFirstDepreciation",
    DateLastDepreciation = "DateLastDepreciation",
    Currency = "Currency",
    DepreciationTypeCode = "DepreciationTypeCode",
    UsefulLife = "UsefulLife",
    DepreciationType = "DepreciationType",
    Items = "Items",
}

export interface IAccountingDepreciationPolicyItemEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    AccumulatedDepreciation?: number;
    CalculatedExpense?: number;
    CurrencyCode?: string;
    DateDepreciation?: Date;
    EndValue?: number;
    Order?: number;
    Currency?: ICurrencyEntity;
    Document?: IInternalDocumentEntity;
}

export enum AccountingDepreciationPolicyItemEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    AccumulatedDepreciation = "AccumulatedDepreciation",
    CalculatedExpense = "CalculatedExpense",
    CurrencyCode = "CurrencyCode",
    DateDepreciation = "DateDepreciation",
    EndValue = "EndValue",
    Order = "Order",
    Currency = "Currency",
    Document = "Document",
}

export interface IAccruedProcessingTypeEntity extends IEntityBase {
    Code?: string;
    Name?: string;
}

export enum AccruedProcessingTypeEntity {
    Code = "Code",
    Name = "Name",
}

export interface IActionLogEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    ActionDetails?: string;
    ActionId?: string;
    ActionTypeCode?: string;
    ActionType?: IActionTypeEntity;
}

export enum ActionLogEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    ActionDetails = "ActionDetails",
    ActionId = "ActionId",
    ActionTypeCode = "ActionTypeCode",
    ActionType = "ActionType",
}

export interface IActionTypeEntity extends IEntityBase {
    Code?: string;
    Name?: string;
}

export enum ActionTypeEntity {
    Code = "Code",
    Name = "Name",
}

export interface IAddressEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    City?: string;
    CountryCode?: string;
    PostalCode?: string;
    Street?: string;
    Country?: ICountryEntity;
}

export enum AddressEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    City = "City",
    CountryCode = "CountryCode",
    PostalCode = "PostalCode",
    Street = "Street",
    Country = "Country",
}

export interface IApprovalStatusTypeEntity extends IEntityBase {
    Code?: string;
    Name?: string;
}

export enum ApprovalStatusTypeEntity {
    Code = "Code",
    Name = "Name",
}

export interface IAssetEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    AccountingBalance?: number;
    CalculatedPrice?: number;
    CanBeDepreciated?: boolean;
    CurrencyCode?: string;
    DateDisposed?: Date;
    DateFirstPutInUse?: Date;
    DateForUsefulLife?: Date;
    DateInUseFrom?: Date;
    DateRemainingPrices?: Date;
    IsImportedAsset?: boolean;
    IsImproved?: boolean;
    IsInUse?: boolean;
    IsLowPriceAsset?: boolean;
    Name?: string;
    Note?: string;
    NumberOurs?: string;
    StatusCode?: string;
    TaxBalance?: number;
    TypeCode?: string;
    ImportedAssetPrice?: number;
    RemainingAccountingPrice?: number;
    RemainingTaxPrice?: number;
    RemainingTaxYears?: number;
    AccountingDepreciationPolicy?: IAccountingDepreciationPolicyEntity;
    AccumulatedDepreciationsAccount?: IAccountEntity;
    ChartOfAccounts?: IChartOfAccountsEntity;
    Company?: ICompanyEntity;
    Currency?: ICurrencyEntity;
    DepreciationAccount?: IAccountEntity;
    FiscalPeriod?: IFiscalPeriodEntity;
    FiscalYear?: IFiscalYearEntity;
    InUseAccount?: IAccountEntity;
    NumberRange?: INumberRangeEntity;
    Status?: IAssetStatusEntity;
    TaxDepreciationPolicy?: ITaxDepreciationPolicyEntity;
    Type?: IAssetTypeEntity;
    Attachments?: IAssetAttachmentEntity[];
    Items?: IAssetItemEntity[];
    Labels?: IAssetLabelEntity[];
}

export enum AssetEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    AccountingBalance = "AccountingBalance",
    CalculatedPrice = "CalculatedPrice",
    CanBeDepreciated = "CanBeDepreciated",
    CurrencyCode = "CurrencyCode",
    DateDisposed = "DateDisposed",
    DateFirstPutInUse = "DateFirstPutInUse",
    DateForUsefulLife = "DateForUsefulLife",
    DateInUseFrom = "DateInUseFrom",
    DateRemainingPrices = "DateRemainingPrices",
    IsImportedAsset = "IsImportedAsset",
    IsImproved = "IsImproved",
    IsInUse = "IsInUse",
    IsLowPriceAsset = "IsLowPriceAsset",
    Name = "Name",
    Note = "Note",
    NumberOurs = "NumberOurs",
    StatusCode = "StatusCode",
    TaxBalance = "TaxBalance",
    TypeCode = "TypeCode",
    ImportedAssetPrice = "ImportedAssetPrice",
    RemainingAccountingPrice = "RemainingAccountingPrice",
    RemainingTaxPrice = "RemainingTaxPrice",
    RemainingTaxYears = "RemainingTaxYears",
    AccountingDepreciationPolicy = "AccountingDepreciationPolicy",
    AccumulatedDepreciationsAccount = "AccumulatedDepreciationsAccount",
    ChartOfAccounts = "ChartOfAccounts",
    Company = "Company",
    Currency = "Currency",
    DepreciationAccount = "DepreciationAccount",
    FiscalPeriod = "FiscalPeriod",
    FiscalYear = "FiscalYear",
    InUseAccount = "InUseAccount",
    NumberRange = "NumberRange",
    Status = "Status",
    TaxDepreciationPolicy = "TaxDepreciationPolicy",
    Type = "Type",
    Attachments = "Attachments",
    Items = "Items",
    Labels = "Labels",
}

export interface IAssetAttachmentEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    Description?: string;
    File?: IFileMetadataEntity;
}

export enum AssetAttachmentEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    Description = "Description",
    File = "File",
}

export interface IAssetDisposalTypeEntity extends IEntityBase {
    Code?: string;
    Name?: string;
}

export enum AssetDisposalTypeEntity {
    Code = "Code",
    Name = "Name",
}

export interface IAssetItemEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    Amount?: number;
    CreditAccount?: string;
    CurrencyCode?: string;
    DateElectronicSubmissionVatStatement?: Date;
    DateOfTransaction?: Date;
    DebitAccount?: string;
    IsForUsefulLifeRefresh?: boolean;
    ItemTypeCode?: string;
    Order?: number;
    UsefulLife?: number;
    Currency?: ICurrencyEntity;
    Document?: IDocumentEntity;
    DocumentItem?: IDocumentItemEntity;
    ItemType?: IAssetItemTypeEntity;
    Children?: IAssetItemEntity[];
}

export enum AssetItemEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    Amount = "Amount",
    CreditAccount = "CreditAccount",
    CurrencyCode = "CurrencyCode",
    DateElectronicSubmissionVatStatement = "DateElectronicSubmissionVatStatement",
    DateOfTransaction = "DateOfTransaction",
    DebitAccount = "DebitAccount",
    IsForUsefulLifeRefresh = "IsForUsefulLifeRefresh",
    ItemTypeCode = "ItemTypeCode",
    Order = "Order",
    UsefulLife = "UsefulLife",
    Currency = "Currency",
    Document = "Document",
    DocumentItem = "DocumentItem",
    ItemType = "ItemType",
    Children = "Children",
}

export interface IAssetItemTypeEntity extends IEntityBase {
    Code?: string;
    Description?: string;
    Name?: string;
}

export enum AssetItemTypeEntity {
    Code = "Code",
    Description = "Description",
    Name = "Name",
}

export interface IAssetLabelEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    EntityTypeCode?: string;
    EntityType?: IEntityTypeEntity;
    Label?: ILabelEntity;
    LabelHierarchy?: ILabelHierarchyEntity;
}

export enum AssetLabelEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    EntityTypeCode = "EntityTypeCode",
    EntityType = "EntityType",
    Label = "Label",
    LabelHierarchy = "LabelHierarchy",
}

export interface IAssetStatusEntity extends IEntityBase {
    Code?: string;
    Name?: string;
}

export enum AssetStatusEntity {
    Code = "Code",
    Name = "Name",
}

export interface IAssetTypeEntity extends IEntityBase {
    Code?: string;
    Name?: string;
}

export enum AssetTypeEntity {
    Code = "Code",
    Name = "Name",
}

export interface IAssetValueEntity extends IEntityBase {
    Code?: string;
    CountryCode?: string;
    DateValidFrom?: Date;
    DateValidTo?: Date;
    MinValue?: number;
    Name?: string;
    Country?: ICountryEntity;
}

export enum AssetValueEntity {
    Code = "Code",
    CountryCode = "CountryCode",
    DateValidFrom = "DateValidFrom",
    DateValidTo = "DateValidTo",
    MinValue = "MinValue",
    Name = "Name",
    Country = "Country",
}

export interface IBackgroundJobEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    IsSeen?: boolean;
    Parameters?: string;
    Result?: string;
    StatusCode?: string;
    TypeCode?: string;
    DateStarted?: Date;
    Company?: ICompanyEntity;
    Status?: IBackgroundJobStatusEntity;
    Type?: IBackgroundJobTypeEntity;
}

export enum BackgroundJobEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    IsSeen = "IsSeen",
    Parameters = "Parameters",
    Result = "Result",
    StatusCode = "StatusCode",
    TypeCode = "TypeCode",
    DateStarted = "DateStarted",
    Company = "Company",
    Status = "Status",
    Type = "Type",
}

export interface IBackgroundJobStatusEntity extends IEntityBase {
    Code?: string;
    Name?: string;
}

export enum BackgroundJobStatusEntity {
    Code = "Code",
    Name = "Name",
}

export interface IBackgroundJobTypeEntity extends IEntityBase {
    Code?: string;
    ClassName?: string;
    IsServiceJob?: boolean;
    IsSystemJob?: boolean;
    Name?: string;
}

export enum BackgroundJobTypeEntity {
    Code = "Code",
    ClassName = "ClassName",
    IsServiceJob = "IsServiceJob",
    IsSystemJob = "IsSystemJob",
    Name = "Name",
}

export interface IBalanceSheetLayoutEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    AssetsReportSectionCode?: string;
    IsAssetsCorrection?: boolean;
    LiabilitiesReportSectionCode?: string;
    Account?: IAccountEntity;
    AssetsReportSection?: IFinancialReportLayoutSectionEntity;
    ChartOfAccounts?: IChartOfAccountsEntity;
    LiabilitiesReportSection?: IFinancialReportLayoutSectionEntity;
}

export enum BalanceSheetLayoutEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    AssetsReportSectionCode = "AssetsReportSectionCode",
    IsAssetsCorrection = "IsAssetsCorrection",
    LiabilitiesReportSectionCode = "LiabilitiesReportSectionCode",
    Account = "Account",
    AssetsReportSection = "AssetsReportSection",
    ChartOfAccounts = "ChartOfAccounts",
    LiabilitiesReportSection = "LiabilitiesReportSection",
}

export interface IBalanceSheetLayoutTemplateEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    AssetsReportSectionCode?: string;
    IsAssetsCorrection?: boolean;
    LiabilitiesReportSectionCode?: string;
    Account?: IAccountTemplateEntity;
    AssetsReportSection?: IFinancialReportLayoutSectionEntity;
    ChartOfAccounts?: IChartOfAccountsTemplateEntity;
    LiabilitiesReportSection?: IFinancialReportLayoutSectionEntity;
}

export enum BalanceSheetLayoutTemplateEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    AssetsReportSectionCode = "AssetsReportSectionCode",
    IsAssetsCorrection = "IsAssetsCorrection",
    LiabilitiesReportSectionCode = "LiabilitiesReportSectionCode",
    Account = "Account",
    AssetsReportSection = "AssetsReportSection",
    ChartOfAccounts = "ChartOfAccounts",
    LiabilitiesReportSection = "LiabilitiesReportSection",
}

export interface IBankEntity extends IEntityBase {
    Code?: string;
    BankStatementApiSupported?: boolean;
    CountryCode?: string;
    LogoId?: number;
    Name?: string;
    PaymentOrdersImportApiSupported?: boolean;
    SWIFT?: string;
    ShortName?: string;
    Country?: ICountryEntity;
}

export enum BankEntity {
    Code = "Code",
    BankStatementApiSupported = "BankStatementApiSupported",
    CountryCode = "CountryCode",
    LogoId = "LogoId",
    Name = "Name",
    PaymentOrdersImportApiSupported = "PaymentOrdersImportApiSupported",
    SWIFT = "SWIFT",
    ShortName = "ShortName",
    Country = "Country",
}

export interface IBankAccountEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    AbaNumber?: string;
    AccountNumber?: string;
    BankCode?: string;
    CountryCode?: string;
    IBAN?: string;
    PaymentServiceID?: string;
    PaymentServiceName?: string;
    SWIFT?: string;
    Bank?: IBankEntity;
    Country?: ICountryEntity;
}

export enum BankAccountEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    AbaNumber = "AbaNumber",
    AccountNumber = "AccountNumber",
    BankCode = "BankCode",
    CountryCode = "CountryCode",
    IBAN = "IBAN",
    PaymentServiceID = "PaymentServiceID",
    PaymentServiceName = "PaymentServiceName",
    SWIFT = "SWIFT",
    Bank = "Bank",
    Country = "Country",
}

export interface IBankAccountNumberFormatTypeEntity extends IEntityBase {
    Code?: string;
    Name?: string;
}

export enum BankAccountNumberFormatTypeEntity {
    Code = "Code",
    Name = "Name",
}

export interface IBankApiConnectionSettingEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    BankAccountId?: string;
    ClientName?: string;
    ContractNumber?: string;
    DateExpirationApiKey?: Date;
    ApiKeySetter?: string;
    ApiKeyId?: number;
    RegistrationInfoId?: number;
}

export enum BankApiConnectionSettingEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    BankAccountId = "BankAccountId",
    ClientName = "ClientName",
    ContractNumber = "ContractNumber",
    DateExpirationApiKey = "DateExpirationApiKey",
    ApiKeySetter = "ApiKeySetter",
    ApiKeyId = "ApiKeyId",
    RegistrationInfoId = "RegistrationInfoId",
}

export interface IBankApiStatementImportFrequencyEntity extends IEntityBase {
    Code?: string;
    Name?: string;
}

export enum BankApiStatementImportFrequencyEntity {
    Code = "Code",
    Name = "Name",
}

export interface IBankApiStatementImportRangeEntity extends IEntityBase {
    Code?: string;
    Name?: string;
}

export enum BankApiStatementImportRangeEntity {
    Code = "Code",
    Name = "Name",
}

export interface IBankApiStatementImportSettingEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    BankApiStatementImportFrequencyCode?: string;
    BankApiStatementImportRangeCode?: string;
    DateFrom?: Date;
    BankApiStatementImportFrequency?: IBankApiStatementImportFrequencyEntity;
    BankApiStatementImportRange?: IBankApiStatementImportRangeEntity;
}

export enum BankApiStatementImportSettingEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    BankApiStatementImportFrequencyCode = "BankApiStatementImportFrequencyCode",
    BankApiStatementImportRangeCode = "BankApiStatementImportRangeCode",
    DateFrom = "DateFrom",
    BankApiStatementImportFrequency = "BankApiStatementImportFrequency",
    BankApiStatementImportRange = "BankApiStatementImportRange",
}

export interface IBankExchangeRateFreshnessEntity extends IEntityBase {
    Code?: string;
    Name?: string;
}

export enum BankExchangeRateFreshnessEntity {
    Code = "Code",
    Name = "Name",
}

export interface IBankPaymentOrderStateEntity extends IEntityBase {
    Code?: string;
    Name?: string;
}

export enum BankPaymentOrderStateEntity {
    Code = "Code",
    Name = "Name",
}

export interface IBankStatementEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    CreditTransactionsCount?: number;
    CreditTurnover?: number;
    DateFrom?: Date;
    DebitTransactionsCount?: number;
    DebitTurnover?: number;
    IsVisible?: boolean;
    Note?: string;
    NumberOurs?: string;
    NumberTheirs?: string;
    OtherTransactionsCount?: number;
    TransactionsCount?: number;
    TransactionsTurnover?: number;
    BankAccount?: ICompanyBankAccountEntity;
    NumberRange?: INumberRangeEntity;
    Attachments?: IBankStatementAttachmentEntity[];
    Locks?: IBankStatementLockEntity[];
    Transactions?: IBankTransactionEntity[];
}

export enum BankStatementEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    CreditTransactionsCount = "CreditTransactionsCount",
    CreditTurnover = "CreditTurnover",
    DateFrom = "DateFrom",
    DebitTransactionsCount = "DebitTransactionsCount",
    DebitTurnover = "DebitTurnover",
    IsVisible = "IsVisible",
    Note = "Note",
    NumberOurs = "NumberOurs",
    NumberTheirs = "NumberTheirs",
    OtherTransactionsCount = "OtherTransactionsCount",
    TransactionsCount = "TransactionsCount",
    TransactionsTurnover = "TransactionsTurnover",
    BankAccount = "BankAccount",
    NumberRange = "NumberRange",
    Attachments = "Attachments",
    Locks = "Locks",
    Transactions = "Transactions",
}

export interface IBankStatementAttachmentEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    Description?: string;
    File?: IFileMetadataEntity;
}

export enum BankStatementAttachmentEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    Description = "Description",
    File = "File",
}

export interface IBankStatementAttachmentDraftEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    Description?: string;
    File?: IFileMetadataEntity;
}

export enum BankStatementAttachmentDraftEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    Description = "Description",
    File = "File",
}

export interface IBankStatementCsvSettingsTextEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    AmountFormat?: string;
    DataStartPosition?: number;
    DateFormat?: string;
    FileEncoding?: string;
    HeaderPosition?: number;
}

export enum BankStatementCsvSettingsTextEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    AmountFormat = "AmountFormat",
    DataStartPosition = "DataStartPosition",
    DateFormat = "DateFormat",
    FileEncoding = "FileEncoding",
    HeaderPosition = "HeaderPosition",
}

export interface IBankStatementDraftEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    DateFrom?: Date;
    Note?: string;
    NumberOurs?: string;
    NumberTheirs?: string;
    CreditTurnover?: number;
    DebitTurnover?: number;
    TransactionsTurnover?: number;
    CreditTransactionsCount?: number;
    DebitTransactionsCount?: number;
    OtherTransactionsCount?: number;
    TransactionsCount?: number;
    BankAccount?: ICompanyBankAccountEntity;
    NumberRange?: INumberRangeEntity;
    Attachments?: IBankStatementAttachmentDraftEntity[];
    Transactions?: IPaymentDocumentDraftEntity[];
}

export enum BankStatementDraftEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    DateFrom = "DateFrom",
    Note = "Note",
    NumberOurs = "NumberOurs",
    NumberTheirs = "NumberTheirs",
    CreditTurnover = "CreditTurnover",
    DebitTurnover = "DebitTurnover",
    TransactionsTurnover = "TransactionsTurnover",
    CreditTransactionsCount = "CreditTransactionsCount",
    DebitTransactionsCount = "DebitTransactionsCount",
    OtherTransactionsCount = "OtherTransactionsCount",
    TransactionsCount = "TransactionsCount",
    BankAccount = "BankAccount",
    NumberRange = "NumberRange",
    Attachments = "Attachments",
    Transactions = "Transactions",
}

export interface IBankStatementLockEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    TypeCode?: string;
    Type?: ILockTypeEntity;
}

export enum BankStatementLockEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    TypeCode = "TypeCode",
    Type = "Type",
}

export interface IBankTransactionEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    Amount?: number;
    ClearedStatusCode?: string;
    CurrencyCode?: string;
    DocumentStatusCode?: string;
    DocumentTypeCode?: string;
    ExchangeRatePerUnit?: number;
    IsUnrelatedToBusiness?: boolean;
    Note?: string;
    NumberOurs?: string;
    PaymentStatusCode?: string;
    PostedStatusCode?: string;
    TransactionAmount?: number;
    TransactionAmountDue?: number;
    TransactionCurrencyCode?: string;
    ClearedStatus?: IClearedStatusEntity;
    Company?: ICompanyEntity;
    Currency?: ICurrencyEntity;
    DocumentStatus?: IDocumentStatusEntity;
    DocumentType?: IDocumentTypeEntity;
    NumberRange?: INumberRangeEntity;
    PaymentDocumentDraft?: IPaymentDocumentDraftEntity;
    PaymentStatus?: IPaymentStatusEntity;
    PostedStatus?: IPostedStatusEntity;
    TransactionCurrency?: ICurrencyEntity;
    Attachments?: IPaymentDocumentAttachmentEntity[];
    DocumentLinks?: IDocumentLinkEntity[];
    Items?: IPaymentDocumentItemEntity[];
    Locks?: IPaymentDocumentLockEntity[];
    BankAccountDescription?: string;
    BankInternalId?: string;
    BankTransactionTypeCode?: string;
    DateBankTransaction?: Date;
    DoesWaitForDuplicateCheck?: boolean;
    IsDuplicate?: boolean;
    Order?: number;
    PaymentInformation?: string;
    PaymentType?: string;
    RemittanceInformation?: string;
    SymbolConstant?: string;
    SymbolSpecific?: string;
    SymbolVariable?: string;
    BankAccount?: IBankTransactionBankAccountEntity;
    BankStatement?: IBankStatementEntity;
    BankTransactionType?: IBankTransactionTypeEntity;
    BusinessPartner?: IBankTransactionBusinessPartnerEntity;
}

export enum BankTransactionEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    Amount = "Amount",
    ClearedStatusCode = "ClearedStatusCode",
    CurrencyCode = "CurrencyCode",
    DocumentStatusCode = "DocumentStatusCode",
    DocumentTypeCode = "DocumentTypeCode",
    ExchangeRatePerUnit = "ExchangeRatePerUnit",
    IsUnrelatedToBusiness = "IsUnrelatedToBusiness",
    Note = "Note",
    NumberOurs = "NumberOurs",
    PaymentStatusCode = "PaymentStatusCode",
    PostedStatusCode = "PostedStatusCode",
    TransactionAmount = "TransactionAmount",
    TransactionAmountDue = "TransactionAmountDue",
    TransactionCurrencyCode = "TransactionCurrencyCode",
    ClearedStatus = "ClearedStatus",
    Company = "Company",
    Currency = "Currency",
    DocumentStatus = "DocumentStatus",
    DocumentType = "DocumentType",
    NumberRange = "NumberRange",
    PaymentDocumentDraft = "PaymentDocumentDraft",
    PaymentStatus = "PaymentStatus",
    PostedStatus = "PostedStatus",
    TransactionCurrency = "TransactionCurrency",
    Attachments = "Attachments",
    DocumentLinks = "DocumentLinks",
    Items = "Items",
    Locks = "Locks",
    BankAccountDescription = "BankAccountDescription",
    BankInternalId = "BankInternalId",
    BankTransactionTypeCode = "BankTransactionTypeCode",
    DateBankTransaction = "DateBankTransaction",
    DoesWaitForDuplicateCheck = "DoesWaitForDuplicateCheck",
    IsDuplicate = "IsDuplicate",
    Order = "Order",
    PaymentInformation = "PaymentInformation",
    PaymentType = "PaymentType",
    RemittanceInformation = "RemittanceInformation",
    SymbolConstant = "SymbolConstant",
    SymbolSpecific = "SymbolSpecific",
    SymbolVariable = "SymbolVariable",
    BankAccount = "BankAccount",
    BankStatement = "BankStatement",
    BankTransactionType = "BankTransactionType",
    BusinessPartner = "BusinessPartner",
}

export interface IBankTransactionBankAccountEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    AbaNumber?: string;
    AccountNumber?: string;
    BankCode?: string;
    CountryCode?: string;
    IBAN?: string;
    PaymentServiceID?: string;
    PaymentServiceName?: string;
    SWIFT?: string;
    Bank?: IBankEntity;
    Country?: ICountryEntity;
}

export enum BankTransactionBankAccountEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    AbaNumber = "AbaNumber",
    AccountNumber = "AccountNumber",
    BankCode = "BankCode",
    CountryCode = "CountryCode",
    IBAN = "IBAN",
    PaymentServiceID = "PaymentServiceID",
    PaymentServiceName = "PaymentServiceName",
    SWIFT = "SWIFT",
    Bank = "Bank",
    Country = "Country",
}

export interface IBankTransactionBankAccountDraftEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    AbaNumber?: string;
    AccountNumber?: string;
    BankCode?: string;
    CountryCode?: string;
    IBAN?: string;
    PaymentServiceID?: string;
    PaymentServiceName?: string;
    SWIFT?: string;
    Bank?: IBankEntity;
    Country?: ICountryEntity;
}

export enum BankTransactionBankAccountDraftEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    AbaNumber = "AbaNumber",
    AccountNumber = "AccountNumber",
    BankCode = "BankCode",
    CountryCode = "CountryCode",
    IBAN = "IBAN",
    PaymentServiceID = "PaymentServiceID",
    PaymentServiceName = "PaymentServiceName",
    SWIFT = "SWIFT",
    Bank = "Bank",
    Country = "Country",
}

export interface IBankTransactionBusinessPartnerEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    City?: string;
    CountryCode?: string;
    Email?: string;
    FirstName?: string;
    LastName?: string;
    LegalNumber?: string;
    Name?: string;
    PhoneNumber?: string;
    PostalCode?: string;
    Street?: string;
    TaxNumber?: string;
    VatStatusCode?: string;
    BusinessPartner?: IBusinessPartnerEntity;
    Country?: ICountryEntity;
    VatStatus?: IVatStatusEntity;
}

export enum BankTransactionBusinessPartnerEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    City = "City",
    CountryCode = "CountryCode",
    Email = "Email",
    FirstName = "FirstName",
    LastName = "LastName",
    LegalNumber = "LegalNumber",
    Name = "Name",
    PhoneNumber = "PhoneNumber",
    PostalCode = "PostalCode",
    Street = "Street",
    TaxNumber = "TaxNumber",
    VatStatusCode = "VatStatusCode",
    BusinessPartner = "BusinessPartner",
    Country = "Country",
    VatStatus = "VatStatus",
}

export interface IBankTransactionBusinessPartnerDraftEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    City?: string;
    CountryCode?: string;
    Email?: string;
    FirstName?: string;
    LastName?: string;
    LegalNumber?: string;
    Name?: string;
    PhoneNumber?: string;
    PostalCode?: string;
    Street?: string;
    TaxNumber?: string;
    VatStatusCode?: string;
    BusinessPartner?: IBusinessPartnerEntity;
    Country?: ICountryEntity;
    VatStatus?: IVatStatusEntity;
}

export enum BankTransactionBusinessPartnerDraftEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    City = "City",
    CountryCode = "CountryCode",
    Email = "Email",
    FirstName = "FirstName",
    LastName = "LastName",
    LegalNumber = "LegalNumber",
    Name = "Name",
    PhoneNumber = "PhoneNumber",
    PostalCode = "PostalCode",
    Street = "Street",
    TaxNumber = "TaxNumber",
    VatStatusCode = "VatStatusCode",
    BusinessPartner = "BusinessPartner",
    Country = "Country",
    VatStatus = "VatStatus",
}

export interface IBankTransactionCsvColumnMappingEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    ColumnId?: number;
    PropertyName?: string;
}

export enum BankTransactionCsvColumnMappingEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    ColumnId = "ColumnId",
    PropertyName = "PropertyName",
}

export interface IBankTransactionDocumentNumberLocationEntity extends IEntityBase {
    Code?: string;
    Name?: string;
}

export enum BankTransactionDocumentNumberLocationEntity {
    Code = "Code",
    Name = "Name",
}

export interface IBankTransactionDuplicateEntity extends IEntityBase {
    NotId?: number;
    CompanyBankAccount?: ICompanyBankAccountEntity;
    SourceBankStatement?: IBankStatementEntity;
    SourceBankTransaction?: IBankTransactionEntity;
    TargetBankStatement?: IBankStatementEntity;
    TargetBankTransaction?: IBankTransactionEntity;
}

export enum BankTransactionDuplicateEntity {
    NotId = "NotId",
    CompanyBankAccount = "CompanyBankAccount",
    SourceBankStatement = "SourceBankStatement",
    SourceBankTransaction = "SourceBankTransaction",
    TargetBankStatement = "TargetBankStatement",
    TargetBankTransaction = "TargetBankTransaction",
}

export interface IBankTransactionTypeEntity extends IEntityBase {
    Code?: string;
    Name?: string;
}

export enum BankTransactionTypeEntity {
    Code = "Code",
    Name = "Name",
}

export interface IBillingEventEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    BillingEventType?: null;
    BillingEventSubType?: null;
    Amount?: number;
    CompanyId?: number;
    AccountingCode?: string;
    UserId?: number;
    ModuleCode?: string;
}

export enum BillingEventEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    BillingEventType = "BillingEventType",
    BillingEventSubType = "BillingEventSubType",
    Amount = "Amount",
    CompanyId = "CompanyId",
    AccountingCode = "AccountingCode",
    UserId = "UserId",
    ModuleCode = "ModuleCode",
}

export interface IBillingItemTypeEntity extends IEntityBase {
    Code?: string;
    Name?: string;
}

export enum BillingItemTypeEntity {
    Code = "Code",
    Name = "Name",
}

export interface IBillingPeriodEntity extends IEntityBase {
    Code?: string;
    Name?: string;
}

export enum BillingPeriodEntity {
    Code = "Code",
    Name = "Name",
}

export interface IBookkeepingEntity extends IEntityBase {
    Code?: string;
    Name?: string;
}

export enum BookkeepingEntity {
    Code = "Code",
    Name = "Name",
}

export interface IBusinessPartnerEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    DataBoxId?: string;
    IsLegalPerson?: boolean;
    IsSynchronizedWithAres?: boolean;
    IsUnreliableVatPayer?: boolean;
    LegalNumber?: string;
    Name?: string;
    Note?: string;
    TaxNumber?: string;
    VatStatusCode?: string;
    BillingAddress?: IBusinessPartnerBillingAddressEntity;
    Company?: ICompanyEntity;
    CorrespondenceAddress?: IBusinessPartnerCorrespondenceAddressEntity;
    IssuedDocumentDefault?: IBusinessPartnerIssuedDocumentDefaultEntity;
    Parent?: IBusinessPartnerEntity;
    ReceivedDocumentDefault?: IBusinessPartnerReceivedDocumentDefaultEntity;
    VatStatus?: IVatStatusEntity;
    Attachments?: IBusinessPartnerAttachmentEntity[];
    BankAccounts?: IBusinessPartnerBankAccountEntity[];
    Contacts?: IBusinessPartnerContactEntity[];
    Children?: IBusinessPartnerEntity[];
}

export enum BusinessPartnerEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    DataBoxId = "DataBoxId",
    IsLegalPerson = "IsLegalPerson",
    IsSynchronizedWithAres = "IsSynchronizedWithAres",
    IsUnreliableVatPayer = "IsUnreliableVatPayer",
    LegalNumber = "LegalNumber",
    Name = "Name",
    Note = "Note",
    TaxNumber = "TaxNumber",
    VatStatusCode = "VatStatusCode",
    BillingAddress = "BillingAddress",
    Company = "Company",
    CorrespondenceAddress = "CorrespondenceAddress",
    IssuedDocumentDefault = "IssuedDocumentDefault",
    Parent = "Parent",
    ReceivedDocumentDefault = "ReceivedDocumentDefault",
    VatStatus = "VatStatus",
    Attachments = "Attachments",
    BankAccounts = "BankAccounts",
    Contacts = "Contacts",
    Children = "Children",
}

export interface IBusinessPartnerAttachmentEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    Description?: string;
    File?: IFileMetadataEntity;
}

export enum BusinessPartnerAttachmentEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    Description = "Description",
    File = "File",
}

export interface IBusinessPartnerBankAccountEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    AbaNumber?: string;
    AccountNumber?: string;
    BankCode?: string;
    CountryCode?: string;
    IBAN?: string;
    PaymentServiceID?: string;
    PaymentServiceName?: string;
    SWIFT?: string;
    Bank?: IBankEntity;
    Country?: ICountryEntity;
    Description?: string;
    IsDefaultForReceivedDocuments?: boolean;
    Order?: number;
}

export enum BusinessPartnerBankAccountEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    AbaNumber = "AbaNumber",
    AccountNumber = "AccountNumber",
    BankCode = "BankCode",
    CountryCode = "CountryCode",
    IBAN = "IBAN",
    PaymentServiceID = "PaymentServiceID",
    PaymentServiceName = "PaymentServiceName",
    SWIFT = "SWIFT",
    Bank = "Bank",
    Country = "Country",
    Description = "Description",
    IsDefaultForReceivedDocuments = "IsDefaultForReceivedDocuments",
    Order = "Order",
}

export interface IBusinessPartnerBillingAddressEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    City?: string;
    CountryCode?: string;
    PostalCode?: string;
    Street?: string;
    Country?: ICountryEntity;
}

export enum BusinessPartnerBillingAddressEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    City = "City",
    CountryCode = "CountryCode",
    PostalCode = "PostalCode",
    Street = "Street",
    Country = "Country",
}

export interface IBusinessPartnerContactEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    Email?: string;
    FirstName?: string;
    LastName?: string;
    PhoneNumber?: string;
    Description?: string;
    Order?: number;
    Role?: string;
}

export enum BusinessPartnerContactEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    Email = "Email",
    FirstName = "FirstName",
    LastName = "LastName",
    PhoneNumber = "PhoneNumber",
    Description = "Description",
    Order = "Order",
    Role = "Role",
}

export interface IBusinessPartnerCorrespondenceAddressEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    City?: string;
    CountryCode?: string;
    PostalCode?: string;
    Street?: string;
    Country?: ICountryEntity;
}

export enum BusinessPartnerCorrespondenceAddressEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    City = "City",
    CountryCode = "CountryCode",
    PostalCode = "PostalCode",
    Street = "Street",
    Country = "Country",
}

export interface IBusinessPartnerDocumentDefaultEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    DefaultPaymentMethodCode?: string;
    Note?: string;
    RemittanceInformation?: string;
    SymbolConstant?: string;
    SymbolSpecific?: string;
    SymbolVariable?: string;
    DaysDue?: number;
    AccountAssignment?: IAccountAssignmentEntity;
    DefaultPaymentMethod?: IPaymentMethodEntity;
    VatClassification?: IVatClassificationEntity;
}

export enum BusinessPartnerDocumentDefaultEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    DefaultPaymentMethodCode = "DefaultPaymentMethodCode",
    Note = "Note",
    RemittanceInformation = "RemittanceInformation",
    SymbolConstant = "SymbolConstant",
    SymbolSpecific = "SymbolSpecific",
    SymbolVariable = "SymbolVariable",
    DaysDue = "DaysDue",
    AccountAssignment = "AccountAssignment",
    DefaultPaymentMethod = "DefaultPaymentMethod",
    VatClassification = "VatClassification",
}

export interface IBusinessPartnerDocumentDefaultTypeEntity extends IEntityBase {
    Code?: string;
    Name?: string;
}

export enum BusinessPartnerDocumentDefaultTypeEntity {
    Code = "Code",
    Name = "Name",
}

export interface IBusinessPartnerIssuedDocumentDefaultEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    DefaultPaymentMethodCode?: string;
    Note?: string;
    RemittanceInformation?: string;
    SymbolConstant?: string;
    SymbolSpecific?: string;
    SymbolVariable?: string;
    DaysDue?: number;
    AccountAssignment?: IAccountAssignmentEntity;
    DefaultPaymentMethod?: IPaymentMethodEntity;
    VatClassification?: IVatClassificationEntity;
    CompanyBankAccount?: ICompanyBankAccountEntity;
}

export enum BusinessPartnerIssuedDocumentDefaultEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    DefaultPaymentMethodCode = "DefaultPaymentMethodCode",
    Note = "Note",
    RemittanceInformation = "RemittanceInformation",
    SymbolConstant = "SymbolConstant",
    SymbolSpecific = "SymbolSpecific",
    SymbolVariable = "SymbolVariable",
    DaysDue = "DaysDue",
    AccountAssignment = "AccountAssignment",
    DefaultPaymentMethod = "DefaultPaymentMethod",
    VatClassification = "VatClassification",
    CompanyBankAccount = "CompanyBankAccount",
}

export interface IBusinessPartnerReceivedDocumentDefaultEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    DefaultPaymentMethodCode?: string;
    Note?: string;
    RemittanceInformation?: string;
    SymbolConstant?: string;
    SymbolSpecific?: string;
    SymbolVariable?: string;
    DaysDue?: number;
    AccountAssignment?: IAccountAssignmentEntity;
    DefaultPaymentMethod?: IPaymentMethodEntity;
    VatClassification?: IVatClassificationEntity;
}

export enum BusinessPartnerReceivedDocumentDefaultEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    DefaultPaymentMethodCode = "DefaultPaymentMethodCode",
    Note = "Note",
    RemittanceInformation = "RemittanceInformation",
    SymbolConstant = "SymbolConstant",
    SymbolSpecific = "SymbolSpecific",
    SymbolVariable = "SymbolVariable",
    DaysDue = "DaysDue",
    AccountAssignment = "AccountAssignment",
    DefaultPaymentMethod = "DefaultPaymentMethod",
    VatClassification = "VatClassification",
}

export interface ICBATransactionTypeEntity extends IEntityBase {
    Code?: string;
    Name?: string;
}

export enum CBATransactionTypeEntity {
    Code = "Code",
    Name = "Name",
}

export interface ICashBoxEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    BalanceSheetAccountName?: string;
    BalanceSheetAccountNumberSuffix?: string;
    CashierName?: string;
    CurrencyCode?: string;
    HasMaterialLiability?: boolean;
    InitialBalance?: number;
    InitialTransactionBalance?: number;
    IsActive?: boolean;
    IsDefault?: boolean;
    Name?: string;
    Note?: string;
    TransactionCurrencyCode?: string;
    Balance?: ICashBoxBalanceEntity;
    Company?: ICompanyEntity;
    Currency?: ICurrencyEntity;
    ReceiptIssuedNumberRangeDefinition?: INumberRangeDefinitionEntity;
    ReceiptReceivedNumberRangeDefinition?: INumberRangeDefinitionEntity;
    TransactionCurrency?: ICurrencyEntity;
}

export enum CashBoxEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    BalanceSheetAccountName = "BalanceSheetAccountName",
    BalanceSheetAccountNumberSuffix = "BalanceSheetAccountNumberSuffix",
    CashierName = "CashierName",
    CurrencyCode = "CurrencyCode",
    HasMaterialLiability = "HasMaterialLiability",
    InitialBalance = "InitialBalance",
    InitialTransactionBalance = "InitialTransactionBalance",
    IsActive = "IsActive",
    IsDefault = "IsDefault",
    Name = "Name",
    Note = "Note",
    TransactionCurrencyCode = "TransactionCurrencyCode",
    Balance = "Balance",
    Company = "Company",
    Currency = "Currency",
    ReceiptIssuedNumberRangeDefinition = "ReceiptIssuedNumberRangeDefinition",
    ReceiptReceivedNumberRangeDefinition = "ReceiptReceivedNumberRangeDefinition",
    TransactionCurrency = "TransactionCurrency",
}

export interface ICashReceiptEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    Amount?: number;
    ClearedStatusCode?: string;
    CurrencyCode?: string;
    DocumentStatusCode?: string;
    DocumentTypeCode?: string;
    ExchangeRatePerUnit?: number;
    IsUnrelatedToBusiness?: boolean;
    Note?: string;
    NumberOurs?: string;
    PaymentStatusCode?: string;
    PostedStatusCode?: string;
    TransactionAmount?: number;
    TransactionAmountDue?: number;
    TransactionCurrencyCode?: string;
    ClearedStatus?: IClearedStatusEntity;
    Company?: ICompanyEntity;
    Currency?: ICurrencyEntity;
    DocumentStatus?: IDocumentStatusEntity;
    DocumentType?: IDocumentTypeEntity;
    NumberRange?: INumberRangeEntity;
    PaymentDocumentDraft?: IPaymentDocumentDraftEntity;
    PaymentStatus?: IPaymentStatusEntity;
    PostedStatus?: IPostedStatusEntity;
    TransactionCurrency?: ICurrencyEntity;
    Attachments?: IPaymentDocumentAttachmentEntity[];
    DocumentLinks?: IDocumentLinkEntity[];
    Items?: IPaymentDocumentItemEntity[];
    Locks?: IPaymentDocumentLockEntity[];
    DateIssued?: Date;
    Purpose?: string;
    CashBox?: ICashBoxEntity;
}

export enum CashReceiptEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    Amount = "Amount",
    ClearedStatusCode = "ClearedStatusCode",
    CurrencyCode = "CurrencyCode",
    DocumentStatusCode = "DocumentStatusCode",
    DocumentTypeCode = "DocumentTypeCode",
    ExchangeRatePerUnit = "ExchangeRatePerUnit",
    IsUnrelatedToBusiness = "IsUnrelatedToBusiness",
    Note = "Note",
    NumberOurs = "NumberOurs",
    PaymentStatusCode = "PaymentStatusCode",
    PostedStatusCode = "PostedStatusCode",
    TransactionAmount = "TransactionAmount",
    TransactionAmountDue = "TransactionAmountDue",
    TransactionCurrencyCode = "TransactionCurrencyCode",
    ClearedStatus = "ClearedStatus",
    Company = "Company",
    Currency = "Currency",
    DocumentStatus = "DocumentStatus",
    DocumentType = "DocumentType",
    NumberRange = "NumberRange",
    PaymentDocumentDraft = "PaymentDocumentDraft",
    PaymentStatus = "PaymentStatus",
    PostedStatus = "PostedStatus",
    TransactionCurrency = "TransactionCurrency",
    Attachments = "Attachments",
    DocumentLinks = "DocumentLinks",
    Items = "Items",
    Locks = "Locks",
    DateIssued = "DateIssued",
    Purpose = "Purpose",
    CashBox = "CashBox",
}

export interface ICashReceiptIssuedEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    Amount?: number;
    ClearedStatusCode?: string;
    CurrencyCode?: string;
    DocumentStatusCode?: string;
    DocumentTypeCode?: string;
    ExchangeRatePerUnit?: number;
    IsUnrelatedToBusiness?: boolean;
    Note?: string;
    NumberOurs?: string;
    PaymentStatusCode?: string;
    PostedStatusCode?: string;
    TransactionAmount?: number;
    TransactionAmountDue?: number;
    TransactionCurrencyCode?: string;
    ClearedStatus?: IClearedStatusEntity;
    Company?: ICompanyEntity;
    Currency?: ICurrencyEntity;
    DocumentStatus?: IDocumentStatusEntity;
    DocumentType?: IDocumentTypeEntity;
    NumberRange?: INumberRangeEntity;
    PaymentDocumentDraft?: IPaymentDocumentDraftEntity;
    PaymentStatus?: IPaymentStatusEntity;
    PostedStatus?: IPostedStatusEntity;
    TransactionCurrency?: ICurrencyEntity;
    Attachments?: IPaymentDocumentAttachmentEntity[];
    DocumentLinks?: IDocumentLinkEntity[];
    Items?: IPaymentDocumentItemEntity[];
    Locks?: IPaymentDocumentLockEntity[];
    DateIssued?: Date;
    Purpose?: string;
    CashBox?: ICashBoxEntity;
    PaidBy?: string;
    PaidTo?: string;
}

export enum CashReceiptIssuedEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    Amount = "Amount",
    ClearedStatusCode = "ClearedStatusCode",
    CurrencyCode = "CurrencyCode",
    DocumentStatusCode = "DocumentStatusCode",
    DocumentTypeCode = "DocumentTypeCode",
    ExchangeRatePerUnit = "ExchangeRatePerUnit",
    IsUnrelatedToBusiness = "IsUnrelatedToBusiness",
    Note = "Note",
    NumberOurs = "NumberOurs",
    PaymentStatusCode = "PaymentStatusCode",
    PostedStatusCode = "PostedStatusCode",
    TransactionAmount = "TransactionAmount",
    TransactionAmountDue = "TransactionAmountDue",
    TransactionCurrencyCode = "TransactionCurrencyCode",
    ClearedStatus = "ClearedStatus",
    Company = "Company",
    Currency = "Currency",
    DocumentStatus = "DocumentStatus",
    DocumentType = "DocumentType",
    NumberRange = "NumberRange",
    PaymentDocumentDraft = "PaymentDocumentDraft",
    PaymentStatus = "PaymentStatus",
    PostedStatus = "PostedStatus",
    TransactionCurrency = "TransactionCurrency",
    Attachments = "Attachments",
    DocumentLinks = "DocumentLinks",
    Items = "Items",
    Locks = "Locks",
    DateIssued = "DateIssued",
    Purpose = "Purpose",
    CashBox = "CashBox",
    PaidBy = "PaidBy",
    PaidTo = "PaidTo",
}

export interface ICashReceiptReceivedEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    Amount?: number;
    ClearedStatusCode?: string;
    CurrencyCode?: string;
    DocumentStatusCode?: string;
    DocumentTypeCode?: string;
    ExchangeRatePerUnit?: number;
    IsUnrelatedToBusiness?: boolean;
    Note?: string;
    NumberOurs?: string;
    PaymentStatusCode?: string;
    PostedStatusCode?: string;
    TransactionAmount?: number;
    TransactionAmountDue?: number;
    TransactionCurrencyCode?: string;
    ClearedStatus?: IClearedStatusEntity;
    Company?: ICompanyEntity;
    Currency?: ICurrencyEntity;
    DocumentStatus?: IDocumentStatusEntity;
    DocumentType?: IDocumentTypeEntity;
    NumberRange?: INumberRangeEntity;
    PaymentDocumentDraft?: IPaymentDocumentDraftEntity;
    PaymentStatus?: IPaymentStatusEntity;
    PostedStatus?: IPostedStatusEntity;
    TransactionCurrency?: ICurrencyEntity;
    Attachments?: IPaymentDocumentAttachmentEntity[];
    DocumentLinks?: IDocumentLinkEntity[];
    Items?: IPaymentDocumentItemEntity[];
    Locks?: IPaymentDocumentLockEntity[];
    DateIssued?: Date;
    Purpose?: string;
    CashBox?: ICashBoxEntity;
    ReceivedBy?: string;
    ReceivedFrom?: string;
}

export enum CashReceiptReceivedEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    Amount = "Amount",
    ClearedStatusCode = "ClearedStatusCode",
    CurrencyCode = "CurrencyCode",
    DocumentStatusCode = "DocumentStatusCode",
    DocumentTypeCode = "DocumentTypeCode",
    ExchangeRatePerUnit = "ExchangeRatePerUnit",
    IsUnrelatedToBusiness = "IsUnrelatedToBusiness",
    Note = "Note",
    NumberOurs = "NumberOurs",
    PaymentStatusCode = "PaymentStatusCode",
    PostedStatusCode = "PostedStatusCode",
    TransactionAmount = "TransactionAmount",
    TransactionAmountDue = "TransactionAmountDue",
    TransactionCurrencyCode = "TransactionCurrencyCode",
    ClearedStatus = "ClearedStatus",
    Company = "Company",
    Currency = "Currency",
    DocumentStatus = "DocumentStatus",
    DocumentType = "DocumentType",
    NumberRange = "NumberRange",
    PaymentDocumentDraft = "PaymentDocumentDraft",
    PaymentStatus = "PaymentStatus",
    PostedStatus = "PostedStatus",
    TransactionCurrency = "TransactionCurrency",
    Attachments = "Attachments",
    DocumentLinks = "DocumentLinks",
    Items = "Items",
    Locks = "Locks",
    DateIssued = "DateIssued",
    Purpose = "Purpose",
    CashBox = "CashBox",
    ReceivedBy = "ReceivedBy",
    ReceivedFrom = "ReceivedFrom",
}

export interface ICbaAssetDisposalTypeEntity extends IEntityBase {
    Code?: string;
    IsTaxByDefault?: boolean;
    Name?: string;
}

export enum CbaAssetDisposalTypeEntity {
    Code = "Code",
    IsTaxByDefault = "IsTaxByDefault",
    Name = "Name",
}

export interface ICbaCategoryEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    IsActive?: boolean;
    IsAssetAcquisition?: boolean;
    Name?: string;
    TaxImpactCode?: string;
    TaxPercentage?: number;
    Company?: ICompanyEntity;
    TaxImpact?: ICbaCategoryTaxImpactEntity;
}

export enum CbaCategoryEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    IsActive = "IsActive",
    IsAssetAcquisition = "IsAssetAcquisition",
    Name = "Name",
    TaxImpactCode = "TaxImpactCode",
    TaxPercentage = "TaxPercentage",
    Company = "Company",
    TaxImpact = "TaxImpact",
}

export interface ICbaCategoryTaxImpactEntity extends IEntityBase {
    Code?: string;
    Name?: string;
}

export enum CbaCategoryTaxImpactEntity {
    Code = "Code",
    Name = "Name",
}

export interface ICbaEntryEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    AmountCleared?: number;
    AmountNonTaxed?: number;
    AmountTaxed?: number;
    AmountVat?: number;
    CbaCategoryTaxImpactCode?: string;
    CurrencyCode?: string;
    DailyRateAmountCleared?: number;
    DailyRateAmountNonTaxed?: number;
    DailyRateAmountTaxed?: number;
    DailyRateAmountVat?: number;
    Date?: Date;
    Description?: string;
    IsAssetAcquisition?: boolean;
    IsReverseProforma?: boolean;
    IsUnrelatedToBusiness?: boolean;
    SpecialVatCalculationCode?: string;
    TaxPercentage?: number;
    TransactionAmountCleared?: number;
    TransactionAmountNonTaxed?: number;
    TransactionAmountTaxed?: number;
    TransactionAmountVat?: number;
    TransactionCurrencyCode?: string;
    TypeCode?: string;
    VatCode?: string;
    VatPercentage?: number;
    AssetDocumentItem?: IDocumentItemEntity;
    Category?: ICbaCategoryEntity;
    Company?: ICompanyEntity;
    Currency?: ICurrencyEntity;
    DocumentLink?: IDocumentLinkEntity;
    InternalDocument?: IInternalDocumentEntity;
    PairedDocument?: IDocumentEntity;
    PaymentDocument?: IPaymentDocumentEntity;
    PaymentDocumentItem?: IPaymentDocumentItemEntity;
    ProformaDocument?: IDocumentEntity;
    SpecialVatCalculation?: ICbaEntrySpecialVatCalculationEntity;
    TransactionCurrency?: ICurrencyEntity;
    Type?: ICbaEntryTypeEntity;
    Vat?: IVatEntity;
    Labels?: ICbaEntryLabelEntity[];
}

export enum CbaEntryEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    AmountCleared = "AmountCleared",
    AmountNonTaxed = "AmountNonTaxed",
    AmountTaxed = "AmountTaxed",
    AmountVat = "AmountVat",
    CbaCategoryTaxImpactCode = "CbaCategoryTaxImpactCode",
    CurrencyCode = "CurrencyCode",
    DailyRateAmountCleared = "DailyRateAmountCleared",
    DailyRateAmountNonTaxed = "DailyRateAmountNonTaxed",
    DailyRateAmountTaxed = "DailyRateAmountTaxed",
    DailyRateAmountVat = "DailyRateAmountVat",
    Date = "Date",
    Description = "Description",
    IsAssetAcquisition = "IsAssetAcquisition",
    IsReverseProforma = "IsReverseProforma",
    IsUnrelatedToBusiness = "IsUnrelatedToBusiness",
    SpecialVatCalculationCode = "SpecialVatCalculationCode",
    TaxPercentage = "TaxPercentage",
    TransactionAmountCleared = "TransactionAmountCleared",
    TransactionAmountNonTaxed = "TransactionAmountNonTaxed",
    TransactionAmountTaxed = "TransactionAmountTaxed",
    TransactionAmountVat = "TransactionAmountVat",
    TransactionCurrencyCode = "TransactionCurrencyCode",
    TypeCode = "TypeCode",
    VatCode = "VatCode",
    VatPercentage = "VatPercentage",
    AssetDocumentItem = "AssetDocumentItem",
    Category = "Category",
    Company = "Company",
    Currency = "Currency",
    DocumentLink = "DocumentLink",
    InternalDocument = "InternalDocument",
    PairedDocument = "PairedDocument",
    PaymentDocument = "PaymentDocument",
    PaymentDocumentItem = "PaymentDocumentItem",
    ProformaDocument = "ProformaDocument",
    SpecialVatCalculation = "SpecialVatCalculation",
    TransactionCurrency = "TransactionCurrency",
    Type = "Type",
    Vat = "Vat",
    Labels = "Labels",
}

export interface ICbaEntryLabelEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    EntityTypeCode?: string;
    EntityType?: IEntityTypeEntity;
    Label?: ILabelEntity;
    LabelHierarchy?: ILabelHierarchyEntity;
}

export enum CbaEntryLabelEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    EntityTypeCode = "EntityTypeCode",
    EntityType = "EntityType",
    Label = "Label",
    LabelHierarchy = "LabelHierarchy",
}

export interface ICbaEntryLedgerDocumentTypeEntity extends IEntityBase {
    Code?: string;
    Name?: string;
}

export enum CbaEntryLedgerDocumentTypeEntity {
    Code = "Code",
    Name = "Name",
}

export interface ICbaEntrySpecialVatCalculationEntity extends IEntityBase {
    Code?: string;
    Name?: string;
}

export enum CbaEntrySpecialVatCalculationEntity {
    Code = "Code",
    Name = "Name",
}

export interface ICbaEntryTypeEntity extends IEntityBase {
    Code?: string;
    Name?: string;
}

export enum CbaEntryTypeEntity {
    Code = "Code",
    Name = "Name",
}

export interface IChartOfAccountsEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    Description?: string;
    Name?: string;
    Note?: string;
    Company?: ICompanyEntity;
    FiscalYear?: IFiscalYearEntity;
    Accounts?: IAccountEntity[];
}

export enum ChartOfAccountsEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    Description = "Description",
    Name = "Name",
    Note = "Note",
    Company = "Company",
    FiscalYear = "FiscalYear",
    Accounts = "Accounts",
}

export interface IChartOfAccountsTemplateEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    AccountingCode?: string;
    Description?: string;
    Name?: string;
    Accounting?: IAccountingEntity;
    Accounts?: IAccountTemplateEntity[];
    RequiredAccountTemplates?: IRequiredAccountTemplateEntity[];
}

export enum ChartOfAccountsTemplateEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    AccountingCode = "AccountingCode",
    Description = "Description",
    Name = "Name",
    Accounting = "Accounting",
    Accounts = "Accounts",
    RequiredAccountTemplates = "RequiredAccountTemplates",
}

export interface IChartedAccountAssignmentEntity extends IEntityBase {
    NotId?: number;
    DocumentTypeCode?: string;
    IsActive?: boolean;
    Name?: string;
    Note?: string;
    ShortName?: string;
    ChartOfAccounts?: IChartOfAccountsEntity;
    CreditAccount?: IAccountEntity;
    DebitAccount?: IAccountEntity;
    DocumentType?: IDocumentTypeEntity;
}

export enum ChartedAccountAssignmentEntity {
    NotId = "NotId",
    DocumentTypeCode = "DocumentTypeCode",
    IsActive = "IsActive",
    Name = "Name",
    Note = "Note",
    ShortName = "ShortName",
    ChartOfAccounts = "ChartOfAccounts",
    CreditAccount = "CreditAccount",
    DebitAccount = "DebitAccount",
    DocumentType = "DocumentType",
}

export interface IClearedStatusEntity extends IEntityBase {
    Code?: string;
    Name?: string;
}

export enum ClearedStatusEntity {
    Code = "Code",
    Name = "Name",
}

export interface IClearingDocumentLinkEntity extends IEntityBase {
    Id?: number;
    CurrencyCode?: string;
    DateClearing?: Date;
    PairedDocumentIsTaxDocument?: boolean;
    TransactionAmount?: number;
    TransactionCurrencyCode?: string;
    TypeCode?: string;
    Amount?: number;
    Currency?: ICurrencyEntity;
    Document?: IDocumentEntity;
    PairedDocument?: IDocumentEntity;
    PairedPaymentDocument?: IPaymentDocumentEntity;
    TransactionCurrency?: ICurrencyEntity;
    Type?: IDocumentLinkTypeEntity;
}

export enum ClearingDocumentLinkEntity {
    Id = "Id",
    CurrencyCode = "CurrencyCode",
    DateClearing = "DateClearing",
    PairedDocumentIsTaxDocument = "PairedDocumentIsTaxDocument",
    TransactionAmount = "TransactionAmount",
    TransactionCurrencyCode = "TransactionCurrencyCode",
    TypeCode = "TypeCode",
    Amount = "Amount",
    Currency = "Currency",
    Document = "Document",
    PairedDocument = "PairedDocument",
    PairedPaymentDocument = "PairedPaymentDocument",
    TransactionCurrency = "TransactionCurrency",
    Type = "Type",
}

export interface IColourThemeEntity extends IEntityBase {
    Code?: string;
    Name?: string;
}

export enum ColourThemeEntity {
    Code = "Code",
    Name = "Name",
}

export interface ICommunicationEntryEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    ApiResponseMessage?: string;
    ChannelCode?: string;
    MessageId?: string;
    PaymentOrderAmount?: string;
    PaymentOrderCurrency?: string;
    Recipient?: string;
    RecipientAddress?: string;
    RecipientBankAccountCurrency?: string;
    RecipientBankAccountName?: string;
    RecipientBankAccountNumber?: string;
    RecipientBankName?: string;
    RecipientDataboxId?: string;
    RecipientEmail?: string;
    RecipientLegalNumber?: string;
    Sender?: string;
    SenderAddress?: string;
    SenderDataboxId?: string;
    SenderEmail?: string;
    SenderLegalNumber?: string;
    StateCode?: string;
    Subject?: string;
    Text?: string;
    TimeSent?: string;
    TypeCode?: string;
    DateSent?: Date;
    DateStateChange?: Date;
    Channel?: ICommunicationEntryChannelEntity;
    Company?: ICompanyEntity;
    State?: ICommunicationEntryStateEntity;
    Type?: ICommunicationEntryTypeEntity;
    User?: IUserEntity;
    Attachments?: ICommunicationEntryAttachmentEntity[];
}

export enum CommunicationEntryEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    ApiResponseMessage = "ApiResponseMessage",
    ChannelCode = "ChannelCode",
    MessageId = "MessageId",
    PaymentOrderAmount = "PaymentOrderAmount",
    PaymentOrderCurrency = "PaymentOrderCurrency",
    Recipient = "Recipient",
    RecipientAddress = "RecipientAddress",
    RecipientBankAccountCurrency = "RecipientBankAccountCurrency",
    RecipientBankAccountName = "RecipientBankAccountName",
    RecipientBankAccountNumber = "RecipientBankAccountNumber",
    RecipientBankName = "RecipientBankName",
    RecipientDataboxId = "RecipientDataboxId",
    RecipientEmail = "RecipientEmail",
    RecipientLegalNumber = "RecipientLegalNumber",
    Sender = "Sender",
    SenderAddress = "SenderAddress",
    SenderDataboxId = "SenderDataboxId",
    SenderEmail = "SenderEmail",
    SenderLegalNumber = "SenderLegalNumber",
    StateCode = "StateCode",
    Subject = "Subject",
    Text = "Text",
    TimeSent = "TimeSent",
    TypeCode = "TypeCode",
    DateSent = "DateSent",
    DateStateChange = "DateStateChange",
    Channel = "Channel",
    Company = "Company",
    State = "State",
    Type = "Type",
    User = "User",
    Attachments = "Attachments",
}

export interface ICommunicationEntryAttachmentEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    Description?: string;
    File?: IFileMetadataEntity;
}

export enum CommunicationEntryAttachmentEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    Description = "Description",
    File = "File",
}

export interface ICommunicationEntryChannelEntity extends IEntityBase {
    Code?: string;
    Name?: string;
}

export enum CommunicationEntryChannelEntity {
    Code = "Code",
    Name = "Name",
}

export interface ICommunicationEntryStateEntity extends IEntityBase {
    Code?: string;
    Name?: string;
}

export enum CommunicationEntryStateEntity {
    Code = "Code",
    Name = "Name",
}

export interface ICommunicationEntryTypeEntity extends IEntityBase {
    Code?: string;
    Name?: string;
}

export enum CommunicationEntryTypeEntity {
    Code = "Code",
    Name = "Name",
}

export interface ICommunicationTypeEntity extends IEntityBase {
    Code?: string;
    Name?: string;
}

export enum CommunicationTypeEntity {
    Code = "Code",
    Name = "Name",
}

export interface ICompanyEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    AccountingCode?: string;
    BirthNumber?: string;
    CompanyRegister?: string;
    CurrencyCode?: string;
    CzNaceCode?: string;
    DateInitialized?: Date;
    FinancialAdministrationCode?: string;
    InboxEmail?: string;
    LegalName?: string;
    LegalNumber?: string;
    Name?: string;
    StateCode?: string;
    TaxNumber?: string;
    TypeOfBusinessCode?: string;
    UseVatReducedDeduction?: boolean;
    WebPage?: string;
    Accounting?: IAccountingEntity;
    CommunicationAddress?: ICompanyCommunicationAddressEntity;
    CommunicationContact?: ICompanyCommunicationContactEntity;
    CompanySetting?: ICompanySettingEntity;
    Currency?: ICurrencyEntity;
    CzNace?: ICzNaceEntity;
    FinancialAdministration?: IGovernmentInstitutionEntity;
    LegalAddress?: ICompanyLegalAddressEntity;
    Logo?: IFileMetadataEntity;
    PrPayrollSetting?: IPrPayrollSettingEntity;
    Stamp?: IFileMetadataEntity;
    State?: ICompanyStateEntity;
    TaxStatementSignatoryPerson?: ITaxStatementSignatoryPersonEntity;
    TypeOfBusiness?: ITypeOfBusinessEntity;
    VatReducedCoefficients?: ICompanyVatReducedCoefficientEntity[];
    VatStatuses?: ICompanyVatStatusEntity[];
}

export enum CompanyEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    AccountingCode = "AccountingCode",
    BirthNumber = "BirthNumber",
    CompanyRegister = "CompanyRegister",
    CurrencyCode = "CurrencyCode",
    CzNaceCode = "CzNaceCode",
    DateInitialized = "DateInitialized",
    FinancialAdministrationCode = "FinancialAdministrationCode",
    InboxEmail = "InboxEmail",
    LegalName = "LegalName",
    LegalNumber = "LegalNumber",
    Name = "Name",
    StateCode = "StateCode",
    TaxNumber = "TaxNumber",
    TypeOfBusinessCode = "TypeOfBusinessCode",
    UseVatReducedDeduction = "UseVatReducedDeduction",
    WebPage = "WebPage",
    Accounting = "Accounting",
    CommunicationAddress = "CommunicationAddress",
    CommunicationContact = "CommunicationContact",
    CompanySetting = "CompanySetting",
    Currency = "Currency",
    CzNace = "CzNace",
    FinancialAdministration = "FinancialAdministration",
    LegalAddress = "LegalAddress",
    Logo = "Logo",
    PrPayrollSetting = "PrPayrollSetting",
    Stamp = "Stamp",
    State = "State",
    TaxStatementSignatoryPerson = "TaxStatementSignatoryPerson",
    TypeOfBusiness = "TypeOfBusiness",
    VatReducedCoefficients = "VatReducedCoefficients",
    VatStatuses = "VatStatuses",
}

export interface ICompanyBankAccountEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    AbaNumber?: string;
    AccountNumber?: string;
    BankCode?: string;
    CountryCode?: string;
    IBAN?: string;
    PaymentServiceID?: string;
    PaymentServiceName?: string;
    SWIFT?: string;
    Bank?: IBankEntity;
    Country?: ICountryEntity;
    BalanceSheetAccountName?: string;
    BalanceSheetAccountNumberSuffix?: string;
    CompanyBankAccountTypeCode?: string;
    CurrencyCode?: string;
    HasAutoPosting?: boolean;
    InitialBalance?: number;
    InitialTransactionBalance?: number;
    IsActive?: boolean;
    IsDefault?: boolean;
    Name?: string;
    TransactionCurrencyCode?: string;
    Balance?: IBankAccountBalanceEntity;
    BankApiConnectionSetting?: IBankApiConnectionSettingEntity;
    BankApiStatementImportSetting?: IBankApiStatementImportSettingEntity;
    BankStatementCsvSettingsText?: IBankStatementCsvSettingsTextEntity;
    BankStatementNumberRangeDefinition?: INumberRangeDefinitionEntity;
    Company?: ICompanyEntity;
    CompanyBankAccountType?: ICompanyBankAccountTypeEntity;
    Currency?: ICurrencyEntity;
    Logo?: IFileMetadataEntity;
    TransactionCurrency?: ICurrencyEntity;
    BankTransactionCsvColumnMappings?: IBankTransactionCsvColumnMappingEntity[];
}

export enum CompanyBankAccountEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    AbaNumber = "AbaNumber",
    AccountNumber = "AccountNumber",
    BankCode = "BankCode",
    CountryCode = "CountryCode",
    IBAN = "IBAN",
    PaymentServiceID = "PaymentServiceID",
    PaymentServiceName = "PaymentServiceName",
    SWIFT = "SWIFT",
    Bank = "Bank",
    Country = "Country",
    BalanceSheetAccountName = "BalanceSheetAccountName",
    BalanceSheetAccountNumberSuffix = "BalanceSheetAccountNumberSuffix",
    CompanyBankAccountTypeCode = "CompanyBankAccountTypeCode",
    CurrencyCode = "CurrencyCode",
    HasAutoPosting = "HasAutoPosting",
    InitialBalance = "InitialBalance",
    InitialTransactionBalance = "InitialTransactionBalance",
    IsActive = "IsActive",
    IsDefault = "IsDefault",
    Name = "Name",
    TransactionCurrencyCode = "TransactionCurrencyCode",
    Balance = "Balance",
    BankApiConnectionSetting = "BankApiConnectionSetting",
    BankApiStatementImportSetting = "BankApiStatementImportSetting",
    BankStatementCsvSettingsText = "BankStatementCsvSettingsText",
    BankStatementNumberRangeDefinition = "BankStatementNumberRangeDefinition",
    Company = "Company",
    CompanyBankAccountType = "CompanyBankAccountType",
    Currency = "Currency",
    Logo = "Logo",
    TransactionCurrency = "TransactionCurrency",
    BankTransactionCsvColumnMappings = "BankTransactionCsvColumnMappings",
}

export interface ICompanyBankAccountTypeEntity extends IEntityBase {
    Code?: string;
    Name?: string;
}

export enum CompanyBankAccountTypeEntity {
    Code = "Code",
    Name = "Name",
}

export interface ICompanyCommunicationAddressEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    City?: string;
    CountryCode?: string;
    PostalCode?: string;
    Street?: string;
    Country?: ICountryEntity;
}

export enum CompanyCommunicationAddressEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    City = "City",
    CountryCode = "CountryCode",
    PostalCode = "PostalCode",
    Street = "Street",
    Country = "Country",
}

export interface ICompanyCommunicationContactEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    Email?: string;
    FirstName?: string;
    LastName?: string;
    PhoneNumber?: string;
}

export enum CompanyCommunicationContactEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    Email = "Email",
    FirstName = "FirstName",
    LastName = "LastName",
    PhoneNumber = "PhoneNumber",
}

export interface ICompanyFixedExchangeRateEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    Amount?: number;
    ExchangeRateDirect?: number;
    ExchangeRateIndirect?: number;
    ExchangeRatePerUnit?: number;
    IsClosingFiscalYear?: boolean;
    FiscalPeriod?: IFiscalPeriodEntity;
    FiscalYear?: IFiscalYearEntity;
}

export enum CompanyFixedExchangeRateEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    Amount = "Amount",
    ExchangeRateDirect = "ExchangeRateDirect",
    ExchangeRateIndirect = "ExchangeRateIndirect",
    ExchangeRatePerUnit = "ExchangeRatePerUnit",
    IsClosingFiscalYear = "IsClosingFiscalYear",
    FiscalPeriod = "FiscalPeriod",
    FiscalYear = "FiscalYear",
}

export interface ICompanyLegalAddressEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    City?: string;
    CountryCode?: string;
    PostalCode?: string;
    Street?: string;
    Country?: ICountryEntity;
}

export enum CompanyLegalAddressEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    City = "City",
    CountryCode = "CountryCode",
    PostalCode = "PostalCode",
    Street = "Street",
    Country = "Country",
}

export interface ICompanyPermissionEntity extends IEntityBase {
    Code?: string;
    Description?: string;
    Name?: string;
}

export enum CompanyPermissionEntity {
    Code = "Code",
    Description = "Description",
    Name = "Name",
}

export interface ICompanyRoleEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    CompanyRoleName?: string;
    IsSystemRole?: boolean;
    TypeCode?: string;
    UserCount?: number;
    Type?: ICompanyRoleTypeEntity;
    CompanyRolePermissions?: ICompanyRolePermissionEntity[];
}

export enum CompanyRoleEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    CompanyRoleName = "CompanyRoleName",
    IsSystemRole = "IsSystemRole",
    TypeCode = "TypeCode",
    UserCount = "UserCount",
    Type = "Type",
    CompanyRolePermissions = "CompanyRolePermissions",
}

export interface ICompanyRolePermissionEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    CompanyPermissionCode?: string;
    IsEnabled?: boolean;
    OwnOnly?: boolean;
    CompanyPermission?: ICompanyPermissionEntity;
}

export enum CompanyRolePermissionEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    CompanyPermissionCode = "CompanyPermissionCode",
    IsEnabled = "IsEnabled",
    OwnOnly = "OwnOnly",
    CompanyPermission = "CompanyPermission",
}

export interface ICompanyRoleTypeEntity extends IEntityBase {
    Code?: string;
    Name?: string;
}

export enum CompanyRoleTypeEntity {
    Code = "Code",
    Name = "Name",
}

export interface ICompanySettingEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    DateInitialAccountBalances?: Date;
    InitialChartOfAccountsTemplateId?: number;
    InitializeUser?: boolean;
    InitialYear?: number;
    InitialBalancesDocument?: IInternalDocumentEntity;
    InitialFiscalYear?: IFiscalYearEntity;
    InitialAccountBalances?: IInitialAccountBalanceEntity[];
    TrustedSenders?: ITrustedSenderEntity[];
    InboxApprovers?: IUserEntity[];
    RequiredAccounts?: IRequiredAccountEntity[];
}

export enum CompanySettingEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    DateInitialAccountBalances = "DateInitialAccountBalances",
    InitialChartOfAccountsTemplateId = "InitialChartOfAccountsTemplateId",
    InitializeUser = "InitializeUser",
    InitialYear = "InitialYear",
    InitialBalancesDocument = "InitialBalancesDocument",
    InitialFiscalYear = "InitialFiscalYear",
    InitialAccountBalances = "InitialAccountBalances",
    TrustedSenders = "TrustedSenders",
    InboxApprovers = "InboxApprovers",
    RequiredAccounts = "RequiredAccounts",
}

export interface ICompanyStateEntity extends IEntityBase {
    Code?: string;
    Name?: string;
}

export enum CompanyStateEntity {
    Code = "Code",
    Name = "Name",
}

export interface ICompanyVatReducedCoefficientEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    VatReducedCoefficient?: number;
    Year?: number;
}

export enum CompanyVatReducedCoefficientEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    VatReducedCoefficient = "VatReducedCoefficient",
    Year = "Year",
}

export interface ICompanyVatStatusEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    CompanyId?: number;
    DateValidFrom?: Date;
    DateValidTo?: Date;
    VatStatementFrequencyCode?: string;
    VatStatusCode?: string;
    VatStatementFrequency?: IVatStatementFrequencyEntity;
    VatStatus?: IVatStatusEntity;
}

export enum CompanyVatStatusEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    CompanyId = "CompanyId",
    DateValidFrom = "DateValidFrom",
    DateValidTo = "DateValidTo",
    VatStatementFrequencyCode = "VatStatementFrequencyCode",
    VatStatusCode = "VatStatusCode",
    VatStatementFrequency = "VatStatementFrequency",
    VatStatus = "VatStatus",
}

export interface ICompoundJournalEntryEntity extends IEntityBase {
    NotId?: number;
    CreditAmount?: number;
    CurrencyCode?: string;
    DateAccountingTransaction?: Date;
    DebitAmount?: number;
    Description?: string;
    DocumentTypeCode?: string;
    IsBalancing?: boolean;
    IsClosing?: boolean;
    IsOpening?: boolean;
    TransactionCreditAmount?: number;
    TransactionCurrencyCode?: string;
    TransactionDebitAmount?: number;
    Account?: IAccountEntity;
    Company?: ICompanyEntity;
    Currency?: ICurrencyEntity;
    Document?: IDocumentEntity;
    DocumentItem?: IDocumentItemEntity;
    DocumentType?: IDocumentTypeEntity;
    FiscalPeriod?: IFiscalPeriodEntity;
    PaymentDocument?: IPaymentDocumentEntity;
    PaymentDocumentItem?: IPaymentDocumentItemEntity;
    TransactionCurrency?: ICurrencyEntity;
}

export enum CompoundJournalEntryEntity {
    NotId = "NotId",
    CreditAmount = "CreditAmount",
    CurrencyCode = "CurrencyCode",
    DateAccountingTransaction = "DateAccountingTransaction",
    DebitAmount = "DebitAmount",
    Description = "Description",
    DocumentTypeCode = "DocumentTypeCode",
    IsBalancing = "IsBalancing",
    IsClosing = "IsClosing",
    IsOpening = "IsOpening",
    TransactionCreditAmount = "TransactionCreditAmount",
    TransactionCurrencyCode = "TransactionCurrencyCode",
    TransactionDebitAmount = "TransactionDebitAmount",
    Account = "Account",
    Company = "Company",
    Currency = "Currency",
    Document = "Document",
    DocumentItem = "DocumentItem",
    DocumentType = "DocumentType",
    FiscalPeriod = "FiscalPeriod",
    PaymentDocument = "PaymentDocument",
    PaymentDocumentItem = "PaymentDocumentItem",
    TransactionCurrency = "TransactionCurrency",
}

export interface IContactEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    Email?: string;
    FirstName?: string;
    LastName?: string;
    PhoneNumber?: string;
}

export enum ContactEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    Email = "Email",
    FirstName = "FirstName",
    LastName = "LastName",
    PhoneNumber = "PhoneNumber",
}

export interface ICorrectiveInvoiceIssuedEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    Amount?: number;
    AmountNet?: number;
    AmountVat?: number;
    ClearedStatusCode?: string;
    CurrencyCode?: string;
    DateAccountingTransaction?: Date;
    DateCbaDocument?: Date;
    DateClearing?: Date;
    DateElectronicSubmissionVatControlStatement?: Date;
    DateElectronicSubmissionVatStatement?: Date;
    DateElectronicSubmissionVatViesStatement?: Date;
    DateVatDeduction?: Date;
    DocumentStatusCode?: string;
    DocumentTypeCode?: string;
    DocumentVatStatementStatusCode?: string;
    ExchangeRatePerUnit?: number;
    Note?: string;
    NumberOurs?: string;
    NumberTheirs?: string;
    PostedStatusCode?: string;
    RemittanceInformation?: string;
    SymbolConstant?: string;
    SymbolSpecific?: string;
    SymbolVariable?: string;
    TransactionAmount?: number;
    TransactionAmountDue?: number;
    TransactionAmountNet?: number;
    TransactionAmountVat?: number;
    TransactionCurrencyCode?: string;
    VatControlStatementStatusCode?: string;
    VatStatementStatusCode?: string;
    VatViesStatementStatusCode?: string;
    ExchangeRatePerUnitDateVatDeduction?: number;
    AccountAssignmentSelection?: IDocumentAccountAssignmentSelectionEntity;
    BankAccount?: IDocumentBankAccountEntity;
    BusinessPartner?: IDocumentBusinessPartnerEntity;
    CbaCategory?: IDocumentCbaCategoryEntity;
    ClearedStatus?: IClearedStatusEntity;
    Company?: ICompanyEntity;
    Currency?: ICurrencyEntity;
    DocumentDraft?: IDocumentDraftEntity;
    DocumentStatus?: IDocumentStatusEntity;
    DocumentType?: IDocumentTypeEntity;
    DocumentVatStatementStatus?: IVatStatementStatusEntity;
    FiscalPeriod?: IFiscalPeriodEntity;
    FiscalYear?: IFiscalYearEntity;
    NumberRange?: INumberRangeEntity;
    PostedStatus?: IPostedStatusEntity;
    TransactionCurrency?: ICurrencyEntity;
    VatClassificationSelection?: IDocumentVatClassificationSelectionEntity;
    VatControlStatementStatus?: IVatStatementStatusEntity;
    VatStatementStatus?: IVatStatementStatusEntity;
    VatViesStatementStatus?: IVatStatementStatusEntity;
    Attachments?: IDocumentAttachmentEntity[];
    DocumentItems?: IDocumentItemViewEntity[];
    DocumentLinks?: IDocumentLinkEntity[];
    Labels?: IDocumentLabelEntity[];
    Locks?: IDocumentLockEntity[];
    OppositeDocumentLinks?: IOppositeDocumentDocumentLinkEntity[];
    Payments?: IOppositePaymentDocumentDocumentLinkEntity[];
    ClearingDocumentLinks?: IClearingDocumentLinkEntity[];
    CorrectiveDocumentDrafts?: IDocumentDraftEntity[];
    DeferredPlans?: IDeferredPlanEntity[];
    BankPaymentOrderStateCode?: string;
    DateBankApiPaymentOrder?: Date;
    DateDue?: Date;
    DateIssued?: Date;
    DateTaxableSupply?: Date;
    Explanation?: string;
    ExternalCorrectedDocumentNumber?: string;
    ExternalCorrectedDocumentTypeCode?: string;
    PaymentMethodCode?: string;
    SymbolMatching?: string;
    TextBeforeItems?: string;
    TransactionAmountToPay?: number;
    TransactionAmountToReceive?: number;
    BankPaymentOrderState?: IBankPaymentOrderStateEntity;
    CashBox?: ICashBoxEntity;
    ExternalCorrectedDocumentType?: IExternalCorrectedDocumentTypeEntity;
    PaymentMethod?: IPaymentMethodEntity;
    Items?: IRegularDocumentItemEntity[];
    CorrectedDocuments?: IDocumentEntity[];
}

export enum CorrectiveInvoiceIssuedEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    Amount = "Amount",
    AmountNet = "AmountNet",
    AmountVat = "AmountVat",
    ClearedStatusCode = "ClearedStatusCode",
    CurrencyCode = "CurrencyCode",
    DateAccountingTransaction = "DateAccountingTransaction",
    DateCbaDocument = "DateCbaDocument",
    DateClearing = "DateClearing",
    DateElectronicSubmissionVatControlStatement = "DateElectronicSubmissionVatControlStatement",
    DateElectronicSubmissionVatStatement = "DateElectronicSubmissionVatStatement",
    DateElectronicSubmissionVatViesStatement = "DateElectronicSubmissionVatViesStatement",
    DateVatDeduction = "DateVatDeduction",
    DocumentStatusCode = "DocumentStatusCode",
    DocumentTypeCode = "DocumentTypeCode",
    DocumentVatStatementStatusCode = "DocumentVatStatementStatusCode",
    ExchangeRatePerUnit = "ExchangeRatePerUnit",
    Note = "Note",
    NumberOurs = "NumberOurs",
    NumberTheirs = "NumberTheirs",
    PostedStatusCode = "PostedStatusCode",
    RemittanceInformation = "RemittanceInformation",
    SymbolConstant = "SymbolConstant",
    SymbolSpecific = "SymbolSpecific",
    SymbolVariable = "SymbolVariable",
    TransactionAmount = "TransactionAmount",
    TransactionAmountDue = "TransactionAmountDue",
    TransactionAmountNet = "TransactionAmountNet",
    TransactionAmountVat = "TransactionAmountVat",
    TransactionCurrencyCode = "TransactionCurrencyCode",
    VatControlStatementStatusCode = "VatControlStatementStatusCode",
    VatStatementStatusCode = "VatStatementStatusCode",
    VatViesStatementStatusCode = "VatViesStatementStatusCode",
    ExchangeRatePerUnitDateVatDeduction = "ExchangeRatePerUnitDateVatDeduction",
    AccountAssignmentSelection = "AccountAssignmentSelection",
    BankAccount = "BankAccount",
    BusinessPartner = "BusinessPartner",
    CbaCategory = "CbaCategory",
    ClearedStatus = "ClearedStatus",
    Company = "Company",
    Currency = "Currency",
    DocumentDraft = "DocumentDraft",
    DocumentStatus = "DocumentStatus",
    DocumentType = "DocumentType",
    DocumentVatStatementStatus = "DocumentVatStatementStatus",
    FiscalPeriod = "FiscalPeriod",
    FiscalYear = "FiscalYear",
    NumberRange = "NumberRange",
    PostedStatus = "PostedStatus",
    TransactionCurrency = "TransactionCurrency",
    VatClassificationSelection = "VatClassificationSelection",
    VatControlStatementStatus = "VatControlStatementStatus",
    VatStatementStatus = "VatStatementStatus",
    VatViesStatementStatus = "VatViesStatementStatus",
    Attachments = "Attachments",
    DocumentItems = "DocumentItems",
    DocumentLinks = "DocumentLinks",
    Labels = "Labels",
    Locks = "Locks",
    OppositeDocumentLinks = "OppositeDocumentLinks",
    Payments = "Payments",
    ClearingDocumentLinks = "ClearingDocumentLinks",
    CorrectiveDocumentDrafts = "CorrectiveDocumentDrafts",
    DeferredPlans = "DeferredPlans",
    BankPaymentOrderStateCode = "BankPaymentOrderStateCode",
    DateBankApiPaymentOrder = "DateBankApiPaymentOrder",
    DateDue = "DateDue",
    DateIssued = "DateIssued",
    DateTaxableSupply = "DateTaxableSupply",
    Explanation = "Explanation",
    ExternalCorrectedDocumentNumber = "ExternalCorrectedDocumentNumber",
    ExternalCorrectedDocumentTypeCode = "ExternalCorrectedDocumentTypeCode",
    PaymentMethodCode = "PaymentMethodCode",
    SymbolMatching = "SymbolMatching",
    TextBeforeItems = "TextBeforeItems",
    TransactionAmountToPay = "TransactionAmountToPay",
    TransactionAmountToReceive = "TransactionAmountToReceive",
    BankPaymentOrderState = "BankPaymentOrderState",
    CashBox = "CashBox",
    ExternalCorrectedDocumentType = "ExternalCorrectedDocumentType",
    PaymentMethod = "PaymentMethod",
    Items = "Items",
    CorrectedDocuments = "CorrectedDocuments",
}

export interface ICorrectiveInvoiceReceivedEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    Amount?: number;
    AmountNet?: number;
    AmountVat?: number;
    ClearedStatusCode?: string;
    CurrencyCode?: string;
    DateAccountingTransaction?: Date;
    DateCbaDocument?: Date;
    DateClearing?: Date;
    DateElectronicSubmissionVatControlStatement?: Date;
    DateElectronicSubmissionVatStatement?: Date;
    DateElectronicSubmissionVatViesStatement?: Date;
    DateVatDeduction?: Date;
    DocumentStatusCode?: string;
    DocumentTypeCode?: string;
    DocumentVatStatementStatusCode?: string;
    ExchangeRatePerUnit?: number;
    Note?: string;
    NumberOurs?: string;
    NumberTheirs?: string;
    PostedStatusCode?: string;
    RemittanceInformation?: string;
    SymbolConstant?: string;
    SymbolSpecific?: string;
    SymbolVariable?: string;
    TransactionAmount?: number;
    TransactionAmountDue?: number;
    TransactionAmountNet?: number;
    TransactionAmountVat?: number;
    TransactionCurrencyCode?: string;
    VatControlStatementStatusCode?: string;
    VatStatementStatusCode?: string;
    VatViesStatementStatusCode?: string;
    ExchangeRatePerUnitDateVatDeduction?: number;
    AccountAssignmentSelection?: IDocumentAccountAssignmentSelectionEntity;
    BankAccount?: IDocumentBankAccountEntity;
    BusinessPartner?: IDocumentBusinessPartnerEntity;
    CbaCategory?: IDocumentCbaCategoryEntity;
    ClearedStatus?: IClearedStatusEntity;
    Company?: ICompanyEntity;
    Currency?: ICurrencyEntity;
    DocumentDraft?: IDocumentDraftEntity;
    DocumentStatus?: IDocumentStatusEntity;
    DocumentType?: IDocumentTypeEntity;
    DocumentVatStatementStatus?: IVatStatementStatusEntity;
    FiscalPeriod?: IFiscalPeriodEntity;
    FiscalYear?: IFiscalYearEntity;
    NumberRange?: INumberRangeEntity;
    PostedStatus?: IPostedStatusEntity;
    TransactionCurrency?: ICurrencyEntity;
    VatClassificationSelection?: IDocumentVatClassificationSelectionEntity;
    VatControlStatementStatus?: IVatStatementStatusEntity;
    VatStatementStatus?: IVatStatementStatusEntity;
    VatViesStatementStatus?: IVatStatementStatusEntity;
    Attachments?: IDocumentAttachmentEntity[];
    DocumentItems?: IDocumentItemViewEntity[];
    DocumentLinks?: IDocumentLinkEntity[];
    Labels?: IDocumentLabelEntity[];
    Locks?: IDocumentLockEntity[];
    OppositeDocumentLinks?: IOppositeDocumentDocumentLinkEntity[];
    Payments?: IOppositePaymentDocumentDocumentLinkEntity[];
    ClearingDocumentLinks?: IClearingDocumentLinkEntity[];
    CorrectiveDocumentDrafts?: IDocumentDraftEntity[];
    DeferredPlans?: IDeferredPlanEntity[];
    BankPaymentOrderStateCode?: string;
    DateBankApiPaymentOrder?: Date;
    DateDue?: Date;
    DateIssued?: Date;
    DateReceived?: Date;
    DateTaxableSupply?: Date;
    Explanation?: string;
    ExternalCorrectedDocumentNumber?: string;
    ExternalCorrectedDocumentTypeCode?: string;
    PaymentMethodCode?: string;
    SymbolMatching?: string;
    TextBeforeItems?: string;
    TransactionAmountToPay?: number;
    TransactionAmountToReceive?: number;
    BankPaymentOrderState?: IBankPaymentOrderStateEntity;
    CashBox?: ICashBoxEntity;
    ExternalCorrectedDocumentType?: IExternalCorrectedDocumentTypeEntity;
    PaymentMethod?: IPaymentMethodEntity;
    Items?: IRegularDocumentItemEntity[];
    CorrectedDocuments?: IDocumentEntity[];
}

export enum CorrectiveInvoiceReceivedEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    Amount = "Amount",
    AmountNet = "AmountNet",
    AmountVat = "AmountVat",
    ClearedStatusCode = "ClearedStatusCode",
    CurrencyCode = "CurrencyCode",
    DateAccountingTransaction = "DateAccountingTransaction",
    DateCbaDocument = "DateCbaDocument",
    DateClearing = "DateClearing",
    DateElectronicSubmissionVatControlStatement = "DateElectronicSubmissionVatControlStatement",
    DateElectronicSubmissionVatStatement = "DateElectronicSubmissionVatStatement",
    DateElectronicSubmissionVatViesStatement = "DateElectronicSubmissionVatViesStatement",
    DateVatDeduction = "DateVatDeduction",
    DocumentStatusCode = "DocumentStatusCode",
    DocumentTypeCode = "DocumentTypeCode",
    DocumentVatStatementStatusCode = "DocumentVatStatementStatusCode",
    ExchangeRatePerUnit = "ExchangeRatePerUnit",
    Note = "Note",
    NumberOurs = "NumberOurs",
    NumberTheirs = "NumberTheirs",
    PostedStatusCode = "PostedStatusCode",
    RemittanceInformation = "RemittanceInformation",
    SymbolConstant = "SymbolConstant",
    SymbolSpecific = "SymbolSpecific",
    SymbolVariable = "SymbolVariable",
    TransactionAmount = "TransactionAmount",
    TransactionAmountDue = "TransactionAmountDue",
    TransactionAmountNet = "TransactionAmountNet",
    TransactionAmountVat = "TransactionAmountVat",
    TransactionCurrencyCode = "TransactionCurrencyCode",
    VatControlStatementStatusCode = "VatControlStatementStatusCode",
    VatStatementStatusCode = "VatStatementStatusCode",
    VatViesStatementStatusCode = "VatViesStatementStatusCode",
    ExchangeRatePerUnitDateVatDeduction = "ExchangeRatePerUnitDateVatDeduction",
    AccountAssignmentSelection = "AccountAssignmentSelection",
    BankAccount = "BankAccount",
    BusinessPartner = "BusinessPartner",
    CbaCategory = "CbaCategory",
    ClearedStatus = "ClearedStatus",
    Company = "Company",
    Currency = "Currency",
    DocumentDraft = "DocumentDraft",
    DocumentStatus = "DocumentStatus",
    DocumentType = "DocumentType",
    DocumentVatStatementStatus = "DocumentVatStatementStatus",
    FiscalPeriod = "FiscalPeriod",
    FiscalYear = "FiscalYear",
    NumberRange = "NumberRange",
    PostedStatus = "PostedStatus",
    TransactionCurrency = "TransactionCurrency",
    VatClassificationSelection = "VatClassificationSelection",
    VatControlStatementStatus = "VatControlStatementStatus",
    VatStatementStatus = "VatStatementStatus",
    VatViesStatementStatus = "VatViesStatementStatus",
    Attachments = "Attachments",
    DocumentItems = "DocumentItems",
    DocumentLinks = "DocumentLinks",
    Labels = "Labels",
    Locks = "Locks",
    OppositeDocumentLinks = "OppositeDocumentLinks",
    Payments = "Payments",
    ClearingDocumentLinks = "ClearingDocumentLinks",
    CorrectiveDocumentDrafts = "CorrectiveDocumentDrafts",
    DeferredPlans = "DeferredPlans",
    BankPaymentOrderStateCode = "BankPaymentOrderStateCode",
    DateBankApiPaymentOrder = "DateBankApiPaymentOrder",
    DateDue = "DateDue",
    DateIssued = "DateIssued",
    DateReceived = "DateReceived",
    DateTaxableSupply = "DateTaxableSupply",
    Explanation = "Explanation",
    ExternalCorrectedDocumentNumber = "ExternalCorrectedDocumentNumber",
    ExternalCorrectedDocumentTypeCode = "ExternalCorrectedDocumentTypeCode",
    PaymentMethodCode = "PaymentMethodCode",
    SymbolMatching = "SymbolMatching",
    TextBeforeItems = "TextBeforeItems",
    TransactionAmountToPay = "TransactionAmountToPay",
    TransactionAmountToReceive = "TransactionAmountToReceive",
    BankPaymentOrderState = "BankPaymentOrderState",
    CashBox = "CashBox",
    ExternalCorrectedDocumentType = "ExternalCorrectedDocumentType",
    PaymentMethod = "PaymentMethod",
    Items = "Items",
    CorrectedDocuments = "CorrectedDocuments",
}

export interface ICountryEntity extends IEntityBase {
    Code?: string;
    Code3?: string;
    DefaultCurrencyCode?: string;
    IsEEA?: boolean;
    IsEuMember?: boolean;
    IsSepa?: boolean;
    Name?: string;
    NumericCode?: string;
    DefaultCurrency?: ICurrencyEntity;
    Currencies?: ICurrencyEntity[];
}

export enum CountryEntity {
    Code = "Code",
    Code3 = "Code3",
    DefaultCurrencyCode = "DefaultCurrencyCode",
    IsEEA = "IsEEA",
    IsEuMember = "IsEuMember",
    IsSepa = "IsSepa",
    Name = "Name",
    NumericCode = "NumericCode",
    DefaultCurrency = "DefaultCurrency",
    Currencies = "Currencies",
}

export interface ICountryClassificationEntity extends IEntityBase {
    Code?: string;
    Name?: string;
}

export enum CountryClassificationEntity {
    Code = "Code",
    Name = "Name",
}

export interface ICountryOrUnionEntity extends IEntityBase {
    Code?: string;
    Code3?: string;
    DefaultCurrencyCode?: string;
    IsEuMember?: boolean;
    Name?: string;
    NumericCode?: string;
    DefaultCurrency?: ICurrencyEntity;
    Currencies?: ICurrencyEntity[];
}

export enum CountryOrUnionEntity {
    Code = "Code",
    Code3 = "Code3",
    DefaultCurrencyCode = "DefaultCurrencyCode",
    IsEuMember = "IsEuMember",
    Name = "Name",
    NumericCode = "NumericCode",
    DefaultCurrency = "DefaultCurrency",
    Currencies = "Currencies",
}

export interface ICurrencyEntity extends IEntityBase {
    Code?: string;
    DefaultCountryCode?: string;
    Name?: string;
    NumericCode?: string;
    MinorUnit?: number;
    DefaultCountry?: ICountryOrUnionEntity;
}

export enum CurrencyEntity {
    Code = "Code",
    DefaultCountryCode = "DefaultCountryCode",
    Name = "Name",
    NumericCode = "NumericCode",
    MinorUnit = "MinorUnit",
    DefaultCountry = "DefaultCountry",
}

export interface ICurrencyUsedByCompanyEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    BankExchangeRateFreshnessCode?: string;
    CountryCode?: string;
    ExchangeRateTypeCode?: string;
    FromCurrencyCode?: string;
    SetFixedExchangeRate?: boolean;
    ToCurrencyCode?: string;
    Amount?: number;
    ExchangeRateDirect?: number;
    BankExchangeRateFreshness?: IBankExchangeRateFreshnessEntity;
    Company?: ICompanyEntity;
    Country?: ICountryOrUnionEntity;
    ExchangeRateType?: IExchangeRateTypeEntity;
    FromCurrency?: ICurrencyEntity;
    ToCurrency?: ICurrencyEntity;
    FixedExchangeRates?: ICompanyFixedExchangeRateEntity[];
}

export enum CurrencyUsedByCompanyEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    BankExchangeRateFreshnessCode = "BankExchangeRateFreshnessCode",
    CountryCode = "CountryCode",
    ExchangeRateTypeCode = "ExchangeRateTypeCode",
    FromCurrencyCode = "FromCurrencyCode",
    SetFixedExchangeRate = "SetFixedExchangeRate",
    ToCurrencyCode = "ToCurrencyCode",
    Amount = "Amount",
    ExchangeRateDirect = "ExchangeRateDirect",
    BankExchangeRateFreshness = "BankExchangeRateFreshness",
    Company = "Company",
    Country = "Country",
    ExchangeRateType = "ExchangeRateType",
    FromCurrency = "FromCurrency",
    ToCurrency = "ToCurrency",
    FixedExchangeRates = "FixedExchangeRates",
}

export interface ICurrencyWithExchangeRateInfoEntity extends IEntityBase {
    Id?: number;
    Code?: string;
    DefaultCountryCode?: string;
    IsCurrencyExchangeRateProvidedByCentralBank?: boolean;
    IsCurrencyExchangeRateProvidedByCentralBankDaily?: boolean;
    Name?: string;
    NumericCode?: string;
    MinorUnit?: number;
}

export enum CurrencyWithExchangeRateInfoEntity {
    Id = "Id",
    Code = "Code",
    DefaultCountryCode = "DefaultCountryCode",
    IsCurrencyExchangeRateProvidedByCentralBank = "IsCurrencyExchangeRateProvidedByCentralBank",
    IsCurrencyExchangeRateProvidedByCentralBankDaily = "IsCurrencyExchangeRateProvidedByCentralBankDaily",
    Name = "Name",
    NumericCode = "NumericCode",
    MinorUnit = "MinorUnit",
}

export interface ICustomerPortalPaymentEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    AbaNumber?: string;
    AccountNumber?: string;
    BankCode?: string;
    BusinessPartnerName?: string;
    CountryCode?: string;
    DateDue?: Date;
    DocumentTypeCode?: string;
    IBAN?: string;
    IsPaid?: boolean;
    LegalNumber?: string;
    NumberOurs?: string;
    NumberTheirs?: string;
    QrCode?: string;
    RemittanceInformation?: string;
    SWIFT?: string;
    SymbolConstant?: string;
    SymbolSpecific?: string;
    SymbolVariable?: string;
    TransactionAmount?: number;
    TransactionCurrencyCode?: string;
    Country?: ICountryEntity;
    Document?: IDocumentEntity;
    DocumentType?: IDocumentTypeEntity;
    TransactionCurrency?: ICurrencyEntity;
}

export enum CustomerPortalPaymentEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    AbaNumber = "AbaNumber",
    AccountNumber = "AccountNumber",
    BankCode = "BankCode",
    BusinessPartnerName = "BusinessPartnerName",
    CountryCode = "CountryCode",
    DateDue = "DateDue",
    DocumentTypeCode = "DocumentTypeCode",
    IBAN = "IBAN",
    IsPaid = "IsPaid",
    LegalNumber = "LegalNumber",
    NumberOurs = "NumberOurs",
    NumberTheirs = "NumberTheirs",
    QrCode = "QrCode",
    RemittanceInformation = "RemittanceInformation",
    SWIFT = "SWIFT",
    SymbolConstant = "SymbolConstant",
    SymbolSpecific = "SymbolSpecific",
    SymbolVariable = "SymbolVariable",
    TransactionAmount = "TransactionAmount",
    TransactionCurrencyCode = "TransactionCurrencyCode",
    Country = "Country",
    Document = "Document",
    DocumentType = "DocumentType",
    TransactionCurrency = "TransactionCurrency",
}

export interface ICzNaceEntity extends IEntityBase {
    Code?: string;
    Description?: string;
    Name?: string;
}

export enum CzNaceEntity {
    Code = "Code",
    Description = "Description",
    Name = "Name",
}

export interface IDashboardTaskEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    Description?: string;
    SequenceOrder?: number;
    DateCompleted?: Date;
}

export enum DashboardTaskEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    Description = "Description",
    SequenceOrder = "SequenceOrder",
    DateCompleted = "DateCompleted",
}

export interface IDataBoxEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    AllowReadingNewMessages?: boolean;
    CanCheckMessages?: boolean;
    CanReadMessages?: boolean;
    CanSendMessages?: boolean;
    CompanyAddress?: string;
    CompanyName?: string;
    DataBoxId?: string;
    DataBoxUserName?: string;
    IsActive?: boolean;
    LegalNumber?: string;
    PasswordId?: number;
    UserName?: string;
    PasswordSetter?: string;
    Company?: ICompanyEntity;
}

export enum DataBoxEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    AllowReadingNewMessages = "AllowReadingNewMessages",
    CanCheckMessages = "CanCheckMessages",
    CanReadMessages = "CanReadMessages",
    CanSendMessages = "CanSendMessages",
    CompanyAddress = "CompanyAddress",
    CompanyName = "CompanyName",
    DataBoxId = "DataBoxId",
    DataBoxUserName = "DataBoxUserName",
    IsActive = "IsActive",
    LegalNumber = "LegalNumber",
    PasswordId = "PasswordId",
    UserName = "UserName",
    PasswordSetter = "PasswordSetter",
    Company = "Company",
}

export interface IDataLimitEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    DataLimitTypeCode?: string;
    Limit?: number;
    DataLimitType?: IDataLimitTypeEntity;
}

export enum DataLimitEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    DataLimitTypeCode = "DataLimitTypeCode",
    Limit = "Limit",
    DataLimitType = "DataLimitType",
}

export interface IDataLimitTypeEntity extends IEntityBase {
    Code?: string;
    Name?: string;
}

export enum DataLimitTypeEntity {
    Code = "Code",
    Name = "Name",
}

export interface IDateFormatEntity extends IEntityBase {
    Code?: string;
    Format?: string;
    Name?: string;
}

export enum DateFormatEntity {
    Code = "Code",
    Format = "Format",
    Name = "Name",
}

export interface IDayOfWeekEntity extends IEntityBase {
    Code?: string;
    Name?: string;
}

export enum DayOfWeekEntity {
    Code = "Code",
    Name = "Name",
}

export interface IDefaultVariantEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    AccessTypeCode?: string;
    AccountingCode?: string;
    VariantTypeCode?: string;
    VatStatusCode?: string;
    ViewId?: string;
    AccessType?: IVariantAccessTypeEntity;
    Accounting?: IAccountingEntity;
    Owner?: IUserEntity;
    Variant?: IVariantEntity;
    VariantType?: IVariantTypeEntity;
    VatStatus?: IVatStatusEntity;
}

export enum DefaultVariantEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    AccessTypeCode = "AccessTypeCode",
    AccountingCode = "AccountingCode",
    VariantTypeCode = "VariantTypeCode",
    VatStatusCode = "VatStatusCode",
    ViewId = "ViewId",
    AccessType = "AccessType",
    Accounting = "Accounting",
    Owner = "Owner",
    Variant = "Variant",
    VariantType = "VariantType",
    VatStatus = "VatStatus",
}

export interface IDeferredDocumentEntity extends IEntityBase {
    Id?: number;
    Amount?: number;
    CurrencyCode?: string;
    DateAccountingTransaction?: Date;
    DocumentLinkTypeCode?: string;
    Company?: ICompanyEntity;
    Currency?: ICurrencyEntity;
    DocumentLinkType?: IDocumentLinkTypeEntity;
    OriginalDocument?: IDocumentEntity;
    TargetDocument?: IInternalDocumentEntity;
}

export enum DeferredDocumentEntity {
    Id = "Id",
    Amount = "Amount",
    CurrencyCode = "CurrencyCode",
    DateAccountingTransaction = "DateAccountingTransaction",
    DocumentLinkTypeCode = "DocumentLinkTypeCode",
    Company = "Company",
    Currency = "Currency",
    DocumentLinkType = "DocumentLinkType",
    OriginalDocument = "OriginalDocument",
    TargetDocument = "TargetDocument",
}

export interface IDeferredPlanEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    DateEnd?: Date;
    DateStart?: Date;
    Description?: string;
    PeriodDayCode?: string;
    PostingTypeCode?: string;
    Account?: IAccountEntity;
    ChartOfAccounts?: IChartOfAccountsEntity;
    Company?: ICompanyEntity;
    OriginalDocument?: IDocumentEntity;
    PeriodDay?: IPeriodDayEntity;
    PostingType?: IDeferredPlanPostingTypeEntity;
    DeferredDocuments?: IDeferredPlanDocumentEntity[];
    OriginalDocumentItems?: IDeferredPlanOriginalDocumentItemEntity[];
}

export enum DeferredPlanEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    DateEnd = "DateEnd",
    DateStart = "DateStart",
    Description = "Description",
    PeriodDayCode = "PeriodDayCode",
    PostingTypeCode = "PostingTypeCode",
    Account = "Account",
    ChartOfAccounts = "ChartOfAccounts",
    Company = "Company",
    OriginalDocument = "OriginalDocument",
    PeriodDay = "PeriodDay",
    PostingType = "PostingType",
    DeferredDocuments = "DeferredDocuments",
    OriginalDocumentItems = "OriginalDocumentItems",
}

export interface IDeferredPlanDocumentEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    Amount?: number;
    DateAccountingTransaction?: Date;
    TargetDocument?: IInternalDocumentEntity;
    Items?: IDeferredPlanDocumentItemEntity[];
}

export enum DeferredPlanDocumentEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    Amount = "Amount",
    DateAccountingTransaction = "DateAccountingTransaction",
    TargetDocument = "TargetDocument",
    Items = "Items",
}

export interface IDeferredPlanDocumentItemEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    OriginalDocumentItem?: IDocumentItemEntity;
    TargetDocumentItem?: IDocumentItemEntity;
}

export enum DeferredPlanDocumentItemEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    OriginalDocumentItem = "OriginalDocumentItem",
    TargetDocumentItem = "TargetDocumentItem",
}

export interface IDeferredPlanOriginalDocumentItemEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    Amount?: number;
    OriginalDocumentItem?: IDocumentItemEntity;
}

export enum DeferredPlanOriginalDocumentItemEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    Amount = "Amount",
    OriginalDocumentItem = "OriginalDocumentItem",
}

export interface IDeferredPlanPostingTypeEntity extends IEntityBase {
    Code?: string;
    Name?: string;
}

export enum DeferredPlanPostingTypeEntity {
    Code = "Code",
    Name = "Name",
}

export interface IDepreciationPolicyEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    AccumulatedDepreciation?: number;
    CurrencyCode?: string;
    DateFirstDepreciation?: Date;
    DateLastDepreciation?: Date;
    Currency?: ICurrencyEntity;
}

export enum DepreciationPolicyEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    AccumulatedDepreciation = "AccumulatedDepreciation",
    CurrencyCode = "CurrencyCode",
    DateFirstDepreciation = "DateFirstDepreciation",
    DateLastDepreciation = "DateLastDepreciation",
    Currency = "Currency",
}

export interface IDepreciationPolicyItemEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    AccumulatedDepreciation?: number;
    CalculatedExpense?: number;
    CurrencyCode?: string;
    DateDepreciation?: Date;
    EndValue?: number;
    Order?: number;
    Currency?: ICurrencyEntity;
}

export enum DepreciationPolicyItemEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    AccumulatedDepreciation = "AccumulatedDepreciation",
    CalculatedExpense = "CalculatedExpense",
    CurrencyCode = "CurrencyCode",
    DateDepreciation = "DateDepreciation",
    EndValue = "EndValue",
    Order = "Order",
    Currency = "Currency",
}

export interface IDepreciationPolicyTypeEntity extends IEntityBase {
    Code?: string;
    Name?: string;
}

export enum DepreciationPolicyTypeEntity {
    Code = "Code",
    Name = "Name",
}

export interface IDepreciationTypeEntity extends IEntityBase {
    Code?: string;
    Name?: string;
}

export enum DepreciationTypeEntity {
    Code = "Code",
    Name = "Name",
}

export interface IDiscountTypeEntity extends IEntityBase {
    Code?: string;
    Name?: string;
    S5articleGuid?: string;
}

export enum DiscountTypeEntity {
    Code = "Code",
    Name = "Name",
    S5articleGuid = "S5articleGuid",
}

export interface IDocumentEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    Amount?: number;
    AmountNet?: number;
    AmountVat?: number;
    ClearedStatusCode?: string;
    CurrencyCode?: string;
    DateAccountingTransaction?: Date;
    DateCbaDocument?: Date;
    DateClearing?: Date;
    DateElectronicSubmissionVatControlStatement?: Date;
    DateElectronicSubmissionVatStatement?: Date;
    DateElectronicSubmissionVatViesStatement?: Date;
    DateVatDeduction?: Date;
    DocumentStatusCode?: string;
    DocumentTypeCode?: string;
    DocumentVatStatementStatusCode?: string;
    ExchangeRatePerUnit?: number;
    Note?: string;
    NumberOurs?: string;
    NumberTheirs?: string;
    PostedStatusCode?: string;
    RemittanceInformation?: string;
    SymbolConstant?: string;
    SymbolSpecific?: string;
    SymbolVariable?: string;
    TransactionAmount?: number;
    TransactionAmountDue?: number;
    TransactionAmountNet?: number;
    TransactionAmountVat?: number;
    TransactionCurrencyCode?: string;
    VatControlStatementStatusCode?: string;
    VatStatementStatusCode?: string;
    VatViesStatementStatusCode?: string;
    ExchangeRatePerUnitDateVatDeduction?: number;
    AccountAssignmentSelection?: IDocumentAccountAssignmentSelectionEntity;
    BankAccount?: IDocumentBankAccountEntity;
    BusinessPartner?: IDocumentBusinessPartnerEntity;
    CbaCategory?: IDocumentCbaCategoryEntity;
    ClearedStatus?: IClearedStatusEntity;
    Company?: ICompanyEntity;
    Currency?: ICurrencyEntity;
    DocumentDraft?: IDocumentDraftEntity;
    DocumentStatus?: IDocumentStatusEntity;
    DocumentType?: IDocumentTypeEntity;
    DocumentVatStatementStatus?: IVatStatementStatusEntity;
    FiscalPeriod?: IFiscalPeriodEntity;
    FiscalYear?: IFiscalYearEntity;
    NumberRange?: INumberRangeEntity;
    PostedStatus?: IPostedStatusEntity;
    TransactionCurrency?: ICurrencyEntity;
    VatClassificationSelection?: IDocumentVatClassificationSelectionEntity;
    VatControlStatementStatus?: IVatStatementStatusEntity;
    VatStatementStatus?: IVatStatementStatusEntity;
    VatViesStatementStatus?: IVatStatementStatusEntity;
    Attachments?: IDocumentAttachmentEntity[];
    DocumentItems?: IDocumentItemViewEntity[];
    DocumentLinks?: IDocumentLinkEntity[];
    Labels?: IDocumentLabelEntity[];
    Locks?: IDocumentLockEntity[];
    OppositeDocumentLinks?: IOppositeDocumentDocumentLinkEntity[];
    Payments?: IOppositePaymentDocumentDocumentLinkEntity[];
    ClearingDocumentLinks?: IClearingDocumentLinkEntity[];
    CorrectiveDocumentDrafts?: IDocumentDraftEntity[];
    DeferredPlans?: IDeferredPlanEntity[];
}

export enum DocumentEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    Amount = "Amount",
    AmountNet = "AmountNet",
    AmountVat = "AmountVat",
    ClearedStatusCode = "ClearedStatusCode",
    CurrencyCode = "CurrencyCode",
    DateAccountingTransaction = "DateAccountingTransaction",
    DateCbaDocument = "DateCbaDocument",
    DateClearing = "DateClearing",
    DateElectronicSubmissionVatControlStatement = "DateElectronicSubmissionVatControlStatement",
    DateElectronicSubmissionVatStatement = "DateElectronicSubmissionVatStatement",
    DateElectronicSubmissionVatViesStatement = "DateElectronicSubmissionVatViesStatement",
    DateVatDeduction = "DateVatDeduction",
    DocumentStatusCode = "DocumentStatusCode",
    DocumentTypeCode = "DocumentTypeCode",
    DocumentVatStatementStatusCode = "DocumentVatStatementStatusCode",
    ExchangeRatePerUnit = "ExchangeRatePerUnit",
    Note = "Note",
    NumberOurs = "NumberOurs",
    NumberTheirs = "NumberTheirs",
    PostedStatusCode = "PostedStatusCode",
    RemittanceInformation = "RemittanceInformation",
    SymbolConstant = "SymbolConstant",
    SymbolSpecific = "SymbolSpecific",
    SymbolVariable = "SymbolVariable",
    TransactionAmount = "TransactionAmount",
    TransactionAmountDue = "TransactionAmountDue",
    TransactionAmountNet = "TransactionAmountNet",
    TransactionAmountVat = "TransactionAmountVat",
    TransactionCurrencyCode = "TransactionCurrencyCode",
    VatControlStatementStatusCode = "VatControlStatementStatusCode",
    VatStatementStatusCode = "VatStatementStatusCode",
    VatViesStatementStatusCode = "VatViesStatementStatusCode",
    ExchangeRatePerUnitDateVatDeduction = "ExchangeRatePerUnitDateVatDeduction",
    AccountAssignmentSelection = "AccountAssignmentSelection",
    BankAccount = "BankAccount",
    BusinessPartner = "BusinessPartner",
    CbaCategory = "CbaCategory",
    ClearedStatus = "ClearedStatus",
    Company = "Company",
    Currency = "Currency",
    DocumentDraft = "DocumentDraft",
    DocumentStatus = "DocumentStatus",
    DocumentType = "DocumentType",
    DocumentVatStatementStatus = "DocumentVatStatementStatus",
    FiscalPeriod = "FiscalPeriod",
    FiscalYear = "FiscalYear",
    NumberRange = "NumberRange",
    PostedStatus = "PostedStatus",
    TransactionCurrency = "TransactionCurrency",
    VatClassificationSelection = "VatClassificationSelection",
    VatControlStatementStatus = "VatControlStatementStatus",
    VatStatementStatus = "VatStatementStatus",
    VatViesStatementStatus = "VatViesStatementStatus",
    Attachments = "Attachments",
    DocumentItems = "DocumentItems",
    DocumentLinks = "DocumentLinks",
    Labels = "Labels",
    Locks = "Locks",
    OppositeDocumentLinks = "OppositeDocumentLinks",
    Payments = "Payments",
    ClearingDocumentLinks = "ClearingDocumentLinks",
    CorrectiveDocumentDrafts = "CorrectiveDocumentDrafts",
    DeferredPlans = "DeferredPlans",
}

export interface IDocumentAccountAssignmentEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    Name?: string;
    Note?: string;
    ShortName?: string;
    ChartOfAccounts?: IChartOfAccountsEntity;
    CreditAccount?: IAccountEntity;
    DebitAccount?: IAccountEntity;
}

export enum DocumentAccountAssignmentEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    Name = "Name",
    Note = "Note",
    ShortName = "ShortName",
    ChartOfAccounts = "ChartOfAccounts",
    CreditAccount = "CreditAccount",
    DebitAccount = "DebitAccount",
}

export interface IDocumentAccountAssignmentDraftEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    Name?: string;
    Note?: string;
    ShortName?: string;
    ChartOfAccounts?: IChartOfAccountsEntity;
    CreditAccount?: IAccountEntity;
    DebitAccount?: IAccountEntity;
}

export enum DocumentAccountAssignmentDraftEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    Name = "Name",
    Note = "Note",
    ShortName = "ShortName",
    ChartOfAccounts = "ChartOfAccounts",
    CreditAccount = "CreditAccount",
    DebitAccount = "DebitAccount",
}

export interface IDocumentAccountAssignmentSelectionEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    SelectionCode?: string;
    Selection?: ISelectionEntity;
    AccountAssignment?: IDocumentAccountAssignmentEntity;
}

export enum DocumentAccountAssignmentSelectionEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    SelectionCode = "SelectionCode",
    Selection = "Selection",
    AccountAssignment = "AccountAssignment",
}

export interface IDocumentAccountAssignmentSelectionDraftEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    SelectionCode?: string;
    Selection?: ISelectionEntity;
    AccountAssignment?: IDocumentAccountAssignmentDraftEntity;
}

export enum DocumentAccountAssignmentSelectionDraftEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    SelectionCode = "SelectionCode",
    Selection = "Selection",
    AccountAssignment = "AccountAssignment",
}

export interface IDocumentAssetEntryEntity extends IEntityBase {
    NotId?: number;
    Asset?: IAssetEntity;
    AssetItem?: IAssetItemEntity;
    Document?: IDocumentEntity;
    DocumentItem?: IDocumentItemEntity;
}

export enum DocumentAssetEntryEntity {
    NotId = "NotId",
    Asset = "Asset",
    AssetItem = "AssetItem",
    Document = "Document",
    DocumentItem = "DocumentItem",
}

export interface IDocumentAttachmentEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    Description?: string;
    File?: IFileMetadataEntity;
}

export enum DocumentAttachmentEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    Description = "Description",
    File = "File",
}

export interface IDocumentAttachmentDeletedEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    Description?: string;
    File?: IFileMetadataEntity;
}

export enum DocumentAttachmentDeletedEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    Description = "Description",
    File = "File",
}

export interface IDocumentAttachmentDraftEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    Description?: string;
    File?: IFileMetadataEntity;
}

export enum DocumentAttachmentDraftEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    Description = "Description",
    File = "File",
}

export interface IDocumentBankAccountEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    AbaNumber?: string;
    AccountNumber?: string;
    BankCode?: string;
    CountryCode?: string;
    IBAN?: string;
    PaymentServiceID?: string;
    PaymentServiceName?: string;
    SWIFT?: string;
    Bank?: IBankEntity;
    Country?: ICountryEntity;
}

export enum DocumentBankAccountEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    AbaNumber = "AbaNumber",
    AccountNumber = "AccountNumber",
    BankCode = "BankCode",
    CountryCode = "CountryCode",
    IBAN = "IBAN",
    PaymentServiceID = "PaymentServiceID",
    PaymentServiceName = "PaymentServiceName",
    SWIFT = "SWIFT",
    Bank = "Bank",
    Country = "Country",
}

export interface IDocumentBankAccountDraftEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    AbaNumber?: string;
    AccountNumber?: string;
    BankCode?: string;
    CountryCode?: string;
    IBAN?: string;
    PaymentServiceID?: string;
    PaymentServiceName?: string;
    SWIFT?: string;
    Bank?: IBankEntity;
    Country?: ICountryEntity;
}

export enum DocumentBankAccountDraftEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    AbaNumber = "AbaNumber",
    AccountNumber = "AccountNumber",
    BankCode = "BankCode",
    CountryCode = "CountryCode",
    IBAN = "IBAN",
    PaymentServiceID = "PaymentServiceID",
    PaymentServiceName = "PaymentServiceName",
    SWIFT = "SWIFT",
    Bank = "Bank",
    Country = "Country",
}

export interface IDocumentBusinessPartnerEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    City?: string;
    CountryCode?: string;
    Email?: string;
    FirstName?: string;
    LastName?: string;
    LegalNumber?: string;
    Name?: string;
    PhoneNumber?: string;
    PostalCode?: string;
    Street?: string;
    TaxNumber?: string;
    VatStatusCode?: string;
    BusinessPartner?: IBusinessPartnerEntity;
    Country?: ICountryEntity;
    VatStatus?: IVatStatusEntity;
}

export enum DocumentBusinessPartnerEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    City = "City",
    CountryCode = "CountryCode",
    Email = "Email",
    FirstName = "FirstName",
    LastName = "LastName",
    LegalNumber = "LegalNumber",
    Name = "Name",
    PhoneNumber = "PhoneNumber",
    PostalCode = "PostalCode",
    Street = "Street",
    TaxNumber = "TaxNumber",
    VatStatusCode = "VatStatusCode",
    BusinessPartner = "BusinessPartner",
    Country = "Country",
    VatStatus = "VatStatus",
}

export interface IDocumentBusinessPartnerDraftEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    City?: string;
    CountryCode?: string;
    Email?: string;
    FirstName?: string;
    LastName?: string;
    LegalNumber?: string;
    Name?: string;
    PhoneNumber?: string;
    PostalCode?: string;
    Street?: string;
    TaxNumber?: string;
    VatStatusCode?: string;
    BusinessPartner?: IBusinessPartnerEntity;
    Country?: ICountryEntity;
    VatStatus?: IVatStatusEntity;
}

export enum DocumentBusinessPartnerDraftEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    City = "City",
    CountryCode = "CountryCode",
    Email = "Email",
    FirstName = "FirstName",
    LastName = "LastName",
    LegalNumber = "LegalNumber",
    Name = "Name",
    PhoneNumber = "PhoneNumber",
    PostalCode = "PostalCode",
    Street = "Street",
    TaxNumber = "TaxNumber",
    VatStatusCode = "VatStatusCode",
    BusinessPartner = "BusinessPartner",
    Country = "Country",
    VatStatus = "VatStatus",
}

export interface IDocumentCbaCategoryEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    IsAssetAcquisition?: boolean;
    TaxImpactCode?: string;
    TaxPercentage?: number;
    Category?: ICbaCategoryEntity;
    TaxImpact?: ICbaCategoryTaxImpactEntity;
}

export enum DocumentCbaCategoryEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    IsAssetAcquisition = "IsAssetAcquisition",
    TaxImpactCode = "TaxImpactCode",
    TaxPercentage = "TaxPercentage",
    Category = "Category",
    TaxImpact = "TaxImpact",
}

export interface IDocumentCbaCategoryDraftEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    IsAssetAcquisition?: boolean;
    TaxImpactCode?: string;
    TaxPercentage?: number;
    Category?: ICbaCategoryEntity;
    TaxImpact?: ICbaCategoryTaxImpactEntity;
}

export enum DocumentCbaCategoryDraftEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    IsAssetAcquisition = "IsAssetAcquisition",
    TaxImpactCode = "TaxImpactCode",
    TaxPercentage = "TaxPercentage",
    Category = "Category",
    TaxImpact = "TaxImpact",
}

export interface IDocumentDeleteLogEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    DocumentId?: number;
    DocumentTypeCode?: string;
    InteractionTypeCode?: string;
    NumberOurs?: string;
    DocumentType?: IDocumentTypeEntity;
    InteractionType?: IInteractionTypeEntity;
    Attachments?: IDocumentAttachmentDeletedEntity[];
}

export enum DocumentDeleteLogEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    DocumentId = "DocumentId",
    DocumentTypeCode = "DocumentTypeCode",
    InteractionTypeCode = "InteractionTypeCode",
    NumberOurs = "NumberOurs",
    DocumentType = "DocumentType",
    InteractionType = "InteractionType",
    Attachments = "Attachments",
}

export interface IDocumentDraftEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    BankPaymentOrderStateCode?: string;
    CalculateVat?: boolean;
    CbaEntryTypeCode?: string;
    CurrencyCode?: string;
    DateAccountingTransaction?: Date;
    DateBankApiPaymentOrder?: Date;
    DateCbaDocument?: Date;
    DateCbaEntry?: Date;
    DateDue?: Date;
    DateElectronicSubmissionVatControlStatement?: Date;
    DateElectronicSubmissionVatStatement?: Date;
    DateElectronicSubmissionVatViesStatement?: Date;
    DateIssued?: Date;
    DatePaymentReceived?: Date;
    DateProcessed?: Date;
    DateReceived?: Date;
    DateTaxableSupply?: Date;
    DateVatDeduction?: Date;
    DocumentStatusCode?: string;
    DocumentTypeCode?: string;
    Explanation?: string;
    ExternalCorrectedDocumentNumber?: string;
    ExternalCorrectedDocumentTypeCode?: string;
    IsTaxDocument?: boolean;
    Note?: string;
    NumberOurs?: string;
    NumberTheirs?: string;
    PaymentMethodCode?: string;
    RemittanceInformation?: string;
    SymbolConstant?: string;
    SymbolMatching?: string;
    SymbolSpecific?: string;
    SymbolVariable?: string;
    TextBeforeItems?: string;
    TransactionCurrencyCode?: string;
    Amount?: number;
    AmountNet?: number;
    AmountVat?: number;
    TransactionAmount?: number;
    TransactionAmountNet?: number;
    TransactionAmountVat?: number;
    TransactionAmountDue?: number;
    ExchangeRatePerUnit?: number;
    ExchangeRatePerUnitDateVatDeduction?: number;
    AccountAssignmentSelection?: IDocumentAccountAssignmentSelectionDraftEntity;
    BankAccount?: IDocumentBankAccountDraftEntity;
    BankPaymentOrderState?: IBankPaymentOrderStateEntity;
    BusinessPartner?: IDocumentBusinessPartnerDraftEntity;
    CashBox?: ICashBoxEntity;
    CbaCategory?: IDocumentCbaCategoryDraftEntity;
    CbaEntryType?: ICbaEntryTypeEntity;
    Company?: ICompanyEntity;
    Currency?: ICurrencyEntity;
    Document?: IDocumentEntity;
    DocumentStatus?: IDocumentStatusEntity;
    DocumentType?: IDocumentTypeEntity;
    ExternalCorrectedDocumentType?: IExternalCorrectedDocumentTypeEntity;
    FiscalPeriod?: IFiscalPeriodEntity;
    FiscalYear?: IFiscalYearEntity;
    GeneratedByRecurringTask?: IRecurringTaskEntity;
    NumberRange?: INumberRangeEntity;
    PaymentMethod?: IPaymentMethodEntity;
    TemplateForRecurringTask?: IRecurringTaskEntity;
    TransactionCurrency?: ICurrencyEntity;
    VatClassificationSelection?: IDocumentVatClassificationSelectionDraftEntity;
    Attachments?: IDocumentAttachmentDraftEntity[];
    DocumentLinks?: IDocumentLinkDraftEntity[];
    Items?: IDocumentItemDraftEntity[];
    Labels?: IDocumentLabelDraftEntity[];
    Locks?: IDocumentLockDraftEntity[];
    CorrectedDocuments?: IDocumentEntity[];
}

export enum DocumentDraftEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    BankPaymentOrderStateCode = "BankPaymentOrderStateCode",
    CalculateVat = "CalculateVat",
    CbaEntryTypeCode = "CbaEntryTypeCode",
    CurrencyCode = "CurrencyCode",
    DateAccountingTransaction = "DateAccountingTransaction",
    DateBankApiPaymentOrder = "DateBankApiPaymentOrder",
    DateCbaDocument = "DateCbaDocument",
    DateCbaEntry = "DateCbaEntry",
    DateDue = "DateDue",
    DateElectronicSubmissionVatControlStatement = "DateElectronicSubmissionVatControlStatement",
    DateElectronicSubmissionVatStatement = "DateElectronicSubmissionVatStatement",
    DateElectronicSubmissionVatViesStatement = "DateElectronicSubmissionVatViesStatement",
    DateIssued = "DateIssued",
    DatePaymentReceived = "DatePaymentReceived",
    DateProcessed = "DateProcessed",
    DateReceived = "DateReceived",
    DateTaxableSupply = "DateTaxableSupply",
    DateVatDeduction = "DateVatDeduction",
    DocumentStatusCode = "DocumentStatusCode",
    DocumentTypeCode = "DocumentTypeCode",
    Explanation = "Explanation",
    ExternalCorrectedDocumentNumber = "ExternalCorrectedDocumentNumber",
    ExternalCorrectedDocumentTypeCode = "ExternalCorrectedDocumentTypeCode",
    IsTaxDocument = "IsTaxDocument",
    Note = "Note",
    NumberOurs = "NumberOurs",
    NumberTheirs = "NumberTheirs",
    PaymentMethodCode = "PaymentMethodCode",
    RemittanceInformation = "RemittanceInformation",
    SymbolConstant = "SymbolConstant",
    SymbolMatching = "SymbolMatching",
    SymbolSpecific = "SymbolSpecific",
    SymbolVariable = "SymbolVariable",
    TextBeforeItems = "TextBeforeItems",
    TransactionCurrencyCode = "TransactionCurrencyCode",
    Amount = "Amount",
    AmountNet = "AmountNet",
    AmountVat = "AmountVat",
    TransactionAmount = "TransactionAmount",
    TransactionAmountNet = "TransactionAmountNet",
    TransactionAmountVat = "TransactionAmountVat",
    TransactionAmountDue = "TransactionAmountDue",
    ExchangeRatePerUnit = "ExchangeRatePerUnit",
    ExchangeRatePerUnitDateVatDeduction = "ExchangeRatePerUnitDateVatDeduction",
    AccountAssignmentSelection = "AccountAssignmentSelection",
    BankAccount = "BankAccount",
    BankPaymentOrderState = "BankPaymentOrderState",
    BusinessPartner = "BusinessPartner",
    CashBox = "CashBox",
    CbaCategory = "CbaCategory",
    CbaEntryType = "CbaEntryType",
    Company = "Company",
    Currency = "Currency",
    Document = "Document",
    DocumentStatus = "DocumentStatus",
    DocumentType = "DocumentType",
    ExternalCorrectedDocumentType = "ExternalCorrectedDocumentType",
    FiscalPeriod = "FiscalPeriod",
    FiscalYear = "FiscalYear",
    GeneratedByRecurringTask = "GeneratedByRecurringTask",
    NumberRange = "NumberRange",
    PaymentMethod = "PaymentMethod",
    TemplateForRecurringTask = "TemplateForRecurringTask",
    TransactionCurrency = "TransactionCurrency",
    VatClassificationSelection = "VatClassificationSelection",
    Attachments = "Attachments",
    DocumentLinks = "DocumentLinks",
    Items = "Items",
    Labels = "Labels",
    Locks = "Locks",
    CorrectedDocuments = "CorrectedDocuments",
}

export interface IDocumentImportTypeEntity extends IEntityBase {
    Code?: string;
    Name?: string;
}

export enum DocumentImportTypeEntity {
    Code = "Code",
    Name = "Name",
}

export interface IDocumentItemEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    Amount?: number;
    AmountNet?: number;
    AmountVat?: number;
    Description?: string;
    Note?: string;
    Order?: number;
    TransactionAmount?: number;
    TransactionAmountNet?: number;
    TransactionAmountVat?: number;
    VatCode?: string;
    AccountAssignmentSelection?: IDocumentItemAccountAssignmentSelectionEntity;
    CbaCategory?: IDocumentItemCbaCategoryEntity;
    DocumentItemDraft?: IDocumentItemDraftEntity;
    LabelSelection?: ILabelSelectionEntity;
    Vat?: IVatEntity;
    VatClassificationSelection?: IDocumentItemVatClassificationSelectionEntity;
}

export enum DocumentItemEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    Amount = "Amount",
    AmountNet = "AmountNet",
    AmountVat = "AmountVat",
    Description = "Description",
    Note = "Note",
    Order = "Order",
    TransactionAmount = "TransactionAmount",
    TransactionAmountNet = "TransactionAmountNet",
    TransactionAmountVat = "TransactionAmountVat",
    VatCode = "VatCode",
    AccountAssignmentSelection = "AccountAssignmentSelection",
    CbaCategory = "CbaCategory",
    DocumentItemDraft = "DocumentItemDraft",
    LabelSelection = "LabelSelection",
    Vat = "Vat",
    VatClassificationSelection = "VatClassificationSelection",
}

export interface IDocumentItemAccountAssignmentEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    Name?: string;
    Note?: string;
    ShortName?: string;
    ChartOfAccounts?: IChartOfAccountsEntity;
    CreditAccount?: IAccountEntity;
    DebitAccount?: IAccountEntity;
}

export enum DocumentItemAccountAssignmentEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    Name = "Name",
    Note = "Note",
    ShortName = "ShortName",
    ChartOfAccounts = "ChartOfAccounts",
    CreditAccount = "CreditAccount",
    DebitAccount = "DebitAccount",
}

export interface IDocumentItemAccountAssignmentDraftEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    Name?: string;
    Note?: string;
    ShortName?: string;
    ChartOfAccounts?: IChartOfAccountsEntity;
    CreditAccount?: IAccountEntity;
    DebitAccount?: IAccountEntity;
}

export enum DocumentItemAccountAssignmentDraftEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    Name = "Name",
    Note = "Note",
    ShortName = "ShortName",
    ChartOfAccounts = "ChartOfAccounts",
    CreditAccount = "CreditAccount",
    DebitAccount = "DebitAccount",
}

export interface IDocumentItemAccountAssignmentSelectionEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    SelectionCode?: string;
    Selection?: ISelectionEntity;
    AccountAssignment?: IDocumentItemAccountAssignmentEntity;
}

export enum DocumentItemAccountAssignmentSelectionEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    SelectionCode = "SelectionCode",
    Selection = "Selection",
    AccountAssignment = "AccountAssignment",
}

export interface IDocumentItemAccountAssignmentSelectionDraftEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    SelectionCode?: string;
    Selection?: ISelectionEntity;
    AccountAssignment?: IDocumentItemAccountAssignmentDraftEntity;
}

export enum DocumentItemAccountAssignmentSelectionDraftEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    SelectionCode = "SelectionCode",
    Selection = "Selection",
    AccountAssignment = "AccountAssignment",
}

export interface IDocumentItemCbaCategoryEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    IsAssetAcquisition?: boolean;
    TaxImpactCode?: string;
    TaxPercentage?: number;
    Category?: ICbaCategoryEntity;
    TaxImpact?: ICbaCategoryTaxImpactEntity;
}

export enum DocumentItemCbaCategoryEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    IsAssetAcquisition = "IsAssetAcquisition",
    TaxImpactCode = "TaxImpactCode",
    TaxPercentage = "TaxPercentage",
    Category = "Category",
    TaxImpact = "TaxImpact",
}

export interface IDocumentItemCbaCategoryDraftEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    IsAssetAcquisition?: boolean;
    TaxImpactCode?: string;
    TaxPercentage?: number;
    Category?: ICbaCategoryEntity;
    TaxImpact?: ICbaCategoryTaxImpactEntity;
}

export enum DocumentItemCbaCategoryDraftEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    IsAssetAcquisition = "IsAssetAcquisition",
    TaxImpactCode = "TaxImpactCode",
    TaxPercentage = "TaxPercentage",
    Category = "Category",
    TaxImpact = "TaxImpact",
}

export interface IDocumentItemDraftEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    Description?: string;
    Note?: string;
    Order?: number;
    TransactionCurrencyCode?: string;
    UnitOfMeasureCode?: string;
    VatCode?: string;
    Quantity?: number;
    UnitPrice?: number;
    UnitPriceNet?: number;
    UnitPriceVat?: number;
    TransactionUnitPrice?: number;
    TransactionUnitPriceNet?: number;
    TransactionUnitPriceVat?: number;
    DiscountPercent?: number;
    Discount?: number;
    Amount?: number;
    AmountNet?: number;
    AmountVat?: number;
    TransactionAmount?: number;
    TransactionAmountNet?: number;
    TransactionAmountVat?: number;
    AccountAssignmentSelection?: IDocumentItemAccountAssignmentSelectionDraftEntity;
    CbaCategory?: IDocumentItemCbaCategoryDraftEntity;
    DocumentItem?: IDocumentItemEntity;
    LabelSelection?: ILabelSelectionDraftEntity;
    LinkedDocument?: IDocumentEntity;
    TransactionCurrency?: ICurrencyEntity;
    UnitOfMeasure?: IUnitOfMeasureEntity;
    Vat?: IVatEntity;
    VatClassificationSelection?: IDocumentItemVatClassificationSelectionDraftEntity;
}

export enum DocumentItemDraftEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    Description = "Description",
    Note = "Note",
    Order = "Order",
    TransactionCurrencyCode = "TransactionCurrencyCode",
    UnitOfMeasureCode = "UnitOfMeasureCode",
    VatCode = "VatCode",
    Quantity = "Quantity",
    UnitPrice = "UnitPrice",
    UnitPriceNet = "UnitPriceNet",
    UnitPriceVat = "UnitPriceVat",
    TransactionUnitPrice = "TransactionUnitPrice",
    TransactionUnitPriceNet = "TransactionUnitPriceNet",
    TransactionUnitPriceVat = "TransactionUnitPriceVat",
    DiscountPercent = "DiscountPercent",
    Discount = "Discount",
    Amount = "Amount",
    AmountNet = "AmountNet",
    AmountVat = "AmountVat",
    TransactionAmount = "TransactionAmount",
    TransactionAmountNet = "TransactionAmountNet",
    TransactionAmountVat = "TransactionAmountVat",
    AccountAssignmentSelection = "AccountAssignmentSelection",
    CbaCategory = "CbaCategory",
    DocumentItem = "DocumentItem",
    LabelSelection = "LabelSelection",
    LinkedDocument = "LinkedDocument",
    TransactionCurrency = "TransactionCurrency",
    UnitOfMeasure = "UnitOfMeasure",
    Vat = "Vat",
    VatClassificationSelection = "VatClassificationSelection",
}

export interface IDocumentItemLabelEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    EntityTypeCode?: string;
    EntityType?: IEntityTypeEntity;
    Label?: ILabelEntity;
    LabelHierarchy?: ILabelHierarchyEntity;
}

export enum DocumentItemLabelEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    EntityTypeCode = "EntityTypeCode",
    EntityType = "EntityType",
    Label = "Label",
    LabelHierarchy = "LabelHierarchy",
}

export interface IDocumentItemLabelDraftEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    EntityTypeCode?: string;
    EntityType?: IEntityTypeEntity;
    Label?: ILabelEntity;
    LabelHierarchy?: ILabelHierarchyEntity;
}

export enum DocumentItemLabelDraftEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    EntityTypeCode = "EntityTypeCode",
    EntityType = "EntityType",
    Label = "Label",
    LabelHierarchy = "LabelHierarchy",
}

export interface IDocumentItemTypeEntity extends IEntityBase {
    Code?: string;
    Name?: string;
}

export enum DocumentItemTypeEntity {
    Code = "Code",
    Name = "Name",
}

export interface IDocumentItemVatClassificationEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    CountryClassificationCode?: string;
    IsInAutomatedVatInclusionMode?: boolean;
    IsVatRegistered?: boolean;
    Name?: string;
    Note?: string;
    PayableReceivableTypeCode?: string;
    VatControlStatementSectionCode?: string;
    VatDeductionTypeCode?: string;
    VatOssCode?: string;
    VatReverseChargeCode?: string;
    VatStatementSectionCode?: string;
    VatViesCode?: string;
    VatProportionalDeduction?: number;
    ChartOfAccounts?: IChartOfAccountsEntity;
    CountryClassification?: ICountryClassificationEntity;
    NonTaxAccount?: IAccountEntity;
    PayableReceivableType?: IPayableReceivableTypeEntity;
    VatControlStatementSection?: IVatControlStatementSectionEntity;
    VatDeductionType?: IVatDeductionTypeEntity;
    VatOss?: IVatOssEntity;
    VatReverseCharge?: IVatReverseChargeEntity;
    VatStatementSection?: IVatStatementSectionEntity;
    VatVies?: IVatViesEntity;
}

export enum DocumentItemVatClassificationEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    CountryClassificationCode = "CountryClassificationCode",
    IsInAutomatedVatInclusionMode = "IsInAutomatedVatInclusionMode",
    IsVatRegistered = "IsVatRegistered",
    Name = "Name",
    Note = "Note",
    PayableReceivableTypeCode = "PayableReceivableTypeCode",
    VatControlStatementSectionCode = "VatControlStatementSectionCode",
    VatDeductionTypeCode = "VatDeductionTypeCode",
    VatOssCode = "VatOssCode",
    VatReverseChargeCode = "VatReverseChargeCode",
    VatStatementSectionCode = "VatStatementSectionCode",
    VatViesCode = "VatViesCode",
    VatProportionalDeduction = "VatProportionalDeduction",
    ChartOfAccounts = "ChartOfAccounts",
    CountryClassification = "CountryClassification",
    NonTaxAccount = "NonTaxAccount",
    PayableReceivableType = "PayableReceivableType",
    VatControlStatementSection = "VatControlStatementSection",
    VatDeductionType = "VatDeductionType",
    VatOss = "VatOss",
    VatReverseCharge = "VatReverseCharge",
    VatStatementSection = "VatStatementSection",
    VatVies = "VatVies",
}

export interface IDocumentItemVatClassificationDraftEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    CountryClassificationCode?: string;
    IsInAutomatedVatInclusionMode?: boolean;
    IsVatRegistered?: boolean;
    Name?: string;
    Note?: string;
    PayableReceivableTypeCode?: string;
    VatControlStatementSectionCode?: string;
    VatDeductionTypeCode?: string;
    VatOssCode?: string;
    VatReverseChargeCode?: string;
    VatStatementSectionCode?: string;
    VatViesCode?: string;
    VatProportionalDeduction?: number;
    ChartOfAccounts?: IChartOfAccountsEntity;
    CountryClassification?: ICountryClassificationEntity;
    NonTaxAccount?: IAccountEntity;
    PayableReceivableType?: IPayableReceivableTypeEntity;
    VatControlStatementSection?: IVatControlStatementSectionEntity;
    VatDeductionType?: IVatDeductionTypeEntity;
    VatOss?: IVatOssEntity;
    VatReverseCharge?: IVatReverseChargeEntity;
    VatStatementSection?: IVatStatementSectionEntity;
    VatVies?: IVatViesEntity;
}

export enum DocumentItemVatClassificationDraftEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    CountryClassificationCode = "CountryClassificationCode",
    IsInAutomatedVatInclusionMode = "IsInAutomatedVatInclusionMode",
    IsVatRegistered = "IsVatRegistered",
    Name = "Name",
    Note = "Note",
    PayableReceivableTypeCode = "PayableReceivableTypeCode",
    VatControlStatementSectionCode = "VatControlStatementSectionCode",
    VatDeductionTypeCode = "VatDeductionTypeCode",
    VatOssCode = "VatOssCode",
    VatReverseChargeCode = "VatReverseChargeCode",
    VatStatementSectionCode = "VatStatementSectionCode",
    VatViesCode = "VatViesCode",
    VatProportionalDeduction = "VatProportionalDeduction",
    ChartOfAccounts = "ChartOfAccounts",
    CountryClassification = "CountryClassification",
    NonTaxAccount = "NonTaxAccount",
    PayableReceivableType = "PayableReceivableType",
    VatControlStatementSection = "VatControlStatementSection",
    VatDeductionType = "VatDeductionType",
    VatOss = "VatOss",
    VatReverseCharge = "VatReverseCharge",
    VatStatementSection = "VatStatementSection",
    VatVies = "VatVies",
}

export interface IDocumentItemVatClassificationSelectionEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    SelectionCode?: string;
    Selection?: ISelectionEntity;
    VatClassification?: IDocumentItemVatClassificationEntity;
}

export enum DocumentItemVatClassificationSelectionEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    SelectionCode = "SelectionCode",
    Selection = "Selection",
    VatClassification = "VatClassification",
}

export interface IDocumentItemVatClassificationSelectionDraftEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    SelectionCode?: string;
    Selection?: ISelectionEntity;
    VatClassification?: IDocumentItemVatClassificationDraftEntity;
}

export enum DocumentItemVatClassificationSelectionDraftEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    SelectionCode = "SelectionCode",
    Selection = "Selection",
    VatClassification = "VatClassification",
}

export interface IDocumentItemViewEntity extends IEntityBase {
    Id?: number;
    Amount?: number;
    AmountNet?: number;
    AmountVat?: number;
    Description?: string;
    Note?: string;
    Order?: number;
    TransactionAmount?: number;
    TransactionAmountNet?: number;
    TransactionAmountVat?: number;
    TransactionCurrencyCode?: string;
    UnitOfMeasureCode?: string;
    VatCode?: string;
    Quantity?: number;
    UnitPrice?: number;
    UnitPriceNet?: number;
    UnitPriceVat?: number;
    TransactionUnitPrice?: number;
    TransactionUnitPriceNet?: number;
    TransactionUnitPriceVat?: number;
    DiscountPercent?: number;
    Discount?: number;
    AccountAssignmentSelection?: IDocumentItemAccountAssignmentSelectionEntity;
    CbaCategory?: IDocumentItemCbaCategoryEntity;
    DocumentItemDraft?: IDocumentItemDraftEntity;
    LabelSelection?: ILabelSelectionEntity;
    LinkedDocument?: IDocumentEntity;
    TransactionCurrency?: ICurrencyEntity;
    UnitOfMeasure?: IUnitOfMeasureEntity;
    Vat?: IVatEntity;
    VatClassificationSelection?: IDocumentItemVatClassificationSelectionEntity;
}

export enum DocumentItemViewEntity {
    Id = "Id",
    Amount = "Amount",
    AmountNet = "AmountNet",
    AmountVat = "AmountVat",
    Description = "Description",
    Note = "Note",
    Order = "Order",
    TransactionAmount = "TransactionAmount",
    TransactionAmountNet = "TransactionAmountNet",
    TransactionAmountVat = "TransactionAmountVat",
    TransactionCurrencyCode = "TransactionCurrencyCode",
    UnitOfMeasureCode = "UnitOfMeasureCode",
    VatCode = "VatCode",
    Quantity = "Quantity",
    UnitPrice = "UnitPrice",
    UnitPriceNet = "UnitPriceNet",
    UnitPriceVat = "UnitPriceVat",
    TransactionUnitPrice = "TransactionUnitPrice",
    TransactionUnitPriceNet = "TransactionUnitPriceNet",
    TransactionUnitPriceVat = "TransactionUnitPriceVat",
    DiscountPercent = "DiscountPercent",
    Discount = "Discount",
    AccountAssignmentSelection = "AccountAssignmentSelection",
    CbaCategory = "CbaCategory",
    DocumentItemDraft = "DocumentItemDraft",
    LabelSelection = "LabelSelection",
    LinkedDocument = "LinkedDocument",
    TransactionCurrency = "TransactionCurrency",
    UnitOfMeasure = "UnitOfMeasure",
    Vat = "Vat",
    VatClassificationSelection = "VatClassificationSelection",
}

export interface IDocumentLabelEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    EntityTypeCode?: string;
    EntityType?: IEntityTypeEntity;
    Label?: ILabelEntity;
    LabelHierarchy?: ILabelHierarchyEntity;
}

export enum DocumentLabelEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    EntityTypeCode = "EntityTypeCode",
    EntityType = "EntityType",
    Label = "Label",
    LabelHierarchy = "LabelHierarchy",
}

export interface IDocumentLabelDraftEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    EntityTypeCode?: string;
    EntityType?: IEntityTypeEntity;
    Label?: ILabelEntity;
    LabelHierarchy?: ILabelHierarchyEntity;
}

export enum DocumentLabelDraftEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    EntityTypeCode = "EntityTypeCode",
    EntityType = "EntityType",
    Label = "Label",
    LabelHierarchy = "LabelHierarchy",
}

export interface IDocumentLinkEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    TypeCode?: string;
    Amount?: number;
    TransactionAmount?: number;
    TargetDocument?: IDocumentEntity;
    TargetDocumentItem?: IDocumentItemEntity;
    Type?: IDocumentLinkTypeEntity;
}

export enum DocumentLinkEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    TypeCode = "TypeCode",
    Amount = "Amount",
    TransactionAmount = "TransactionAmount",
    TargetDocument = "TargetDocument",
    TargetDocumentItem = "TargetDocumentItem",
    Type = "Type",
}

export interface IDocumentLinkClearingTypeEntity extends IEntityBase {
    Code?: string;
    Name?: string;
}

export enum DocumentLinkClearingTypeEntity {
    Code = "Code",
    Name = "Name",
}

export interface IDocumentLinkDraftEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    TypeCode?: string;
    Amount?: number;
    TransactionAmount?: number;
    TargetDocument?: IDocumentEntity;
    TargetDocumentItem?: IDocumentItemEntity;
    Type?: IDocumentLinkTypeEntity;
}

export enum DocumentLinkDraftEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    TypeCode = "TypeCode",
    Amount = "Amount",
    TransactionAmount = "TransactionAmount",
    TargetDocument = "TargetDocument",
    TargetDocumentItem = "TargetDocumentItem",
    Type = "Type",
}

export interface IDocumentLinkTypeEntity extends IEntityBase {
    Code?: string;
    DocumentLinkClearingTypeCode?: string;
    Name?: string;
    DocumentLinkClearingType?: IDocumentLinkClearingTypeEntity;
}

export enum DocumentLinkTypeEntity {
    Code = "Code",
    DocumentLinkClearingTypeCode = "DocumentLinkClearingTypeCode",
    Name = "Name",
    DocumentLinkClearingType = "DocumentLinkClearingType",
}

export interface IDocumentLockEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    TypeCode?: string;
    Type?: ILockTypeEntity;
}

export enum DocumentLockEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    TypeCode = "TypeCode",
    Type = "Type",
}

export interface IDocumentLockDraftEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    TypeCode?: string;
    Type?: ILockTypeEntity;
}

export enum DocumentLockDraftEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    TypeCode = "TypeCode",
    Type = "Type",
}

export interface IDocumentMinorAssetItemEntity extends IEntityBase {
    NotId?: number;
    Document?: IDocumentEntity;
    DocumentItem?: IDocumentItemEntity;
    MinorAsset?: IMinorAssetEntity;
    MinorAssetItem?: IMinorAssetItemEntity;
}

export enum DocumentMinorAssetItemEntity {
    NotId = "NotId",
    Document = "Document",
    DocumentItem = "DocumentItem",
    MinorAsset = "MinorAsset",
    MinorAssetItem = "MinorAssetItem",
}

export interface IDocumentStatusEntity extends IEntityBase {
    Code?: string;
    Name?: string;
}

export enum DocumentStatusEntity {
    Code = "Code",
    Name = "Name",
}

export interface IDocumentTypeEntity extends IEntityBase {
    Code?: string;
    Name?: string;
    PayableReceivableTypeCode?: string;
    PayableReceivableType?: IPayableReceivableTypeEntity;
}

export enum DocumentTypeEntity {
    Code = "Code",
    Name = "Name",
    PayableReceivableTypeCode = "PayableReceivableTypeCode",
    PayableReceivableType = "PayableReceivableType",
}

export interface IDocumentVatClassificationEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    CountryClassificationCode?: string;
    IsInAutomatedVatInclusionMode?: boolean;
    IsVatRegistered?: boolean;
    Name?: string;
    Note?: string;
    PayableReceivableTypeCode?: string;
    VatControlStatementSectionCode?: string;
    VatDeductionTypeCode?: string;
    VatOssCode?: string;
    VatReverseChargeCode?: string;
    VatStatementSectionCode?: string;
    VatViesCode?: string;
    VatProportionalDeduction?: number;
    ChartOfAccounts?: IChartOfAccountsEntity;
    CountryClassification?: ICountryClassificationEntity;
    NonTaxAccount?: IAccountEntity;
    PayableReceivableType?: IPayableReceivableTypeEntity;
    VatControlStatementSection?: IVatControlStatementSectionEntity;
    VatDeductionType?: IVatDeductionTypeEntity;
    VatOss?: IVatOssEntity;
    VatReverseCharge?: IVatReverseChargeEntity;
    VatStatementSection?: IVatStatementSectionEntity;
    VatVies?: IVatViesEntity;
}

export enum DocumentVatClassificationEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    CountryClassificationCode = "CountryClassificationCode",
    IsInAutomatedVatInclusionMode = "IsInAutomatedVatInclusionMode",
    IsVatRegistered = "IsVatRegistered",
    Name = "Name",
    Note = "Note",
    PayableReceivableTypeCode = "PayableReceivableTypeCode",
    VatControlStatementSectionCode = "VatControlStatementSectionCode",
    VatDeductionTypeCode = "VatDeductionTypeCode",
    VatOssCode = "VatOssCode",
    VatReverseChargeCode = "VatReverseChargeCode",
    VatStatementSectionCode = "VatStatementSectionCode",
    VatViesCode = "VatViesCode",
    VatProportionalDeduction = "VatProportionalDeduction",
    ChartOfAccounts = "ChartOfAccounts",
    CountryClassification = "CountryClassification",
    NonTaxAccount = "NonTaxAccount",
    PayableReceivableType = "PayableReceivableType",
    VatControlStatementSection = "VatControlStatementSection",
    VatDeductionType = "VatDeductionType",
    VatOss = "VatOss",
    VatReverseCharge = "VatReverseCharge",
    VatStatementSection = "VatStatementSection",
    VatVies = "VatVies",
}

export interface IDocumentVatClassificationDraftEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    CountryClassificationCode?: string;
    IsInAutomatedVatInclusionMode?: boolean;
    IsVatRegistered?: boolean;
    Name?: string;
    Note?: string;
    PayableReceivableTypeCode?: string;
    VatControlStatementSectionCode?: string;
    VatDeductionTypeCode?: string;
    VatOssCode?: string;
    VatReverseChargeCode?: string;
    VatStatementSectionCode?: string;
    VatViesCode?: string;
    VatProportionalDeduction?: number;
    ChartOfAccounts?: IChartOfAccountsEntity;
    CountryClassification?: ICountryClassificationEntity;
    NonTaxAccount?: IAccountEntity;
    PayableReceivableType?: IPayableReceivableTypeEntity;
    VatControlStatementSection?: IVatControlStatementSectionEntity;
    VatDeductionType?: IVatDeductionTypeEntity;
    VatOss?: IVatOssEntity;
    VatReverseCharge?: IVatReverseChargeEntity;
    VatStatementSection?: IVatStatementSectionEntity;
    VatVies?: IVatViesEntity;
}

export enum DocumentVatClassificationDraftEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    CountryClassificationCode = "CountryClassificationCode",
    IsInAutomatedVatInclusionMode = "IsInAutomatedVatInclusionMode",
    IsVatRegistered = "IsVatRegistered",
    Name = "Name",
    Note = "Note",
    PayableReceivableTypeCode = "PayableReceivableTypeCode",
    VatControlStatementSectionCode = "VatControlStatementSectionCode",
    VatDeductionTypeCode = "VatDeductionTypeCode",
    VatOssCode = "VatOssCode",
    VatReverseChargeCode = "VatReverseChargeCode",
    VatStatementSectionCode = "VatStatementSectionCode",
    VatViesCode = "VatViesCode",
    VatProportionalDeduction = "VatProportionalDeduction",
    ChartOfAccounts = "ChartOfAccounts",
    CountryClassification = "CountryClassification",
    NonTaxAccount = "NonTaxAccount",
    PayableReceivableType = "PayableReceivableType",
    VatControlStatementSection = "VatControlStatementSection",
    VatDeductionType = "VatDeductionType",
    VatOss = "VatOss",
    VatReverseCharge = "VatReverseCharge",
    VatStatementSection = "VatStatementSection",
    VatVies = "VatVies",
}

export interface IDocumentVatClassificationSelectionEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    SelectionCode?: string;
    Selection?: ISelectionEntity;
    VatClassification?: IDocumentVatClassificationEntity;
}

export enum DocumentVatClassificationSelectionEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    SelectionCode = "SelectionCode",
    Selection = "Selection",
    VatClassification = "VatClassification",
}

export interface IDocumentVatClassificationSelectionDraftEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    SelectionCode?: string;
    Selection?: ISelectionEntity;
    VatClassification?: IDocumentVatClassificationDraftEntity;
}

export enum DocumentVatClassificationSelectionDraftEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    SelectionCode = "SelectionCode",
    Selection = "Selection",
    VatClassification = "VatClassification",
}

export interface IElectronicSubmissionEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    AreVatDocumentsCreated?: boolean;
    DatePeriodEnd?: Date;
    DatePeriodStart?: Date;
    DateSubmission?: Date;
    ElectronicSubmissionTypeCode?: string;
    VatLiability?: number;
    Company?: ICompanyEntity;
    ElectronicSubmissionType?: IElectronicSubmissionTypeEntity;
    VatClearingDocument?: IInternalDocumentEntity;
    VatStatementDocument?: IDocumentEntity;
}

export enum ElectronicSubmissionEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    AreVatDocumentsCreated = "AreVatDocumentsCreated",
    DatePeriodEnd = "DatePeriodEnd",
    DatePeriodStart = "DatePeriodStart",
    DateSubmission = "DateSubmission",
    ElectronicSubmissionTypeCode = "ElectronicSubmissionTypeCode",
    VatLiability = "VatLiability",
    Company = "Company",
    ElectronicSubmissionType = "ElectronicSubmissionType",
    VatClearingDocument = "VatClearingDocument",
    VatStatementDocument = "VatStatementDocument",
}

export interface IElectronicSubmissionTypeEntity extends IEntityBase {
    Code?: string;
    Name?: string;
}

export enum ElectronicSubmissionTypeEntity {
    Code = "Code",
    Name = "Name",
}

export interface IEntityEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
}

export enum EntityEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
}

export interface IEntityAccountAssignmentEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    Name?: string;
    Note?: string;
    ShortName?: string;
    ChartOfAccounts?: IChartOfAccountsEntity;
    CreditAccount?: IAccountEntity;
    DebitAccount?: IAccountEntity;
}

export enum EntityAccountAssignmentEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    Name = "Name",
    Note = "Note",
    ShortName = "ShortName",
    ChartOfAccounts = "ChartOfAccounts",
    CreditAccount = "CreditAccount",
    DebitAccount = "DebitAccount",
}

export interface IEntityAccountAssignmentDraftEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    Name?: string;
    Note?: string;
    ShortName?: string;
    ChartOfAccounts?: IChartOfAccountsEntity;
    CreditAccount?: IAccountEntity;
    DebitAccount?: IAccountEntity;
}

export enum EntityAccountAssignmentDraftEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    Name = "Name",
    Note = "Note",
    ShortName = "ShortName",
    ChartOfAccounts = "ChartOfAccounts",
    CreditAccount = "CreditAccount",
    DebitAccount = "DebitAccount",
}

export interface IEntityAttachmentEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    Description?: string;
    File?: IFileMetadataEntity;
}

export enum EntityAttachmentEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    Description = "Description",
    File = "File",
}

export interface IEntityBankAccountEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    AbaNumber?: string;
    AccountNumber?: string;
    BankCode?: string;
    CountryCode?: string;
    IBAN?: string;
    PaymentServiceID?: string;
    PaymentServiceName?: string;
    SWIFT?: string;
    Bank?: IBankEntity;
    Country?: ICountryEntity;
}

export enum EntityBankAccountEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    AbaNumber = "AbaNumber",
    AccountNumber = "AccountNumber",
    BankCode = "BankCode",
    CountryCode = "CountryCode",
    IBAN = "IBAN",
    PaymentServiceID = "PaymentServiceID",
    PaymentServiceName = "PaymentServiceName",
    SWIFT = "SWIFT",
    Bank = "Bank",
    Country = "Country",
}

export interface IEntityBankAccountDraftEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    AbaNumber?: string;
    AccountNumber?: string;
    BankCode?: string;
    CountryCode?: string;
    IBAN?: string;
    PaymentServiceID?: string;
    PaymentServiceName?: string;
    SWIFT?: string;
    Bank?: IBankEntity;
    Country?: ICountryEntity;
}

export enum EntityBankAccountDraftEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    AbaNumber = "AbaNumber",
    AccountNumber = "AccountNumber",
    BankCode = "BankCode",
    CountryCode = "CountryCode",
    IBAN = "IBAN",
    PaymentServiceID = "PaymentServiceID",
    PaymentServiceName = "PaymentServiceName",
    SWIFT = "SWIFT",
    Bank = "Bank",
    Country = "Country",
}

export interface IEntityBusinessPartnerEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    City?: string;
    CountryCode?: string;
    Email?: string;
    FirstName?: string;
    LastName?: string;
    LegalNumber?: string;
    Name?: string;
    PhoneNumber?: string;
    PostalCode?: string;
    Street?: string;
    TaxNumber?: string;
    VatStatusCode?: string;
    BusinessPartner?: IBusinessPartnerEntity;
    Country?: ICountryEntity;
    VatStatus?: IVatStatusEntity;
}

export enum EntityBusinessPartnerEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    City = "City",
    CountryCode = "CountryCode",
    Email = "Email",
    FirstName = "FirstName",
    LastName = "LastName",
    LegalNumber = "LegalNumber",
    Name = "Name",
    PhoneNumber = "PhoneNumber",
    PostalCode = "PostalCode",
    Street = "Street",
    TaxNumber = "TaxNumber",
    VatStatusCode = "VatStatusCode",
    BusinessPartner = "BusinessPartner",
    Country = "Country",
    VatStatus = "VatStatus",
}

export interface IEntityBusinessPartnerDraftEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    City?: string;
    CountryCode?: string;
    Email?: string;
    FirstName?: string;
    LastName?: string;
    LegalNumber?: string;
    Name?: string;
    PhoneNumber?: string;
    PostalCode?: string;
    Street?: string;
    TaxNumber?: string;
    VatStatusCode?: string;
    BusinessPartner?: IBusinessPartnerEntity;
    Country?: ICountryEntity;
    VatStatus?: IVatStatusEntity;
}

export enum EntityBusinessPartnerDraftEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    City = "City",
    CountryCode = "CountryCode",
    Email = "Email",
    FirstName = "FirstName",
    LastName = "LastName",
    LegalNumber = "LegalNumber",
    Name = "Name",
    PhoneNumber = "PhoneNumber",
    PostalCode = "PostalCode",
    Street = "Street",
    TaxNumber = "TaxNumber",
    VatStatusCode = "VatStatusCode",
    BusinessPartner = "BusinessPartner",
    Country = "Country",
    VatStatus = "VatStatus",
}

export interface IEntityCbaCategoryEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    IsAssetAcquisition?: boolean;
    TaxImpactCode?: string;
    TaxPercentage?: number;
    Category?: ICbaCategoryEntity;
    TaxImpact?: ICbaCategoryTaxImpactEntity;
}

export enum EntityCbaCategoryEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    IsAssetAcquisition = "IsAssetAcquisition",
    TaxImpactCode = "TaxImpactCode",
    TaxPercentage = "TaxPercentage",
    Category = "Category",
    TaxImpact = "TaxImpact",
}

export interface IEntityCbaCategoryDraftEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    IsAssetAcquisition?: boolean;
    TaxImpactCode?: string;
    TaxPercentage?: number;
    Category?: ICbaCategoryEntity;
    TaxImpact?: ICbaCategoryTaxImpactEntity;
}

export enum EntityCbaCategoryDraftEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    IsAssetAcquisition = "IsAssetAcquisition",
    TaxImpactCode = "TaxImpactCode",
    TaxPercentage = "TaxPercentage",
    Category = "Category",
    TaxImpact = "TaxImpact",
}

export interface IEntityLabelEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    EntityTypeCode?: string;
    EntityType?: IEntityTypeEntity;
    Label?: ILabelEntity;
    LabelHierarchy?: ILabelHierarchyEntity;
}

export enum EntityLabelEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    EntityTypeCode = "EntityTypeCode",
    EntityType = "EntityType",
    Label = "Label",
    LabelHierarchy = "LabelHierarchy",
}

export interface IEntityLockEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    TypeCode?: string;
    Type?: ILockTypeEntity;
}

export enum EntityLockEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    TypeCode = "TypeCode",
    Type = "Type",
}

export interface IEntityPermissionLevelEntity extends IEntityBase {
    Code?: string;
    Name?: string;
}

export enum EntityPermissionLevelEntity {
    Code = "Code",
    Name = "Name",
}

export interface IEntityTypeEntity extends IEntityBase {
    Code?: string;
    Name?: string;
}

export enum EntityTypeEntity {
    Code = "Code",
    Name = "Name",
}

export interface IEntityVatClassificationEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    CountryClassificationCode?: string;
    IsInAutomatedVatInclusionMode?: boolean;
    IsVatRegistered?: boolean;
    Name?: string;
    Note?: string;
    PayableReceivableTypeCode?: string;
    VatControlStatementSectionCode?: string;
    VatDeductionTypeCode?: string;
    VatOssCode?: string;
    VatReverseChargeCode?: string;
    VatStatementSectionCode?: string;
    VatViesCode?: string;
    VatProportionalDeduction?: number;
    ChartOfAccounts?: IChartOfAccountsEntity;
    CountryClassification?: ICountryClassificationEntity;
    NonTaxAccount?: IAccountEntity;
    PayableReceivableType?: IPayableReceivableTypeEntity;
    VatControlStatementSection?: IVatControlStatementSectionEntity;
    VatDeductionType?: IVatDeductionTypeEntity;
    VatOss?: IVatOssEntity;
    VatReverseCharge?: IVatReverseChargeEntity;
    VatStatementSection?: IVatStatementSectionEntity;
    VatVies?: IVatViesEntity;
}

export enum EntityVatClassificationEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    CountryClassificationCode = "CountryClassificationCode",
    IsInAutomatedVatInclusionMode = "IsInAutomatedVatInclusionMode",
    IsVatRegistered = "IsVatRegistered",
    Name = "Name",
    Note = "Note",
    PayableReceivableTypeCode = "PayableReceivableTypeCode",
    VatControlStatementSectionCode = "VatControlStatementSectionCode",
    VatDeductionTypeCode = "VatDeductionTypeCode",
    VatOssCode = "VatOssCode",
    VatReverseChargeCode = "VatReverseChargeCode",
    VatStatementSectionCode = "VatStatementSectionCode",
    VatViesCode = "VatViesCode",
    VatProportionalDeduction = "VatProportionalDeduction",
    ChartOfAccounts = "ChartOfAccounts",
    CountryClassification = "CountryClassification",
    NonTaxAccount = "NonTaxAccount",
    PayableReceivableType = "PayableReceivableType",
    VatControlStatementSection = "VatControlStatementSection",
    VatDeductionType = "VatDeductionType",
    VatOss = "VatOss",
    VatReverseCharge = "VatReverseCharge",
    VatStatementSection = "VatStatementSection",
    VatVies = "VatVies",
}

export interface IEntityVatClassificationDraftEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    CountryClassificationCode?: string;
    IsInAutomatedVatInclusionMode?: boolean;
    IsVatRegistered?: boolean;
    Name?: string;
    Note?: string;
    PayableReceivableTypeCode?: string;
    VatControlStatementSectionCode?: string;
    VatDeductionTypeCode?: string;
    VatOssCode?: string;
    VatReverseChargeCode?: string;
    VatStatementSectionCode?: string;
    VatViesCode?: string;
    VatProportionalDeduction?: number;
    ChartOfAccounts?: IChartOfAccountsEntity;
    CountryClassification?: ICountryClassificationEntity;
    NonTaxAccount?: IAccountEntity;
    PayableReceivableType?: IPayableReceivableTypeEntity;
    VatControlStatementSection?: IVatControlStatementSectionEntity;
    VatDeductionType?: IVatDeductionTypeEntity;
    VatOss?: IVatOssEntity;
    VatReverseCharge?: IVatReverseChargeEntity;
    VatStatementSection?: IVatStatementSectionEntity;
    VatVies?: IVatViesEntity;
}

export enum EntityVatClassificationDraftEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    CountryClassificationCode = "CountryClassificationCode",
    IsInAutomatedVatInclusionMode = "IsInAutomatedVatInclusionMode",
    IsVatRegistered = "IsVatRegistered",
    Name = "Name",
    Note = "Note",
    PayableReceivableTypeCode = "PayableReceivableTypeCode",
    VatControlStatementSectionCode = "VatControlStatementSectionCode",
    VatDeductionTypeCode = "VatDeductionTypeCode",
    VatOssCode = "VatOssCode",
    VatReverseChargeCode = "VatReverseChargeCode",
    VatStatementSectionCode = "VatStatementSectionCode",
    VatViesCode = "VatViesCode",
    VatProportionalDeduction = "VatProportionalDeduction",
    ChartOfAccounts = "ChartOfAccounts",
    CountryClassification = "CountryClassification",
    NonTaxAccount = "NonTaxAccount",
    PayableReceivableType = "PayableReceivableType",
    VatControlStatementSection = "VatControlStatementSection",
    VatDeductionType = "VatDeductionType",
    VatOss = "VatOss",
    VatReverseCharge = "VatReverseCharge",
    VatStatementSection = "VatStatementSection",
    VatVies = "VatVies",
}

export interface IExchangeRateEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    Amount?: number;
    CountryCode?: string;
    DateIssued?: Date;
    ExchangeRateDirect?: number;
    ExchangeRateIndirect?: number;
    ExchangeRatePerUnit?: number;
    FromCurrencyCode?: string;
    IsIssuedDaily?: boolean;
    ToCurrencyCode?: string;
    Country?: ICountryOrUnionEntity;
    FromCurrency?: ICurrencyEntity;
    ToCurrency?: ICurrencyEntity;
}

export enum ExchangeRateEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    Amount = "Amount",
    CountryCode = "CountryCode",
    DateIssued = "DateIssued",
    ExchangeRateDirect = "ExchangeRateDirect",
    ExchangeRateIndirect = "ExchangeRateIndirect",
    ExchangeRatePerUnit = "ExchangeRatePerUnit",
    FromCurrencyCode = "FromCurrencyCode",
    IsIssuedDaily = "IsIssuedDaily",
    ToCurrencyCode = "ToCurrencyCode",
    Country = "Country",
    FromCurrency = "FromCurrency",
    ToCurrency = "ToCurrency",
}

export interface IExchangeRateTypeEntity extends IEntityBase {
    Code?: string;
    Name?: string;
}

export enum ExchangeRateTypeEntity {
    Code = "Code",
    Name = "Name",
}

export interface IExpenseTaxRateEntity extends IEntityBase {
    Code?: string;
    CountryCode?: string;
    DateValidFrom?: Date;
    DateValidTo?: Date;
    Name?: string;
    PercentageRate?: number;
    Country?: ICountryEntity;
}

export enum ExpenseTaxRateEntity {
    Code = "Code",
    CountryCode = "CountryCode",
    DateValidFrom = "DateValidFrom",
    DateValidTo = "DateValidTo",
    Name = "Name",
    PercentageRate = "PercentageRate",
    Country = "Country",
}

export interface IExportLogEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    IncludeAttachments?: boolean;
    RangeCode?: string;
    ResultTypeCode?: string;
    TypeCode?: string;
    Company?: ICompanyEntity;
    Range?: IExportRangeEntity;
    ResultType?: IExportResultTypeEntity;
    Type?: IExportTypeEntity;
}

export enum ExportLogEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    IncludeAttachments = "IncludeAttachments",
    RangeCode = "RangeCode",
    ResultTypeCode = "ResultTypeCode",
    TypeCode = "TypeCode",
    Company = "Company",
    Range = "Range",
    ResultType = "ResultType",
    Type = "Type",
}

export interface IExportRangeEntity extends IEntityBase {
    Code?: string;
    Name?: string;
}

export enum ExportRangeEntity {
    Code = "Code",
    Name = "Name",
}

export interface IExportResultTypeEntity extends IEntityBase {
    Code?: string;
    Name?: string;
}

export enum ExportResultTypeEntity {
    Code = "Code",
    Name = "Name",
}

export interface IExportTypeEntity extends IEntityBase {
    Code?: string;
    Name?: string;
}

export enum ExportTypeEntity {
    Code = "Code",
    Name = "Name",
}

export interface IExternalCorrectedDocumentTypeEntity extends IEntityBase {
    Code?: string;
    Name?: string;
}

export enum ExternalCorrectedDocumentTypeEntity {
    Code = "Code",
    Name = "Name",
}

export interface IFeatureEntity extends IEntityBase {
    Code?: string;
    Name?: string;
}

export enum FeatureEntity {
    Code = "Code",
    Name = "Name",
}

export interface IFileMetadataEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    IsIsdocReadable?: boolean;
    IsRossumReadable?: boolean;
    Name?: string;
    Size?: number;
}

export enum FileMetadataEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    IsIsdocReadable = "IsIsdocReadable",
    IsRossumReadable = "IsRossumReadable",
    Name = "Name",
    Size = "Size",
}

export interface IFinancialReportLayoutEntity extends IEntityBase {
    Code?: string;
    CountryCode?: string;
    DateValidFrom?: Date;
    DateValidTo?: Date;
    Name?: string;
    ReportTypeCode?: string;
    Country?: ICountryEntity;
    ReportType?: IReportTypeEntity;
    Sections?: IFinancialReportLayoutSectionEntity[];
}

export enum FinancialReportLayoutEntity {
    Code = "Code",
    CountryCode = "CountryCode",
    DateValidFrom = "DateValidFrom",
    DateValidTo = "DateValidTo",
    Name = "Name",
    ReportTypeCode = "ReportTypeCode",
    Country = "Country",
    ReportType = "ReportType",
    Sections = "Sections",
}

export interface IFinancialReportLayoutSectionEntity extends IEntityBase {
    Code?: string;
    FinancialReportLayoutCode?: string;
    IsInBasicView?: boolean;
    Name?: string;
    Number?: string;
    OrderNumber?: number;
    ParentCode?: string;
    ReportSectionTypeCode?: string;
    FinancialReportLayout?: IFinancialReportLayoutEntity;
    Parent?: IFinancialReportLayoutSectionEntity;
    ReportSectionType?: IReportSectionTypeEntity;
    Children?: IFinancialReportLayoutSectionEntity[];
}

export enum FinancialReportLayoutSectionEntity {
    Code = "Code",
    FinancialReportLayoutCode = "FinancialReportLayoutCode",
    IsInBasicView = "IsInBasicView",
    Name = "Name",
    Number = "Number",
    OrderNumber = "OrderNumber",
    ParentCode = "ParentCode",
    ReportSectionTypeCode = "ReportSectionTypeCode",
    FinancialReportLayout = "FinancialReportLayout",
    Parent = "Parent",
    ReportSectionType = "ReportSectionType",
    Children = "Children",
}

export interface IFiscalPeriodEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    DateEnd?: Date;
    DateStart?: Date;
    IsLockedForAP?: boolean;
    IsLockedForAR?: boolean;
    Name?: string;
    Number?: string;
    Usage?: IFiscalPeriodUsageEntity;
}

export enum FiscalPeriodEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    DateEnd = "DateEnd",
    DateStart = "DateStart",
    IsLockedForAP = "IsLockedForAP",
    IsLockedForAR = "IsLockedForAR",
    Name = "Name",
    Number = "Number",
    Usage = "Usage",
}

export interface IFiscalPeriodUsageEntity extends IEntityBase {
    Id?: number;
    DateMax?: Date;
    DateMin?: Date;
}

export enum FiscalPeriodUsageEntity {
    Id = "Id",
    DateMax = "DateMax",
    DateMin = "DateMin",
}

export interface IFiscalYearEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    DateEnd?: Date;
    DateStart?: Date;
    Number?: string;
    StatusCode?: string;
    ChartOfAccounts?: IChartOfAccountsEntity;
    Company?: ICompanyEntity;
    FiscalYearClose?: IFiscalYearCloseEntity;
    Status?: IFiscalYearStatusEntity;
    Usage?: IFiscalYearUsageEntity;
    Periods?: IFiscalPeriodEntity[];
    NumberRanges?: INumberRangeEntity[];
}

export enum FiscalYearEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    DateEnd = "DateEnd",
    DateStart = "DateStart",
    Number = "Number",
    StatusCode = "StatusCode",
    ChartOfAccounts = "ChartOfAccounts",
    Company = "Company",
    FiscalYearClose = "FiscalYearClose",
    Status = "Status",
    Usage = "Usage",
    Periods = "Periods",
    NumberRanges = "NumberRanges",
}

export interface IFiscalYearCloseEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    DateIncomeTax?: Date;
    ExpenseTaxRateCode?: string;
    HasTaxAdvisor?: boolean;
    InventoryTypeCode?: string;
    LegalEntityCategoryCode?: string;
    IncomeTaxAmount?: number;
    IncomeTaxPaidAdvanceAmount?: number;
    DateClosed?: Date;
    BalanceSheetClose?: IInternalDocumentEntity;
    ClosedByUser?: IUserEntity;
    Company?: ICompanyEntity;
    ExpenseTaxRate?: IExpenseTaxRateEntity;
    FiscalYear?: IFiscalYearEntity;
    IncomeExpenseTransfer?: IInternalDocumentEntity;
    IncomeTaxDocument?: IInternalDocumentEntity;
    IncomeTaxPaymentOrReturnDocument?: IDocumentEntity;
    InventoryCheckDocument?: IInternalDocumentEntity;
    InventoryClearingDocument?: IInternalDocumentEntity;
    InventoryType?: IInventoryTypeEntity;
    LegalEntityCategory?: ILegalEntityCategoryEntity;
    NextYearBalanceSheetOpen?: IInternalDocumentEntity;
    NextYearProfitOrLossTransfer?: IInternalDocumentEntity;
    ProfitOrLoss?: IInternalDocumentEntity;
    AdvanceTaxPayments?: IFiscalYearCloseAdvanceTaxEntity[];
    Attachments?: IFiscalYearCloseAttachmentEntity[];
    ExchangeRates?: IFiscalYearCloseExchangeRateEntity[];
    InventoryAccounts?: IFiscalYearCloseInventoryEntity[];
    Sections?: IFiscalYearCloseSectionEntity[];
    ExchangeRateDocuments?: IInternalDocumentEntity[];
}

export enum FiscalYearCloseEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    DateIncomeTax = "DateIncomeTax",
    ExpenseTaxRateCode = "ExpenseTaxRateCode",
    HasTaxAdvisor = "HasTaxAdvisor",
    InventoryTypeCode = "InventoryTypeCode",
    LegalEntityCategoryCode = "LegalEntityCategoryCode",
    IncomeTaxAmount = "IncomeTaxAmount",
    IncomeTaxPaidAdvanceAmount = "IncomeTaxPaidAdvanceAmount",
    DateClosed = "DateClosed",
    BalanceSheetClose = "BalanceSheetClose",
    ClosedByUser = "ClosedByUser",
    Company = "Company",
    ExpenseTaxRate = "ExpenseTaxRate",
    FiscalYear = "FiscalYear",
    IncomeExpenseTransfer = "IncomeExpenseTransfer",
    IncomeTaxDocument = "IncomeTaxDocument",
    IncomeTaxPaymentOrReturnDocument = "IncomeTaxPaymentOrReturnDocument",
    InventoryCheckDocument = "InventoryCheckDocument",
    InventoryClearingDocument = "InventoryClearingDocument",
    InventoryType = "InventoryType",
    LegalEntityCategory = "LegalEntityCategory",
    NextYearBalanceSheetOpen = "NextYearBalanceSheetOpen",
    NextYearProfitOrLossTransfer = "NextYearProfitOrLossTransfer",
    ProfitOrLoss = "ProfitOrLoss",
    AdvanceTaxPayments = "AdvanceTaxPayments",
    Attachments = "Attachments",
    ExchangeRates = "ExchangeRates",
    InventoryAccounts = "InventoryAccounts",
    Sections = "Sections",
    ExchangeRateDocuments = "ExchangeRateDocuments",
}

export interface IFiscalYearCloseAdvanceTaxEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    Amount?: number;
    DateAccountingTransaction?: Date;
    DateDue?: Date;
    IsCreated?: boolean;
    CreatedDocument?: IOtherLiabilityEntity;
}

export enum FiscalYearCloseAdvanceTaxEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    Amount = "Amount",
    DateAccountingTransaction = "DateAccountingTransaction",
    DateDue = "DateDue",
    IsCreated = "IsCreated",
    CreatedDocument = "CreatedDocument",
}

export interface IFiscalYearCloseAttachmentEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    Description?: string;
    File?: IFileMetadataEntity;
}

export enum FiscalYearCloseAttachmentEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    Description = "Description",
    File = "File",
}

export interface IFiscalYearCloseExchangeRateEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    CurrencyCode?: string;
    ExchangeRatePerUnit?: number;
    Currency?: ICurrencyEntity;
}

export enum FiscalYearCloseExchangeRateEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    CurrencyCode = "CurrencyCode",
    ExchangeRatePerUnit = "ExchangeRatePerUnit",
    Currency = "Currency",
}

export interface IFiscalYearCloseInventoryEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    Amount?: number;
    ChartOfAccounts?: IChartOfAccountsEntity;
    ConsumptionAccount?: IAccountEntity;
    InventoryAccount?: IAccountEntity;
}

export enum FiscalYearCloseInventoryEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    Amount = "Amount",
    ChartOfAccounts = "ChartOfAccounts",
    ConsumptionAccount = "ConsumptionAccount",
    InventoryAccount = "InventoryAccount",
}

export interface IFiscalYearCloseSectionEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    HasErrors?: boolean;
    IsChecked?: boolean;
    TypeCode?: string;
    Type?: IFiscalYearCloseSectionTypeEntity;
}

export enum FiscalYearCloseSectionEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    HasErrors = "HasErrors",
    IsChecked = "IsChecked",
    TypeCode = "TypeCode",
    Type = "Type",
}

export interface IFiscalYearCloseSectionTypeEntity extends IEntityBase {
    Code?: string;
    IsAccrual?: boolean;
    IsCashBasis?: boolean;
    Name?: string;
}

export enum FiscalYearCloseSectionTypeEntity {
    Code = "Code",
    IsAccrual = "IsAccrual",
    IsCashBasis = "IsCashBasis",
    Name = "Name",
}

export interface IFiscalYearStatusEntity extends IEntityBase {
    Code?: string;
    Name?: string;
}

export enum FiscalYearStatusEntity {
    Code = "Code",
    Name = "Name",
}

export interface IFiscalYearUsageEntity extends IEntityBase {
    Id?: number;
    DateMax?: Date;
    DateMin?: Date;
}

export enum FiscalYearUsageEntity {
    Id = "Id",
    DateMax = "DateMax",
    DateMin = "DateMin",
}

export interface IFlattenAccountEntity extends IEntityBase {
    Id?: number;
    CategoryCode?: string;
    Name?: string;
    Number?: string;
    TaxApplicabilityCode?: string;
    TypeCode?: string;
}

export enum FlattenAccountEntity {
    Id = "Id",
    CategoryCode = "CategoryCode",
    Name = "Name",
    Number = "Number",
    TaxApplicabilityCode = "TaxApplicabilityCode",
    TypeCode = "TypeCode",
}

export interface IFulfilmentTypeEntity extends IEntityBase {
    Code?: string;
    Name?: string;
}

export enum FulfilmentTypeEntity {
    Code = "Code",
    Name = "Name",
}

export interface IGeneralPermissionEntity extends IEntityBase {
    Code?: string;
    Description?: string;
    IsOwnerOnly?: boolean;
    Name?: string;
}

export enum GeneralPermissionEntity {
    Code = "Code",
    Description = "Description",
    IsOwnerOnly = "IsOwnerOnly",
    Name = "Name",
}

export interface IGeneralRoleEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    GeneralRoleName?: string;
    IsDefaultRole?: boolean;
    IsSystemRole?: boolean;
    UserCount?: number;
    GeneralRolePermissions?: IGeneralRolePermissionEntity[];
}

export enum GeneralRoleEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    GeneralRoleName = "GeneralRoleName",
    IsDefaultRole = "IsDefaultRole",
    IsSystemRole = "IsSystemRole",
    UserCount = "UserCount",
    GeneralRolePermissions = "GeneralRolePermissions",
}

export interface IGeneralRolePermissionEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    IsEnabled?: boolean;
    PermissionCode?: string;
    Permission?: IGeneralPermissionEntity;
}

export enum GeneralRolePermissionEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    IsEnabled = "IsEnabled",
    PermissionCode = "PermissionCode",
    Permission = "Permission",
}

export interface IGovernmentInstitutionEntity extends IEntityBase {
    Code?: string;
    City?: string;
    CountryCode?: string;
    DataBoxId?: string;
    Email?: string;
    GovernmentInstitutionTypeCode?: string;
    Name?: string;
    OpenDataCode?: string;
    Phone?: string;
    PostalCode?: string;
    ShortName?: string;
    Street?: string;
    Ufo?: string;
    UfoName?: string;
    Country?: ICountryEntity;
    GovernmentInstitutionType?: IGovernmentInstitutionTypeEntity;
    BankAccounts?: IGovernmentInstitutionBankAccountEntity[];
}

export enum GovernmentInstitutionEntity {
    Code = "Code",
    City = "City",
    CountryCode = "CountryCode",
    DataBoxId = "DataBoxId",
    Email = "Email",
    GovernmentInstitutionTypeCode = "GovernmentInstitutionTypeCode",
    Name = "Name",
    OpenDataCode = "OpenDataCode",
    Phone = "Phone",
    PostalCode = "PostalCode",
    ShortName = "ShortName",
    Street = "Street",
    Ufo = "Ufo",
    UfoName = "UfoName",
    Country = "Country",
    GovernmentInstitutionType = "GovernmentInstitutionType",
    BankAccounts = "BankAccounts",
}

export interface IGovernmentInstitutionBankAccountEntity extends IEntityBase {
    Code?: string;
    AccountNumber?: string;
    AccountPrefix?: string;
    BankCode?: string;
    CountryCode?: string;
    GovernmentInstitutionBankAccountTypeCode?: string;
    IBAN?: string;
    Name?: string;
    Bank?: IBankEntity;
    Country?: ICountryEntity;
    GovernmentInstitutionBankAccountType?: IGovernmentInstitutionBankAccountTypeEntity;
}

export enum GovernmentInstitutionBankAccountEntity {
    Code = "Code",
    AccountNumber = "AccountNumber",
    AccountPrefix = "AccountPrefix",
    BankCode = "BankCode",
    CountryCode = "CountryCode",
    GovernmentInstitutionBankAccountTypeCode = "GovernmentInstitutionBankAccountTypeCode",
    IBAN = "IBAN",
    Name = "Name",
    Bank = "Bank",
    Country = "Country",
    GovernmentInstitutionBankAccountType = "GovernmentInstitutionBankAccountType",
}

export interface IGovernmentInstitutionBankAccountTypeEntity extends IEntityBase {
    Code?: string;
    CountryCode?: string;
    GovernmentInstitutionTypeCode?: string;
    Name?: string;
    GovernmentInstitutionType?: IGovernmentInstitutionTypeEntity;
}

export enum GovernmentInstitutionBankAccountTypeEntity {
    Code = "Code",
    CountryCode = "CountryCode",
    GovernmentInstitutionTypeCode = "GovernmentInstitutionTypeCode",
    Name = "Name",
    GovernmentInstitutionType = "GovernmentInstitutionType",
}

export interface IGovernmentInstitutionTypeEntity extends IEntityBase {
    Code?: string;
    CountryCode?: string;
    Name?: string;
    Country?: ICountryEntity;
}

export enum GovernmentInstitutionTypeEntity {
    Code = "Code",
    CountryCode = "CountryCode",
    Name = "Name",
    Country = "Country",
}

export interface IHotspotEntity extends IEntityBase {
    ViewId?: string;
    ElementId?: string;
    IsSystem?: boolean;
}

export enum HotspotEntity {
    ViewId = "ViewId",
    ElementId = "ElementId",
    IsSystem = "IsSystem",
}

export interface IImportDocumentEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    DocumentTypeCode?: string;
    ErrorMessage?: string;
    ImportTypeCode?: string;
    BackgroundJob?: IBackgroundJobEntity;
    DocumentType?: IDocumentTypeEntity;
    FileMetadata?: IFileMetadataEntity;
    ImportType?: IDocumentImportTypeEntity;
}

export enum ImportDocumentEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    DocumentTypeCode = "DocumentTypeCode",
    ErrorMessage = "ErrorMessage",
    ImportTypeCode = "ImportTypeCode",
    BackgroundJob = "BackgroundJob",
    DocumentType = "DocumentType",
    FileMetadata = "FileMetadata",
    ImportType = "ImportType",
}

export interface IInboxCommunicationEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    Body?: string;
    CommunicationTypeCode?: string;
    CommunicationType?: ICommunicationTypeEntity;
}

export enum InboxCommunicationEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    Body = "Body",
    CommunicationTypeCode = "CommunicationTypeCode",
    CommunicationType = "CommunicationType",
}

export interface IInboxEntityTypeEntity extends IEntityBase {
    Code?: string;
    IsTaxDocument?: boolean;
    Name?: string;
}

export enum InboxEntityTypeEntity {
    Code = "Code",
    IsTaxDocument = "IsTaxDocument",
    Name = "Name",
}

export interface IInboxFileEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    ApprovalStatusTypeCode?: string;
    Body?: string;
    DateReceived?: Date;
    EntityTypeCode?: string;
    InboxFileSourceCode?: string;
    IsSorted?: boolean;
    IsSpam?: boolean;
    Sender?: string;
    Subject?: string;
    ApprovalStatusType?: IApprovalStatusTypeEntity;
    CanceledByUser?: IUserEntity;
    Company?: ICompanyEntity;
    EntityType?: IInboxEntityTypeEntity;
    FileMetadata?: IFileMetadataEntity;
    InboxFileSource?: IInboxFileSourceEntity;
    Communications?: IInboxCommunicationEntity[];
}

export enum InboxFileEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    ApprovalStatusTypeCode = "ApprovalStatusTypeCode",
    Body = "Body",
    DateReceived = "DateReceived",
    EntityTypeCode = "EntityTypeCode",
    InboxFileSourceCode = "InboxFileSourceCode",
    IsSorted = "IsSorted",
    IsSpam = "IsSpam",
    Sender = "Sender",
    Subject = "Subject",
    ApprovalStatusType = "ApprovalStatusType",
    CanceledByUser = "CanceledByUser",
    Company = "Company",
    EntityType = "EntityType",
    FileMetadata = "FileMetadata",
    InboxFileSource = "InboxFileSource",
    Communications = "Communications",
}

export interface IInboxFileLogEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    FileName?: string;
    InboxFileStatusCode?: string;
    FileMetadata?: IFileMetadataEntity;
    InboxFileStatus?: IInboxFileStatusEntity;
}

export enum InboxFileLogEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    FileName = "FileName",
    InboxFileStatusCode = "InboxFileStatusCode",
    FileMetadata = "FileMetadata",
    InboxFileStatus = "InboxFileStatus",
}

export interface IInboxFileSourceEntity extends IEntityBase {
    Code?: string;
    Name?: string;
}

export enum InboxFileSourceEntity {
    Code = "Code",
    Name = "Name",
}

export interface IInboxFileStatusEntity extends IEntityBase {
    Code?: string;
    Name?: string;
}

export enum InboxFileStatusEntity {
    Code = "Code",
    Name = "Name",
}

export interface IInboxItemLogEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    DateReceived?: Date;
    EntityTypeCode?: string;
    InboxFileId?: number;
    InboxFileSourceCode?: string;
    InboxItemLogStateCode?: string;
    Name?: string;
    Sender?: string;
    Subject?: string;
    EntityType?: IInboxEntityTypeEntity;
    InboxFileSource?: IInboxFileSourceEntity;
    InboxItemLogState?: IInboxItemLogStateEntity;
    User?: IUserEntity;
}

export enum InboxItemLogEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    DateReceived = "DateReceived",
    EntityTypeCode = "EntityTypeCode",
    InboxFileId = "InboxFileId",
    InboxFileSourceCode = "InboxFileSourceCode",
    InboxItemLogStateCode = "InboxItemLogStateCode",
    Name = "Name",
    Sender = "Sender",
    Subject = "Subject",
    EntityType = "EntityType",
    InboxFileSource = "InboxFileSource",
    InboxItemLogState = "InboxItemLogState",
    User = "User",
}

export interface IInboxItemLogStateEntity extends IEntityBase {
    Code?: string;
    Name?: string;
}

export enum InboxItemLogStateEntity {
    Code = "Code",
    Name = "Name",
}

export interface IIncomeStatementLayoutEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    ByFunctionReportSectionCode?: string;
    ByNatureReportSectionCode?: string;
    Account?: IAccountEntity;
    ByFunctionReportSection?: IFinancialReportLayoutSectionEntity;
    ByNatureReportSection?: IFinancialReportLayoutSectionEntity;
    ChartOfAccounts?: IChartOfAccountsEntity;
}

export enum IncomeStatementLayoutEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    ByFunctionReportSectionCode = "ByFunctionReportSectionCode",
    ByNatureReportSectionCode = "ByNatureReportSectionCode",
    Account = "Account",
    ByFunctionReportSection = "ByFunctionReportSection",
    ByNatureReportSection = "ByNatureReportSection",
    ChartOfAccounts = "ChartOfAccounts",
}

export interface IIncomeStatementLayoutTemplateEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    ByFunctionReportSectionCode?: string;
    ByNatureReportSectionCode?: string;
    Account?: IAccountTemplateEntity;
    ByFunctionReportSection?: IFinancialReportLayoutSectionEntity;
    ByNatureReportSection?: IFinancialReportLayoutSectionEntity;
    ChartOfAccounts?: IChartOfAccountsTemplateEntity;
}

export enum IncomeStatementLayoutTemplateEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    ByFunctionReportSectionCode = "ByFunctionReportSectionCode",
    ByNatureReportSectionCode = "ByNatureReportSectionCode",
    Account = "Account",
    ByFunctionReportSection = "ByFunctionReportSection",
    ByNatureReportSection = "ByNatureReportSection",
    ChartOfAccounts = "ChartOfAccounts",
}

export interface IInitialAccountBalanceEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    InitialBalance?: number;
    InitialTransactionBalance?: number;
    IsDebit?: boolean;
    Number?: string;
    TransactionCurrencyCode?: string;
    TransactionCurrency?: ICurrencyEntity;
}

export enum InitialAccountBalanceEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    InitialBalance = "InitialBalance",
    InitialTransactionBalance = "InitialTransactionBalance",
    IsDebit = "IsDebit",
    Number = "Number",
    TransactionCurrencyCode = "TransactionCurrencyCode",
    TransactionCurrency = "TransactionCurrency",
}

export interface IInteractionTypeEntity extends IEntityBase {
    Code?: string;
    Name?: string;
}

export enum InteractionTypeEntity {
    Code = "Code",
    Name = "Name",
}

export interface IInternalDocumentEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    Amount?: number;
    AmountNet?: number;
    AmountVat?: number;
    ClearedStatusCode?: string;
    CurrencyCode?: string;
    DateAccountingTransaction?: Date;
    DateCbaDocument?: Date;
    DateClearing?: Date;
    DateElectronicSubmissionVatControlStatement?: Date;
    DateElectronicSubmissionVatStatement?: Date;
    DateElectronicSubmissionVatViesStatement?: Date;
    DateVatDeduction?: Date;
    DocumentStatusCode?: string;
    DocumentTypeCode?: string;
    DocumentVatStatementStatusCode?: string;
    ExchangeRatePerUnit?: number;
    Note?: string;
    NumberOurs?: string;
    NumberTheirs?: string;
    PostedStatusCode?: string;
    RemittanceInformation?: string;
    SymbolConstant?: string;
    SymbolSpecific?: string;
    SymbolVariable?: string;
    TransactionAmount?: number;
    TransactionAmountDue?: number;
    TransactionAmountNet?: number;
    TransactionAmountVat?: number;
    TransactionCurrencyCode?: string;
    VatControlStatementStatusCode?: string;
    VatStatementStatusCode?: string;
    VatViesStatementStatusCode?: string;
    ExchangeRatePerUnitDateVatDeduction?: number;
    AccountAssignmentSelection?: IDocumentAccountAssignmentSelectionEntity;
    BankAccount?: IDocumentBankAccountEntity;
    BusinessPartner?: IDocumentBusinessPartnerEntity;
    CbaCategory?: IDocumentCbaCategoryEntity;
    ClearedStatus?: IClearedStatusEntity;
    Company?: ICompanyEntity;
    Currency?: ICurrencyEntity;
    DocumentDraft?: IDocumentDraftEntity;
    DocumentStatus?: IDocumentStatusEntity;
    DocumentType?: IDocumentTypeEntity;
    DocumentVatStatementStatus?: IVatStatementStatusEntity;
    FiscalPeriod?: IFiscalPeriodEntity;
    FiscalYear?: IFiscalYearEntity;
    NumberRange?: INumberRangeEntity;
    PostedStatus?: IPostedStatusEntity;
    TransactionCurrency?: ICurrencyEntity;
    VatClassificationSelection?: IDocumentVatClassificationSelectionEntity;
    VatControlStatementStatus?: IVatStatementStatusEntity;
    VatStatementStatus?: IVatStatementStatusEntity;
    VatViesStatementStatus?: IVatStatementStatusEntity;
    Attachments?: IDocumentAttachmentEntity[];
    DocumentItems?: IDocumentItemViewEntity[];
    DocumentLinks?: IDocumentLinkEntity[];
    Labels?: IDocumentLabelEntity[];
    Locks?: IDocumentLockEntity[];
    OppositeDocumentLinks?: IOppositeDocumentDocumentLinkEntity[];
    Payments?: IOppositePaymentDocumentDocumentLinkEntity[];
    ClearingDocumentLinks?: IClearingDocumentLinkEntity[];
    CorrectiveDocumentDrafts?: IDocumentDraftEntity[];
    DeferredPlans?: IDeferredPlanEntity[];
    CbaEntryTypeCode?: string;
    DateCbaEntry?: Date;
    Explanation?: string;
    IsInternallyManaged?: boolean;
    CbaEntryType?: ICbaEntryTypeEntity;
    Items?: IInternalDocumentItemEntity[];
}

export enum InternalDocumentEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    Amount = "Amount",
    AmountNet = "AmountNet",
    AmountVat = "AmountVat",
    ClearedStatusCode = "ClearedStatusCode",
    CurrencyCode = "CurrencyCode",
    DateAccountingTransaction = "DateAccountingTransaction",
    DateCbaDocument = "DateCbaDocument",
    DateClearing = "DateClearing",
    DateElectronicSubmissionVatControlStatement = "DateElectronicSubmissionVatControlStatement",
    DateElectronicSubmissionVatStatement = "DateElectronicSubmissionVatStatement",
    DateElectronicSubmissionVatViesStatement = "DateElectronicSubmissionVatViesStatement",
    DateVatDeduction = "DateVatDeduction",
    DocumentStatusCode = "DocumentStatusCode",
    DocumentTypeCode = "DocumentTypeCode",
    DocumentVatStatementStatusCode = "DocumentVatStatementStatusCode",
    ExchangeRatePerUnit = "ExchangeRatePerUnit",
    Note = "Note",
    NumberOurs = "NumberOurs",
    NumberTheirs = "NumberTheirs",
    PostedStatusCode = "PostedStatusCode",
    RemittanceInformation = "RemittanceInformation",
    SymbolConstant = "SymbolConstant",
    SymbolSpecific = "SymbolSpecific",
    SymbolVariable = "SymbolVariable",
    TransactionAmount = "TransactionAmount",
    TransactionAmountDue = "TransactionAmountDue",
    TransactionAmountNet = "TransactionAmountNet",
    TransactionAmountVat = "TransactionAmountVat",
    TransactionCurrencyCode = "TransactionCurrencyCode",
    VatControlStatementStatusCode = "VatControlStatementStatusCode",
    VatStatementStatusCode = "VatStatementStatusCode",
    VatViesStatementStatusCode = "VatViesStatementStatusCode",
    ExchangeRatePerUnitDateVatDeduction = "ExchangeRatePerUnitDateVatDeduction",
    AccountAssignmentSelection = "AccountAssignmentSelection",
    BankAccount = "BankAccount",
    BusinessPartner = "BusinessPartner",
    CbaCategory = "CbaCategory",
    ClearedStatus = "ClearedStatus",
    Company = "Company",
    Currency = "Currency",
    DocumentDraft = "DocumentDraft",
    DocumentStatus = "DocumentStatus",
    DocumentType = "DocumentType",
    DocumentVatStatementStatus = "DocumentVatStatementStatus",
    FiscalPeriod = "FiscalPeriod",
    FiscalYear = "FiscalYear",
    NumberRange = "NumberRange",
    PostedStatus = "PostedStatus",
    TransactionCurrency = "TransactionCurrency",
    VatClassificationSelection = "VatClassificationSelection",
    VatControlStatementStatus = "VatControlStatementStatus",
    VatStatementStatus = "VatStatementStatus",
    VatViesStatementStatus = "VatViesStatementStatus",
    Attachments = "Attachments",
    DocumentItems = "DocumentItems",
    DocumentLinks = "DocumentLinks",
    Labels = "Labels",
    Locks = "Locks",
    OppositeDocumentLinks = "OppositeDocumentLinks",
    Payments = "Payments",
    ClearingDocumentLinks = "ClearingDocumentLinks",
    CorrectiveDocumentDrafts = "CorrectiveDocumentDrafts",
    DeferredPlans = "DeferredPlans",
    CbaEntryTypeCode = "CbaEntryTypeCode",
    DateCbaEntry = "DateCbaEntry",
    Explanation = "Explanation",
    IsInternallyManaged = "IsInternallyManaged",
    CbaEntryType = "CbaEntryType",
    Items = "Items",
}

export interface IInternalDocumentItemEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    Amount?: number;
    AmountNet?: number;
    AmountVat?: number;
    Description?: string;
    Note?: string;
    Order?: number;
    TransactionAmount?: number;
    TransactionAmountNet?: number;
    TransactionAmountVat?: number;
    VatCode?: string;
    AccountAssignmentSelection?: IDocumentItemAccountAssignmentSelectionEntity;
    CbaCategory?: IDocumentItemCbaCategoryEntity;
    DocumentItemDraft?: IDocumentItemDraftEntity;
    LabelSelection?: ILabelSelectionEntity;
    Vat?: IVatEntity;
    VatClassificationSelection?: IDocumentItemVatClassificationSelectionEntity;
    TransactionCurrencyCode?: string;
    LinkedDocument?: IDocumentEntity;
    TransactionCurrency?: ICurrencyEntity;
}

export enum InternalDocumentItemEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    Amount = "Amount",
    AmountNet = "AmountNet",
    AmountVat = "AmountVat",
    Description = "Description",
    Note = "Note",
    Order = "Order",
    TransactionAmount = "TransactionAmount",
    TransactionAmountNet = "TransactionAmountNet",
    TransactionAmountVat = "TransactionAmountVat",
    VatCode = "VatCode",
    AccountAssignmentSelection = "AccountAssignmentSelection",
    CbaCategory = "CbaCategory",
    DocumentItemDraft = "DocumentItemDraft",
    LabelSelection = "LabelSelection",
    Vat = "Vat",
    VatClassificationSelection = "VatClassificationSelection",
    TransactionCurrencyCode = "TransactionCurrencyCode",
    LinkedDocument = "LinkedDocument",
    TransactionCurrency = "TransactionCurrency",
}

export interface IInventoryTypeEntity extends IEntityBase {
    Code?: string;
    Name?: string;
}

export enum InventoryTypeEntity {
    Code = "Code",
    Name = "Name",
}

export interface IInvoiceIssuedEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    Amount?: number;
    AmountNet?: number;
    AmountVat?: number;
    ClearedStatusCode?: string;
    CurrencyCode?: string;
    DateAccountingTransaction?: Date;
    DateCbaDocument?: Date;
    DateClearing?: Date;
    DateElectronicSubmissionVatControlStatement?: Date;
    DateElectronicSubmissionVatStatement?: Date;
    DateElectronicSubmissionVatViesStatement?: Date;
    DateVatDeduction?: Date;
    DocumentStatusCode?: string;
    DocumentTypeCode?: string;
    DocumentVatStatementStatusCode?: string;
    ExchangeRatePerUnit?: number;
    Note?: string;
    NumberOurs?: string;
    NumberTheirs?: string;
    PostedStatusCode?: string;
    RemittanceInformation?: string;
    SymbolConstant?: string;
    SymbolSpecific?: string;
    SymbolVariable?: string;
    TransactionAmount?: number;
    TransactionAmountDue?: number;
    TransactionAmountNet?: number;
    TransactionAmountVat?: number;
    TransactionCurrencyCode?: string;
    VatControlStatementStatusCode?: string;
    VatStatementStatusCode?: string;
    VatViesStatementStatusCode?: string;
    ExchangeRatePerUnitDateVatDeduction?: number;
    AccountAssignmentSelection?: IDocumentAccountAssignmentSelectionEntity;
    BankAccount?: IDocumentBankAccountEntity;
    BusinessPartner?: IDocumentBusinessPartnerEntity;
    CbaCategory?: IDocumentCbaCategoryEntity;
    ClearedStatus?: IClearedStatusEntity;
    Company?: ICompanyEntity;
    Currency?: ICurrencyEntity;
    DocumentDraft?: IDocumentDraftEntity;
    DocumentStatus?: IDocumentStatusEntity;
    DocumentType?: IDocumentTypeEntity;
    DocumentVatStatementStatus?: IVatStatementStatusEntity;
    FiscalPeriod?: IFiscalPeriodEntity;
    FiscalYear?: IFiscalYearEntity;
    NumberRange?: INumberRangeEntity;
    PostedStatus?: IPostedStatusEntity;
    TransactionCurrency?: ICurrencyEntity;
    VatClassificationSelection?: IDocumentVatClassificationSelectionEntity;
    VatControlStatementStatus?: IVatStatementStatusEntity;
    VatStatementStatus?: IVatStatementStatusEntity;
    VatViesStatementStatus?: IVatStatementStatusEntity;
    Attachments?: IDocumentAttachmentEntity[];
    DocumentItems?: IDocumentItemViewEntity[];
    DocumentLinks?: IDocumentLinkEntity[];
    Labels?: IDocumentLabelEntity[];
    Locks?: IDocumentLockEntity[];
    OppositeDocumentLinks?: IOppositeDocumentDocumentLinkEntity[];
    Payments?: IOppositePaymentDocumentDocumentLinkEntity[];
    ClearingDocumentLinks?: IClearingDocumentLinkEntity[];
    CorrectiveDocumentDrafts?: IDocumentDraftEntity[];
    DeferredPlans?: IDeferredPlanEntity[];
    DateDue?: Date;
    DateIssued?: Date;
    DateTaxableSupply?: Date;
    PaymentMethodCode?: string;
    SymbolMatching?: string;
    TextBeforeItems?: string;
    TransactionAmountToPay?: number;
    TransactionAmountToReceive?: number;
    CashBox?: ICashBoxEntity;
    PaymentMethod?: IPaymentMethodEntity;
    Items?: IRegularDocumentItemEntity[];
}

export enum InvoiceIssuedEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    Amount = "Amount",
    AmountNet = "AmountNet",
    AmountVat = "AmountVat",
    ClearedStatusCode = "ClearedStatusCode",
    CurrencyCode = "CurrencyCode",
    DateAccountingTransaction = "DateAccountingTransaction",
    DateCbaDocument = "DateCbaDocument",
    DateClearing = "DateClearing",
    DateElectronicSubmissionVatControlStatement = "DateElectronicSubmissionVatControlStatement",
    DateElectronicSubmissionVatStatement = "DateElectronicSubmissionVatStatement",
    DateElectronicSubmissionVatViesStatement = "DateElectronicSubmissionVatViesStatement",
    DateVatDeduction = "DateVatDeduction",
    DocumentStatusCode = "DocumentStatusCode",
    DocumentTypeCode = "DocumentTypeCode",
    DocumentVatStatementStatusCode = "DocumentVatStatementStatusCode",
    ExchangeRatePerUnit = "ExchangeRatePerUnit",
    Note = "Note",
    NumberOurs = "NumberOurs",
    NumberTheirs = "NumberTheirs",
    PostedStatusCode = "PostedStatusCode",
    RemittanceInformation = "RemittanceInformation",
    SymbolConstant = "SymbolConstant",
    SymbolSpecific = "SymbolSpecific",
    SymbolVariable = "SymbolVariable",
    TransactionAmount = "TransactionAmount",
    TransactionAmountDue = "TransactionAmountDue",
    TransactionAmountNet = "TransactionAmountNet",
    TransactionAmountVat = "TransactionAmountVat",
    TransactionCurrencyCode = "TransactionCurrencyCode",
    VatControlStatementStatusCode = "VatControlStatementStatusCode",
    VatStatementStatusCode = "VatStatementStatusCode",
    VatViesStatementStatusCode = "VatViesStatementStatusCode",
    ExchangeRatePerUnitDateVatDeduction = "ExchangeRatePerUnitDateVatDeduction",
    AccountAssignmentSelection = "AccountAssignmentSelection",
    BankAccount = "BankAccount",
    BusinessPartner = "BusinessPartner",
    CbaCategory = "CbaCategory",
    ClearedStatus = "ClearedStatus",
    Company = "Company",
    Currency = "Currency",
    DocumentDraft = "DocumentDraft",
    DocumentStatus = "DocumentStatus",
    DocumentType = "DocumentType",
    DocumentVatStatementStatus = "DocumentVatStatementStatus",
    FiscalPeriod = "FiscalPeriod",
    FiscalYear = "FiscalYear",
    NumberRange = "NumberRange",
    PostedStatus = "PostedStatus",
    TransactionCurrency = "TransactionCurrency",
    VatClassificationSelection = "VatClassificationSelection",
    VatControlStatementStatus = "VatControlStatementStatus",
    VatStatementStatus = "VatStatementStatus",
    VatViesStatementStatus = "VatViesStatementStatus",
    Attachments = "Attachments",
    DocumentItems = "DocumentItems",
    DocumentLinks = "DocumentLinks",
    Labels = "Labels",
    Locks = "Locks",
    OppositeDocumentLinks = "OppositeDocumentLinks",
    Payments = "Payments",
    ClearingDocumentLinks = "ClearingDocumentLinks",
    CorrectiveDocumentDrafts = "CorrectiveDocumentDrafts",
    DeferredPlans = "DeferredPlans",
    DateDue = "DateDue",
    DateIssued = "DateIssued",
    DateTaxableSupply = "DateTaxableSupply",
    PaymentMethodCode = "PaymentMethodCode",
    SymbolMatching = "SymbolMatching",
    TextBeforeItems = "TextBeforeItems",
    TransactionAmountToPay = "TransactionAmountToPay",
    TransactionAmountToReceive = "TransactionAmountToReceive",
    CashBox = "CashBox",
    PaymentMethod = "PaymentMethod",
    Items = "Items",
}

export interface IInvoiceReceivedEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    Amount?: number;
    AmountNet?: number;
    AmountVat?: number;
    ClearedStatusCode?: string;
    CurrencyCode?: string;
    DateAccountingTransaction?: Date;
    DateCbaDocument?: Date;
    DateClearing?: Date;
    DateElectronicSubmissionVatControlStatement?: Date;
    DateElectronicSubmissionVatStatement?: Date;
    DateElectronicSubmissionVatViesStatement?: Date;
    DateVatDeduction?: Date;
    DocumentStatusCode?: string;
    DocumentTypeCode?: string;
    DocumentVatStatementStatusCode?: string;
    ExchangeRatePerUnit?: number;
    Note?: string;
    NumberOurs?: string;
    NumberTheirs?: string;
    PostedStatusCode?: string;
    RemittanceInformation?: string;
    SymbolConstant?: string;
    SymbolSpecific?: string;
    SymbolVariable?: string;
    TransactionAmount?: number;
    TransactionAmountDue?: number;
    TransactionAmountNet?: number;
    TransactionAmountVat?: number;
    TransactionCurrencyCode?: string;
    VatControlStatementStatusCode?: string;
    VatStatementStatusCode?: string;
    VatViesStatementStatusCode?: string;
    ExchangeRatePerUnitDateVatDeduction?: number;
    AccountAssignmentSelection?: IDocumentAccountAssignmentSelectionEntity;
    BankAccount?: IDocumentBankAccountEntity;
    BusinessPartner?: IDocumentBusinessPartnerEntity;
    CbaCategory?: IDocumentCbaCategoryEntity;
    ClearedStatus?: IClearedStatusEntity;
    Company?: ICompanyEntity;
    Currency?: ICurrencyEntity;
    DocumentDraft?: IDocumentDraftEntity;
    DocumentStatus?: IDocumentStatusEntity;
    DocumentType?: IDocumentTypeEntity;
    DocumentVatStatementStatus?: IVatStatementStatusEntity;
    FiscalPeriod?: IFiscalPeriodEntity;
    FiscalYear?: IFiscalYearEntity;
    NumberRange?: INumberRangeEntity;
    PostedStatus?: IPostedStatusEntity;
    TransactionCurrency?: ICurrencyEntity;
    VatClassificationSelection?: IDocumentVatClassificationSelectionEntity;
    VatControlStatementStatus?: IVatStatementStatusEntity;
    VatStatementStatus?: IVatStatementStatusEntity;
    VatViesStatementStatus?: IVatStatementStatusEntity;
    Attachments?: IDocumentAttachmentEntity[];
    DocumentItems?: IDocumentItemViewEntity[];
    DocumentLinks?: IDocumentLinkEntity[];
    Labels?: IDocumentLabelEntity[];
    Locks?: IDocumentLockEntity[];
    OppositeDocumentLinks?: IOppositeDocumentDocumentLinkEntity[];
    Payments?: IOppositePaymentDocumentDocumentLinkEntity[];
    ClearingDocumentLinks?: IClearingDocumentLinkEntity[];
    CorrectiveDocumentDrafts?: IDocumentDraftEntity[];
    DeferredPlans?: IDeferredPlanEntity[];
    BankPaymentOrderStateCode?: string;
    DateBankApiPaymentOrder?: Date;
    DateDue?: Date;
    DateIssued?: Date;
    DateReceived?: Date;
    DateTaxableSupply?: Date;
    PaymentMethodCode?: string;
    SymbolMatching?: string;
    TextBeforeItems?: string;
    TransactionAmountToPay?: number;
    TransactionAmountToReceive?: number;
    BankPaymentOrderState?: IBankPaymentOrderStateEntity;
    CashBox?: ICashBoxEntity;
    PaymentMethod?: IPaymentMethodEntity;
    Items?: IRegularDocumentItemEntity[];
}

export enum InvoiceReceivedEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    Amount = "Amount",
    AmountNet = "AmountNet",
    AmountVat = "AmountVat",
    ClearedStatusCode = "ClearedStatusCode",
    CurrencyCode = "CurrencyCode",
    DateAccountingTransaction = "DateAccountingTransaction",
    DateCbaDocument = "DateCbaDocument",
    DateClearing = "DateClearing",
    DateElectronicSubmissionVatControlStatement = "DateElectronicSubmissionVatControlStatement",
    DateElectronicSubmissionVatStatement = "DateElectronicSubmissionVatStatement",
    DateElectronicSubmissionVatViesStatement = "DateElectronicSubmissionVatViesStatement",
    DateVatDeduction = "DateVatDeduction",
    DocumentStatusCode = "DocumentStatusCode",
    DocumentTypeCode = "DocumentTypeCode",
    DocumentVatStatementStatusCode = "DocumentVatStatementStatusCode",
    ExchangeRatePerUnit = "ExchangeRatePerUnit",
    Note = "Note",
    NumberOurs = "NumberOurs",
    NumberTheirs = "NumberTheirs",
    PostedStatusCode = "PostedStatusCode",
    RemittanceInformation = "RemittanceInformation",
    SymbolConstant = "SymbolConstant",
    SymbolSpecific = "SymbolSpecific",
    SymbolVariable = "SymbolVariable",
    TransactionAmount = "TransactionAmount",
    TransactionAmountDue = "TransactionAmountDue",
    TransactionAmountNet = "TransactionAmountNet",
    TransactionAmountVat = "TransactionAmountVat",
    TransactionCurrencyCode = "TransactionCurrencyCode",
    VatControlStatementStatusCode = "VatControlStatementStatusCode",
    VatStatementStatusCode = "VatStatementStatusCode",
    VatViesStatementStatusCode = "VatViesStatementStatusCode",
    ExchangeRatePerUnitDateVatDeduction = "ExchangeRatePerUnitDateVatDeduction",
    AccountAssignmentSelection = "AccountAssignmentSelection",
    BankAccount = "BankAccount",
    BusinessPartner = "BusinessPartner",
    CbaCategory = "CbaCategory",
    ClearedStatus = "ClearedStatus",
    Company = "Company",
    Currency = "Currency",
    DocumentDraft = "DocumentDraft",
    DocumentStatus = "DocumentStatus",
    DocumentType = "DocumentType",
    DocumentVatStatementStatus = "DocumentVatStatementStatus",
    FiscalPeriod = "FiscalPeriod",
    FiscalYear = "FiscalYear",
    NumberRange = "NumberRange",
    PostedStatus = "PostedStatus",
    TransactionCurrency = "TransactionCurrency",
    VatClassificationSelection = "VatClassificationSelection",
    VatControlStatementStatus = "VatControlStatementStatus",
    VatStatementStatus = "VatStatementStatus",
    VatViesStatementStatus = "VatViesStatementStatus",
    Attachments = "Attachments",
    DocumentItems = "DocumentItems",
    DocumentLinks = "DocumentLinks",
    Labels = "Labels",
    Locks = "Locks",
    OppositeDocumentLinks = "OppositeDocumentLinks",
    Payments = "Payments",
    ClearingDocumentLinks = "ClearingDocumentLinks",
    CorrectiveDocumentDrafts = "CorrectiveDocumentDrafts",
    DeferredPlans = "DeferredPlans",
    BankPaymentOrderStateCode = "BankPaymentOrderStateCode",
    DateBankApiPaymentOrder = "DateBankApiPaymentOrder",
    DateDue = "DateDue",
    DateIssued = "DateIssued",
    DateReceived = "DateReceived",
    DateTaxableSupply = "DateTaxableSupply",
    PaymentMethodCode = "PaymentMethodCode",
    SymbolMatching = "SymbolMatching",
    TextBeforeItems = "TextBeforeItems",
    TransactionAmountToPay = "TransactionAmountToPay",
    TransactionAmountToReceive = "TransactionAmountToReceive",
    BankPaymentOrderState = "BankPaymentOrderState",
    CashBox = "CashBox",
    PaymentMethod = "PaymentMethod",
    Items = "Items",
}

export interface IIsdocImportDocumentEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    DocumentTypeCode?: string;
    ErrorMessage?: string;
    ImportTypeCode?: string;
    BackgroundJob?: IBackgroundJobEntity;
    DocumentType?: IDocumentTypeEntity;
    FileMetadata?: IFileMetadataEntity;
    ImportType?: IDocumentImportTypeEntity;
}

export enum IsdocImportDocumentEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    DocumentTypeCode = "DocumentTypeCode",
    ErrorMessage = "ErrorMessage",
    ImportTypeCode = "ImportTypeCode",
    BackgroundJob = "BackgroundJob",
    DocumentType = "DocumentType",
    FileMetadata = "FileMetadata",
    ImportType = "ImportType",
}

export interface IItemTemplateEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    AccountingCode?: string;
    AlwaysOffer?: boolean;
    Description?: string;
    TransactionCurrencyCode?: string;
    UnitOfMeasureCode?: string;
    VatCode?: string;
    VatStatusCode?: string;
    Quantity?: number;
    TransactionAmount?: number;
    TransactionAmountNet?: number;
    TransactionAmountVat?: number;
    AccountAssignmentSelection?: IItemTemplateAccountAssignmentSelectionEntity;
    Accounting?: IAccountingEntity;
    CbaTaxImpact?: IItemTemplateCbaTaxImpactEntity;
    TransactionCurrency?: ICurrencyEntity;
    UnitOfMeasure?: IUnitOfMeasureEntity;
    Vat?: IVatEntity;
    VatClassificationSelection?: IItemTemplateVatClassificationSelectionEntity;
    VatStatus?: IVatStatusEntity;
    AllowedCompanies?: ICompanyEntity[];
}

export enum ItemTemplateEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    AccountingCode = "AccountingCode",
    AlwaysOffer = "AlwaysOffer",
    Description = "Description",
    TransactionCurrencyCode = "TransactionCurrencyCode",
    UnitOfMeasureCode = "UnitOfMeasureCode",
    VatCode = "VatCode",
    VatStatusCode = "VatStatusCode",
    Quantity = "Quantity",
    TransactionAmount = "TransactionAmount",
    TransactionAmountNet = "TransactionAmountNet",
    TransactionAmountVat = "TransactionAmountVat",
    AccountAssignmentSelection = "AccountAssignmentSelection",
    Accounting = "Accounting",
    CbaTaxImpact = "CbaTaxImpact",
    TransactionCurrency = "TransactionCurrency",
    UnitOfMeasure = "UnitOfMeasure",
    Vat = "Vat",
    VatClassificationSelection = "VatClassificationSelection",
    VatStatus = "VatStatus",
    AllowedCompanies = "AllowedCompanies",
}

export interface IItemTemplateAccountAssignmentEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    CreditAccountName?: string;
    CreditAccountNumber?: string;
    DebitAccountName?: string;
    DebitAccountNumber?: string;
    Name?: string;
    ShortName?: string;
}

export enum ItemTemplateAccountAssignmentEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    CreditAccountName = "CreditAccountName",
    CreditAccountNumber = "CreditAccountNumber",
    DebitAccountName = "DebitAccountName",
    DebitAccountNumber = "DebitAccountNumber",
    Name = "Name",
    ShortName = "ShortName",
}

export interface IItemTemplateAccountAssignmentSelectionEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    SelectionCode?: string;
    AccountAssignment?: IItemTemplateAccountAssignmentEntity;
    Selection?: ISelectionEntity;
}

export enum ItemTemplateAccountAssignmentSelectionEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    SelectionCode = "SelectionCode",
    AccountAssignment = "AccountAssignment",
    Selection = "Selection",
}

export interface IItemTemplateCbaTaxImpactEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    IsAssetAcquisition?: boolean;
    TaxImpactCode?: string;
    TaxPercentage?: number;
    TaxImpact?: ICbaCategoryTaxImpactEntity;
}

export enum ItemTemplateCbaTaxImpactEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    IsAssetAcquisition = "IsAssetAcquisition",
    TaxImpactCode = "TaxImpactCode",
    TaxPercentage = "TaxPercentage",
    TaxImpact = "TaxImpact",
}

export interface IItemTemplateVatClassificationEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    NonTaxAccountName?: string;
    NonTaxAccountNumber?: string;
    VatDeductionTypeCode?: string;
    VatProportionalDeduction?: number;
    VatDeductionType?: IVatDeductionTypeEntity;
}

export enum ItemTemplateVatClassificationEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    NonTaxAccountName = "NonTaxAccountName",
    NonTaxAccountNumber = "NonTaxAccountNumber",
    VatDeductionTypeCode = "VatDeductionTypeCode",
    VatProportionalDeduction = "VatProportionalDeduction",
    VatDeductionType = "VatDeductionType",
}

export interface IItemTemplateVatClassificationSelectionEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    SelectionCode?: string;
    Selection?: ISelectionEntity;
    VatClassification?: IItemTemplateVatClassificationEntity;
}

export enum ItemTemplateVatClassificationSelectionEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    SelectionCode = "SelectionCode",
    Selection = "Selection",
    VatClassification = "VatClassification",
}

export interface IJournalEntryEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    Amount?: number;
    CurrencyCode?: string;
    DateAccountingTransaction?: Date;
    DatePosted?: Date;
    Description?: string;
    DocumentTypeCode?: string;
    IsBalancing?: boolean;
    IsClosing?: boolean;
    IsOpening?: boolean;
    TransactionAmount?: number;
    TransactionCurrencyCode?: string;
    Company?: ICompanyEntity;
    CreditAccount?: IAccountEntity;
    Currency?: ICurrencyEntity;
    DebitAccount?: IAccountEntity;
    Document?: IDocumentEntity;
    DocumentItem?: IDocumentItemEntity;
    DocumentType?: IDocumentTypeEntity;
    FiscalPeriod?: IFiscalPeriodEntity;
    FiscalYear?: IFiscalYearEntity;
    PaymentDocument?: IPaymentDocumentEntity;
    PaymentDocumentItem?: IPaymentDocumentItemEntity;
    SplitItemAssignment?: ISplitAccountAssignmentEntity;
    TransactionCurrency?: ICurrencyEntity;
    Labels?: IJournalEntryLabelEntity[];
}

export enum JournalEntryEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    Amount = "Amount",
    CurrencyCode = "CurrencyCode",
    DateAccountingTransaction = "DateAccountingTransaction",
    DatePosted = "DatePosted",
    Description = "Description",
    DocumentTypeCode = "DocumentTypeCode",
    IsBalancing = "IsBalancing",
    IsClosing = "IsClosing",
    IsOpening = "IsOpening",
    TransactionAmount = "TransactionAmount",
    TransactionCurrencyCode = "TransactionCurrencyCode",
    Company = "Company",
    CreditAccount = "CreditAccount",
    Currency = "Currency",
    DebitAccount = "DebitAccount",
    Document = "Document",
    DocumentItem = "DocumentItem",
    DocumentType = "DocumentType",
    FiscalPeriod = "FiscalPeriod",
    FiscalYear = "FiscalYear",
    PaymentDocument = "PaymentDocument",
    PaymentDocumentItem = "PaymentDocumentItem",
    SplitItemAssignment = "SplitItemAssignment",
    TransactionCurrency = "TransactionCurrency",
    Labels = "Labels",
}

export interface IJournalEntryLabelEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    EntityTypeCode?: string;
    EntityType?: IEntityTypeEntity;
    Label?: ILabelEntity;
    LabelHierarchy?: ILabelHierarchyEntity;
}

export enum JournalEntryLabelEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    EntityTypeCode = "EntityTypeCode",
    EntityType = "EntityType",
    Label = "Label",
    LabelHierarchy = "LabelHierarchy",
}

export interface IJournalEntryTypeEntity extends IEntityBase {
    Code?: string;
    Name?: string;
}

export enum JournalEntryTypeEntity {
    Code = "Code",
    Name = "Name",
}

export interface ILabelEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    IsActive?: boolean;
    Name?: string;
    Parent?: ILabelEntity;
    Children?: ILabelEntity[];
}

export enum LabelEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    IsActive = "IsActive",
    Name = "Name",
    Parent = "Parent",
    Children = "Children",
}

export interface ILabelHierarchyEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    Color?: string;
    Name?: string;
    Company?: ICompanyEntity;
    Labels?: ILabelEntity[];
}

export enum LabelHierarchyEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    Color = "Color",
    Name = "Name",
    Company = "Company",
    Labels = "Labels",
}

export interface ILabelSelectionEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    SelectionCode?: string;
    Selection?: ISelectionEntity;
    Labels?: IDocumentItemLabelEntity[];
}

export enum LabelSelectionEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    SelectionCode = "SelectionCode",
    Selection = "Selection",
    Labels = "Labels",
}

export interface ILabelSelectionDraftEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    SelectionCode?: string;
    Selection?: ISelectionEntity;
    Labels?: IDocumentItemLabelDraftEntity[];
}

export enum LabelSelectionDraftEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    SelectionCode = "SelectionCode",
    Selection = "Selection",
    Labels = "Labels",
}

export interface ILanguageEntity extends IEntityBase {
    Code?: string;
    Code2?: string;
    Code3B?: string;
    Code3T?: string;
    Name?: string;
}

export enum LanguageEntity {
    Code = "Code",
    Code2 = "Code2",
    Code3B = "Code3B",
    Code3T = "Code3T",
    Name = "Name",
}

export interface ILegalEntityCategoryEntity extends IEntityBase {
    Code?: string;
    Name?: string;
}

export enum LegalEntityCategoryEntity {
    Code = "Code",
    Name = "Name",
}

export interface ILegislativeFixedExchangeRateEntity extends IEntityBase {
    Code?: string;
    Amount?: number;
    ExchangeRateDirect?: number;
    FromCurrencyCode?: string;
    LegislativeCountryCode?: string;
    Name?: string;
    ToCurrencyCode?: string;
    Year?: number;
    FromCurrency?: ICurrencyEntity;
    LegislativeCountry?: ICountryEntity;
    ToCurrency?: ICurrencyEntity;
}

export enum LegislativeFixedExchangeRateEntity {
    Code = "Code",
    Amount = "Amount",
    ExchangeRateDirect = "ExchangeRateDirect",
    FromCurrencyCode = "FromCurrencyCode",
    LegislativeCountryCode = "LegislativeCountryCode",
    Name = "Name",
    ToCurrencyCode = "ToCurrencyCode",
    Year = "Year",
    FromCurrency = "FromCurrency",
    LegislativeCountry = "LegislativeCountry",
    ToCurrency = "ToCurrency",
}

export interface ILockTypeEntity extends IEntityBase {
    Code?: string;
    Name?: string;
}

export enum LockTypeEntity {
    Code = "Code",
    Name = "Name",
}

export interface IMandatoryAccountEntity extends IEntityBase {
    Code?: string;
    AccountRoleCode?: string;
    Name?: string;
    Number?: string;
    AccountRole?: IAccountRoleEntity;
}

export enum MandatoryAccountEntity {
    Code = "Code",
    AccountRoleCode = "AccountRoleCode",
    Name = "Name",
    Number = "Number",
    AccountRole = "AccountRole",
}

export interface IMandatoryChartOfAccountsEntity extends IEntityBase {
    Code?: string;
    AccountingCode?: string;
    Name?: string;
    Accounting?: IAccountingEntity;
    Accounts?: IMandatoryAccountEntity[];
}

export enum MandatoryChartOfAccountsEntity {
    Code = "Code",
    AccountingCode = "AccountingCode",
    Name = "Name",
    Accounting = "Accounting",
    Accounts = "Accounts",
}

export interface IMinorAssetEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    Amount?: number;
    CurrencyCode?: string;
    DateOfLastTransaction?: Date;
    Description?: string;
    Note?: string;
    NumberOurs?: string;
    Quantity?: number;
    StatusCode?: string;
    Company?: ICompanyEntity;
    Currency?: ICurrencyEntity;
    NumberRange?: INumberRangeEntity;
    Status?: IMinorAssetStatusEntity;
    Attachments?: IMinorAssetAttachmentEntity[];
    Items?: IMinorAssetItemEntity[];
    Labels?: IMinorAssetLabelEntity[];
}

export enum MinorAssetEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    Amount = "Amount",
    CurrencyCode = "CurrencyCode",
    DateOfLastTransaction = "DateOfLastTransaction",
    Description = "Description",
    Note = "Note",
    NumberOurs = "NumberOurs",
    Quantity = "Quantity",
    StatusCode = "StatusCode",
    Company = "Company",
    Currency = "Currency",
    NumberRange = "NumberRange",
    Status = "Status",
    Attachments = "Attachments",
    Items = "Items",
    Labels = "Labels",
}

export interface IMinorAssetAttachmentEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    Description?: string;
    File?: IFileMetadataEntity;
}

export enum MinorAssetAttachmentEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    Description = "Description",
    File = "File",
}

export interface IMinorAssetHistoryEntryEntity extends IEntityBase {
    NotId?: number;
    MinorAsset?: IMinorAssetEntity;
    MinorAssetHistoryItem?: IMinorAssetHistoryItemEntity;
    MinorAssetItem?: IMinorAssetItemEntity;
}

export enum MinorAssetHistoryEntryEntity {
    NotId = "NotId",
    MinorAsset = "MinorAsset",
    MinorAssetHistoryItem = "MinorAssetHistoryItem",
    MinorAssetItem = "MinorAssetItem",
}

export interface IMinorAssetHistoryItemEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    Amount?: number;
    CurrencyCode?: string;
    DateOfTransaction?: Date;
    IsVatIncluded?: boolean;
    ItemTypeCode?: string;
    Quantity?: number;
    Currency?: ICurrencyEntity;
    ItemType?: IAssetItemTypeEntity;
}

export enum MinorAssetHistoryItemEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    Amount = "Amount",
    CurrencyCode = "CurrencyCode",
    DateOfTransaction = "DateOfTransaction",
    IsVatIncluded = "IsVatIncluded",
    ItemTypeCode = "ItemTypeCode",
    Quantity = "Quantity",
    Currency = "Currency",
    ItemType = "ItemType",
}

export interface IMinorAssetItemEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    Amount?: number;
    CurrencyCode?: string;
    DateAcquired?: Date;
    DateGuarantee?: Date;
    DateUsefulLife?: Date;
    Description?: string;
    IsVatIncluded?: boolean;
    Note?: string;
    Order?: number;
    Quantity?: number;
    Currency?: ICurrencyEntity;
    Document?: IDocumentEntity;
    DocumentItem?: IDocumentItemEntity;
    Items?: IMinorAssetHistoryItemEntity[];
}

export enum MinorAssetItemEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    Amount = "Amount",
    CurrencyCode = "CurrencyCode",
    DateAcquired = "DateAcquired",
    DateGuarantee = "DateGuarantee",
    DateUsefulLife = "DateUsefulLife",
    Description = "Description",
    IsVatIncluded = "IsVatIncluded",
    Note = "Note",
    Order = "Order",
    Quantity = "Quantity",
    Currency = "Currency",
    Document = "Document",
    DocumentItem = "DocumentItem",
    Items = "Items",
}

export interface IMinorAssetLabelEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    EntityTypeCode?: string;
    EntityType?: IEntityTypeEntity;
    Label?: ILabelEntity;
    LabelHierarchy?: ILabelHierarchyEntity;
}

export enum MinorAssetLabelEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    EntityTypeCode = "EntityTypeCode",
    EntityType = "EntityType",
    Label = "Label",
    LabelHierarchy = "LabelHierarchy",
}

export interface IMinorAssetStatusEntity extends IEntityBase {
    Code?: string;
    Name?: string;
}

export enum MinorAssetStatusEntity {
    Code = "Code",
    Name = "Name",
}

export interface IModuleEntity extends IEntityBase {
    Code?: string;
    Description?: string;
    Name?: string;
    S5articleGuid?: string;
    IsActive?: boolean;
}

export enum ModuleEntity {
    Code = "Code",
    Description = "Description",
    Name = "Name",
    S5articleGuid = "S5articleGuid",
    IsActive = "IsActive",
}

export interface IMoneyS3ImportDocumentEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    DocumentTypeCode?: string;
    ErrorMessage?: string;
    ImportTypeCode?: string;
    BackgroundJob?: IBackgroundJobEntity;
    DocumentType?: IDocumentTypeEntity;
    FileMetadata?: IFileMetadataEntity;
    ImportType?: IDocumentImportTypeEntity;
}

export enum MoneyS3ImportDocumentEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    DocumentTypeCode = "DocumentTypeCode",
    ErrorMessage = "ErrorMessage",
    ImportTypeCode = "ImportTypeCode",
    BackgroundJob = "BackgroundJob",
    DocumentType = "DocumentType",
    FileMetadata = "FileMetadata",
    ImportType = "ImportType",
}

export interface IMonthOfYearEntity extends IEntityBase {
    Code?: string;
    Name?: string;
    Number?: number;
}

export enum MonthOfYearEntity {
    Code = "Code",
    Name = "Name",
    Number = "Number",
}

export interface IMultiplePostingDateAccountingTypeEntity extends IEntityBase {
    Code?: string;
    Name?: string;
}

export enum MultiplePostingDateAccountingTypeEntity {
    Code = "Code",
    Name = "Name",
}

export interface INotificationEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    NotificationTypeCode?: string;
    TextArgument?: string;
    Company?: ICompanyEntity;
    NotificationType?: INotificationTypeEntity;
}

export enum NotificationEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    NotificationTypeCode = "NotificationTypeCode",
    TextArgument = "TextArgument",
    Company = "Company",
    NotificationType = "NotificationType",
}

export interface INotificationSettingEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    IsBannerDisabled?: boolean;
    TypeSettings?: INotificationTypeSettingEntity[];
}

export enum NotificationSettingEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    IsBannerDisabled = "IsBannerDisabled",
    TypeSettings = "TypeSettings",
}

export interface INotificationTypeEntity extends IEntityBase {
    Code?: string;
    Name?: string;
    NotificationTypeGroupCode?: string;
    SendEmailByDefault?: boolean;
    NotificationTypeGroup?: INotificationTypeGroupEntity;
}

export enum NotificationTypeEntity {
    Code = "Code",
    Name = "Name",
    NotificationTypeGroupCode = "NotificationTypeGroupCode",
    SendEmailByDefault = "SendEmailByDefault",
    NotificationTypeGroup = "NotificationTypeGroup",
}

export interface INotificationTypeGroupEntity extends IEntityBase {
    Code?: string;
    CompanyPermissionCode?: string;
    Name?: string;
    CompanyPermission?: ICompanyPermissionEntity;
}

export enum NotificationTypeGroupEntity {
    Code = "Code",
    CompanyPermissionCode = "CompanyPermissionCode",
    Name = "Name",
    CompanyPermission = "CompanyPermission",
}

export interface INotificationTypeSettingEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    IsDisabled?: boolean;
    IsSendToEmailEnabled?: boolean;
    NotificationTypeCode?: string;
    NotificationType?: INotificationTypeEntity;
}

export enum NotificationTypeSettingEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    IsDisabled = "IsDisabled",
    IsSendToEmailEnabled = "IsSendToEmailEnabled",
    NotificationTypeCode = "NotificationTypeCode",
    NotificationType = "NotificationType",
}

export interface INumberRangeEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    InitialValue?: number;
    IsExhausted?: boolean;
    NextNumber?: string;
    NextValue?: number;
    Definition?: INumberRangeDefinitionEntity;
    FiscalYear?: IFiscalYearEntity;
    Usage?: INumberRangeUsageEntity;
}

export enum NumberRangeEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    InitialValue = "InitialValue",
    IsExhausted = "IsExhausted",
    NextNumber = "NextNumber",
    NextValue = "NextValue",
    Definition = "Definition",
    FiscalYear = "FiscalYear",
    Usage = "Usage",
}

export interface INumberRangeDefinitionEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    CarryForward?: boolean;
    CarryForwardPrefix?: string;
    IsActive?: boolean;
    IsDefault?: boolean;
    Name?: string;
    NumberOfDigits?: number;
    NumberRangeTypeCode?: string;
    NumberRangeTypePrefix?: string;
    PadWithZeros?: boolean;
    Prefix?: string;
    Suffix?: string;
    NumberRangeType?: INumberRangeTypeEntity;
    NumberRanges?: INumberRangeEntity[];
}

export enum NumberRangeDefinitionEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    CarryForward = "CarryForward",
    CarryForwardPrefix = "CarryForwardPrefix",
    IsActive = "IsActive",
    IsDefault = "IsDefault",
    Name = "Name",
    NumberOfDigits = "NumberOfDigits",
    NumberRangeTypeCode = "NumberRangeTypeCode",
    NumberRangeTypePrefix = "NumberRangeTypePrefix",
    PadWithZeros = "PadWithZeros",
    Prefix = "Prefix",
    Suffix = "Suffix",
    NumberRangeType = "NumberRangeType",
    NumberRanges = "NumberRanges",
}

export interface INumberRangeDefinitionTemplateEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    CarryForward?: boolean;
    CarryForwardPrefix?: string;
    IsActive?: boolean;
    IsDefault?: boolean;
    Name?: string;
    NumberOfDigits?: number;
    NumberRangeTypeCode?: string;
    NumberRangeTypePrefix?: string;
    PadWithZeros?: boolean;
    Prefix?: string;
    Suffix?: string;
    NumberRangeType?: INumberRangeTypeEntity;
}

export enum NumberRangeDefinitionTemplateEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    CarryForward = "CarryForward",
    CarryForwardPrefix = "CarryForwardPrefix",
    IsActive = "IsActive",
    IsDefault = "IsDefault",
    Name = "Name",
    NumberOfDigits = "NumberOfDigits",
    NumberRangeTypeCode = "NumberRangeTypeCode",
    NumberRangeTypePrefix = "NumberRangeTypePrefix",
    PadWithZeros = "PadWithZeros",
    Prefix = "Prefix",
    Suffix = "Suffix",
    NumberRangeType = "NumberRangeType",
}

export interface INumberRangeTypeEntity extends IEntityBase {
    Code?: string;
    DocumentTypeCode?: string;
    EntityTypeCode?: string;
    Name?: string;
    Prefix?: string;
    DocumentType?: IDocumentTypeEntity;
    EntityType?: IEntityTypeEntity;
    Wildcards?: INumberRangeWildcardEntity[];
}

export enum NumberRangeTypeEntity {
    Code = "Code",
    DocumentTypeCode = "DocumentTypeCode",
    EntityTypeCode = "EntityTypeCode",
    Name = "Name",
    Prefix = "Prefix",
    DocumentType = "DocumentType",
    EntityType = "EntityType",
    Wildcards = "Wildcards",
}

export interface INumberRangeUsageEntity extends IEntityBase {
    Id?: number;
    IsUsed?: boolean;
}

export enum NumberRangeUsageEntity {
    Id = "Id",
    IsUsed = "IsUsed",
}

export interface INumberRangeWildcardEntity extends IEntityBase {
    Code?: string;
    Description?: string;
    IsFiscalYearDependent?: boolean;
    Name?: string;
    Replacement?: string;
    Types?: INumberRangeTypeEntity[];
}

export enum NumberRangeWildcardEntity {
    Code = "Code",
    Description = "Description",
    IsFiscalYearDependent = "IsFiscalYearDependent",
    Name = "Name",
    Replacement = "Replacement",
    Types = "Types",
}

export interface IOppositeDocumentDocumentLinkEntity extends IEntityBase {
    Id?: number;
    OppositeDocumentLinkType?: string;
    TypeCode?: string;
    Amount?: number;
    TransactionAmount?: number;
    Type?: IDocumentLinkTypeEntity;
    SourceDocument?: IDocumentEntity;
}

export enum OppositeDocumentDocumentLinkEntity {
    Id = "Id",
    OppositeDocumentLinkType = "OppositeDocumentLinkType",
    TypeCode = "TypeCode",
    Amount = "Amount",
    TransactionAmount = "TransactionAmount",
    Type = "Type",
    SourceDocument = "SourceDocument",
}

export interface IOppositeDocumentLinkEntity extends IEntityBase {
    Id?: number;
    OppositeDocumentLinkType?: string;
    TypeCode?: string;
    Amount?: number;
    TransactionAmount?: number;
    Type?: IDocumentLinkTypeEntity;
}

export enum OppositeDocumentLinkEntity {
    Id = "Id",
    OppositeDocumentLinkType = "OppositeDocumentLinkType",
    TypeCode = "TypeCode",
    Amount = "Amount",
    TransactionAmount = "TransactionAmount",
    Type = "Type",
}

export interface IOppositePaymentDocumentDocumentLinkEntity extends IEntityBase {
    Id?: number;
    OppositeDocumentLinkType?: string;
    TypeCode?: string;
    Amount?: number;
    TransactionAmount?: number;
    Type?: IDocumentLinkTypeEntity;
    SourcePaymentDocument?: IPaymentDocumentEntity;
}

export enum OppositePaymentDocumentDocumentLinkEntity {
    Id = "Id",
    OppositeDocumentLinkType = "OppositeDocumentLinkType",
    TypeCode = "TypeCode",
    Amount = "Amount",
    TransactionAmount = "TransactionAmount",
    Type = "Type",
    SourcePaymentDocument = "SourcePaymentDocument",
}

export interface IOrganizationEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    IsActive?: boolean;
    Name?: string;
    AccountingCompany?: ICompanyEntity;
}

export enum OrganizationEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    IsActive = "IsActive",
    Name = "Name",
    AccountingCompany = "AccountingCompany",
}

export interface IOtherLiabilityEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    Amount?: number;
    AmountNet?: number;
    AmountVat?: number;
    ClearedStatusCode?: string;
    CurrencyCode?: string;
    DateAccountingTransaction?: Date;
    DateCbaDocument?: Date;
    DateClearing?: Date;
    DateElectronicSubmissionVatControlStatement?: Date;
    DateElectronicSubmissionVatStatement?: Date;
    DateElectronicSubmissionVatViesStatement?: Date;
    DateVatDeduction?: Date;
    DocumentStatusCode?: string;
    DocumentTypeCode?: string;
    DocumentVatStatementStatusCode?: string;
    ExchangeRatePerUnit?: number;
    Note?: string;
    NumberOurs?: string;
    NumberTheirs?: string;
    PostedStatusCode?: string;
    RemittanceInformation?: string;
    SymbolConstant?: string;
    SymbolSpecific?: string;
    SymbolVariable?: string;
    TransactionAmount?: number;
    TransactionAmountDue?: number;
    TransactionAmountNet?: number;
    TransactionAmountVat?: number;
    TransactionCurrencyCode?: string;
    VatControlStatementStatusCode?: string;
    VatStatementStatusCode?: string;
    VatViesStatementStatusCode?: string;
    ExchangeRatePerUnitDateVatDeduction?: number;
    AccountAssignmentSelection?: IDocumentAccountAssignmentSelectionEntity;
    BankAccount?: IDocumentBankAccountEntity;
    BusinessPartner?: IDocumentBusinessPartnerEntity;
    CbaCategory?: IDocumentCbaCategoryEntity;
    ClearedStatus?: IClearedStatusEntity;
    Company?: ICompanyEntity;
    Currency?: ICurrencyEntity;
    DocumentDraft?: IDocumentDraftEntity;
    DocumentStatus?: IDocumentStatusEntity;
    DocumentType?: IDocumentTypeEntity;
    DocumentVatStatementStatus?: IVatStatementStatusEntity;
    FiscalPeriod?: IFiscalPeriodEntity;
    FiscalYear?: IFiscalYearEntity;
    NumberRange?: INumberRangeEntity;
    PostedStatus?: IPostedStatusEntity;
    TransactionCurrency?: ICurrencyEntity;
    VatClassificationSelection?: IDocumentVatClassificationSelectionEntity;
    VatControlStatementStatus?: IVatStatementStatusEntity;
    VatStatementStatus?: IVatStatementStatusEntity;
    VatViesStatementStatus?: IVatStatementStatusEntity;
    Attachments?: IDocumentAttachmentEntity[];
    DocumentItems?: IDocumentItemViewEntity[];
    DocumentLinks?: IDocumentLinkEntity[];
    Labels?: IDocumentLabelEntity[];
    Locks?: IDocumentLockEntity[];
    OppositeDocumentLinks?: IOppositeDocumentDocumentLinkEntity[];
    Payments?: IOppositePaymentDocumentDocumentLinkEntity[];
    ClearingDocumentLinks?: IClearingDocumentLinkEntity[];
    CorrectiveDocumentDrafts?: IDocumentDraftEntity[];
    DeferredPlans?: IDeferredPlanEntity[];
    BankPaymentOrderStateCode?: string;
    DateBankApiPaymentOrder?: Date;
    DateDue?: Date;
    DateIssued?: Date;
    DateReceived?: Date;
    DateTaxableSupply?: Date;
    PaymentMethodCode?: string;
    SymbolMatching?: string;
    TextBeforeItems?: string;
    TransactionAmountToPay?: number;
    TransactionAmountToReceive?: number;
    BankPaymentOrderState?: IBankPaymentOrderStateEntity;
    CashBox?: ICashBoxEntity;
    PaymentMethod?: IPaymentMethodEntity;
    Items?: IRegularDocumentItemEntity[];
}

export enum OtherLiabilityEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    Amount = "Amount",
    AmountNet = "AmountNet",
    AmountVat = "AmountVat",
    ClearedStatusCode = "ClearedStatusCode",
    CurrencyCode = "CurrencyCode",
    DateAccountingTransaction = "DateAccountingTransaction",
    DateCbaDocument = "DateCbaDocument",
    DateClearing = "DateClearing",
    DateElectronicSubmissionVatControlStatement = "DateElectronicSubmissionVatControlStatement",
    DateElectronicSubmissionVatStatement = "DateElectronicSubmissionVatStatement",
    DateElectronicSubmissionVatViesStatement = "DateElectronicSubmissionVatViesStatement",
    DateVatDeduction = "DateVatDeduction",
    DocumentStatusCode = "DocumentStatusCode",
    DocumentTypeCode = "DocumentTypeCode",
    DocumentVatStatementStatusCode = "DocumentVatStatementStatusCode",
    ExchangeRatePerUnit = "ExchangeRatePerUnit",
    Note = "Note",
    NumberOurs = "NumberOurs",
    NumberTheirs = "NumberTheirs",
    PostedStatusCode = "PostedStatusCode",
    RemittanceInformation = "RemittanceInformation",
    SymbolConstant = "SymbolConstant",
    SymbolSpecific = "SymbolSpecific",
    SymbolVariable = "SymbolVariable",
    TransactionAmount = "TransactionAmount",
    TransactionAmountDue = "TransactionAmountDue",
    TransactionAmountNet = "TransactionAmountNet",
    TransactionAmountVat = "TransactionAmountVat",
    TransactionCurrencyCode = "TransactionCurrencyCode",
    VatControlStatementStatusCode = "VatControlStatementStatusCode",
    VatStatementStatusCode = "VatStatementStatusCode",
    VatViesStatementStatusCode = "VatViesStatementStatusCode",
    ExchangeRatePerUnitDateVatDeduction = "ExchangeRatePerUnitDateVatDeduction",
    AccountAssignmentSelection = "AccountAssignmentSelection",
    BankAccount = "BankAccount",
    BusinessPartner = "BusinessPartner",
    CbaCategory = "CbaCategory",
    ClearedStatus = "ClearedStatus",
    Company = "Company",
    Currency = "Currency",
    DocumentDraft = "DocumentDraft",
    DocumentStatus = "DocumentStatus",
    DocumentType = "DocumentType",
    DocumentVatStatementStatus = "DocumentVatStatementStatus",
    FiscalPeriod = "FiscalPeriod",
    FiscalYear = "FiscalYear",
    NumberRange = "NumberRange",
    PostedStatus = "PostedStatus",
    TransactionCurrency = "TransactionCurrency",
    VatClassificationSelection = "VatClassificationSelection",
    VatControlStatementStatus = "VatControlStatementStatus",
    VatStatementStatus = "VatStatementStatus",
    VatViesStatementStatus = "VatViesStatementStatus",
    Attachments = "Attachments",
    DocumentItems = "DocumentItems",
    DocumentLinks = "DocumentLinks",
    Labels = "Labels",
    Locks = "Locks",
    OppositeDocumentLinks = "OppositeDocumentLinks",
    Payments = "Payments",
    ClearingDocumentLinks = "ClearingDocumentLinks",
    CorrectiveDocumentDrafts = "CorrectiveDocumentDrafts",
    DeferredPlans = "DeferredPlans",
    BankPaymentOrderStateCode = "BankPaymentOrderStateCode",
    DateBankApiPaymentOrder = "DateBankApiPaymentOrder",
    DateDue = "DateDue",
    DateIssued = "DateIssued",
    DateReceived = "DateReceived",
    DateTaxableSupply = "DateTaxableSupply",
    PaymentMethodCode = "PaymentMethodCode",
    SymbolMatching = "SymbolMatching",
    TextBeforeItems = "TextBeforeItems",
    TransactionAmountToPay = "TransactionAmountToPay",
    TransactionAmountToReceive = "TransactionAmountToReceive",
    BankPaymentOrderState = "BankPaymentOrderState",
    CashBox = "CashBox",
    PaymentMethod = "PaymentMethod",
    Items = "Items",
}

export interface IOtherReceivableEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    Amount?: number;
    AmountNet?: number;
    AmountVat?: number;
    ClearedStatusCode?: string;
    CurrencyCode?: string;
    DateAccountingTransaction?: Date;
    DateCbaDocument?: Date;
    DateClearing?: Date;
    DateElectronicSubmissionVatControlStatement?: Date;
    DateElectronicSubmissionVatStatement?: Date;
    DateElectronicSubmissionVatViesStatement?: Date;
    DateVatDeduction?: Date;
    DocumentStatusCode?: string;
    DocumentTypeCode?: string;
    DocumentVatStatementStatusCode?: string;
    ExchangeRatePerUnit?: number;
    Note?: string;
    NumberOurs?: string;
    NumberTheirs?: string;
    PostedStatusCode?: string;
    RemittanceInformation?: string;
    SymbolConstant?: string;
    SymbolSpecific?: string;
    SymbolVariable?: string;
    TransactionAmount?: number;
    TransactionAmountDue?: number;
    TransactionAmountNet?: number;
    TransactionAmountVat?: number;
    TransactionCurrencyCode?: string;
    VatControlStatementStatusCode?: string;
    VatStatementStatusCode?: string;
    VatViesStatementStatusCode?: string;
    ExchangeRatePerUnitDateVatDeduction?: number;
    AccountAssignmentSelection?: IDocumentAccountAssignmentSelectionEntity;
    BankAccount?: IDocumentBankAccountEntity;
    BusinessPartner?: IDocumentBusinessPartnerEntity;
    CbaCategory?: IDocumentCbaCategoryEntity;
    ClearedStatus?: IClearedStatusEntity;
    Company?: ICompanyEntity;
    Currency?: ICurrencyEntity;
    DocumentDraft?: IDocumentDraftEntity;
    DocumentStatus?: IDocumentStatusEntity;
    DocumentType?: IDocumentTypeEntity;
    DocumentVatStatementStatus?: IVatStatementStatusEntity;
    FiscalPeriod?: IFiscalPeriodEntity;
    FiscalYear?: IFiscalYearEntity;
    NumberRange?: INumberRangeEntity;
    PostedStatus?: IPostedStatusEntity;
    TransactionCurrency?: ICurrencyEntity;
    VatClassificationSelection?: IDocumentVatClassificationSelectionEntity;
    VatControlStatementStatus?: IVatStatementStatusEntity;
    VatStatementStatus?: IVatStatementStatusEntity;
    VatViesStatementStatus?: IVatStatementStatusEntity;
    Attachments?: IDocumentAttachmentEntity[];
    DocumentItems?: IDocumentItemViewEntity[];
    DocumentLinks?: IDocumentLinkEntity[];
    Labels?: IDocumentLabelEntity[];
    Locks?: IDocumentLockEntity[];
    OppositeDocumentLinks?: IOppositeDocumentDocumentLinkEntity[];
    Payments?: IOppositePaymentDocumentDocumentLinkEntity[];
    ClearingDocumentLinks?: IClearingDocumentLinkEntity[];
    CorrectiveDocumentDrafts?: IDocumentDraftEntity[];
    DeferredPlans?: IDeferredPlanEntity[];
    DateDue?: Date;
    DateIssued?: Date;
    DateTaxableSupply?: Date;
    PaymentMethodCode?: string;
    SymbolMatching?: string;
    TextBeforeItems?: string;
    TransactionAmountToPay?: number;
    TransactionAmountToReceive?: number;
    CashBox?: ICashBoxEntity;
    PaymentMethod?: IPaymentMethodEntity;
    Items?: IRegularDocumentItemEntity[];
}

export enum OtherReceivableEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    Amount = "Amount",
    AmountNet = "AmountNet",
    AmountVat = "AmountVat",
    ClearedStatusCode = "ClearedStatusCode",
    CurrencyCode = "CurrencyCode",
    DateAccountingTransaction = "DateAccountingTransaction",
    DateCbaDocument = "DateCbaDocument",
    DateClearing = "DateClearing",
    DateElectronicSubmissionVatControlStatement = "DateElectronicSubmissionVatControlStatement",
    DateElectronicSubmissionVatStatement = "DateElectronicSubmissionVatStatement",
    DateElectronicSubmissionVatViesStatement = "DateElectronicSubmissionVatViesStatement",
    DateVatDeduction = "DateVatDeduction",
    DocumentStatusCode = "DocumentStatusCode",
    DocumentTypeCode = "DocumentTypeCode",
    DocumentVatStatementStatusCode = "DocumentVatStatementStatusCode",
    ExchangeRatePerUnit = "ExchangeRatePerUnit",
    Note = "Note",
    NumberOurs = "NumberOurs",
    NumberTheirs = "NumberTheirs",
    PostedStatusCode = "PostedStatusCode",
    RemittanceInformation = "RemittanceInformation",
    SymbolConstant = "SymbolConstant",
    SymbolSpecific = "SymbolSpecific",
    SymbolVariable = "SymbolVariable",
    TransactionAmount = "TransactionAmount",
    TransactionAmountDue = "TransactionAmountDue",
    TransactionAmountNet = "TransactionAmountNet",
    TransactionAmountVat = "TransactionAmountVat",
    TransactionCurrencyCode = "TransactionCurrencyCode",
    VatControlStatementStatusCode = "VatControlStatementStatusCode",
    VatStatementStatusCode = "VatStatementStatusCode",
    VatViesStatementStatusCode = "VatViesStatementStatusCode",
    ExchangeRatePerUnitDateVatDeduction = "ExchangeRatePerUnitDateVatDeduction",
    AccountAssignmentSelection = "AccountAssignmentSelection",
    BankAccount = "BankAccount",
    BusinessPartner = "BusinessPartner",
    CbaCategory = "CbaCategory",
    ClearedStatus = "ClearedStatus",
    Company = "Company",
    Currency = "Currency",
    DocumentDraft = "DocumentDraft",
    DocumentStatus = "DocumentStatus",
    DocumentType = "DocumentType",
    DocumentVatStatementStatus = "DocumentVatStatementStatus",
    FiscalPeriod = "FiscalPeriod",
    FiscalYear = "FiscalYear",
    NumberRange = "NumberRange",
    PostedStatus = "PostedStatus",
    TransactionCurrency = "TransactionCurrency",
    VatClassificationSelection = "VatClassificationSelection",
    VatControlStatementStatus = "VatControlStatementStatus",
    VatStatementStatus = "VatStatementStatus",
    VatViesStatementStatus = "VatViesStatementStatus",
    Attachments = "Attachments",
    DocumentItems = "DocumentItems",
    DocumentLinks = "DocumentLinks",
    Labels = "Labels",
    Locks = "Locks",
    OppositeDocumentLinks = "OppositeDocumentLinks",
    Payments = "Payments",
    ClearingDocumentLinks = "ClearingDocumentLinks",
    CorrectiveDocumentDrafts = "CorrectiveDocumentDrafts",
    DeferredPlans = "DeferredPlans",
    DateDue = "DateDue",
    DateIssued = "DateIssued",
    DateTaxableSupply = "DateTaxableSupply",
    PaymentMethodCode = "PaymentMethodCode",
    SymbolMatching = "SymbolMatching",
    TextBeforeItems = "TextBeforeItems",
    TransactionAmountToPay = "TransactionAmountToPay",
    TransactionAmountToReceive = "TransactionAmountToReceive",
    CashBox = "CashBox",
    PaymentMethod = "PaymentMethod",
    Items = "Items",
}

export interface IPayableReceivableTypeEntity extends IEntityBase {
    Code?: string;
    Name?: string;
}

export enum PayableReceivableTypeEntity {
    Code = "Code",
    Name = "Name",
}

export interface IPayingDocumentEntity extends IEntityBase {
    Id?: number;
    Amount?: number;
    ClearedStatusCode?: string;
    CurrencyCode?: string;
    DateAccountingTransaction?: Date;
    DateCreated?: Date;
    DateDue?: Date;
    DateIssued?: Date;
    DateReceived?: Date;
    DocumentStatusCode?: string;
    DocumentTypeCode?: string;
    DocumentVatStatementStatusCode?: string;
    ExchangeRatePerUnit?: number;
    IsTaxDocument?: boolean;
    Note?: string;
    NumberOurs?: string;
    NumberTheirs?: string;
    PostedStatusCode?: string;
    SymbolVariable?: string;
    TransactionAmount?: number;
    TransactionAmountDue?: number;
    TransactionAmountToPay?: number;
    TransactionAmountToReceive?: number;
    TransactionCurrencyCode?: string;
    AccountAssignment?: IDocumentAccountAssignmentEntity;
    BusinessPartner?: IDocumentBusinessPartnerEntity;
    ClearedStatus?: IClearedStatusEntity;
    Company?: ICompanyEntity;
    CorrectiveDocument?: IDocumentEntity;
    Currency?: ICurrencyEntity;
    DocumentStatus?: IDocumentStatusEntity;
    DocumentType?: IDocumentTypeEntity;
    DocumentVatStatementStatus?: IVatStatementStatusEntity;
    PostedStatus?: IPostedStatusEntity;
    TransactionCurrency?: ICurrencyEntity;
    DocumentItems?: IPayingDocumentItemViewEntity[];
}

export enum PayingDocumentEntity {
    Id = "Id",
    Amount = "Amount",
    ClearedStatusCode = "ClearedStatusCode",
    CurrencyCode = "CurrencyCode",
    DateAccountingTransaction = "DateAccountingTransaction",
    DateCreated = "DateCreated",
    DateDue = "DateDue",
    DateIssued = "DateIssued",
    DateReceived = "DateReceived",
    DocumentStatusCode = "DocumentStatusCode",
    DocumentTypeCode = "DocumentTypeCode",
    DocumentVatStatementStatusCode = "DocumentVatStatementStatusCode",
    ExchangeRatePerUnit = "ExchangeRatePerUnit",
    IsTaxDocument = "IsTaxDocument",
    Note = "Note",
    NumberOurs = "NumberOurs",
    NumberTheirs = "NumberTheirs",
    PostedStatusCode = "PostedStatusCode",
    SymbolVariable = "SymbolVariable",
    TransactionAmount = "TransactionAmount",
    TransactionAmountDue = "TransactionAmountDue",
    TransactionAmountToPay = "TransactionAmountToPay",
    TransactionAmountToReceive = "TransactionAmountToReceive",
    TransactionCurrencyCode = "TransactionCurrencyCode",
    AccountAssignment = "AccountAssignment",
    BusinessPartner = "BusinessPartner",
    ClearedStatus = "ClearedStatus",
    Company = "Company",
    CorrectiveDocument = "CorrectiveDocument",
    Currency = "Currency",
    DocumentStatus = "DocumentStatus",
    DocumentType = "DocumentType",
    DocumentVatStatementStatus = "DocumentVatStatementStatus",
    PostedStatus = "PostedStatus",
    TransactionCurrency = "TransactionCurrency",
    DocumentItems = "DocumentItems",
}

export interface IPayingDocumentItemViewEntity extends IEntityBase {
    Id?: number;
    Amount?: number;
    AmountNet?: number;
    AmountVat?: number;
    Description?: string;
    Note?: string;
    Order?: number;
    TransactionAmount?: number;
    TransactionAmountNet?: number;
    TransactionAmountVat?: number;
    TransactionCurrencyCode?: string;
    UnitOfMeasureCode?: string;
    VatCode?: string;
    Quantity?: number;
    UnitPrice?: number;
    UnitPriceNet?: number;
    UnitPriceVat?: number;
    TransactionUnitPrice?: number;
    TransactionUnitPriceNet?: number;
    TransactionUnitPriceVat?: number;
    DiscountPercent?: number;
    Discount?: number;
    AccountAssignmentSelection?: IDocumentItemAccountAssignmentSelectionEntity;
    CbaCategory?: IDocumentItemCbaCategoryEntity;
    DocumentItemDraft?: IDocumentItemDraftEntity;
    LabelSelection?: ILabelSelectionEntity;
    LinkedDocument?: IDocumentEntity;
    TransactionCurrency?: ICurrencyEntity;
    UnitOfMeasure?: IUnitOfMeasureEntity;
    Vat?: IVatEntity;
    VatClassificationSelection?: IDocumentItemVatClassificationSelectionEntity;
}

export enum PayingDocumentItemViewEntity {
    Id = "Id",
    Amount = "Amount",
    AmountNet = "AmountNet",
    AmountVat = "AmountVat",
    Description = "Description",
    Note = "Note",
    Order = "Order",
    TransactionAmount = "TransactionAmount",
    TransactionAmountNet = "TransactionAmountNet",
    TransactionAmountVat = "TransactionAmountVat",
    TransactionCurrencyCode = "TransactionCurrencyCode",
    UnitOfMeasureCode = "UnitOfMeasureCode",
    VatCode = "VatCode",
    Quantity = "Quantity",
    UnitPrice = "UnitPrice",
    UnitPriceNet = "UnitPriceNet",
    UnitPriceVat = "UnitPriceVat",
    TransactionUnitPrice = "TransactionUnitPrice",
    TransactionUnitPriceNet = "TransactionUnitPriceNet",
    TransactionUnitPriceVat = "TransactionUnitPriceVat",
    DiscountPercent = "DiscountPercent",
    Discount = "Discount",
    AccountAssignmentSelection = "AccountAssignmentSelection",
    CbaCategory = "CbaCategory",
    DocumentItemDraft = "DocumentItemDraft",
    LabelSelection = "LabelSelection",
    LinkedDocument = "LinkedDocument",
    TransactionCurrency = "TransactionCurrency",
    UnitOfMeasure = "UnitOfMeasure",
    Vat = "Vat",
    VatClassificationSelection = "VatClassificationSelection",
}

export interface IPaymentDocumentEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    Amount?: number;
    ClearedStatusCode?: string;
    CurrencyCode?: string;
    DocumentStatusCode?: string;
    DocumentTypeCode?: string;
    ExchangeRatePerUnit?: number;
    IsUnrelatedToBusiness?: boolean;
    Note?: string;
    NumberOurs?: string;
    PaymentStatusCode?: string;
    PostedStatusCode?: string;
    TransactionAmount?: number;
    TransactionAmountDue?: number;
    TransactionCurrencyCode?: string;
    ClearedStatus?: IClearedStatusEntity;
    Company?: ICompanyEntity;
    Currency?: ICurrencyEntity;
    DocumentStatus?: IDocumentStatusEntity;
    DocumentType?: IDocumentTypeEntity;
    NumberRange?: INumberRangeEntity;
    PaymentDocumentDraft?: IPaymentDocumentDraftEntity;
    PaymentStatus?: IPaymentStatusEntity;
    PostedStatus?: IPostedStatusEntity;
    TransactionCurrency?: ICurrencyEntity;
    Attachments?: IPaymentDocumentAttachmentEntity[];
    DocumentLinks?: IDocumentLinkEntity[];
    Items?: IPaymentDocumentItemEntity[];
    Locks?: IPaymentDocumentLockEntity[];
}

export enum PaymentDocumentEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    Amount = "Amount",
    ClearedStatusCode = "ClearedStatusCode",
    CurrencyCode = "CurrencyCode",
    DocumentStatusCode = "DocumentStatusCode",
    DocumentTypeCode = "DocumentTypeCode",
    ExchangeRatePerUnit = "ExchangeRatePerUnit",
    IsUnrelatedToBusiness = "IsUnrelatedToBusiness",
    Note = "Note",
    NumberOurs = "NumberOurs",
    PaymentStatusCode = "PaymentStatusCode",
    PostedStatusCode = "PostedStatusCode",
    TransactionAmount = "TransactionAmount",
    TransactionAmountDue = "TransactionAmountDue",
    TransactionCurrencyCode = "TransactionCurrencyCode",
    ClearedStatus = "ClearedStatus",
    Company = "Company",
    Currency = "Currency",
    DocumentStatus = "DocumentStatus",
    DocumentType = "DocumentType",
    NumberRange = "NumberRange",
    PaymentDocumentDraft = "PaymentDocumentDraft",
    PaymentStatus = "PaymentStatus",
    PostedStatus = "PostedStatus",
    TransactionCurrency = "TransactionCurrency",
    Attachments = "Attachments",
    DocumentLinks = "DocumentLinks",
    Items = "Items",
    Locks = "Locks",
}

export interface IPaymentDocumentAttachmentEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    Description?: string;
    File?: IFileMetadataEntity;
}

export enum PaymentDocumentAttachmentEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    Description = "Description",
    File = "File",
}

export interface IPaymentDocumentAttachmentDraftEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    Description?: string;
    File?: IFileMetadataEntity;
}

export enum PaymentDocumentAttachmentDraftEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    Description = "Description",
    File = "File",
}

export interface IPaymentDocumentDraftEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    BankAccountDescription?: string;
    BankInternalId?: string;
    BankTransactionTypeCode?: string;
    CurrencyCode?: string;
    DateBankTransaction?: Date;
    DateIssued?: Date;
    DocumentStatusCode?: string;
    DocumentTypeCode?: string;
    Note?: string;
    NumberOurs?: string;
    PaidBy?: string;
    PaidTo?: string;
    PaymentInformation?: string;
    PaymentType?: string;
    Purpose?: string;
    ReceivedBy?: string;
    ReceivedFrom?: string;
    RemittanceInformation?: string;
    SymbolConstant?: string;
    SymbolSpecific?: string;
    SymbolVariable?: string;
    TransactionCurrencyCode?: string;
    Amount?: number;
    TransactionAmount?: number;
    ExchangeRatePerUnit?: number;
    BankAccount?: IBankTransactionBankAccountDraftEntity;
    BankStatement?: IBankStatementEntity;
    BankStatementDraft?: IBankStatementDraftEntity;
    BankTransactionType?: IBankTransactionTypeEntity;
    BusinessPartner?: IBankTransactionBusinessPartnerDraftEntity;
    CashBox?: ICashBoxEntity;
    Company?: ICompanyEntity;
    Currency?: ICurrencyEntity;
    DocumentStatus?: IDocumentStatusEntity;
    DocumentType?: IDocumentTypeEntity;
    NumberRange?: INumberRangeEntity;
    PaymentDocument?: IPaymentDocumentEntity;
    TransactionCurrency?: ICurrencyEntity;
    Attachments?: IPaymentDocumentAttachmentDraftEntity[];
    Items?: IPaymentDocumentItemDraftEntity[];
    Locks?: IPaymentDocumentLockDraftEntity[];
}

export enum PaymentDocumentDraftEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    BankAccountDescription = "BankAccountDescription",
    BankInternalId = "BankInternalId",
    BankTransactionTypeCode = "BankTransactionTypeCode",
    CurrencyCode = "CurrencyCode",
    DateBankTransaction = "DateBankTransaction",
    DateIssued = "DateIssued",
    DocumentStatusCode = "DocumentStatusCode",
    DocumentTypeCode = "DocumentTypeCode",
    Note = "Note",
    NumberOurs = "NumberOurs",
    PaidBy = "PaidBy",
    PaidTo = "PaidTo",
    PaymentInformation = "PaymentInformation",
    PaymentType = "PaymentType",
    Purpose = "Purpose",
    ReceivedBy = "ReceivedBy",
    ReceivedFrom = "ReceivedFrom",
    RemittanceInformation = "RemittanceInformation",
    SymbolConstant = "SymbolConstant",
    SymbolSpecific = "SymbolSpecific",
    SymbolVariable = "SymbolVariable",
    TransactionCurrencyCode = "TransactionCurrencyCode",
    Amount = "Amount",
    TransactionAmount = "TransactionAmount",
    ExchangeRatePerUnit = "ExchangeRatePerUnit",
    BankAccount = "BankAccount",
    BankStatement = "BankStatement",
    BankStatementDraft = "BankStatementDraft",
    BankTransactionType = "BankTransactionType",
    BusinessPartner = "BusinessPartner",
    CashBox = "CashBox",
    Company = "Company",
    Currency = "Currency",
    DocumentStatus = "DocumentStatus",
    DocumentType = "DocumentType",
    NumberRange = "NumberRange",
    PaymentDocument = "PaymentDocument",
    TransactionCurrency = "TransactionCurrency",
    Attachments = "Attachments",
    Items = "Items",
    Locks = "Locks",
}

export interface IPaymentDocumentItemEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    Amount?: number;
    DateAccountingTransaction?: Date;
    Order?: number;
    PaymentDocumentItemTypeCode?: string;
    TransactionAmount?: number;
    ExchangeRate?: number;
    ClearedAmount?: number;
    AccountAssignmentSelection?: IPaymentDocumentItemAccountAssignmentSelectionEntity;
    CbaCategory?: IPaymentDocumentItemCbaCategoryEntity;
    CreatedByPostingRule?: IPostingRuleEntity;
    DocumentLink?: IDocumentLinkEntity;
    FiscalPeriod?: IFiscalPeriodEntity;
    FiscalYear?: IFiscalYearEntity;
    LinkedDocument?: IDocumentEntity;
    PaymentDocumentItemDraft?: IPaymentDocumentItemDraftEntity;
    PaymentDocumentItemType?: IPaymentDocumentItemTypeEntity;
    SplitAccountAssignments?: ISplitAccountAssignmentEntity[];
}

export enum PaymentDocumentItemEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    Amount = "Amount",
    DateAccountingTransaction = "DateAccountingTransaction",
    Order = "Order",
    PaymentDocumentItemTypeCode = "PaymentDocumentItemTypeCode",
    TransactionAmount = "TransactionAmount",
    ExchangeRate = "ExchangeRate",
    ClearedAmount = "ClearedAmount",
    AccountAssignmentSelection = "AccountAssignmentSelection",
    CbaCategory = "CbaCategory",
    CreatedByPostingRule = "CreatedByPostingRule",
    DocumentLink = "DocumentLink",
    FiscalPeriod = "FiscalPeriod",
    FiscalYear = "FiscalYear",
    LinkedDocument = "LinkedDocument",
    PaymentDocumentItemDraft = "PaymentDocumentItemDraft",
    PaymentDocumentItemType = "PaymentDocumentItemType",
    SplitAccountAssignments = "SplitAccountAssignments",
}

export interface IPaymentDocumentItemAccountAssignmentEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    Name?: string;
    Note?: string;
    ShortName?: string;
    ChartOfAccounts?: IChartOfAccountsEntity;
    CreditAccount?: IAccountEntity;
    DebitAccount?: IAccountEntity;
}

export enum PaymentDocumentItemAccountAssignmentEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    Name = "Name",
    Note = "Note",
    ShortName = "ShortName",
    ChartOfAccounts = "ChartOfAccounts",
    CreditAccount = "CreditAccount",
    DebitAccount = "DebitAccount",
}

export interface IPaymentDocumentItemAccountAssignmentDraftEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    Name?: string;
    Note?: string;
    ShortName?: string;
    ChartOfAccounts?: IChartOfAccountsEntity;
    CreditAccount?: IAccountEntity;
    DebitAccount?: IAccountEntity;
}

export enum PaymentDocumentItemAccountAssignmentDraftEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    Name = "Name",
    Note = "Note",
    ShortName = "ShortName",
    ChartOfAccounts = "ChartOfAccounts",
    CreditAccount = "CreditAccount",
    DebitAccount = "DebitAccount",
}

export interface IPaymentDocumentItemAccountAssignmentSelectionEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    SelectionCode?: string;
    Selection?: ISelectionEntity;
    AccountAssignment?: IPaymentDocumentItemAccountAssignmentEntity;
}

export enum PaymentDocumentItemAccountAssignmentSelectionEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    SelectionCode = "SelectionCode",
    Selection = "Selection",
    AccountAssignment = "AccountAssignment",
}

export interface IPaymentDocumentItemAccountAssignmentSelectionDraftEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    SelectionCode?: string;
    Selection?: ISelectionEntity;
    AccountAssignment?: IPaymentDocumentItemAccountAssignmentDraftEntity;
}

export enum PaymentDocumentItemAccountAssignmentSelectionDraftEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    SelectionCode = "SelectionCode",
    Selection = "Selection",
    AccountAssignment = "AccountAssignment",
}

export interface IPaymentDocumentItemCbaCategoryEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    IsAssetAcquisition?: boolean;
    TaxImpactCode?: string;
    TaxPercentage?: number;
    Category?: ICbaCategoryEntity;
    TaxImpact?: ICbaCategoryTaxImpactEntity;
}

export enum PaymentDocumentItemCbaCategoryEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    IsAssetAcquisition = "IsAssetAcquisition",
    TaxImpactCode = "TaxImpactCode",
    TaxPercentage = "TaxPercentage",
    Category = "Category",
    TaxImpact = "TaxImpact",
}

export interface IPaymentDocumentItemCbaCategoryDraftEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    IsAssetAcquisition?: boolean;
    TaxImpactCode?: string;
    TaxPercentage?: number;
    Category?: ICbaCategoryEntity;
    TaxImpact?: ICbaCategoryTaxImpactEntity;
}

export enum PaymentDocumentItemCbaCategoryDraftEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    IsAssetAcquisition = "IsAssetAcquisition",
    TaxImpactCode = "TaxImpactCode",
    TaxPercentage = "TaxPercentage",
    Category = "Category",
    TaxImpact = "TaxImpact",
}

export interface IPaymentDocumentItemDraftEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    DateAccountingTransaction?: Date;
    Order?: number;
    PaymentDocumentItemTypeCode?: string;
    ExchangeRate?: number;
    ClearedAmount?: number;
    Amount?: number;
    TransactionAmount?: number;
    AccountAssignmentSelection?: IPaymentDocumentItemAccountAssignmentSelectionDraftEntity;
    CbaCategory?: IPaymentDocumentItemCbaCategoryDraftEntity;
    CreatedByPostingRule?: IPostingRuleEntity;
    LinkedDocument?: IDocumentEntity;
    PaymentDocumentItem?: IPaymentDocumentItemEntity;
    PaymentDocumentItemType?: IPaymentDocumentItemTypeEntity;
    SplitAccountAssignments?: ISplitAccountAssignmentDraftEntity[];
}

export enum PaymentDocumentItemDraftEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    DateAccountingTransaction = "DateAccountingTransaction",
    Order = "Order",
    PaymentDocumentItemTypeCode = "PaymentDocumentItemTypeCode",
    ExchangeRate = "ExchangeRate",
    ClearedAmount = "ClearedAmount",
    Amount = "Amount",
    TransactionAmount = "TransactionAmount",
    AccountAssignmentSelection = "AccountAssignmentSelection",
    CbaCategory = "CbaCategory",
    CreatedByPostingRule = "CreatedByPostingRule",
    LinkedDocument = "LinkedDocument",
    PaymentDocumentItem = "PaymentDocumentItem",
    PaymentDocumentItemType = "PaymentDocumentItemType",
    SplitAccountAssignments = "SplitAccountAssignments",
}

export interface IPaymentDocumentItemTypeEntity extends IEntityBase {
    Code?: string;
    Name?: string;
}

export enum PaymentDocumentItemTypeEntity {
    Code = "Code",
    Name = "Name",
}

export interface IPaymentDocumentLockEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    TypeCode?: string;
    Type?: ILockTypeEntity;
}

export enum PaymentDocumentLockEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    TypeCode = "TypeCode",
    Type = "Type",
}

export interface IPaymentDocumentLockDraftEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    TypeCode?: string;
    Type?: ILockTypeEntity;
}

export enum PaymentDocumentLockDraftEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    TypeCode = "TypeCode",
    Type = "Type",
}

export interface IBankAccountBalanceEntity extends IEntityBase {
    Id?: number;
    TransactionBalance?: number;
    DateBalanceChanged?: Date;
}

export enum BankAccountBalanceEntity {
    Id = "Id",
    TransactionBalance = "TransactionBalance",
    DateBalanceChanged = "DateBalanceChanged",
}

export interface ICashBoxBalanceEntity extends IEntityBase {
    Id?: number;
    TransactionBalance?: number;
    DateBalanceChanged?: Date;
}

export enum CashBoxBalanceEntity {
    Id = "Id",
    TransactionBalance = "TransactionBalance",
    DateBalanceChanged = "DateBalanceChanged",
}

export interface IPaymentMethodEntity extends IEntityBase {
    Code?: string;
    Name?: string;
}

export enum PaymentMethodEntity {
    Code = "Code",
    Name = "Name",
}

export interface IPaymentOrderFormatEntity extends IEntityBase {
    Code?: string;
    Name?: string;
}

export enum PaymentOrderFormatEntity {
    Code = "Code",
    Name = "Name",
}

export interface IPaymentOrderOutputEntity extends IEntityBase {
    Code?: string;
    Name?: string;
}

export enum PaymentOrderOutputEntity {
    Code = "Code",
    Name = "Name",
}

export interface IPaymentStatusEntity extends IEntityBase {
    Code?: string;
    Name?: string;
}

export enum PaymentStatusEntity {
    Code = "Code",
    Name = "Name",
}

export interface IPeriodDayEntity extends IEntityBase {
    Code?: string;
    Name?: string;
}

export enum PeriodDayEntity {
    Code = "Code",
    Name = "Name",
}

export interface IPohodaColumnPreviewValueEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    Value?: string;
}

export enum PohodaColumnPreviewValueEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    Value = "Value",
}

export interface IPohodaDocumentEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    Number?: string;
    DocumentDraft?: IDocumentDraftEntity;
}

export enum PohodaDocumentEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    Number = "Number",
    DocumentDraft = "DocumentDraft",
}

export interface IPohodaEnumMappingEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    EvalaValue?: string;
    IsPresentInFile?: boolean;
    PohodaValue?: string;
}

export enum PohodaEnumMappingEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    EvalaValue = "EvalaValue",
    IsPresentInFile = "IsPresentInFile",
    PohodaValue = "PohodaValue",
}

export interface IPohodaImportEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    AzureFileGuid?: string;
    DocumentTypeCode?: string;
    FileName?: string;
    PohodaCompanyName?: string;
    DocumentType?: IDocumentTypeEntity;
    FiscalYear?: IFiscalYearEntity;
    PohodaDocuments?: IPohodaDocumentEntity[];
    PohodaProgresses?: IPohodaImportProgressEntity[];
    PohodaMappings?: IPohodaMappingEntity[];
}

export enum PohodaImportEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    AzureFileGuid = "AzureFileGuid",
    DocumentTypeCode = "DocumentTypeCode",
    FileName = "FileName",
    PohodaCompanyName = "PohodaCompanyName",
    DocumentType = "DocumentType",
    FiscalYear = "FiscalYear",
    PohodaDocuments = "PohodaDocuments",
    PohodaProgresses = "PohodaProgresses",
    PohodaMappings = "PohodaMappings",
}

export interface IPohodaImportDocumentEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    DocumentTypeCode?: string;
    ErrorMessage?: string;
    ImportTypeCode?: string;
    BackgroundJob?: IBackgroundJobEntity;
    DocumentType?: IDocumentTypeEntity;
    FileMetadata?: IFileMetadataEntity;
    ImportType?: IDocumentImportTypeEntity;
}

export enum PohodaImportDocumentEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    DocumentTypeCode = "DocumentTypeCode",
    ErrorMessage = "ErrorMessage",
    ImportTypeCode = "ImportTypeCode",
    BackgroundJob = "BackgroundJob",
    DocumentType = "DocumentType",
    FileMetadata = "FileMetadata",
    ImportType = "ImportType",
}

export interface IPohodaImportProgressEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    CountAll?: number;
    CountForImport?: number;
    DocumentTypeCode?: string;
    IsFinished?: boolean;
    DocumentType?: IDocumentTypeEntity;
}

export enum PohodaImportProgressEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    CountAll = "CountAll",
    CountForImport = "CountForImport",
    DocumentTypeCode = "DocumentTypeCode",
    IsFinished = "IsFinished",
    DocumentType = "DocumentType",
}

export interface IPohodaMappingEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    EvalaPropertyName?: string;
    EvalaPropertyTranslated?: string;
    EvalaTypeName?: string;
    PohodaColumnName?: string;
    PohodaImport?: IPohodaImportEntity;
    EnumValuesMappings?: IPohodaEnumMappingEntity[];
    PreviewValues?: IPohodaColumnPreviewValueEntity[];
}

export enum PohodaMappingEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    EvalaPropertyName = "EvalaPropertyName",
    EvalaPropertyTranslated = "EvalaPropertyTranslated",
    EvalaTypeName = "EvalaTypeName",
    PohodaColumnName = "PohodaColumnName",
    PohodaImport = "PohodaImport",
    EnumValuesMappings = "EnumValuesMappings",
    PreviewValues = "PreviewValues",
}

export interface IPostedStatusEntity extends IEntityBase {
    Code?: string;
    Name?: string;
}

export enum PostedStatusEntity {
    Code = "Code",
    Name = "Name",
}

export interface IPostingRuleEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    AmountInCZKTolerance?: number;
    AmountInTransactionCurrencyTolerance?: number;
    BankTransactionTypeCode?: string;
    DocumentNumberMustMatchCode?: string;
    DocumentSymbolMatchingMustMatchCode?: string;
    DocumentTypeCode?: string;
    IsActive?: boolean;
    MustAmountInCZKMatch?: boolean;
    MustAmountInTransactionCurrencyMatch?: boolean;
    MustSymbolSpecificMatch?: boolean;
    MustSymbolVariableMatch?: boolean;
    Name?: string;
    Order?: number;
    BankTransactionType?: IBankTransactionTypeEntity;
    Company?: ICompanyEntity;
    DocumentNumberMustMatch?: IBankTransactionDocumentNumberLocationEntity;
    DocumentSymbolMatchingMustMatch?: IBankTransactionDocumentNumberLocationEntity;
    DocumentType?: IPostingRuleDocumentTypeEntity;
    CompanyBankAccounts?: ICompanyBankAccountEntity[];
}

export enum PostingRuleEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    AmountInCZKTolerance = "AmountInCZKTolerance",
    AmountInTransactionCurrencyTolerance = "AmountInTransactionCurrencyTolerance",
    BankTransactionTypeCode = "BankTransactionTypeCode",
    DocumentNumberMustMatchCode = "DocumentNumberMustMatchCode",
    DocumentSymbolMatchingMustMatchCode = "DocumentSymbolMatchingMustMatchCode",
    DocumentTypeCode = "DocumentTypeCode",
    IsActive = "IsActive",
    MustAmountInCZKMatch = "MustAmountInCZKMatch",
    MustAmountInTransactionCurrencyMatch = "MustAmountInTransactionCurrencyMatch",
    MustSymbolSpecificMatch = "MustSymbolSpecificMatch",
    MustSymbolVariableMatch = "MustSymbolVariableMatch",
    Name = "Name",
    Order = "Order",
    BankTransactionType = "BankTransactionType",
    Company = "Company",
    DocumentNumberMustMatch = "DocumentNumberMustMatch",
    DocumentSymbolMatchingMustMatch = "DocumentSymbolMatchingMustMatch",
    DocumentType = "DocumentType",
    CompanyBankAccounts = "CompanyBankAccounts",
}

export interface IPostingRuleDocumentTypeEntity extends IEntityBase {
    Code?: string;
    Name?: string;
}

export enum PostingRuleDocumentTypeEntity {
    Code = "Code",
    Name = "Name",
}

export interface IPrAbsenceCategoryEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    BaseTypeCode?: string;
    Color?: string;
    DateValidFrom?: Date;
    DateValidTo?: Date;
    LegislativeTypeCode?: string;
    BaseType?: IPrBaseTypeEntity;
    CurrentTemporalPropertyBag?: IPrAbsenceCategoryTemporalCurrentEntity;
    LegislativeType?: IPrAbsenceCategoryLegislativeTypeEntity;
    TemporalPropertyBag?: IPrAbsenceCategoryTemporalEntity[];
}

export enum PrAbsenceCategoryEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    BaseTypeCode = "BaseTypeCode",
    Color = "Color",
    DateValidFrom = "DateValidFrom",
    DateValidTo = "DateValidTo",
    LegislativeTypeCode = "LegislativeTypeCode",
    BaseType = "BaseType",
    CurrentTemporalPropertyBag = "CurrentTemporalPropertyBag",
    LegislativeType = "LegislativeType",
    TemporalPropertyBag = "TemporalPropertyBag",
}

export interface IPrAbsenceCategoryLegislativeTypeEntity extends IEntityBase {
    Code?: string;
    CountryCode?: string;
    DateValidFrom?: Date;
    DateValidTo?: Date;
    DefaultColor?: string;
    Name?: string;
    Country?: ICountryEntity;
}

export enum PrAbsenceCategoryLegislativeTypeEntity {
    Code = "Code",
    CountryCode = "CountryCode",
    DateValidFrom = "DateValidFrom",
    DateValidTo = "DateValidTo",
    DefaultColor = "DefaultColor",
    Name = "Name",
    Country = "Country",
}

export interface IPrAbsenceCategoryTemporalEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    DateValidFrom?: Date;
    DateValidTo?: Date;
    Name?: string;
    CompensationRate?: number;
}

export enum PrAbsenceCategoryTemporalEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    DateValidFrom = "DateValidFrom",
    DateValidTo = "DateValidTo",
    Name = "Name",
    CompensationRate = "CompensationRate",
}

export interface IPrAbsenceCategoryTemporalCurrentEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    DateValidFrom?: Date;
    DateValidTo?: Date;
    Name?: string;
    CompensationRate?: number;
}

export enum PrAbsenceCategoryTemporalCurrentEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    DateValidFrom = "DateValidFrom",
    DateValidTo = "DateValidTo",
    Name = "Name",
    CompensationRate = "CompensationRate",
}

export interface IPrAttendanceEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    Balance?: number;
    Month?: number;
    Overtime?: number;
    OvertimeFromPreviousMonth?: number;
    StatusCode?: string;
    Year?: number;
    Employment?: IPrEmploymentEntity;
    Status?: IPrAttendanceStatusEntity;
    Days?: IPrAttendanceDayEntity[];
}

export enum PrAttendanceEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    Balance = "Balance",
    Month = "Month",
    Overtime = "Overtime",
    OvertimeFromPreviousMonth = "OvertimeFromPreviousMonth",
    StatusCode = "StatusCode",
    Year = "Year",
    Employment = "Employment",
    Status = "Status",
    Days = "Days",
}

export interface IPrAttendanceDayEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    Date?: Date;
    Intervals?: IPrAttendanceDayIntervalEntity[];
}

export enum PrAttendanceDayEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    Date = "Date",
    Intervals = "Intervals",
}

export interface IPrAttendanceDayIntervalEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    IsEndNextDay?: boolean;
    IsStartNextDay?: boolean;
    IsWholeDay?: boolean;
    Order?: number;
    TimeEnd?: Date;
    TimeStart?: Date;
    WorkTypeCode?: string;
    AbsenceCategory?: IPrAbsenceCategoryEntity;
    WorkType?: IPrWorkIntervalTypeEntity;
}

export enum PrAttendanceDayIntervalEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    IsEndNextDay = "IsEndNextDay",
    IsStartNextDay = "IsStartNextDay",
    IsWholeDay = "IsWholeDay",
    Order = "Order",
    TimeEnd = "TimeEnd",
    TimeStart = "TimeStart",
    WorkTypeCode = "WorkTypeCode",
    AbsenceCategory = "AbsenceCategory",
    WorkType = "WorkType",
}

export interface IPrAttendanceStatusEntity extends IEntityBase {
    Code?: string;
    Name?: string;
}

export enum PrAttendanceStatusEntity {
    Code = "Code",
    Name = "Name",
}

export interface IPrAttendanceWithWorkingPatternEntity extends IEntityBase {
    Id?: number;
    DateEnd?: Date;
    DateStart?: Date;
    Month?: number;
    StatusCode?: string;
    Year?: number;
    Employment?: IPrEmploymentEntity;
    EmploymentWorkingPattern?: IPrWorkingPatternEntity;
    Status?: IPrAttendanceStatusEntity;
    Template?: IPrEmploymentTemplateEntity;
    TemplateWorkingPattern?: IPrWorkingPatternEntity;
}

export enum PrAttendanceWithWorkingPatternEntity {
    Id = "Id",
    DateEnd = "DateEnd",
    DateStart = "DateStart",
    Month = "Month",
    StatusCode = "StatusCode",
    Year = "Year",
    Employment = "Employment",
    EmploymentWorkingPattern = "EmploymentWorkingPattern",
    Status = "Status",
    Template = "Template",
    TemplateWorkingPattern = "TemplateWorkingPattern",
}

export interface IPrBaseTypeEntity extends IEntityBase {
    Code?: string;
    Name?: string;
}

export enum PrBaseTypeEntity {
    Code = "Code",
    Name = "Name",
}

export interface IPrChildSupportDeductionTypeEntity extends IEntityBase {
    Code?: string;
    Name?: string;
}

export enum PrChildSupportDeductionTypeEntity {
    Code = "Code",
    Name = "Name",
}

export interface IPrCzechReductionLimitEntity extends IEntityBase {
    Code?: string;
    CountryCode?: string;
    DateValidFrom?: Date;
    DateValidTo?: Date;
    Name?: string;
    Percentage?: number;
    AmountLimit?: number;
    Country?: ICountryEntity;
}

export enum PrCzechReductionLimitEntity {
    Code = "Code",
    CountryCode = "CountryCode",
    DateValidFrom = "DateValidFrom",
    DateValidTo = "DateValidTo",
    Name = "Name",
    Percentage = "Percentage",
    AmountLimit = "AmountLimit",
    Country = "Country",
}

export interface IPrDeductionEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    DateEnd?: Date;
    DateStart?: Date;
    Name?: string;
    NumberOurs?: string;
    RemittanceInformation?: string;
    StatusCode?: string;
    SymbolConstant?: string;
    SymbolSpecific?: string;
    SymbolVariable?: string;
    TypeCode?: string;
    BankAccount?: IPrDeductionBankAccountEntity;
    CurrentTemporalPropertyBag?: IPrDeductionTemporalCurrentEntity;
    NumberRange?: INumberRangeEntity;
    Recipient?: IPrDeductionBusinessPartnerEntity;
    Status?: IPrDeductionStatusEntity;
    Type?: IPrDeductionTypeEntity;
    TemporalPropertyBag?: IPrDeductionTemporalEntity[];
}

export enum PrDeductionEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    DateEnd = "DateEnd",
    DateStart = "DateStart",
    Name = "Name",
    NumberOurs = "NumberOurs",
    RemittanceInformation = "RemittanceInformation",
    StatusCode = "StatusCode",
    SymbolConstant = "SymbolConstant",
    SymbolSpecific = "SymbolSpecific",
    SymbolVariable = "SymbolVariable",
    TypeCode = "TypeCode",
    BankAccount = "BankAccount",
    CurrentTemporalPropertyBag = "CurrentTemporalPropertyBag",
    NumberRange = "NumberRange",
    Recipient = "Recipient",
    Status = "Status",
    Type = "Type",
    TemporalPropertyBag = "TemporalPropertyBag",
}

export interface IPrDeductionBankAccountEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    AbaNumber?: string;
    AccountNumber?: string;
    BankCode?: string;
    CountryCode?: string;
    IBAN?: string;
    PaymentServiceID?: string;
    PaymentServiceName?: string;
    SWIFT?: string;
    Bank?: IBankEntity;
    Country?: ICountryEntity;
}

export enum PrDeductionBankAccountEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    AbaNumber = "AbaNumber",
    AccountNumber = "AccountNumber",
    BankCode = "BankCode",
    CountryCode = "CountryCode",
    IBAN = "IBAN",
    PaymentServiceID = "PaymentServiceID",
    PaymentServiceName = "PaymentServiceName",
    SWIFT = "SWIFT",
    Bank = "Bank",
    Country = "Country",
}

export interface IPrDeductionBusinessPartnerEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    City?: string;
    CountryCode?: string;
    Email?: string;
    FirstName?: string;
    LastName?: string;
    LegalNumber?: string;
    Name?: string;
    PhoneNumber?: string;
    PostalCode?: string;
    Street?: string;
    TaxNumber?: string;
    VatStatusCode?: string;
    BusinessPartner?: IBusinessPartnerEntity;
    Country?: ICountryEntity;
    VatStatus?: IVatStatusEntity;
}

export enum PrDeductionBusinessPartnerEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    City = "City",
    CountryCode = "CountryCode",
    Email = "Email",
    FirstName = "FirstName",
    LastName = "LastName",
    LegalNumber = "LegalNumber",
    Name = "Name",
    PhoneNumber = "PhoneNumber",
    PostalCode = "PostalCode",
    Street = "Street",
    TaxNumber = "TaxNumber",
    VatStatusCode = "VatStatusCode",
    BusinessPartner = "BusinessPartner",
    Country = "Country",
    VatStatus = "VatStatus",
}

export interface IPrDeductionCalculationTypeEntity extends IEntityBase {
    Code?: string;
    Name?: string;
}

export enum PrDeductionCalculationTypeEntity {
    Code = "Code",
    Name = "Name",
}

export interface IPrDeductionEntityTypeEntity extends IEntityBase {
    Code?: string;
    Name?: string;
}

export enum PrDeductionEntityTypeEntity {
    Code = "Code",
    Name = "Name",
}

export interface IPrDeductionStatusEntity extends IEntityBase {
    Code?: string;
    Name?: string;
}

export enum PrDeductionStatusEntity {
    Code = "Code",
    Name = "Name",
}

export interface IPrDeductionTemporalEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    DateValidFrom?: Date;
    DateValidTo?: Date;
    CalculationTypeCode?: string;
    IsIncludingAllEmployees?: boolean;
    IsWorkdaysOnly?: boolean;
    Amount?: number;
    PercentageEmployee?: number;
    PercentageEmployer?: number;
    CalculationType?: IPrDeductionCalculationTypeEntity;
}

export enum PrDeductionTemporalEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    DateValidFrom = "DateValidFrom",
    DateValidTo = "DateValidTo",
    CalculationTypeCode = "CalculationTypeCode",
    IsIncludingAllEmployees = "IsIncludingAllEmployees",
    IsWorkdaysOnly = "IsWorkdaysOnly",
    Amount = "Amount",
    PercentageEmployee = "PercentageEmployee",
    PercentageEmployer = "PercentageEmployer",
    CalculationType = "CalculationType",
}

export interface IPrDeductionTemporalCurrentEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    DateValidFrom?: Date;
    DateValidTo?: Date;
    CalculationTypeCode?: string;
    IsIncludingAllEmployees?: boolean;
    IsWorkdaysOnly?: boolean;
    Amount?: number;
    PercentageEmployee?: number;
    PercentageEmployer?: number;
    CalculationType?: IPrDeductionCalculationTypeEntity;
}

export enum PrDeductionTemporalCurrentEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    DateValidFrom = "DateValidFrom",
    DateValidTo = "DateValidTo",
    CalculationTypeCode = "CalculationTypeCode",
    IsIncludingAllEmployees = "IsIncludingAllEmployees",
    IsWorkdaysOnly = "IsWorkdaysOnly",
    Amount = "Amount",
    PercentageEmployee = "PercentageEmployee",
    PercentageEmployer = "PercentageEmployer",
    CalculationType = "CalculationType",
}

export interface IPrDeductionTypeEntity extends IEntityBase {
    Code?: string;
    Name?: string;
}

export enum PrDeductionTypeEntity {
    Code = "Code",
    Name = "Name",
}

export interface IPrDisabilityTypeEntity extends IEntityBase {
    Code?: string;
    Name?: string;
}

export enum PrDisabilityTypeEntity {
    Code = "Code",
    Name = "Name",
}

export interface IPrDistrainmentDeductionTypeEntity extends IEntityBase {
    Code?: string;
    Name?: string;
}

export enum PrDistrainmentDeductionTypeEntity {
    Code = "Code",
    Name = "Name",
}

export interface IPrEmployeeEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    BornLastName?: string;
    CitizenshipCode?: string;
    DateIDEndOfValidity?: Date;
    DateOfBirth?: Date;
    DatePensionFrom?: Date;
    Email?: string;
    EuHealthCareNumber?: string;
    EuSocialInsuranceNumber?: string;
    ExternalNumber?: string;
    FirstName?: string;
    ForeignPersonalIdentificationNumber?: string;
    IDCountryOfIssueCode?: string;
    IDNumber?: string;
    LastName?: string;
    NumberOurs?: string;
    PasswordForPayslip?: string;
    PerformAnnualAccounting?: boolean;
    PersonalIdentificationNumber?: string;
    PhoneNumber?: string;
    PrEmployeeStateCode?: string;
    PrIdentificationDocumentCode?: string;
    PrSexCode?: string;
    RemittanceInformation?: string;
    SalaryCurrencyCode?: string;
    SymbolConstant?: string;
    SymbolSpecific?: string;
    SymbolVariable?: string;
    TitleAfterName?: string;
    TitleBeforeName?: string;
    PreviousEmploymentDurationDays?: number;
    Cars?: IPrEmployeeCarEntity[];
    BankAccount?: IPrEmployeeBankAccountEntity;
    Citizenship?: ICountryEntity;
    ContactAddress?: IPrEmployeeContactAddressEntity;
    CurrentTemporalPropertyBag?: IPrEmployeeTemporalCurrentEntity;
    ForeignAddress?: IPrEmployeeForeignAddressEntity;
    IDCountryOfIssue?: ICountryEntity;
    LocalAddress?: IPrEmployeeLocalAddressEntity;
    NumberRange?: INumberRangeEntity;
    PrIdentificationDocument?: IPrIdentityDocumentTypeEntity;
    PrSex?: IPrSexEntity;
    SalaryCurrency?: ICurrencyEntity;
    Dependents?: IPrEmployeeDependentPersonEntity[];
    Labels?: IPrEmployeeLabelEntity[];
    TemporalPropertyBag?: IPrEmployeeTemporalEntity[];
}

export enum PrEmployeeEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    BornLastName = "BornLastName",
    CitizenshipCode = "CitizenshipCode",
    DateIDEndOfValidity = "DateIDEndOfValidity",
    DateOfBirth = "DateOfBirth",
    DatePensionFrom = "DatePensionFrom",
    Email = "Email",
    EuHealthCareNumber = "EuHealthCareNumber",
    EuSocialInsuranceNumber = "EuSocialInsuranceNumber",
    ExternalNumber = "ExternalNumber",
    FirstName = "FirstName",
    ForeignPersonalIdentificationNumber = "ForeignPersonalIdentificationNumber",
    IDCountryOfIssueCode = "IDCountryOfIssueCode",
    IDNumber = "IDNumber",
    LastName = "LastName",
    NumberOurs = "NumberOurs",
    PasswordForPayslip = "PasswordForPayslip",
    PerformAnnualAccounting = "PerformAnnualAccounting",
    PersonalIdentificationNumber = "PersonalIdentificationNumber",
    PhoneNumber = "PhoneNumber",
    PrEmployeeStateCode = "PrEmployeeStateCode",
    PrIdentificationDocumentCode = "PrIdentificationDocumentCode",
    PrSexCode = "PrSexCode",
    RemittanceInformation = "RemittanceInformation",
    SalaryCurrencyCode = "SalaryCurrencyCode",
    SymbolConstant = "SymbolConstant",
    SymbolSpecific = "SymbolSpecific",
    SymbolVariable = "SymbolVariable",
    TitleAfterName = "TitleAfterName",
    TitleBeforeName = "TitleBeforeName",
    PreviousEmploymentDurationDays = "PreviousEmploymentDurationDays",
    Cars = "Cars",
    BankAccount = "BankAccount",
    Citizenship = "Citizenship",
    ContactAddress = "ContactAddress",
    CurrentTemporalPropertyBag = "CurrentTemporalPropertyBag",
    ForeignAddress = "ForeignAddress",
    IDCountryOfIssue = "IDCountryOfIssue",
    LocalAddress = "LocalAddress",
    NumberRange = "NumberRange",
    PrIdentificationDocument = "PrIdentificationDocument",
    PrSex = "PrSex",
    SalaryCurrency = "SalaryCurrency",
    Dependents = "Dependents",
    Labels = "Labels",
    TemporalPropertyBag = "TemporalPropertyBag",
}

export interface IPrEmployeeBankAccountEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    AbaNumber?: string;
    AccountNumber?: string;
    BankCode?: string;
    CountryCode?: string;
    IBAN?: string;
    PaymentServiceID?: string;
    PaymentServiceName?: string;
    SWIFT?: string;
    Bank?: IBankEntity;
    Country?: ICountryEntity;
}

export enum PrEmployeeBankAccountEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    AbaNumber = "AbaNumber",
    AccountNumber = "AccountNumber",
    BankCode = "BankCode",
    CountryCode = "CountryCode",
    IBAN = "IBAN",
    PaymentServiceID = "PaymentServiceID",
    PaymentServiceName = "PaymentServiceName",
    SWIFT = "SWIFT",
    Bank = "Bank",
    Country = "Country",
}

export interface IPrEmployeeCarEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    DateValidFrom?: Date;
    DateValidTo?: Date;
    LowEmission?: boolean;
    Name?: string;
    PurchasePrice?: number;
}

export enum PrEmployeeCarEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    DateValidFrom = "DateValidFrom",
    DateValidTo = "DateValidTo",
    LowEmission = "LowEmission",
    Name = "Name",
    PurchasePrice = "PurchasePrice",
}

export interface IPrEmployeeContactAddressEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    City?: string;
    CountryCode?: string;
    PostalCode?: string;
    Street?: string;
    Country?: ICountryEntity;
}

export enum PrEmployeeContactAddressEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    City = "City",
    CountryCode = "CountryCode",
    PostalCode = "PostalCode",
    Street = "Street",
    Country = "Country",
}

export interface IPrEmployeeDependentPersonEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    DateValidFrom?: Date;
    DateValidTo?: Date;
    DateOfBirth?: Date;
    FirstName?: string;
    LastName?: string;
    PersonalIdentificationNumber?: string;
}

export enum PrEmployeeDependentPersonEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    DateValidFrom = "DateValidFrom",
    DateValidTo = "DateValidTo",
    DateOfBirth = "DateOfBirth",
    FirstName = "FirstName",
    LastName = "LastName",
    PersonalIdentificationNumber = "PersonalIdentificationNumber",
}

export interface IPrEmployeeForeignAddressEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    City?: string;
    CountryCode?: string;
    PostalCode?: string;
    Street?: string;
    Country?: ICountryEntity;
}

export enum PrEmployeeForeignAddressEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    City = "City",
    CountryCode = "CountryCode",
    PostalCode = "PostalCode",
    Street = "Street",
    Country = "Country",
}

export interface IPrEmployeeLabelEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    EntityTypeCode?: string;
    EntityType?: IEntityTypeEntity;
    Label?: ILabelEntity;
    LabelHierarchy?: ILabelHierarchyEntity;
}

export enum PrEmployeeLabelEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    EntityTypeCode = "EntityTypeCode",
    EntityType = "EntityType",
    Label = "Label",
    LabelHierarchy = "LabelHierarchy",
}

export interface IPrEmployeeLocalAddressEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    City?: string;
    CountryCode?: string;
    PostalCode?: string;
    Street?: string;
    Country?: ICountryEntity;
}

export enum PrEmployeeLocalAddressEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    City = "City",
    CountryCode = "CountryCode",
    PostalCode = "PostalCode",
    Street = "Street",
    Country = "Country",
}

export interface IPrEmployeeStateEntity extends IEntityBase {
    Code?: string;
    Name?: string;
}

export enum PrEmployeeStateEntity {
    Code = "Code",
    Name = "Name",
}

export interface IPrEmployeeTemporalEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    DateValidFrom?: Date;
    DateValidTo?: Date;
    HealthInsuranceCode?: string;
    IsDepositing?: boolean;
    PrDisabilityTypeCode?: string;
    PrFormOfStudyTypeCode?: string;
    HealthInsurance?: IPrInsuranceCompanyPredefinedEntity;
    PrDisabilityType?: IPrDisabilityTypeEntity;
    PrFormOfStudyType?: IPrFormOfStudyTypeEntity;
}

export enum PrEmployeeTemporalEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    DateValidFrom = "DateValidFrom",
    DateValidTo = "DateValidTo",
    HealthInsuranceCode = "HealthInsuranceCode",
    IsDepositing = "IsDepositing",
    PrDisabilityTypeCode = "PrDisabilityTypeCode",
    PrFormOfStudyTypeCode = "PrFormOfStudyTypeCode",
    HealthInsurance = "HealthInsurance",
    PrDisabilityType = "PrDisabilityType",
    PrFormOfStudyType = "PrFormOfStudyType",
}

export interface IPrEmployeeTemporalCurrentEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    DateValidFrom?: Date;
    DateValidTo?: Date;
    HealthInsuranceCode?: string;
    IsDepositing?: boolean;
    PrDisabilityTypeCode?: string;
    PrFormOfStudyTypeCode?: string;
    HealthInsurance?: IPrInsuranceCompanyPredefinedEntity;
    PrDisabilityType?: IPrDisabilityTypeEntity;
    PrFormOfStudyType?: IPrFormOfStudyTypeEntity;
}

export enum PrEmployeeTemporalCurrentEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    DateValidFrom = "DateValidFrom",
    DateValidTo = "DateValidTo",
    HealthInsuranceCode = "HealthInsuranceCode",
    IsDepositing = "IsDepositing",
    PrDisabilityTypeCode = "PrDisabilityTypeCode",
    PrFormOfStudyTypeCode = "PrFormOfStudyTypeCode",
    HealthInsurance = "HealthInsurance",
    PrDisabilityType = "PrDisabilityType",
    PrFormOfStudyType = "PrFormOfStudyType",
}

export interface IPrEmploymentEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    DateCalculatePayslipStart?: Date;
    DateEnd?: Date;
    DateStart?: Date;
    NumberOurs?: string;
    TypeCode?: string;
    CurrentTemporalPropertyBag?: IPrEmploymentTemporalCurrentEntity;
    Employee?: IPrEmployeeEntity;
    NumberRange?: INumberRangeEntity;
    PreviousEmployment?: IPrEmploymentEntity;
    Template?: IPrEmploymentTemplateEntity;
    Type?: IPrEmploymentTypeEntity;
    ExtraSalaries?: IPrEmploymentExtraSalaryEntity[];
    SalaryComponents?: IPrEmploymentSalaryComponentEntity[];
    TemporalPropertyBag?: IPrEmploymentTemporalEntity[];
}

export enum PrEmploymentEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    DateCalculatePayslipStart = "DateCalculatePayslipStart",
    DateEnd = "DateEnd",
    DateStart = "DateStart",
    NumberOurs = "NumberOurs",
    TypeCode = "TypeCode",
    CurrentTemporalPropertyBag = "CurrentTemporalPropertyBag",
    Employee = "Employee",
    NumberRange = "NumberRange",
    PreviousEmployment = "PreviousEmployment",
    Template = "Template",
    Type = "Type",
    ExtraSalaries = "ExtraSalaries",
    SalaryComponents = "SalaryComponents",
    TemporalPropertyBag = "TemporalPropertyBag",
}

export interface IPrEmploymentExtraSalaryEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    DateValidFrom?: Date;
    DateValidTo?: Date;
    CurrentTemporalPropertyBag?: IPrEmploymentExtraSalaryTemporalCurrentEntity;
    Default?: IPrExtraSalaryEntity;
    Template?: IPrEmploymentTemplateExtraSalaryEntity;
    TemporalPropertyBag?: IPrEmploymentExtraSalaryTemporalEntity[];
}

export enum PrEmploymentExtraSalaryEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    DateValidFrom = "DateValidFrom",
    DateValidTo = "DateValidTo",
    CurrentTemporalPropertyBag = "CurrentTemporalPropertyBag",
    Default = "Default",
    Template = "Template",
    TemporalPropertyBag = "TemporalPropertyBag",
}

export interface IPrEmploymentExtraSalaryTemporalEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    DateValidFrom?: Date;
    DateValidTo?: Date;
    ComputationTypeCode?: string;
    Name?: string;
    Value?: number;
    ComputationType?: IPrExtraSalaryComputationTypeEntity;
}

export enum PrEmploymentExtraSalaryTemporalEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    DateValidFrom = "DateValidFrom",
    DateValidTo = "DateValidTo",
    ComputationTypeCode = "ComputationTypeCode",
    Name = "Name",
    Value = "Value",
    ComputationType = "ComputationType",
}

export interface IPrEmploymentExtraSalaryTemporalCurrentEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    DateValidFrom?: Date;
    DateValidTo?: Date;
    ComputationTypeCode?: string;
    ComputationTypeSourceCode?: string;
    Name?: string;
    NameSourceCode?: string;
    ValueSourceCode?: string;
    Value?: number;
    ComputationType?: IPrExtraSalaryComputationTypeEntity;
    ComputationTypeSource?: IPrEntityValueSourceEntity;
    NameSource?: IPrEntityValueSourceEntity;
    ValueSource?: IPrEntityValueSourceEntity;
}

export enum PrEmploymentExtraSalaryTemporalCurrentEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    DateValidFrom = "DateValidFrom",
    DateValidTo = "DateValidTo",
    ComputationTypeCode = "ComputationTypeCode",
    ComputationTypeSourceCode = "ComputationTypeSourceCode",
    Name = "Name",
    NameSourceCode = "NameSourceCode",
    ValueSourceCode = "ValueSourceCode",
    Value = "Value",
    ComputationType = "ComputationType",
    ComputationTypeSource = "ComputationTypeSource",
    NameSource = "NameSource",
    ValueSource = "ValueSource",
}

export interface IPrEmploymentSalaryComponentEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    DateValidFrom?: Date;
    DateValidTo?: Date;
    CurrentTemporalPropertyBag?: IPrEmploymentSalaryComponentTemporalCurrentEntity;
    Default?: IPrSalaryComponentEntity;
    Template?: IPrEmploymentTemplateSalaryComponentEntity;
    TemporalPropertyBag?: IPrEmploymentSalaryComponentTemporalEntity[];
}

export enum PrEmploymentSalaryComponentEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    DateValidFrom = "DateValidFrom",
    DateValidTo = "DateValidTo",
    CurrentTemporalPropertyBag = "CurrentTemporalPropertyBag",
    Default = "Default",
    Template = "Template",
    TemporalPropertyBag = "TemporalPropertyBag",
}

export interface IPrEmploymentSalaryComponentTemporalEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    DateValidFrom?: Date;
    DateValidTo?: Date;
    Name?: string;
    TypeCode?: string;
    Amount?: number;
    Type?: IPrSalaryComponentTypeEntity;
}

export enum PrEmploymentSalaryComponentTemporalEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    DateValidFrom = "DateValidFrom",
    DateValidTo = "DateValidTo",
    Name = "Name",
    TypeCode = "TypeCode",
    Amount = "Amount",
    Type = "Type",
}

export interface IPrEmploymentSalaryComponentTemporalCurrentEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    DateValidFrom?: Date;
    DateValidTo?: Date;
    AmountSourceCode?: string;
    Name?: string;
    NameSourceCode?: string;
    TypeCode?: string;
    TypeSourceCode?: string;
    Amount?: number;
    AmountSource?: IPrEntityValueSourceEntity;
    NameSource?: IPrEntityValueSourceEntity;
    Type?: IPrSalaryComponentTypeEntity;
    TypeSource?: IPrEntityValueSourceEntity;
}

export enum PrEmploymentSalaryComponentTemporalCurrentEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    DateValidFrom = "DateValidFrom",
    DateValidTo = "DateValidTo",
    AmountSourceCode = "AmountSourceCode",
    Name = "Name",
    NameSourceCode = "NameSourceCode",
    TypeCode = "TypeCode",
    TypeSourceCode = "TypeSourceCode",
    Amount = "Amount",
    AmountSource = "AmountSource",
    NameSource = "NameSource",
    Type = "Type",
    TypeSource = "TypeSource",
}

export interface IPrEmploymentTemplateEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    Name?: string;
    NumberOurs?: string;
    CurrentTemporalPropertyBag?: IPrEmploymentTemplateTemporalCurrentEntity;
    NumberRange?: INumberRangeEntity;
    ExtraSalaries?: IPrEmploymentTemplateExtraSalaryEntity[];
    SalaryComponents?: IPrEmploymentTemplateSalaryComponentEntity[];
    TemporalPropertyBag?: IPrEmploymentTemplateTemporalEntity[];
}

export enum PrEmploymentTemplateEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    Name = "Name",
    NumberOurs = "NumberOurs",
    CurrentTemporalPropertyBag = "CurrentTemporalPropertyBag",
    NumberRange = "NumberRange",
    ExtraSalaries = "ExtraSalaries",
    SalaryComponents = "SalaryComponents",
    TemporalPropertyBag = "TemporalPropertyBag",
}

export interface IPrEmploymentTemplateExtraSalaryEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    DateValidFrom?: Date;
    DateValidTo?: Date;
    CurrentTemporalPropertyBag?: IPrEmploymentTemplateExtraSalaryTemporalCurrentEntity;
    Default?: IPrExtraSalaryEntity;
    TemporalPropertyBag?: IPrEmploymentTemplateExtraSalaryTemporalEntity[];
}

export enum PrEmploymentTemplateExtraSalaryEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    DateValidFrom = "DateValidFrom",
    DateValidTo = "DateValidTo",
    CurrentTemporalPropertyBag = "CurrentTemporalPropertyBag",
    Default = "Default",
    TemporalPropertyBag = "TemporalPropertyBag",
}

export interface IPrEmploymentTemplateExtraSalaryTemporalEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    DateValidFrom?: Date;
    DateValidTo?: Date;
    ComputationTypeCode?: string;
    Name?: string;
    Value?: number;
    ComputationType?: IPrExtraSalaryComputationTypeEntity;
}

export enum PrEmploymentTemplateExtraSalaryTemporalEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    DateValidFrom = "DateValidFrom",
    DateValidTo = "DateValidTo",
    ComputationTypeCode = "ComputationTypeCode",
    Name = "Name",
    Value = "Value",
    ComputationType = "ComputationType",
}

export interface IPrEmploymentTemplateExtraSalaryTemporalCurrentEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    DateValidFrom?: Date;
    DateValidTo?: Date;
    ComputationTypeCode?: string;
    ComputationTypeSourceCode?: string;
    Name?: string;
    NameSourceCode?: string;
    ValueSourceCode?: string;
    Value?: number;
    ComputationType?: IPrExtraSalaryComputationTypeEntity;
    ComputationTypeSource?: IPrEntityValueSourceEntity;
    NameSource?: IPrEntityValueSourceEntity;
    ValueSource?: IPrEntityValueSourceEntity;
}

export enum PrEmploymentTemplateExtraSalaryTemporalCurrentEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    DateValidFrom = "DateValidFrom",
    DateValidTo = "DateValidTo",
    ComputationTypeCode = "ComputationTypeCode",
    ComputationTypeSourceCode = "ComputationTypeSourceCode",
    Name = "Name",
    NameSourceCode = "NameSourceCode",
    ValueSourceCode = "ValueSourceCode",
    Value = "Value",
    ComputationType = "ComputationType",
    ComputationTypeSource = "ComputationTypeSource",
    NameSource = "NameSource",
    ValueSource = "ValueSource",
}

export interface IPrEmploymentTemplateSalaryComponentEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    DateValidFrom?: Date;
    DateValidTo?: Date;
    CurrentTemporalPropertyBag?: IPrEmploymentTemplateSalaryComponentTemporalCurrentEntity;
    Default?: IPrSalaryComponentEntity;
    TemporalPropertyBag?: IPrEmploymentTemplateSalaryComponentTemporalEntity[];
}

export enum PrEmploymentTemplateSalaryComponentEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    DateValidFrom = "DateValidFrom",
    DateValidTo = "DateValidTo",
    CurrentTemporalPropertyBag = "CurrentTemporalPropertyBag",
    Default = "Default",
    TemporalPropertyBag = "TemporalPropertyBag",
}

export interface IPrEmploymentTemplateSalaryComponentTemporalEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    DateValidFrom?: Date;
    DateValidTo?: Date;
    Name?: string;
    TypeCode?: string;
    Amount?: number;
    Type?: IPrSalaryComponentTypeEntity;
}

export enum PrEmploymentTemplateSalaryComponentTemporalEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    DateValidFrom = "DateValidFrom",
    DateValidTo = "DateValidTo",
    Name = "Name",
    TypeCode = "TypeCode",
    Amount = "Amount",
    Type = "Type",
}

export interface IPrEmploymentTemplateSalaryComponentTemporalCurrentEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    DateValidFrom?: Date;
    DateValidTo?: Date;
    AmountSourceCode?: string;
    Name?: string;
    NameSourceCode?: string;
    TypeCode?: string;
    TypeSourceCode?: string;
    Amount?: number;
    AmountSource?: IPrEntityValueSourceEntity;
    NameSource?: IPrEntityValueSourceEntity;
    Type?: IPrSalaryComponentTypeEntity;
    TypeSource?: IPrEntityValueSourceEntity;
}

export enum PrEmploymentTemplateSalaryComponentTemporalCurrentEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    DateValidFrom = "DateValidFrom",
    DateValidTo = "DateValidTo",
    AmountSourceCode = "AmountSourceCode",
    Name = "Name",
    NameSourceCode = "NameSourceCode",
    TypeCode = "TypeCode",
    TypeSourceCode = "TypeSourceCode",
    Amount = "Amount",
    AmountSource = "AmountSource",
    NameSource = "NameSource",
    Type = "Type",
    TypeSource = "TypeSource",
}

export interface IPrEmploymentTemplateTemporalEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    DateValidFrom?: Date;
    DateValidTo?: Date;
    GuaranteedSalaryDegreeCode?: string;
    LeaveDays?: number;
    SickDays?: number;
    GuaranteedSalaryDegree?: IPrGuaranteedSalaryDegreeEntity;
    WorkingPattern?: IPrWorkingPatternEntity;
}

export enum PrEmploymentTemplateTemporalEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    DateValidFrom = "DateValidFrom",
    DateValidTo = "DateValidTo",
    GuaranteedSalaryDegreeCode = "GuaranteedSalaryDegreeCode",
    LeaveDays = "LeaveDays",
    SickDays = "SickDays",
    GuaranteedSalaryDegree = "GuaranteedSalaryDegree",
    WorkingPattern = "WorkingPattern",
}

export interface IPrEmploymentTemplateTemporalCurrentEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    DateValidFrom?: Date;
    DateValidTo?: Date;
    GuaranteedSalaryDegreeCode?: string;
    GuaranteedSalaryDegreeSourceCode?: string;
    LeaveDaysSourceCode?: string;
    SickDaysSourceCode?: string;
    WorkingPatternSourceCode?: string;
    LeaveDays?: number;
    SickDays?: number;
    GuaranteedSalaryDegree?: IPrGuaranteedSalaryDegreeEntity;
    GuaranteedSalaryDegreeSource?: IPrEntityValueSourceEntity;
    LeaveDaysSource?: IPrEntityValueSourceEntity;
    SickDaysSource?: IPrEntityValueSourceEntity;
    WorkingPattern?: IPrWorkingPatternEntity;
    WorkingPatternSource?: IPrEntityValueSourceEntity;
}

export enum PrEmploymentTemplateTemporalCurrentEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    DateValidFrom = "DateValidFrom",
    DateValidTo = "DateValidTo",
    GuaranteedSalaryDegreeCode = "GuaranteedSalaryDegreeCode",
    GuaranteedSalaryDegreeSourceCode = "GuaranteedSalaryDegreeSourceCode",
    LeaveDaysSourceCode = "LeaveDaysSourceCode",
    SickDaysSourceCode = "SickDaysSourceCode",
    WorkingPatternSourceCode = "WorkingPatternSourceCode",
    LeaveDays = "LeaveDays",
    SickDays = "SickDays",
    GuaranteedSalaryDegree = "GuaranteedSalaryDegree",
    GuaranteedSalaryDegreeSource = "GuaranteedSalaryDegreeSource",
    LeaveDaysSource = "LeaveDaysSource",
    SickDaysSource = "SickDaysSource",
    WorkingPattern = "WorkingPattern",
    WorkingPatternSource = "WorkingPatternSource",
}

export interface IPrEmploymentTemporalEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    DateValidFrom?: Date;
    DateValidTo?: Date;
    GuaranteedSalaryDegreeCode?: string;
    LeaveDays?: number;
    SickDays?: number;
    GuaranteedSalaryDegree?: IPrGuaranteedSalaryDegreeEntity;
    WorkingPattern?: IPrWorkingPatternEntity;
}

export enum PrEmploymentTemporalEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    DateValidFrom = "DateValidFrom",
    DateValidTo = "DateValidTo",
    GuaranteedSalaryDegreeCode = "GuaranteedSalaryDegreeCode",
    LeaveDays = "LeaveDays",
    SickDays = "SickDays",
    GuaranteedSalaryDegree = "GuaranteedSalaryDegree",
    WorkingPattern = "WorkingPattern",
}

export interface IPrEmploymentTemporalCurrentEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    DateValidFrom?: Date;
    DateValidTo?: Date;
    GuaranteedSalaryDegreeCode?: string;
    GuaranteedSalaryDegreeSourceCode?: string;
    LeaveDaysSourceCode?: string;
    SickDaysSourceCode?: string;
    WorkingPatternSourceCode?: string;
    LeaveDays?: number;
    SickDays?: number;
    GuaranteedSalaryDegree?: IPrGuaranteedSalaryDegreeEntity;
    GuaranteedSalaryDegreeSource?: IPrEntityValueSourceEntity;
    LeaveDaysSource?: IPrEntityValueSourceEntity;
    SickDaysSource?: IPrEntityValueSourceEntity;
    WorkingPattern?: IPrWorkingPatternEntity;
    WorkingPatternSource?: IPrEntityValueSourceEntity;
}

export enum PrEmploymentTemporalCurrentEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    DateValidFrom = "DateValidFrom",
    DateValidTo = "DateValidTo",
    GuaranteedSalaryDegreeCode = "GuaranteedSalaryDegreeCode",
    GuaranteedSalaryDegreeSourceCode = "GuaranteedSalaryDegreeSourceCode",
    LeaveDaysSourceCode = "LeaveDaysSourceCode",
    SickDaysSourceCode = "SickDaysSourceCode",
    WorkingPatternSourceCode = "WorkingPatternSourceCode",
    LeaveDays = "LeaveDays",
    SickDays = "SickDays",
    GuaranteedSalaryDegree = "GuaranteedSalaryDegree",
    GuaranteedSalaryDegreeSource = "GuaranteedSalaryDegreeSource",
    LeaveDaysSource = "LeaveDaysSource",
    SickDaysSource = "SickDaysSource",
    WorkingPattern = "WorkingPattern",
    WorkingPatternSource = "WorkingPatternSource",
}

export interface IPrEmploymentTypeEntity extends IEntityBase {
    Code?: string;
    CountryCode?: string;
    Name?: string;
    Country?: ICountryEntity;
}

export enum PrEmploymentTypeEntity {
    Code = "Code",
    CountryCode = "CountryCode",
    Name = "Name",
    Country = "Country",
}

export interface IPrEntityValueSourceEntity extends IEntityBase {
    Code?: string;
    Name?: string;
}

export enum PrEntityValueSourceEntity {
    Code = "Code",
    Name = "Name",
}

export interface IPrExtraSalaryEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    BaseTypeCode?: string;
    TypeCode?: string;
    BaseType?: IPrBaseTypeEntity;
    CurrentTemporalPropertyBag?: IPrExtraSalaryTemporalCurrentEntity;
    Type?: IPrExtraSalaryTypeEntity;
    TemporalPropertyBag?: IPrExtraSalaryTemporalEntity[];
}

export enum PrExtraSalaryEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    BaseTypeCode = "BaseTypeCode",
    TypeCode = "TypeCode",
    BaseType = "BaseType",
    CurrentTemporalPropertyBag = "CurrentTemporalPropertyBag",
    Type = "Type",
    TemporalPropertyBag = "TemporalPropertyBag",
}

export interface IPrExtraSalaryComputationTypeEntity extends IEntityBase {
    Code?: string;
    Name?: string;
}

export enum PrExtraSalaryComputationTypeEntity {
    Code = "Code",
    Name = "Name",
}

export interface IPrExtraSalaryLegislativeValueEntity extends IEntityBase {
    Code?: string;
    CountryCode?: string;
    DateValidFrom?: Date;
    DateValidTo?: Date;
    ExtraSalaryComputationTypeCode?: string;
    ExtraSalaryName?: string;
    ExtraSalaryTypeCode?: string;
    MinValue?: number;
    Name?: string;
    Country?: ICountryEntity;
    ExtraSalaryComputationType?: IPrExtraSalaryComputationTypeEntity;
    ExtraSalaryType?: IPrExtraSalaryTypeEntity;
}

export enum PrExtraSalaryLegislativeValueEntity {
    Code = "Code",
    CountryCode = "CountryCode",
    DateValidFrom = "DateValidFrom",
    DateValidTo = "DateValidTo",
    ExtraSalaryComputationTypeCode = "ExtraSalaryComputationTypeCode",
    ExtraSalaryName = "ExtraSalaryName",
    ExtraSalaryTypeCode = "ExtraSalaryTypeCode",
    MinValue = "MinValue",
    Name = "Name",
    Country = "Country",
    ExtraSalaryComputationType = "ExtraSalaryComputationType",
    ExtraSalaryType = "ExtraSalaryType",
}

export interface IPrExtraSalaryTemporalEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    DateValidFrom?: Date;
    DateValidTo?: Date;
    ComputationTypeCode?: string;
    Name?: string;
    Value?: number;
    ComputationType?: IPrExtraSalaryComputationTypeEntity;
}

export enum PrExtraSalaryTemporalEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    DateValidFrom = "DateValidFrom",
    DateValidTo = "DateValidTo",
    ComputationTypeCode = "ComputationTypeCode",
    Name = "Name",
    Value = "Value",
    ComputationType = "ComputationType",
}

export interface IPrExtraSalaryTemporalCurrentEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    DateValidFrom?: Date;
    DateValidTo?: Date;
    ComputationTypeCode?: string;
    Name?: string;
    Value?: number;
    ComputationType?: IPrExtraSalaryComputationTypeEntity;
}

export enum PrExtraSalaryTemporalCurrentEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    DateValidFrom = "DateValidFrom",
    DateValidTo = "DateValidTo",
    ComputationTypeCode = "ComputationTypeCode",
    Name = "Name",
    Value = "Value",
    ComputationType = "ComputationType",
}

export interface IPrExtraSalaryTypeEntity extends IEntityBase {
    Code?: string;
    IsLegallyRequired?: boolean;
    Name?: string;
}

export enum PrExtraSalaryTypeEntity {
    Code = "Code",
    IsLegallyRequired = "IsLegallyRequired",
    Name = "Name",
}

export interface IPrFormOfStudyTypeEntity extends IEntityBase {
    Code?: string;
    Name?: string;
}

export enum PrFormOfStudyTypeEntity {
    Code = "Code",
    Name = "Name",
}

export interface IPrGovernmentInstitutionBankAccountDetailEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    GovernmentInstitutionBankAccountCode?: string;
    RemittanceInformation?: string;
    SymbolConstant?: string;
    SymbolSpecific?: string;
    SymbolVariable?: string;
    GovernmentInstitutionBankAccount?: IGovernmentInstitutionBankAccountEntity;
}

export enum PrGovernmentInstitutionBankAccountDetailEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    GovernmentInstitutionBankAccountCode = "GovernmentInstitutionBankAccountCode",
    RemittanceInformation = "RemittanceInformation",
    SymbolConstant = "SymbolConstant",
    SymbolSpecific = "SymbolSpecific",
    SymbolVariable = "SymbolVariable",
    GovernmentInstitutionBankAccount = "GovernmentInstitutionBankAccount",
}

export interface IPrGovernmentInstitutionDetailEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    ContactEmail?: string;
    ContactName?: string;
    ContactPhone?: string;
    GovernmentInstitutionCode?: string;
    GovernmentInstitution?: IGovernmentInstitutionEntity;
    BankAccountDetails?: IPrGovernmentInstitutionBankAccountDetailEntity[];
}

export enum PrGovernmentInstitutionDetailEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    ContactEmail = "ContactEmail",
    ContactName = "ContactName",
    ContactPhone = "ContactPhone",
    GovernmentInstitutionCode = "GovernmentInstitutionCode",
    GovernmentInstitution = "GovernmentInstitution",
    BankAccountDetails = "BankAccountDetails",
}

export interface IPrGroupDeductionEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    DateEnd?: Date;
    DateStart?: Date;
    Name?: string;
    NumberOurs?: string;
    RemittanceInformation?: string;
    StatusCode?: string;
    SymbolConstant?: string;
    SymbolSpecific?: string;
    SymbolVariable?: string;
    TypeCode?: string;
    BankAccount?: IPrDeductionBankAccountEntity;
    CurrentTemporalPropertyBag?: IPrDeductionTemporalCurrentEntity;
    NumberRange?: INumberRangeEntity;
    Recipient?: IPrDeductionBusinessPartnerEntity;
    Status?: IPrDeductionStatusEntity;
    Type?: IPrDeductionTypeEntity;
    TemporalPropertyBag?: IPrDeductionTemporalEntity[];
    Employees?: IPrGroupDeductionEmployeeEntity[];
}

export enum PrGroupDeductionEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    DateEnd = "DateEnd",
    DateStart = "DateStart",
    Name = "Name",
    NumberOurs = "NumberOurs",
    RemittanceInformation = "RemittanceInformation",
    StatusCode = "StatusCode",
    SymbolConstant = "SymbolConstant",
    SymbolSpecific = "SymbolSpecific",
    SymbolVariable = "SymbolVariable",
    TypeCode = "TypeCode",
    BankAccount = "BankAccount",
    CurrentTemporalPropertyBag = "CurrentTemporalPropertyBag",
    NumberRange = "NumberRange",
    Recipient = "Recipient",
    Status = "Status",
    Type = "Type",
    TemporalPropertyBag = "TemporalPropertyBag",
    Employees = "Employees",
}

export interface IPrGroupDeductionEmployeeEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    DateValidFrom?: Date;
    DateValidTo?: Date;
    Employee?: IPrEmployeeEntity;
}

export enum PrGroupDeductionEmployeeEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    DateValidFrom = "DateValidFrom",
    DateValidTo = "DateValidTo",
    Employee = "Employee",
}

export interface IPrGuaranteedSalaryDegreeEntity extends IEntityBase {
    Code?: string;
    CountryCode?: string;
    DateValidFrom?: Date;
    DateValidTo?: Date;
    Name?: string;
    Country?: ICountryEntity;
}

export enum PrGuaranteedSalaryDegreeEntity {
    Code = "Code",
    CountryCode = "CountryCode",
    DateValidFrom = "DateValidFrom",
    DateValidTo = "DateValidTo",
    Name = "Name",
    Country = "Country",
}

export interface IPrHealthInsuranceCompanyEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    DataBoxId?: string;
    IsActive?: boolean;
    Name?: string;
    PrInsuranceCompanyTypeCode?: string;
    RemittanceInformation?: string;
    SymbolConstant?: string;
    SymbolSpecific?: string;
    SymbolVariable?: string;
    BankAccount?: IPrInsuranceCompanyBankAccountEntity;
    HealthInsuranceCompanyCode?: string;
    ShortName?: string;
}

export enum PrHealthInsuranceCompanyEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    DataBoxId = "DataBoxId",
    IsActive = "IsActive",
    Name = "Name",
    PrInsuranceCompanyTypeCode = "PrInsuranceCompanyTypeCode",
    RemittanceInformation = "RemittanceInformation",
    SymbolConstant = "SymbolConstant",
    SymbolSpecific = "SymbolSpecific",
    SymbolVariable = "SymbolVariable",
    BankAccount = "BankAccount",
    HealthInsuranceCompanyCode = "HealthInsuranceCompanyCode",
    ShortName = "ShortName",
}

export interface IPrIdentityDocumentTypeEntity extends IEntityBase {
    Code?: string;
    Name?: string;
}

export enum PrIdentityDocumentTypeEntity {
    Code = "Code",
    Name = "Name",
}

export interface IPrIndividualDeductionEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    DateEnd?: Date;
    DateStart?: Date;
    Name?: string;
    NumberOurs?: string;
    RemittanceInformation?: string;
    StatusCode?: string;
    SymbolConstant?: string;
    SymbolSpecific?: string;
    SymbolVariable?: string;
    TypeCode?: string;
    BankAccount?: IPrDeductionBankAccountEntity;
    CurrentTemporalPropertyBag?: IPrDeductionTemporalCurrentEntity;
    NumberRange?: INumberRangeEntity;
    Recipient?: IPrDeductionBusinessPartnerEntity;
    Status?: IPrDeductionStatusEntity;
    Type?: IPrDeductionTypeEntity;
    TemporalPropertyBag?: IPrDeductionTemporalEntity[];
    ChildSupportTypeCode?: string;
    DateReceived?: Date;
    DecreeNumber?: string;
    DistrainmentTypeCode?: string;
    AmountDebtTotal?: number;
    ChildSupportType?: IPrChildSupportDeductionTypeEntity;
    DistrainmentType?: IPrDistrainmentDeductionTypeEntity;
    Employee?: IPrEmployeeEntity;
    DebtPayments?: IPrIndividualDeductionDebtPaymentEntity[];
}

export enum PrIndividualDeductionEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    DateEnd = "DateEnd",
    DateStart = "DateStart",
    Name = "Name",
    NumberOurs = "NumberOurs",
    RemittanceInformation = "RemittanceInformation",
    StatusCode = "StatusCode",
    SymbolConstant = "SymbolConstant",
    SymbolSpecific = "SymbolSpecific",
    SymbolVariable = "SymbolVariable",
    TypeCode = "TypeCode",
    BankAccount = "BankAccount",
    CurrentTemporalPropertyBag = "CurrentTemporalPropertyBag",
    NumberRange = "NumberRange",
    Recipient = "Recipient",
    Status = "Status",
    Type = "Type",
    TemporalPropertyBag = "TemporalPropertyBag",
    ChildSupportTypeCode = "ChildSupportTypeCode",
    DateReceived = "DateReceived",
    DecreeNumber = "DecreeNumber",
    DistrainmentTypeCode = "DistrainmentTypeCode",
    AmountDebtTotal = "AmountDebtTotal",
    ChildSupportType = "ChildSupportType",
    DistrainmentType = "DistrainmentType",
    Employee = "Employee",
    DebtPayments = "DebtPayments",
}

export interface IPrIndividualDeductionDebtPaymentEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    Amount?: number;
    DateMonthStart?: Date;
}

export enum PrIndividualDeductionDebtPaymentEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    Amount = "Amount",
    DateMonthStart = "DateMonthStart",
}

export interface IPrInsuranceBankAccountPredefinedEntity extends IEntityBase {
    Code?: string;
    AbaNumber?: string;
    AccountNumber?: string;
    BankCode?: string;
    CountryCode?: string;
    IBAN?: string;
    Name?: string;
    PrInsuranceBankAccountTypeCode?: string;
    SWIFT?: string;
    Bank?: IBankEntity;
    Country?: ICountryEntity;
    PrInsuranceBankAccountType?: IPrInsuranceBankAccountTypeEntity;
}

export enum PrInsuranceBankAccountPredefinedEntity {
    Code = "Code",
    AbaNumber = "AbaNumber",
    AccountNumber = "AccountNumber",
    BankCode = "BankCode",
    CountryCode = "CountryCode",
    IBAN = "IBAN",
    Name = "Name",
    PrInsuranceBankAccountTypeCode = "PrInsuranceBankAccountTypeCode",
    SWIFT = "SWIFT",
    Bank = "Bank",
    Country = "Country",
    PrInsuranceBankAccountType = "PrInsuranceBankAccountType",
}

export interface IPrInsuranceBankAccountTypeEntity extends IEntityBase {
    Code?: string;
    Name?: string;
}

export enum PrInsuranceBankAccountTypeEntity {
    Code = "Code",
    Name = "Name",
}

export interface IPrInsuranceCompanyEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    DataBoxId?: string;
    IsActive?: boolean;
    Name?: string;
    PrInsuranceCompanyTypeCode?: string;
    RemittanceInformation?: string;
    SymbolConstant?: string;
    SymbolSpecific?: string;
    SymbolVariable?: string;
    BankAccount?: IPrInsuranceCompanyBankAccountEntity;
}

export enum PrInsuranceCompanyEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    DataBoxId = "DataBoxId",
    IsActive = "IsActive",
    Name = "Name",
    PrInsuranceCompanyTypeCode = "PrInsuranceCompanyTypeCode",
    RemittanceInformation = "RemittanceInformation",
    SymbolConstant = "SymbolConstant",
    SymbolSpecific = "SymbolSpecific",
    SymbolVariable = "SymbolVariable",
    BankAccount = "BankAccount",
}

export interface IPrInsuranceCompanyBankAccountEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    AbaNumber?: string;
    AccountNumber?: string;
    BankCode?: string;
    CountryCode?: string;
    IBAN?: string;
    PaymentServiceID?: string;
    PaymentServiceName?: string;
    SWIFT?: string;
    Bank?: IBankEntity;
    Country?: ICountryEntity;
}

export enum PrInsuranceCompanyBankAccountEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    AbaNumber = "AbaNumber",
    AccountNumber = "AccountNumber",
    BankCode = "BankCode",
    CountryCode = "CountryCode",
    IBAN = "IBAN",
    PaymentServiceID = "PaymentServiceID",
    PaymentServiceName = "PaymentServiceName",
    SWIFT = "SWIFT",
    Bank = "Bank",
    Country = "Country",
}

export interface IPrInsuranceCompanyPredefinedEntity extends IEntityBase {
    Code?: string;
    CountryCode?: string;
    DataBoxId?: string;
    HealthInsuranceCompanyCode?: string;
    Name?: string;
    PrInsuranceCompanyTypeCode?: string;
    ShortName?: string;
    Country?: ICountryEntity;
    PrInsuranceCompanyType?: IPrInsuranceCompanyTypeEntity;
    BankAccounts?: IPrInsuranceBankAccountPredefinedEntity[];
}

export enum PrInsuranceCompanyPredefinedEntity {
    Code = "Code",
    CountryCode = "CountryCode",
    DataBoxId = "DataBoxId",
    HealthInsuranceCompanyCode = "HealthInsuranceCompanyCode",
    Name = "Name",
    PrInsuranceCompanyTypeCode = "PrInsuranceCompanyTypeCode",
    ShortName = "ShortName",
    Country = "Country",
    PrInsuranceCompanyType = "PrInsuranceCompanyType",
    BankAccounts = "BankAccounts",
}

export interface IPrInsuranceCompanyTypeEntity extends IEntityBase {
    Code?: string;
    Name?: string;
}

export enum PrInsuranceCompanyTypeEntity {
    Code = "Code",
    Name = "Name",
}

export interface IPrLegislativeAverageSalaryEntity extends IEntityBase {
    Code?: string;
    Amount?: number;
    CountryCode?: string;
    DateValidFrom?: Date;
    DateValidTo?: Date;
    Name?: string;
    Country?: ICountryEntity;
}

export enum PrLegislativeAverageSalaryEntity {
    Code = "Code",
    Amount = "Amount",
    CountryCode = "CountryCode",
    DateValidFrom = "DateValidFrom",
    DateValidTo = "DateValidTo",
    Name = "Name",
    Country = "Country",
}

export interface IPrLifeInsuranceCompanyEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    DataBoxId?: string;
    IsActive?: boolean;
    Name?: string;
    PrInsuranceCompanyTypeCode?: string;
    RemittanceInformation?: string;
    SymbolConstant?: string;
    SymbolSpecific?: string;
    SymbolVariable?: string;
    BankAccount?: IPrInsuranceCompanyBankAccountEntity;
}

export enum PrLifeInsuranceCompanyEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    DataBoxId = "DataBoxId",
    IsActive = "IsActive",
    Name = "Name",
    PrInsuranceCompanyTypeCode = "PrInsuranceCompanyTypeCode",
    RemittanceInformation = "RemittanceInformation",
    SymbolConstant = "SymbolConstant",
    SymbolSpecific = "SymbolSpecific",
    SymbolVariable = "SymbolVariable",
    BankAccount = "BankAccount",
}

export interface IPrPayrollComponentCategoryTypeEntity extends IEntityBase {
    Code?: string;
    Name?: string;
}

export enum PrPayrollComponentCategoryTypeEntity {
    Code = "Code",
    Name = "Name",
}

export interface IPrPayrollComponentTypeEntity extends IEntityBase {
    Code?: string;
    Name?: string;
}

export enum PrPayrollComponentTypeEntity {
    Code = "Code",
    Name = "Name",
}

export interface IPrPayrollSettingEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    CurrentTemporalPropertyBag?: IPrPayrollSettingTemporalCurrentEntity;
    CzechSocialSecurityAdministration?: IPrGovernmentInstitutionDetailEntity;
    FinancialAdministration?: IPrGovernmentInstitutionDetailEntity;
    LabourOffice?: IPrGovernmentInstitutionDetailEntity;
    StatutoryInsuranceCompany?: IPrGovernmentInstitutionDetailEntity;
    TemporalPropertyBag?: IPrPayrollSettingTemporalEntity[];
}

export enum PrPayrollSettingEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    CurrentTemporalPropertyBag = "CurrentTemporalPropertyBag",
    CzechSocialSecurityAdministration = "CzechSocialSecurityAdministration",
    FinancialAdministration = "FinancialAdministration",
    LabourOffice = "LabourOffice",
    StatutoryInsuranceCompany = "StatutoryInsuranceCompany",
    TemporalPropertyBag = "TemporalPropertyBag",
}

export interface IPrPayrollSettingTemporalEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    DateValidFrom?: Date;
    DateValidTo?: Date;
    LeaveDays?: number;
    SickDays?: number;
}

export enum PrPayrollSettingTemporalEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    DateValidFrom = "DateValidFrom",
    DateValidTo = "DateValidTo",
    LeaveDays = "LeaveDays",
    SickDays = "SickDays",
}

export interface IPrPayrollSettingTemporalCurrentEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    DateValidFrom?: Date;
    DateValidTo?: Date;
    LeaveDays?: number;
    SickDays?: number;
}

export enum PrPayrollSettingTemporalCurrentEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    DateValidFrom = "DateValidFrom",
    DateValidTo = "DateValidTo",
    LeaveDays = "LeaveDays",
    SickDays = "SickDays",
}

export interface IPrPensionInsuranceCompanyEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    DataBoxId?: string;
    IsActive?: boolean;
    Name?: string;
    PrInsuranceCompanyTypeCode?: string;
    RemittanceInformation?: string;
    SymbolConstant?: string;
    SymbolSpecific?: string;
    SymbolVariable?: string;
    BankAccount?: IPrInsuranceCompanyBankAccountEntity;
}

export enum PrPensionInsuranceCompanyEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    DataBoxId = "DataBoxId",
    IsActive = "IsActive",
    Name = "Name",
    PrInsuranceCompanyTypeCode = "PrInsuranceCompanyTypeCode",
    RemittanceInformation = "RemittanceInformation",
    SymbolConstant = "SymbolConstant",
    SymbolSpecific = "SymbolSpecific",
    SymbolVariable = "SymbolVariable",
    BankAccount = "BankAccount",
}

export interface IPrSalaryAdvanceEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    Amount?: number;
    CurrencyCode?: string;
    DateAccountingTransaction?: Date;
    Month?: number;
    NumberOurs?: string;
    Year?: number;
    Currency?: ICurrencyEntity;
    Employee?: IPrEmployeeEntity;
    NumberRange?: INumberRangeEntity;
    PairedDocument?: IOtherLiabilityEntity;
}

export enum PrSalaryAdvanceEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    Amount = "Amount",
    CurrencyCode = "CurrencyCode",
    DateAccountingTransaction = "DateAccountingTransaction",
    Month = "Month",
    NumberOurs = "NumberOurs",
    Year = "Year",
    Currency = "Currency",
    Employee = "Employee",
    NumberRange = "NumberRange",
    PairedDocument = "PairedDocument",
}

export interface IPrSalaryComponentEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    CurrentTemporalPropertyBag?: IPrSalaryComponentTemporalCurrentEntity;
    TemporalPropertyBag?: IPrSalaryComponentTemporalEntity[];
}

export enum PrSalaryComponentEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    CurrentTemporalPropertyBag = "CurrentTemporalPropertyBag",
    TemporalPropertyBag = "TemporalPropertyBag",
}

export interface IPrSalaryComponentTemporalEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    DateValidFrom?: Date;
    DateValidTo?: Date;
    Amount?: number;
    Name?: string;
    TypeCode?: string;
    Type?: IPrSalaryComponentTypeEntity;
}

export enum PrSalaryComponentTemporalEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    DateValidFrom = "DateValidFrom",
    DateValidTo = "DateValidTo",
    Amount = "Amount",
    Name = "Name",
    TypeCode = "TypeCode",
    Type = "Type",
}

export interface IPrSalaryComponentTemporalCurrentEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    DateValidFrom?: Date;
    DateValidTo?: Date;
    Amount?: number;
    Name?: string;
    TypeCode?: string;
    Type?: IPrSalaryComponentTypeEntity;
}

export enum PrSalaryComponentTemporalCurrentEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    DateValidFrom = "DateValidFrom",
    DateValidTo = "DateValidTo",
    Amount = "Amount",
    Name = "Name",
    TypeCode = "TypeCode",
    Type = "Type",
}

export interface IPrSalaryComponentTypeEntity extends IEntityBase {
    Code?: string;
    Name?: string;
}

export enum PrSalaryComponentTypeEntity {
    Code = "Code",
    Name = "Name",
}

export interface IPrSexEntity extends IEntityBase {
    Code?: string;
    Name?: string;
}

export enum PrSexEntity {
    Code = "Code",
    Name = "Name",
}

export interface IPrUnseizableAmountEntity extends IEntityBase {
    Code?: string;
    AmountBase?: number;
    AmountDependant?: number;
    AmountToBeDistributedLimit?: number;
    CountryCode?: string;
    DateValidFrom?: Date;
    DateValidTo?: Date;
    Name?: string;
    Country?: ICountryEntity;
}

export enum PrUnseizableAmountEntity {
    Code = "Code",
    AmountBase = "AmountBase",
    AmountDependant = "AmountDependant",
    AmountToBeDistributedLimit = "AmountToBeDistributedLimit",
    CountryCode = "CountryCode",
    DateValidFrom = "DateValidFrom",
    DateValidTo = "DateValidTo",
    Name = "Name",
    Country = "Country",
}

export interface IPrWorkIntervalTypeEntity extends IEntityBase {
    Code?: string;
    IncludeInWorkingHours?: boolean;
    Name?: string;
}

export enum PrWorkIntervalTypeEntity {
    Code = "Code",
    IncludeInWorkingHours = "IncludeInWorkingHours",
    Name = "Name",
}

export interface IPrWorkingPatternEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    DefaultDayStart?: Date;
    IsActive?: boolean;
    IsDifferentOddAndEvenWeek?: boolean;
    IsIncludingHolidays?: boolean;
    Name?: string;
    TypeCode?: string;
    Type?: IPrWorkingPatternTypeEntity;
    Days?: IPrWorkingPatternDayEntity[];
}

export enum PrWorkingPatternEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    DefaultDayStart = "DefaultDayStart",
    IsActive = "IsActive",
    IsDifferentOddAndEvenWeek = "IsDifferentOddAndEvenWeek",
    IsIncludingHolidays = "IsIncludingHolidays",
    Name = "Name",
    TypeCode = "TypeCode",
    Type = "Type",
    Days = "Days",
}

export interface IPrWorkingPatternDayEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    Date?: Date;
    WorkingHours?: number;
    Intervals?: IPrWorkingPatternDayIntervalEntity[];
}

export enum PrWorkingPatternDayEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    Date = "Date",
    WorkingHours = "WorkingHours",
    Intervals = "Intervals",
}

export interface IPrWorkingPatternDayIntervalEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    IsEndNextDay?: boolean;
    IsStartNextDay?: boolean;
    Order?: number;
    TimeEnd?: Date;
    TimeStart?: Date;
    TypeCode?: string;
    Type?: IPrWorkIntervalTypeEntity;
}

export enum PrWorkingPatternDayIntervalEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    IsEndNextDay = "IsEndNextDay",
    IsStartNextDay = "IsStartNextDay",
    Order = "Order",
    TimeEnd = "TimeEnd",
    TimeStart = "TimeStart",
    TypeCode = "TypeCode",
    Type = "Type",
}

export interface IPrWorkingPatternTypeEntity extends IEntityBase {
    Code?: string;
    Name?: string;
}

export enum PrWorkingPatternTypeEntity {
    Code = "Code",
    Name = "Name",
}

export interface IProductEntity extends IEntityBase {
    Code?: string;
    Name?: string;
}

export enum ProductEntity {
    Code = "Code",
    Name = "Name",
}

export interface IProductPriceTypeEntity extends IEntityBase {
    Code?: string;
    Name?: string;
    S5articleGuid?: string;
}

export enum ProductPriceTypeEntity {
    Code = "Code",
    Name = "Name",
    S5articleGuid = "S5articleGuid",
}

export interface IProformaInvoiceIssuedEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    Amount?: number;
    AmountNet?: number;
    AmountVat?: number;
    ClearedStatusCode?: string;
    CurrencyCode?: string;
    DateAccountingTransaction?: Date;
    DateCbaDocument?: Date;
    DateClearing?: Date;
    DateElectronicSubmissionVatControlStatement?: Date;
    DateElectronicSubmissionVatStatement?: Date;
    DateElectronicSubmissionVatViesStatement?: Date;
    DateVatDeduction?: Date;
    DocumentStatusCode?: string;
    DocumentTypeCode?: string;
    DocumentVatStatementStatusCode?: string;
    ExchangeRatePerUnit?: number;
    Note?: string;
    NumberOurs?: string;
    NumberTheirs?: string;
    PostedStatusCode?: string;
    RemittanceInformation?: string;
    SymbolConstant?: string;
    SymbolSpecific?: string;
    SymbolVariable?: string;
    TransactionAmount?: number;
    TransactionAmountDue?: number;
    TransactionAmountNet?: number;
    TransactionAmountVat?: number;
    TransactionCurrencyCode?: string;
    VatControlStatementStatusCode?: string;
    VatStatementStatusCode?: string;
    VatViesStatementStatusCode?: string;
    ExchangeRatePerUnitDateVatDeduction?: number;
    AccountAssignmentSelection?: IDocumentAccountAssignmentSelectionEntity;
    BankAccount?: IDocumentBankAccountEntity;
    BusinessPartner?: IDocumentBusinessPartnerEntity;
    CbaCategory?: IDocumentCbaCategoryEntity;
    ClearedStatus?: IClearedStatusEntity;
    Company?: ICompanyEntity;
    Currency?: ICurrencyEntity;
    DocumentDraft?: IDocumentDraftEntity;
    DocumentStatus?: IDocumentStatusEntity;
    DocumentType?: IDocumentTypeEntity;
    DocumentVatStatementStatus?: IVatStatementStatusEntity;
    FiscalPeriod?: IFiscalPeriodEntity;
    FiscalYear?: IFiscalYearEntity;
    NumberRange?: INumberRangeEntity;
    PostedStatus?: IPostedStatusEntity;
    TransactionCurrency?: ICurrencyEntity;
    VatClassificationSelection?: IDocumentVatClassificationSelectionEntity;
    VatControlStatementStatus?: IVatStatementStatusEntity;
    VatStatementStatus?: IVatStatementStatusEntity;
    VatViesStatementStatus?: IVatStatementStatusEntity;
    Attachments?: IDocumentAttachmentEntity[];
    DocumentItems?: IDocumentItemViewEntity[];
    DocumentLinks?: IDocumentLinkEntity[];
    Labels?: IDocumentLabelEntity[];
    Locks?: IDocumentLockEntity[];
    OppositeDocumentLinks?: IOppositeDocumentDocumentLinkEntity[];
    Payments?: IOppositePaymentDocumentDocumentLinkEntity[];
    ClearingDocumentLinks?: IClearingDocumentLinkEntity[];
    CorrectiveDocumentDrafts?: IDocumentDraftEntity[];
    DeferredPlans?: IDeferredPlanEntity[];
    CalculateVat?: boolean;
    DateDue?: Date;
    DateIssued?: Date;
    DatePaymentReceived?: Date;
    DateProcessed?: Date;
    DateTaxableSupply?: Date;
    IsTaxDocument?: boolean;
    PaymentMethodCode?: string;
    SymbolMatching?: string;
    TextBeforeItems?: string;
    TransactionAmountToPay?: number;
    TransactionAmountToReceive?: number;
    CashBox?: ICashBoxEntity;
    PaymentMethod?: IPaymentMethodEntity;
    Items?: IRegularDocumentItemEntity[];
}

export enum ProformaInvoiceIssuedEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    Amount = "Amount",
    AmountNet = "AmountNet",
    AmountVat = "AmountVat",
    ClearedStatusCode = "ClearedStatusCode",
    CurrencyCode = "CurrencyCode",
    DateAccountingTransaction = "DateAccountingTransaction",
    DateCbaDocument = "DateCbaDocument",
    DateClearing = "DateClearing",
    DateElectronicSubmissionVatControlStatement = "DateElectronicSubmissionVatControlStatement",
    DateElectronicSubmissionVatStatement = "DateElectronicSubmissionVatStatement",
    DateElectronicSubmissionVatViesStatement = "DateElectronicSubmissionVatViesStatement",
    DateVatDeduction = "DateVatDeduction",
    DocumentStatusCode = "DocumentStatusCode",
    DocumentTypeCode = "DocumentTypeCode",
    DocumentVatStatementStatusCode = "DocumentVatStatementStatusCode",
    ExchangeRatePerUnit = "ExchangeRatePerUnit",
    Note = "Note",
    NumberOurs = "NumberOurs",
    NumberTheirs = "NumberTheirs",
    PostedStatusCode = "PostedStatusCode",
    RemittanceInformation = "RemittanceInformation",
    SymbolConstant = "SymbolConstant",
    SymbolSpecific = "SymbolSpecific",
    SymbolVariable = "SymbolVariable",
    TransactionAmount = "TransactionAmount",
    TransactionAmountDue = "TransactionAmountDue",
    TransactionAmountNet = "TransactionAmountNet",
    TransactionAmountVat = "TransactionAmountVat",
    TransactionCurrencyCode = "TransactionCurrencyCode",
    VatControlStatementStatusCode = "VatControlStatementStatusCode",
    VatStatementStatusCode = "VatStatementStatusCode",
    VatViesStatementStatusCode = "VatViesStatementStatusCode",
    ExchangeRatePerUnitDateVatDeduction = "ExchangeRatePerUnitDateVatDeduction",
    AccountAssignmentSelection = "AccountAssignmentSelection",
    BankAccount = "BankAccount",
    BusinessPartner = "BusinessPartner",
    CbaCategory = "CbaCategory",
    ClearedStatus = "ClearedStatus",
    Company = "Company",
    Currency = "Currency",
    DocumentDraft = "DocumentDraft",
    DocumentStatus = "DocumentStatus",
    DocumentType = "DocumentType",
    DocumentVatStatementStatus = "DocumentVatStatementStatus",
    FiscalPeriod = "FiscalPeriod",
    FiscalYear = "FiscalYear",
    NumberRange = "NumberRange",
    PostedStatus = "PostedStatus",
    TransactionCurrency = "TransactionCurrency",
    VatClassificationSelection = "VatClassificationSelection",
    VatControlStatementStatus = "VatControlStatementStatus",
    VatStatementStatus = "VatStatementStatus",
    VatViesStatementStatus = "VatViesStatementStatus",
    Attachments = "Attachments",
    DocumentItems = "DocumentItems",
    DocumentLinks = "DocumentLinks",
    Labels = "Labels",
    Locks = "Locks",
    OppositeDocumentLinks = "OppositeDocumentLinks",
    Payments = "Payments",
    ClearingDocumentLinks = "ClearingDocumentLinks",
    CorrectiveDocumentDrafts = "CorrectiveDocumentDrafts",
    DeferredPlans = "DeferredPlans",
    CalculateVat = "CalculateVat",
    DateDue = "DateDue",
    DateIssued = "DateIssued",
    DatePaymentReceived = "DatePaymentReceived",
    DateProcessed = "DateProcessed",
    DateTaxableSupply = "DateTaxableSupply",
    IsTaxDocument = "IsTaxDocument",
    PaymentMethodCode = "PaymentMethodCode",
    SymbolMatching = "SymbolMatching",
    TextBeforeItems = "TextBeforeItems",
    TransactionAmountToPay = "TransactionAmountToPay",
    TransactionAmountToReceive = "TransactionAmountToReceive",
    CashBox = "CashBox",
    PaymentMethod = "PaymentMethod",
    Items = "Items",
}

export interface IProformaInvoiceReceivedEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    Amount?: number;
    AmountNet?: number;
    AmountVat?: number;
    ClearedStatusCode?: string;
    CurrencyCode?: string;
    DateAccountingTransaction?: Date;
    DateCbaDocument?: Date;
    DateClearing?: Date;
    DateElectronicSubmissionVatControlStatement?: Date;
    DateElectronicSubmissionVatStatement?: Date;
    DateElectronicSubmissionVatViesStatement?: Date;
    DateVatDeduction?: Date;
    DocumentStatusCode?: string;
    DocumentTypeCode?: string;
    DocumentVatStatementStatusCode?: string;
    ExchangeRatePerUnit?: number;
    Note?: string;
    NumberOurs?: string;
    NumberTheirs?: string;
    PostedStatusCode?: string;
    RemittanceInformation?: string;
    SymbolConstant?: string;
    SymbolSpecific?: string;
    SymbolVariable?: string;
    TransactionAmount?: number;
    TransactionAmountDue?: number;
    TransactionAmountNet?: number;
    TransactionAmountVat?: number;
    TransactionCurrencyCode?: string;
    VatControlStatementStatusCode?: string;
    VatStatementStatusCode?: string;
    VatViesStatementStatusCode?: string;
    ExchangeRatePerUnitDateVatDeduction?: number;
    AccountAssignmentSelection?: IDocumentAccountAssignmentSelectionEntity;
    BankAccount?: IDocumentBankAccountEntity;
    BusinessPartner?: IDocumentBusinessPartnerEntity;
    CbaCategory?: IDocumentCbaCategoryEntity;
    ClearedStatus?: IClearedStatusEntity;
    Company?: ICompanyEntity;
    Currency?: ICurrencyEntity;
    DocumentDraft?: IDocumentDraftEntity;
    DocumentStatus?: IDocumentStatusEntity;
    DocumentType?: IDocumentTypeEntity;
    DocumentVatStatementStatus?: IVatStatementStatusEntity;
    FiscalPeriod?: IFiscalPeriodEntity;
    FiscalYear?: IFiscalYearEntity;
    NumberRange?: INumberRangeEntity;
    PostedStatus?: IPostedStatusEntity;
    TransactionCurrency?: ICurrencyEntity;
    VatClassificationSelection?: IDocumentVatClassificationSelectionEntity;
    VatControlStatementStatus?: IVatStatementStatusEntity;
    VatStatementStatus?: IVatStatementStatusEntity;
    VatViesStatementStatus?: IVatStatementStatusEntity;
    Attachments?: IDocumentAttachmentEntity[];
    DocumentItems?: IDocumentItemViewEntity[];
    DocumentLinks?: IDocumentLinkEntity[];
    Labels?: IDocumentLabelEntity[];
    Locks?: IDocumentLockEntity[];
    OppositeDocumentLinks?: IOppositeDocumentDocumentLinkEntity[];
    Payments?: IOppositePaymentDocumentDocumentLinkEntity[];
    ClearingDocumentLinks?: IClearingDocumentLinkEntity[];
    CorrectiveDocumentDrafts?: IDocumentDraftEntity[];
    DeferredPlans?: IDeferredPlanEntity[];
    BankPaymentOrderStateCode?: string;
    CalculateVat?: boolean;
    DateBankApiPaymentOrder?: Date;
    DateDue?: Date;
    DateIssued?: Date;
    DateProcessed?: Date;
    DateReceived?: Date;
    DateTaxableSupply?: Date;
    IsTaxDocument?: boolean;
    PaymentMethodCode?: string;
    SymbolMatching?: string;
    TextBeforeItems?: string;
    TransactionAmountToPay?: number;
    TransactionAmountToReceive?: number;
    BankPaymentOrderState?: IBankPaymentOrderStateEntity;
    CashBox?: ICashBoxEntity;
    PaymentMethod?: IPaymentMethodEntity;
    Items?: IRegularDocumentItemEntity[];
}

export enum ProformaInvoiceReceivedEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    Amount = "Amount",
    AmountNet = "AmountNet",
    AmountVat = "AmountVat",
    ClearedStatusCode = "ClearedStatusCode",
    CurrencyCode = "CurrencyCode",
    DateAccountingTransaction = "DateAccountingTransaction",
    DateCbaDocument = "DateCbaDocument",
    DateClearing = "DateClearing",
    DateElectronicSubmissionVatControlStatement = "DateElectronicSubmissionVatControlStatement",
    DateElectronicSubmissionVatStatement = "DateElectronicSubmissionVatStatement",
    DateElectronicSubmissionVatViesStatement = "DateElectronicSubmissionVatViesStatement",
    DateVatDeduction = "DateVatDeduction",
    DocumentStatusCode = "DocumentStatusCode",
    DocumentTypeCode = "DocumentTypeCode",
    DocumentVatStatementStatusCode = "DocumentVatStatementStatusCode",
    ExchangeRatePerUnit = "ExchangeRatePerUnit",
    Note = "Note",
    NumberOurs = "NumberOurs",
    NumberTheirs = "NumberTheirs",
    PostedStatusCode = "PostedStatusCode",
    RemittanceInformation = "RemittanceInformation",
    SymbolConstant = "SymbolConstant",
    SymbolSpecific = "SymbolSpecific",
    SymbolVariable = "SymbolVariable",
    TransactionAmount = "TransactionAmount",
    TransactionAmountDue = "TransactionAmountDue",
    TransactionAmountNet = "TransactionAmountNet",
    TransactionAmountVat = "TransactionAmountVat",
    TransactionCurrencyCode = "TransactionCurrencyCode",
    VatControlStatementStatusCode = "VatControlStatementStatusCode",
    VatStatementStatusCode = "VatStatementStatusCode",
    VatViesStatementStatusCode = "VatViesStatementStatusCode",
    ExchangeRatePerUnitDateVatDeduction = "ExchangeRatePerUnitDateVatDeduction",
    AccountAssignmentSelection = "AccountAssignmentSelection",
    BankAccount = "BankAccount",
    BusinessPartner = "BusinessPartner",
    CbaCategory = "CbaCategory",
    ClearedStatus = "ClearedStatus",
    Company = "Company",
    Currency = "Currency",
    DocumentDraft = "DocumentDraft",
    DocumentStatus = "DocumentStatus",
    DocumentType = "DocumentType",
    DocumentVatStatementStatus = "DocumentVatStatementStatus",
    FiscalPeriod = "FiscalPeriod",
    FiscalYear = "FiscalYear",
    NumberRange = "NumberRange",
    PostedStatus = "PostedStatus",
    TransactionCurrency = "TransactionCurrency",
    VatClassificationSelection = "VatClassificationSelection",
    VatControlStatementStatus = "VatControlStatementStatus",
    VatStatementStatus = "VatStatementStatus",
    VatViesStatementStatus = "VatViesStatementStatus",
    Attachments = "Attachments",
    DocumentItems = "DocumentItems",
    DocumentLinks = "DocumentLinks",
    Labels = "Labels",
    Locks = "Locks",
    OppositeDocumentLinks = "OppositeDocumentLinks",
    Payments = "Payments",
    ClearingDocumentLinks = "ClearingDocumentLinks",
    CorrectiveDocumentDrafts = "CorrectiveDocumentDrafts",
    DeferredPlans = "DeferredPlans",
    BankPaymentOrderStateCode = "BankPaymentOrderStateCode",
    CalculateVat = "CalculateVat",
    DateBankApiPaymentOrder = "DateBankApiPaymentOrder",
    DateDue = "DateDue",
    DateIssued = "DateIssued",
    DateProcessed = "DateProcessed",
    DateReceived = "DateReceived",
    DateTaxableSupply = "DateTaxableSupply",
    IsTaxDocument = "IsTaxDocument",
    PaymentMethodCode = "PaymentMethodCode",
    SymbolMatching = "SymbolMatching",
    TextBeforeItems = "TextBeforeItems",
    TransactionAmountToPay = "TransactionAmountToPay",
    TransactionAmountToReceive = "TransactionAmountToReceive",
    BankPaymentOrderState = "BankPaymentOrderState",
    CashBox = "CashBox",
    PaymentMethod = "PaymentMethod",
    Items = "Items",
}

export interface IPurchaseStatusEntity extends IEntityBase {
    Code?: string;
    Name?: string;
}

export enum PurchaseStatusEntity {
    Code = "Code",
    Name = "Name",
}

export interface IReceivableAdjustmentGroupEntity extends IEntityBase {
    Code?: string;
    CountryCode?: string;
    Name?: string;
    Country?: ICountryEntity;
}

export enum ReceivableAdjustmentGroupEntity {
    Code = "Code",
    CountryCode = "CountryCode",
    Name = "Name",
    Country = "Country",
}

export interface IReceivableAdjustmentTypeEntity extends IEntityBase {
    Code?: string;
    CountryCode?: string;
    Explanation?: string;
    GroupCode?: string;
    Name?: string;
    Country?: ICountryEntity;
    Group?: IReceivableAdjustmentGroupEntity;
}

export enum ReceivableAdjustmentTypeEntity {
    Code = "Code",
    CountryCode = "CountryCode",
    Explanation = "Explanation",
    GroupCode = "GroupCode",
    Name = "Name",
    Country = "Country",
    Group = "Group",
}

export interface IRecurrentPaymentStateEntity extends IEntityBase {
    Code?: string;
    Name?: string;
}

export enum RecurrentPaymentStateEntity {
    Code = "Code",
    Name = "Name",
}

export interface IRecurringTaskEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    DateEnd?: Date;
    DateNextExecution?: Date;
    DateStart?: Date;
    DoesExecuteEveryWeekDay?: boolean;
    ExecuteEverySpecificDayOfWeekCode?: string;
    ExecuteInMonthCode?: string;
    IntervalCode?: string;
    Name?: string;
    PeriodCode?: string;
    StateCode?: string;
    TypeCode?: string;
    ExecuteEveryNth?: number;
    ExecuteEveryNthDayOfMonth?: number;
    Company?: ICompanyEntity;
    DocumentDraft?: IDocumentDraftEntity;
    ExecuteEverySpecificDayOfWeek?: IDayOfWeekEntity;
    ExecuteInMonth?: IMonthOfYearEntity;
    Interval?: IRecurringTaskIntervalEntity;
    Period?: IRecurringTaskPeriodEntity;
    State?: IRecurringTaskStateEntity;
    Type?: IRecurringTaskTypeEntity;
    Executions?: IRecurringTaskExecutionEntity[];
}

export enum RecurringTaskEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    DateEnd = "DateEnd",
    DateNextExecution = "DateNextExecution",
    DateStart = "DateStart",
    DoesExecuteEveryWeekDay = "DoesExecuteEveryWeekDay",
    ExecuteEverySpecificDayOfWeekCode = "ExecuteEverySpecificDayOfWeekCode",
    ExecuteInMonthCode = "ExecuteInMonthCode",
    IntervalCode = "IntervalCode",
    Name = "Name",
    PeriodCode = "PeriodCode",
    StateCode = "StateCode",
    TypeCode = "TypeCode",
    ExecuteEveryNth = "ExecuteEveryNth",
    ExecuteEveryNthDayOfMonth = "ExecuteEveryNthDayOfMonth",
    Company = "Company",
    DocumentDraft = "DocumentDraft",
    ExecuteEverySpecificDayOfWeek = "ExecuteEverySpecificDayOfWeek",
    ExecuteInMonth = "ExecuteInMonth",
    Interval = "Interval",
    Period = "Period",
    State = "State",
    Type = "Type",
    Executions = "Executions",
}

export interface IRecurringTaskExecutionEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    ErrorMessage?: string;
    GeneratedDocument?: IDocumentEntity;
    GeneratedDocumentDraft?: IDocumentDraftEntity;
}

export enum RecurringTaskExecutionEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    ErrorMessage = "ErrorMessage",
    GeneratedDocument = "GeneratedDocument",
    GeneratedDocumentDraft = "GeneratedDocumentDraft",
}

export interface IRecurringTaskIntervalEntity extends IEntityBase {
    Code?: string;
    Name?: string;
    Number?: number;
}

export enum RecurringTaskIntervalEntity {
    Code = "Code",
    Name = "Name",
    Number = "Number",
}

export interface IRecurringTaskPeriodEntity extends IEntityBase {
    Code?: string;
    Name?: string;
}

export enum RecurringTaskPeriodEntity {
    Code = "Code",
    Name = "Name",
}

export interface IRecurringTaskStateEntity extends IEntityBase {
    Code?: string;
    Name?: string;
}

export enum RecurringTaskStateEntity {
    Code = "Code",
    Name = "Name",
}

export interface IRecurringTaskTypeEntity extends IEntityBase {
    Code?: string;
    Name?: string;
}

export enum RecurringTaskTypeEntity {
    Code = "Code",
    Name = "Name",
}

export interface IRegularDocumentItemEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    Amount?: number;
    AmountNet?: number;
    AmountVat?: number;
    Description?: string;
    Note?: string;
    Order?: number;
    TransactionAmount?: number;
    TransactionAmountNet?: number;
    TransactionAmountVat?: number;
    VatCode?: string;
    AccountAssignmentSelection?: IDocumentItemAccountAssignmentSelectionEntity;
    CbaCategory?: IDocumentItemCbaCategoryEntity;
    DocumentItemDraft?: IDocumentItemDraftEntity;
    LabelSelection?: ILabelSelectionEntity;
    Vat?: IVatEntity;
    VatClassificationSelection?: IDocumentItemVatClassificationSelectionEntity;
    Quantity?: number;
    TransactionUnitPrice?: number;
    TransactionUnitPriceNet?: number;
    TransactionUnitPriceVat?: number;
    UnitOfMeasureCode?: string;
    UnitPrice?: number;
    UnitPriceNet?: number;
    UnitPriceVat?: number;
    DiscountPercent?: number;
    Discount?: number;
    UnitOfMeasure?: IUnitOfMeasureEntity;
}

export enum RegularDocumentItemEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    Amount = "Amount",
    AmountNet = "AmountNet",
    AmountVat = "AmountVat",
    Description = "Description",
    Note = "Note",
    Order = "Order",
    TransactionAmount = "TransactionAmount",
    TransactionAmountNet = "TransactionAmountNet",
    TransactionAmountVat = "TransactionAmountVat",
    VatCode = "VatCode",
    AccountAssignmentSelection = "AccountAssignmentSelection",
    CbaCategory = "CbaCategory",
    DocumentItemDraft = "DocumentItemDraft",
    LabelSelection = "LabelSelection",
    Vat = "Vat",
    VatClassificationSelection = "VatClassificationSelection",
    Quantity = "Quantity",
    TransactionUnitPrice = "TransactionUnitPrice",
    TransactionUnitPriceNet = "TransactionUnitPriceNet",
    TransactionUnitPriceVat = "TransactionUnitPriceVat",
    UnitOfMeasureCode = "UnitOfMeasureCode",
    UnitPrice = "UnitPrice",
    UnitPriceNet = "UnitPriceNet",
    UnitPriceVat = "UnitPriceVat",
    DiscountPercent = "DiscountPercent",
    Discount = "Discount",
    UnitOfMeasure = "UnitOfMeasure",
}

export interface IReportSectionTypeEntity extends IEntityBase {
    Code?: string;
    Name?: string;
    ReportTypeCode?: string;
    ReportType?: IReportTypeEntity;
}

export enum ReportSectionTypeEntity {
    Code = "Code",
    Name = "Name",
    ReportTypeCode = "ReportTypeCode",
    ReportType = "ReportType",
}

export interface IReportTypeEntity extends IEntityBase {
    Code?: string;
    Name?: string;
}

export enum ReportTypeEntity {
    Code = "Code",
    Name = "Name",
}

export interface IRequiredAccountEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    AccountNumber?: string;
    ReceivableAdjustmentGroupCode?: string;
    RequiredAccountTypeCode?: string;
    VatRate?: number;
    ReceivableAdjustmentGroup?: IReceivableAdjustmentGroupEntity;
    RequiredAccountType?: IRequiredAccountTypeEntity;
}

export enum RequiredAccountEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    AccountNumber = "AccountNumber",
    ReceivableAdjustmentGroupCode = "ReceivableAdjustmentGroupCode",
    RequiredAccountTypeCode = "RequiredAccountTypeCode",
    VatRate = "VatRate",
    ReceivableAdjustmentGroup = "ReceivableAdjustmentGroup",
    RequiredAccountType = "RequiredAccountType",
}

export interface IRequiredAccountTemplateEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    AccountNumber?: string;
    ReceivableAdjustmentGroupCode?: string;
    RequiredAccountTypeCode?: string;
    VatRate?: number;
    ReceivableAdjustmentGroup?: IReceivableAdjustmentGroupEntity;
    RequiredAccountType?: IRequiredAccountTypeEntity;
}

export enum RequiredAccountTemplateEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    AccountNumber = "AccountNumber",
    ReceivableAdjustmentGroupCode = "ReceivableAdjustmentGroupCode",
    RequiredAccountTypeCode = "RequiredAccountTypeCode",
    VatRate = "VatRate",
    ReceivableAdjustmentGroup = "ReceivableAdjustmentGroup",
    RequiredAccountType = "RequiredAccountType",
}

export interface IRequiredAccountTypeEntity extends IEntityBase {
    Code?: string;
    Name?: string;
}

export enum RequiredAccountTypeEntity {
    Code = "Code",
    Name = "Name",
}

export interface IRossumJobEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    FileMetadataId?: number;
    NumberOfPages?: number;
    RossumAnnotationId?: string;
    RossumDocumentId?: string;
    RossumStatusCode?: string;
    BackgroundJob?: IBackgroundJobEntity;
    RossumStatus?: IRossumJobStatusEntity;
}

export enum RossumJobEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    FileMetadataId = "FileMetadataId",
    NumberOfPages = "NumberOfPages",
    RossumAnnotationId = "RossumAnnotationId",
    RossumDocumentId = "RossumDocumentId",
    RossumStatusCode = "RossumStatusCode",
    BackgroundJob = "BackgroundJob",
    RossumStatus = "RossumStatus",
}

export interface IRossumJobStatusEntity extends IEntityBase {
    Code?: string;
    Name?: string;
}

export enum RossumJobStatusEntity {
    Code = "Code",
    Name = "Name",
}

export interface IS5InvoiceStateEntity extends IEntityBase {
    Code?: string;
    Name?: string;
}

export enum S5InvoiceStateEntity {
    Code = "Code",
    Name = "Name",
}

export interface ISelectionEntity extends IEntityBase {
    Code?: string;
    Name?: string;
}

export enum SelectionEntity {
    Code = "Code",
    Name = "Name",
}

export interface ISignatoryPersonEntity extends IEntityBase {
    Code?: string;
    Name?: string;
}

export enum SignatoryPersonEntity {
    Code = "Code",
    Name = "Name",
}

export interface ISingleJournalEntryEntity extends IEntityBase {
    NotId?: number;
    Amount?: number;
    CurrencyCode?: string;
    DateAccountingTransaction?: Date;
    Description?: string;
    DocumentTypeCode?: string;
    IsBalancing?: boolean;
    IsClosing?: boolean;
    IsOpening?: boolean;
    JournalEntryId?: number;
    JournalEntryTypeCode?: string;
    TransactionAmount?: number;
    TransactionCurrencyCode?: string;
    Account?: IAccountEntity;
    Company?: ICompanyEntity;
    Currency?: ICurrencyEntity;
    Document?: IDocumentEntity;
    DocumentItem?: IDocumentItemEntity;
    DocumentType?: IDocumentTypeEntity;
    FiscalPeriod?: IFiscalPeriodEntity;
    FiscalYear?: IFiscalYearEntity;
    JournalEntryType?: IJournalEntryTypeEntity;
    PairAccount?: IAccountEntity;
    PaymentDocument?: IPaymentDocumentEntity;
    PaymentDocumentItem?: IPaymentDocumentItemEntity;
    TransactionCurrency?: ICurrencyEntity;
}

export enum SingleJournalEntryEntity {
    NotId = "NotId",
    Amount = "Amount",
    CurrencyCode = "CurrencyCode",
    DateAccountingTransaction = "DateAccountingTransaction",
    Description = "Description",
    DocumentTypeCode = "DocumentTypeCode",
    IsBalancing = "IsBalancing",
    IsClosing = "IsClosing",
    IsOpening = "IsOpening",
    JournalEntryId = "JournalEntryId",
    JournalEntryTypeCode = "JournalEntryTypeCode",
    TransactionAmount = "TransactionAmount",
    TransactionCurrencyCode = "TransactionCurrencyCode",
    Account = "Account",
    Company = "Company",
    Currency = "Currency",
    Document = "Document",
    DocumentItem = "DocumentItem",
    DocumentType = "DocumentType",
    FiscalPeriod = "FiscalPeriod",
    FiscalYear = "FiscalYear",
    JournalEntryType = "JournalEntryType",
    PairAccount = "PairAccount",
    PaymentDocument = "PaymentDocument",
    PaymentDocumentItem = "PaymentDocumentItem",
    TransactionCurrency = "TransactionCurrency",
}

export interface ISplitAccountAssignmentEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    Amount?: number;
    Description?: string;
    TransactionAmount?: number;
    ChartOfAccounts?: IChartOfAccountsEntity;
    CreditAccount?: IAccountEntity;
    DebitAccount?: IAccountEntity;
}

export enum SplitAccountAssignmentEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    Amount = "Amount",
    Description = "Description",
    TransactionAmount = "TransactionAmount",
    ChartOfAccounts = "ChartOfAccounts",
    CreditAccount = "CreditAccount",
    DebitAccount = "DebitAccount",
}

export interface ISplitAccountAssignmentDraftEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    Description?: string;
    Amount?: number;
    TransactionAmount?: number;
    ChartOfAccounts?: IChartOfAccountsEntity;
    CreditAccount?: IAccountEntity;
    DebitAccount?: IAccountEntity;
}

export enum SplitAccountAssignmentDraftEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    Description = "Description",
    Amount = "Amount",
    TransactionAmount = "TransactionAmount",
    ChartOfAccounts = "ChartOfAccounts",
    CreditAccount = "CreditAccount",
    DebitAccount = "DebitAccount",
}

export interface ISubscriptionStatusEntity extends IEntityBase {
    Code?: string;
    Name?: string;
}

export enum SubscriptionStatusEntity {
    Code = "Code",
    Name = "Name",
}

export interface ISubscriptionTypeEntity extends IEntityBase {
    Code?: string;
    Name?: string;
}

export enum SubscriptionTypeEntity {
    Code = "Code",
    Name = "Name",
}

export interface ITaxApplicabilityEntity extends IEntityBase {
    Code?: string;
    Name?: string;
}

export enum TaxApplicabilityEntity {
    Code = "Code",
    Name = "Name",
}

export interface ITaxCalendarEntity extends IEntityBase {
    Id?: number;
    CountryCode?: string;
    Date?: Date;
    Description?: string;
    TaxTypeCode?: string;
    Country?: ICountryEntity;
    TaxType?: ITaxCalendarTaxTypeEntity;
}

export enum TaxCalendarEntity {
    Id = "Id",
    CountryCode = "CountryCode",
    Date = "Date",
    Description = "Description",
    TaxTypeCode = "TaxTypeCode",
    Country = "Country",
    TaxType = "TaxType",
}

export interface ITaxCalendarTaxTypeEntity extends IEntityBase {
    Code?: string;
    Name?: string;
}

export enum TaxCalendarTaxTypeEntity {
    Code = "Code",
    Name = "Name",
}

export interface ITaxDepreciationCategoryEntity extends IEntityBase {
    Code?: string;
    CountryCode?: string;
    DateValidFrom?: Date;
    DateValidTo?: Date;
    Name?: string;
    YearsOfDepreciation?: number;
    Country?: ICountryEntity;
    DepreciationCoefficients?: ITaxDepreciationCoefficientEntity[];
}

export enum TaxDepreciationCategoryEntity {
    Code = "Code",
    CountryCode = "CountryCode",
    DateValidFrom = "DateValidFrom",
    DateValidTo = "DateValidTo",
    Name = "Name",
    YearsOfDepreciation = "YearsOfDepreciation",
    Country = "Country",
    DepreciationCoefficients = "DepreciationCoefficients",
}

export interface ITaxDepreciationCoefficientEntity extends IEntityBase {
    Code?: string;
    DepreciationTypeCode?: string;
    FirstYearRate?: number;
    IncreasedValueRate?: number;
    IsSpecialCalculation?: boolean;
    Name?: string;
    NextYearsRate?: number;
    TaxDepreciationCategoryCode?: string;
    FirstYearValueIncrease?: number;
    DepreciationType?: IDepreciationTypeEntity;
    TaxDepreciationCategory?: ITaxDepreciationCategoryEntity;
}

export enum TaxDepreciationCoefficientEntity {
    Code = "Code",
    DepreciationTypeCode = "DepreciationTypeCode",
    FirstYearRate = "FirstYearRate",
    IncreasedValueRate = "IncreasedValueRate",
    IsSpecialCalculation = "IsSpecialCalculation",
    Name = "Name",
    NextYearsRate = "NextYearsRate",
    TaxDepreciationCategoryCode = "TaxDepreciationCategoryCode",
    FirstYearValueIncrease = "FirstYearValueIncrease",
    DepreciationType = "DepreciationType",
    TaxDepreciationCategory = "TaxDepreciationCategory",
}

export interface ITaxDepreciationPolicyEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    AccumulatedDepreciation?: number;
    CurrencyCode?: string;
    DateFirstDepreciation?: Date;
    DateLastDepreciation?: Date;
    Currency?: ICurrencyEntity;
    CoefficientCode?: string;
    DateInterruptedFrom?: Date;
    ReductionRate?: number;
    Coefficient?: ITaxDepreciationCoefficientEntity;
    Items?: ITaxDepreciationPolicyItemEntity[];
}

export enum TaxDepreciationPolicyEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    AccumulatedDepreciation = "AccumulatedDepreciation",
    CurrencyCode = "CurrencyCode",
    DateFirstDepreciation = "DateFirstDepreciation",
    DateLastDepreciation = "DateLastDepreciation",
    Currency = "Currency",
    CoefficientCode = "CoefficientCode",
    DateInterruptedFrom = "DateInterruptedFrom",
    ReductionRate = "ReductionRate",
    Coefficient = "Coefficient",
    Items = "Items",
}

export interface ITaxDepreciationPolicyItemEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    AccumulatedDepreciation?: number;
    CalculatedExpense?: number;
    CurrencyCode?: string;
    DateDepreciation?: Date;
    EndValue?: number;
    Order?: number;
    Currency?: ICurrencyEntity;
    DateCalculatedFor?: Date;
    DepreciationExpense?: number;
    IsDisposedDepreciation?: boolean;
    IsForReportOnly?: boolean;
    IsWholeYear?: boolean;
    ReductionRate?: number;
}

export enum TaxDepreciationPolicyItemEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    AccumulatedDepreciation = "AccumulatedDepreciation",
    CalculatedExpense = "CalculatedExpense",
    CurrencyCode = "CurrencyCode",
    DateDepreciation = "DateDepreciation",
    EndValue = "EndValue",
    Order = "Order",
    Currency = "Currency",
    DateCalculatedFor = "DateCalculatedFor",
    DepreciationExpense = "DepreciationExpense",
    IsDisposedDepreciation = "IsDisposedDepreciation",
    IsForReportOnly = "IsForReportOnly",
    IsWholeYear = "IsWholeYear",
    ReductionRate = "ReductionRate",
}

export interface ITaxStatementSignatoryPersonEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    DateOfBirth?: Date;
    FirstName?: string;
    LastName?: string;
    LegalNumber?: string;
    LegalPersonName?: string;
    PersonTypeCode?: string;
    RelationshipToTaxSubject?: string;
    SignatoryPersonCode?: string;
    TaxAdvisorRegistrationNumber?: string;
    TaxStatementSubmitterCode?: string;
    PersonType?: ITypeOfBusinessEntity;
    SignatoryPerson?: ISignatoryPersonEntity;
    TaxStatementSubmitter?: ITaxStatementSubmitterEntity;
}

export enum TaxStatementSignatoryPersonEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    DateOfBirth = "DateOfBirth",
    FirstName = "FirstName",
    LastName = "LastName",
    LegalNumber = "LegalNumber",
    LegalPersonName = "LegalPersonName",
    PersonTypeCode = "PersonTypeCode",
    RelationshipToTaxSubject = "RelationshipToTaxSubject",
    SignatoryPersonCode = "SignatoryPersonCode",
    TaxAdvisorRegistrationNumber = "TaxAdvisorRegistrationNumber",
    TaxStatementSubmitterCode = "TaxStatementSubmitterCode",
    PersonType = "PersonType",
    SignatoryPerson = "SignatoryPerson",
    TaxStatementSubmitter = "TaxStatementSubmitter",
}

export interface ITaxStatementSubmitterEntity extends IEntityBase {
    Code?: string;
    Name?: string;
}

export enum TaxStatementSubmitterEntity {
    Code = "Code",
    Name = "Name",
}

export interface ITemporalEntityEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    DateValidFrom?: Date;
    DateValidTo?: Date;
}

export enum TemporalEntityEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    DateValidFrom = "DateValidFrom",
    DateValidTo = "DateValidTo",
}

export interface ITicketEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    Number?: string;
    TicketStatusCode?: string;
    Title?: string;
    TicketStatus?: ITicketStatusEntity;
    Messages?: ITicketMessageEntity[];
}

export enum TicketEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    Number = "Number",
    TicketStatusCode = "TicketStatusCode",
    Title = "Title",
    TicketStatus = "TicketStatus",
    Messages = "Messages",
}

export interface ITicketMessageEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    IsReadByTicketAssignee?: boolean;
    IsReadByTicketAuthor?: boolean;
    Text?: string;
    Attachments?: ITicketMessageAttachmentEntity[];
}

export enum TicketMessageEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    IsReadByTicketAssignee = "IsReadByTicketAssignee",
    IsReadByTicketAuthor = "IsReadByTicketAuthor",
    Text = "Text",
    Attachments = "Attachments",
}

export interface ITicketMessageAttachmentEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    Description?: string;
    File?: IFileMetadataEntity;
}

export enum TicketMessageAttachmentEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    Description = "Description",
    File = "File",
}

export interface ITicketStatusEntity extends IEntityBase {
    Code?: string;
    Name?: string;
}

export enum TicketStatusEntity {
    Code = "Code",
    Name = "Name",
}

export interface ITimeFormatEntity extends IEntityBase {
    Code?: string;
    Format?: string;
    Name?: string;
}

export enum TimeFormatEntity {
    Code = "Code",
    Format = "Format",
    Name = "Name",
}

export interface ITimeZoneEntity extends IEntityBase {
    Code?: string;
    Name?: string;
}

export enum TimeZoneEntity {
    Code = "Code",
    Name = "Name",
}

export interface IPropertyTranslationEntity extends IEntityBase {
    Language?: string;
    Namespace?: string;
    EntityType?: string;
    Property?: string;
    Translation?: string;
}

export enum PropertyTranslationEntity {
    Language = "Language",
    Namespace = "Namespace",
    EntityType = "EntityType",
    Property = "Property",
    Translation = "Translation",
}

export interface ITrustedSenderEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    Email?: string;
}

export enum TrustedSenderEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    Email = "Email",
}

export interface ITypeOfBusinessEntity extends IEntityBase {
    Code?: string;
    Name?: string;
}

export enum TypeOfBusinessEntity {
    Code = "Code",
    Name = "Name",
}

export interface IUndirectedDocumentLinkEntity extends IEntityBase {
    Id?: number;
    CurrencyCode?: string;
    DateAccountingTransaction?: Date;
    DateCbaPayment?: Date;
    PairedDocumentIsTaxDocument?: boolean;
    TransactionCurrencyCode?: string;
    TypeCode?: string;
    Amount?: number;
    TransactionAmount?: number;
    Currency?: ICurrencyEntity;
    Document?: IDocumentEntity;
    PairedDocument?: IDocumentEntity;
    TransactionCurrency?: ICurrencyEntity;
    Type?: IDocumentLinkTypeEntity;
}

export enum UndirectedDocumentLinkEntity {
    Id = "Id",
    CurrencyCode = "CurrencyCode",
    DateAccountingTransaction = "DateAccountingTransaction",
    DateCbaPayment = "DateCbaPayment",
    PairedDocumentIsTaxDocument = "PairedDocumentIsTaxDocument",
    TransactionCurrencyCode = "TransactionCurrencyCode",
    TypeCode = "TypeCode",
    Amount = "Amount",
    TransactionAmount = "TransactionAmount",
    Currency = "Currency",
    Document = "Document",
    PairedDocument = "PairedDocument",
    TransactionCurrency = "TransactionCurrency",
    Type = "Type",
}

export interface IUndirectedPaymentDocumentLinkEntity extends IEntityBase {
    Id?: number;
    CurrencyCode?: string;
    DateAccountingTransaction?: Date;
    DateCbaPayment?: Date;
    PairedDocumentIsTaxDocument?: boolean;
    TransactionCurrencyCode?: string;
    TypeCode?: string;
    Amount?: number;
    TransactionAmount?: number;
    Currency?: ICurrencyEntity;
    Document?: IPaymentDocumentEntity;
    PairedDocument?: IDocumentEntity;
    TransactionCurrency?: ICurrencyEntity;
    Type?: IDocumentLinkTypeEntity;
}

export enum UndirectedPaymentDocumentLinkEntity {
    Id = "Id",
    CurrencyCode = "CurrencyCode",
    DateAccountingTransaction = "DateAccountingTransaction",
    DateCbaPayment = "DateCbaPayment",
    PairedDocumentIsTaxDocument = "PairedDocumentIsTaxDocument",
    TransactionCurrencyCode = "TransactionCurrencyCode",
    TypeCode = "TypeCode",
    Amount = "Amount",
    TransactionAmount = "TransactionAmount",
    Currency = "Currency",
    Document = "Document",
    PairedDocument = "PairedDocument",
    TransactionCurrency = "TransactionCurrency",
    Type = "Type",
}

export interface IUnitOfMeasureEntity extends IEntityBase {
    Code?: string;
    Name?: string;
    ShortName?: string;
}

export enum UnitOfMeasureEntity {
    Code = "Code",
    Name = "Name",
    ShortName = "ShortName",
}

export interface IUnorganizedAssetEntity extends IEntityBase {
    Id?: number;
    Amount?: number;
    AssetTypeCode?: string;
    CurrencyCode?: string;
    EntryDate?: Date;
    AssetType?: IAssetTypeEntity;
    Currency?: ICurrencyEntity;
    Document?: IDocumentEntity;
    DocumentItem?: IDocumentItemEntity;
    Labels?: IDocumentItemLabelEntity[];
}

export enum UnorganizedAssetEntity {
    Id = "Id",
    Amount = "Amount",
    AssetTypeCode = "AssetTypeCode",
    CurrencyCode = "CurrencyCode",
    EntryDate = "EntryDate",
    AssetType = "AssetType",
    Currency = "Currency",
    Document = "Document",
    DocumentItem = "DocumentItem",
    Labels = "Labels",
}

export interface IUserEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    ColourThemeCode?: string;
    CustomerSupportPin?: string;
    DateFormatCode?: string;
    Email?: string;
    FirstName?: string;
    LanguageCode?: string;
    LastName?: string;
    Name?: string;
    PhoneNumber?: string;
    StatusCode?: string;
    TimeFormatCode?: string;
    TimeZoneCode?: string;
    CustomerSupportGuid?: string;
    CompanyRoles?: IUserCompanyRoleEntity[];
    ColourTheme?: IColourThemeEntity;
    DateFormat?: IDateFormatEntity;
    Language?: ILanguageEntity;
    NotificationSetting?: INotificationSettingEntity;
    Status?: IUserStatusEntity;
    TimeFormat?: ITimeFormatEntity;
    TimeZone?: ITimeZoneEntity;
    GeneralRoles?: IUserGeneralRoleEntity[];
}

export enum UserEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    ColourThemeCode = "ColourThemeCode",
    CustomerSupportPin = "CustomerSupportPin",
    DateFormatCode = "DateFormatCode",
    Email = "Email",
    FirstName = "FirstName",
    LanguageCode = "LanguageCode",
    LastName = "LastName",
    Name = "Name",
    PhoneNumber = "PhoneNumber",
    StatusCode = "StatusCode",
    TimeFormatCode = "TimeFormatCode",
    TimeZoneCode = "TimeZoneCode",
    CustomerSupportGuid = "CustomerSupportGuid",
    CompanyRoles = "CompanyRoles",
    ColourTheme = "ColourTheme",
    DateFormat = "DateFormat",
    Language = "Language",
    NotificationSetting = "NotificationSetting",
    Status = "Status",
    TimeFormat = "TimeFormat",
    TimeZone = "TimeZone",
    GeneralRoles = "GeneralRoles",
}

export interface IUserCompanyRoleEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    Company?: ICompanyEntity;
    CompanyRole?: ICompanyRoleEntity;
}

export enum UserCompanyRoleEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    Company = "Company",
    CompanyRole = "CompanyRole",
}

export interface IUserGeneralRoleEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    GeneralRole?: IGeneralRoleEntity;
}

export enum UserGeneralRoleEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    GeneralRole = "GeneralRole",
}

export interface IUserNotificationEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    IsRead?: boolean;
    Notification?: INotificationEntity;
    User?: IUserEntity;
}

export enum UserNotificationEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    IsRead = "IsRead",
    Notification = "Notification",
    User = "User",
}

export interface IUserStatusEntity extends IEntityBase {
    Code?: string;
    Name?: string;
}

export enum UserStatusEntity {
    Code = "Code",
    Name = "Name",
}

export interface IVariantEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    AccessTypeCode?: string;
    AccountingCode?: string;
    DefaultValues?: string;
    EntityTypeCode?: string;
    Name?: string;
    VariantData?: string;
    VariantTypeCode?: string;
    VatStatusCode?: string;
    ViewId?: string;
    AccessType?: IVariantAccessTypeEntity;
    Accounting?: IAccountingEntity;
    EntityType?: IEntityTypeEntity;
    Owner?: IUserEntity;
    VariantType?: IVariantTypeEntity;
    VatStatus?: IVatStatusEntity;
}

export enum VariantEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    AccessTypeCode = "AccessTypeCode",
    AccountingCode = "AccountingCode",
    DefaultValues = "DefaultValues",
    EntityTypeCode = "EntityTypeCode",
    Name = "Name",
    VariantData = "VariantData",
    VariantTypeCode = "VariantTypeCode",
    VatStatusCode = "VatStatusCode",
    ViewId = "ViewId",
    AccessType = "AccessType",
    Accounting = "Accounting",
    EntityType = "EntityType",
    Owner = "Owner",
    VariantType = "VariantType",
    VatStatus = "VatStatus",
}

export interface IVariantAccessTypeEntity extends IEntityBase {
    Code?: string;
    Name?: string;
}

export enum VariantAccessTypeEntity {
    Code = "Code",
    Name = "Name",
}

export interface IVariantTypeEntity extends IEntityBase {
    Code?: string;
    Name?: string;
}

export enum VariantTypeEntity {
    Code = "Code",
    Name = "Name",
}

export interface IVatEntity extends IEntityBase {
    Code?: string;
    CountryCode?: string;
    DateValidFrom?: Date;
    DateValidTo?: Date;
    Name?: string;
    Rate?: number;
    RateName?: string;
    VatRateTypeCode?: string;
    Country?: ICountryEntity;
    VatRateType?: IVatRateTypeEntity;
}

export enum VatEntity {
    Code = "Code",
    CountryCode = "CountryCode",
    DateValidFrom = "DateValidFrom",
    DateValidTo = "DateValidTo",
    Name = "Name",
    Rate = "Rate",
    RateName = "RateName",
    VatRateTypeCode = "VatRateTypeCode",
    Country = "Country",
    VatRateType = "VatRateType",
}

export interface IVatClassificationEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    CountryClassificationCode?: string;
    IsInAutomatedVatInclusionMode?: boolean;
    IsVatRegistered?: boolean;
    Name?: string;
    NonTaxAccountName?: string;
    NonTaxAccountNumber?: string;
    Note?: string;
    PayableReceivableTypeCode?: string;
    VatControlStatementSectionCode?: string;
    VatDeductionTypeCode?: string;
    VatOssCode?: string;
    VatReverseChargeCode?: string;
    VatStatementSectionCode?: string;
    VatViesCode?: string;
    VatProportionalDeduction?: number;
    CountryClassification?: ICountryClassificationEntity;
    PayableReceivableType?: IPayableReceivableTypeEntity;
    VatControlStatementSection?: IVatControlStatementSectionEntity;
    VatDeductionType?: IVatDeductionTypeEntity;
    VatOss?: IVatOssEntity;
    VatReverseCharge?: IVatReverseChargeEntity;
    VatStatementSection?: IVatStatementSectionEntity;
    VatVies?: IVatViesEntity;
}

export enum VatClassificationEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    CountryClassificationCode = "CountryClassificationCode",
    IsInAutomatedVatInclusionMode = "IsInAutomatedVatInclusionMode",
    IsVatRegistered = "IsVatRegistered",
    Name = "Name",
    NonTaxAccountName = "NonTaxAccountName",
    NonTaxAccountNumber = "NonTaxAccountNumber",
    Note = "Note",
    PayableReceivableTypeCode = "PayableReceivableTypeCode",
    VatControlStatementSectionCode = "VatControlStatementSectionCode",
    VatDeductionTypeCode = "VatDeductionTypeCode",
    VatOssCode = "VatOssCode",
    VatReverseChargeCode = "VatReverseChargeCode",
    VatStatementSectionCode = "VatStatementSectionCode",
    VatViesCode = "VatViesCode",
    VatProportionalDeduction = "VatProportionalDeduction",
    CountryClassification = "CountryClassification",
    PayableReceivableType = "PayableReceivableType",
    VatControlStatementSection = "VatControlStatementSection",
    VatDeductionType = "VatDeductionType",
    VatOss = "VatOss",
    VatReverseCharge = "VatReverseCharge",
    VatStatementSection = "VatStatementSection",
    VatVies = "VatVies",
}

export interface IVatClassificationSelectionEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    SelectionCode?: string;
    Selection?: ISelectionEntity;
}

export enum VatClassificationSelectionEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    SelectionCode = "SelectionCode",
    Selection = "Selection",
}

export interface IVatClassificationSelectionDraftEntity extends IEntityBase {
    Id?: number;
    DateCreated?: Date;
    DateLastModified?: Date;
    TemporaryGuid?: string;
    CreatedBy?: IUserEntity;
    LastModifiedBy?: IUserEntity;
    SelectionCode?: string;
    Selection?: ISelectionEntity;
}

export enum VatClassificationSelectionDraftEntity {
    Id = "Id",
    DateCreated = "DateCreated",
    DateLastModified = "DateLastModified",
    TemporaryGuid = "TemporaryGuid",
    CreatedBy = "CreatedBy",
    LastModifiedBy = "LastModifiedBy",
    SelectionCode = "SelectionCode",
    Selection = "Selection",
}

export interface IVatControlStatementEntity extends IEntityBase {
    Code?: string;
    CountryCode?: string;
    DateValidFrom?: Date;
    DateValidTo?: Date;
    Name?: string;
    Country?: ICountryEntity;
}

export enum VatControlStatementEntity {
    Code = "Code",
    CountryCode = "CountryCode",
    DateValidFrom = "DateValidFrom",
    DateValidTo = "DateValidTo",
    Name = "Name",
    Country = "Country",
}

export interface IVatControlStatementSectionEntity extends IEntityBase {
    Code?: string;
    Description?: string;
    Name?: string;
    PayableReceivableTypeCode?: string;
    SecondaryStatementSection?: string;
    StatementSection?: string;
    VatControlStatementCode?: string;
    PayableReceivableType?: IPayableReceivableTypeEntity;
    VatControlStatement?: IVatControlStatementEntity;
}

export enum VatControlStatementSectionEntity {
    Code = "Code",
    Description = "Description",
    Name = "Name",
    PayableReceivableTypeCode = "PayableReceivableTypeCode",
    SecondaryStatementSection = "SecondaryStatementSection",
    StatementSection = "StatementSection",
    VatControlStatementCode = "VatControlStatementCode",
    PayableReceivableType = "PayableReceivableType",
    VatControlStatement = "VatControlStatement",
}

export interface IVatDeductionTypeEntity extends IEntityBase {
    Code?: string;
    Name?: string;
}

export enum VatDeductionTypeEntity {
    Code = "Code",
    Name = "Name",
}

export interface IVatOssEntity extends IEntityBase {
    Code?: string;
    Name?: string;
}

export enum VatOssEntity {
    Code = "Code",
    Name = "Name",
}

export interface IVatRateTypeEntity extends IEntityBase {
    Code?: string;
    Name?: string;
}

export enum VatRateTypeEntity {
    Code = "Code",
    Name = "Name",
}

export interface IVatReverseChargeEntity extends IEntityBase {
    Code?: string;
    Name?: string;
}

export enum VatReverseChargeEntity {
    Code = "Code",
    Name = "Name",
}

export interface IVatStatementEntity extends IEntityBase {
    Code?: string;
    CountryCode?: string;
    DateValidFrom?: Date;
    DateValidTo?: Date;
    Name?: string;
    Country?: ICountryEntity;
}

export enum VatStatementEntity {
    Code = "Code",
    CountryCode = "CountryCode",
    DateValidFrom = "DateValidFrom",
    DateValidTo = "DateValidTo",
    Name = "Name",
    Country = "Country",
}

export interface IVatStatementDocumentEntity extends IEntityBase {
    Id?: number;
    Amount?: number;
    CurrencyCode?: string;
    DateAccountingTransaction?: Date;
    DateCreated?: Date;
    DateElectronicSubmissionVatControlStatement?: Date;
    DateElectronicSubmissionVatStatement?: Date;
    DateElectronicSubmissionVatViesStatement?: Date;
    DocumentStatusCode?: string;
    DocumentTypeCode?: string;
    NumberOurs?: string;
    NumberTheirs?: string;
    TransactionAmount?: number;
    TransactionAmountDue?: number;
    TransactionCurrencyCode?: string;
    VatControlStatementStatusCode?: string;
    VatStatementStatusCode?: string;
    VatViesStatementStatusCode?: string;
    BusinessPartner?: IDocumentBusinessPartnerEntity;
    Company?: ICompanyEntity;
    CreatedBy?: IUserEntity;
    Currency?: ICurrencyEntity;
    DocumentStatus?: IDocumentStatusEntity;
    DocumentType?: IDocumentTypeEntity;
    TransactionCurrency?: ICurrencyEntity;
    VatControlStatementStatus?: IDocumentStatusEntity;
    VatStatementStatus?: IDocumentStatusEntity;
    VatViesStatementStatus?: IDocumentStatusEntity;
}

export enum VatStatementDocumentEntity {
    Id = "Id",
    Amount = "Amount",
    CurrencyCode = "CurrencyCode",
    DateAccountingTransaction = "DateAccountingTransaction",
    DateCreated = "DateCreated",
    DateElectronicSubmissionVatControlStatement = "DateElectronicSubmissionVatControlStatement",
    DateElectronicSubmissionVatStatement = "DateElectronicSubmissionVatStatement",
    DateElectronicSubmissionVatViesStatement = "DateElectronicSubmissionVatViesStatement",
    DocumentStatusCode = "DocumentStatusCode",
    DocumentTypeCode = "DocumentTypeCode",
    NumberOurs = "NumberOurs",
    NumberTheirs = "NumberTheirs",
    TransactionAmount = "TransactionAmount",
    TransactionAmountDue = "TransactionAmountDue",
    TransactionCurrencyCode = "TransactionCurrencyCode",
    VatControlStatementStatusCode = "VatControlStatementStatusCode",
    VatStatementStatusCode = "VatStatementStatusCode",
    VatViesStatementStatusCode = "VatViesStatementStatusCode",
    BusinessPartner = "BusinessPartner",
    Company = "Company",
    CreatedBy = "CreatedBy",
    Currency = "Currency",
    DocumentStatus = "DocumentStatus",
    DocumentType = "DocumentType",
    TransactionCurrency = "TransactionCurrency",
    VatControlStatementStatus = "VatControlStatementStatus",
    VatStatementStatus = "VatStatementStatus",
    VatViesStatementStatus = "VatViesStatementStatus",
}

export interface IVatStatementFrequencyEntity extends IEntityBase {
    Code?: string;
    Name?: string;
}

export enum VatStatementFrequencyEntity {
    Code = "Code",
    Name = "Name",
}

export interface IVatStatementSectionEntity extends IEntityBase {
    Code?: string;
    Description?: string;
    Name?: string;
    VatStatementCode?: string;
    VatStatementSectionCategoryCode?: string;
    StandardVatRateRow?: number;
    ReducedVatRateRow?: number;
    AdditionalStandardVatRateRow?: number;
    AdditionalReducedVatRateRow?: number;
    VatStatement?: IVatStatementEntity;
    VatStatementSectionCategory?: IVatStatementSectionCategoryEntity;
}

export enum VatStatementSectionEntity {
    Code = "Code",
    Description = "Description",
    Name = "Name",
    VatStatementCode = "VatStatementCode",
    VatStatementSectionCategoryCode = "VatStatementSectionCategoryCode",
    StandardVatRateRow = "StandardVatRateRow",
    ReducedVatRateRow = "ReducedVatRateRow",
    AdditionalStandardVatRateRow = "AdditionalStandardVatRateRow",
    AdditionalReducedVatRateRow = "AdditionalReducedVatRateRow",
    VatStatement = "VatStatement",
    VatStatementSectionCategory = "VatStatementSectionCategory",
}

export interface IVatStatementSectionCategoryEntity extends IEntityBase {
    Code?: string;
    Name?: string;
}

export enum VatStatementSectionCategoryEntity {
    Code = "Code",
    Name = "Name",
}

export interface IVatStatementStatusEntity extends IEntityBase {
    Code?: string;
    Name?: string;
}

export enum VatStatementStatusEntity {
    Code = "Code",
    Name = "Name",
}

export interface IVatStatusEntity extends IEntityBase {
    Code?: string;
    Name?: string;
}

export enum VatStatusEntity {
    Code = "Code",
    Name = "Name",
}

export interface IVatViesEntity extends IEntityBase {
    Code?: string;
    Description?: string;
    Name?: string;
}

export enum VatViesEntity {
    Code = "Code",
    Description = "Description",
    Name = "Name",
}

export interface IWebSocketMessageTypeEntity extends IEntityBase {
    Code?: string;
    Name?: string;
}

export enum WebSocketMessageTypeEntity {
    Code = "Code",
    Name = "Name",
}

export interface IWriteOffTypeEntity extends IEntityBase {
    Code?: string;
    Name?: string;
}

export enum WriteOffTypeEntity {
    Code = "Code",
    Name = "Name",
}

export enum EntityTypeName {
    CentralAcceptableUserPolicyItem = "AcceptableUserPolicyItem",
    CentralArchivedCompanyItem = "ArchivedCompanyItem",
    CentralBillingAddress = "BillingAddress",
    CentralBillingContact = "BillingContact",
    CentralBillingItem = "BillingItem",
    CentralCompanyItem = "CompanyItem",
    CentralDatabase = "Database",
    CentralDiscountItem = "DiscountItem",
    CentralEnabledFeature = "EnabledFeature",
    CentralInvitation = "Invitation",
    CentralInvoice = "Invoice",
    CentralInvoiceItem = "InvoiceItem",
    CentralModuleItem = "ModuleItem",
    CentralModulePrice = "ModulePrice",
    CentralProductPrice = "ProductPrice",
    CentralSubscription = "Subscription",
    CentralTenant = "Tenant",
    CentralUserItem = "UserItem",
    Account = "Account",
    AccountAssignment = "AccountAssignment",
    AccountAssignmentDocumentType = "AccountAssignmentDocumentType",
    AccountAssignmentDocumentTypeTemplate = "AccountAssignmentDocumentTypeTemplate",
    AccountAssignmentSelection = "AccountAssignmentSelection",
    AccountAssignmentSelectionDraft = "AccountAssignmentSelectionDraft",
    AccountAssignmentTemplate = "AccountAssignmentTemplate",
    AccountCategory = "AccountCategory",
    AccountRole = "AccountRole",
    AccountTemplate = "AccountTemplate",
    AccountType = "AccountType",
    Accounting = "Accounting",
    AccountingDepreciationPolicy = "AccountingDepreciationPolicy",
    AccountingDepreciationPolicyItem = "AccountingDepreciationPolicyItem",
    AccruedProcessingType = "AccruedProcessingType",
    ActionLog = "ActionLog",
    ActionType = "ActionType",
    Address = "Address",
    ApprovalStatusType = "ApprovalStatusType",
    Asset = "Asset",
    AssetAttachment = "AssetAttachment",
    AssetDisposalType = "AssetDisposalType",
    AssetItem = "AssetItem",
    AssetItemType = "AssetItemType",
    AssetLabel = "AssetLabel",
    AssetStatus = "AssetStatus",
    AssetType = "AssetType",
    AssetValue = "AssetValue",
    BackgroundJob = "BackgroundJob",
    BackgroundJobStatus = "BackgroundJobStatus",
    BackgroundJobType = "BackgroundJobType",
    BalanceSheetLayout = "BalanceSheetLayout",
    BalanceSheetLayoutTemplate = "BalanceSheetLayoutTemplate",
    Bank = "Bank",
    BankAccount = "BankAccount",
    BankAccountNumberFormatType = "BankAccountNumberFormatType",
    BankApiConnectionSetting = "BankApiConnectionSetting",
    BankApiStatementImportFrequency = "BankApiStatementImportFrequency",
    BankApiStatementImportRange = "BankApiStatementImportRange",
    BankApiStatementImportSetting = "BankApiStatementImportSetting",
    BankExchangeRateFreshness = "BankExchangeRateFreshness",
    BankPaymentOrderState = "BankPaymentOrderState",
    BankStatement = "BankStatement",
    BankStatementAttachment = "BankStatementAttachment",
    BankStatementAttachmentDraft = "BankStatementAttachmentDraft",
    BankStatementCsvSettingsText = "BankStatementCsvSettingsText",
    BankStatementDraft = "BankStatementDraft",
    BankStatementLock = "BankStatementLock",
    BankTransaction = "BankTransaction",
    BankTransactionBankAccount = "BankTransactionBankAccount",
    BankTransactionBankAccountDraft = "BankTransactionBankAccountDraft",
    BankTransactionBusinessPartner = "BankTransactionBusinessPartner",
    BankTransactionBusinessPartnerDraft = "BankTransactionBusinessPartnerDraft",
    BankTransactionCsvColumnMapping = "BankTransactionCsvColumnMapping",
    BankTransactionDocumentNumberLocation = "BankTransactionDocumentNumberLocation",
    BankTransactionDuplicate = "BankTransactionDuplicate",
    BankTransactionType = "BankTransactionType",
    BillingEvent = "BillingEvent",
    BillingItemType = "BillingItemType",
    BillingPeriod = "BillingPeriod",
    Bookkeeping = "Bookkeeping",
    BusinessPartner = "BusinessPartner",
    BusinessPartnerAttachment = "BusinessPartnerAttachment",
    BusinessPartnerBankAccount = "BusinessPartnerBankAccount",
    BusinessPartnerBillingAddress = "BusinessPartnerBillingAddress",
    BusinessPartnerContact = "BusinessPartnerContact",
    BusinessPartnerCorrespondenceAddress = "BusinessPartnerCorrespondenceAddress",
    BusinessPartnerDocumentDefault = "BusinessPartnerDocumentDefault",
    BusinessPartnerDocumentDefaultType = "BusinessPartnerDocumentDefaultType",
    BusinessPartnerIssuedDocumentDefault = "BusinessPartnerIssuedDocumentDefault",
    BusinessPartnerReceivedDocumentDefault = "BusinessPartnerReceivedDocumentDefault",
    CBATransactionType = "CBATransactionType",
    CashBox = "CashBox",
    CashReceipt = "CashReceipt",
    CashReceiptIssued = "CashReceiptIssued",
    CashReceiptReceived = "CashReceiptReceived",
    CbaAssetDisposalType = "CbaAssetDisposalType",
    CbaCategory = "CbaCategory",
    CbaCategoryTaxImpact = "CbaCategoryTaxImpact",
    CbaEntry = "CbaEntry",
    CbaEntryLabel = "CbaEntryLabel",
    CbaEntryLedgerDocumentType = "CbaEntryLedgerDocumentType",
    CbaEntrySpecialVatCalculation = "CbaEntrySpecialVatCalculation",
    CbaEntryType = "CbaEntryType",
    ChartOfAccounts = "ChartOfAccounts",
    ChartOfAccountsTemplate = "ChartOfAccountsTemplate",
    ChartedAccountAssignment = "ChartedAccountAssignment",
    ClearedStatus = "ClearedStatus",
    ClearingDocumentLink = "ClearingDocumentLink",
    ColourTheme = "ColourTheme",
    CommunicationEntry = "CommunicationEntry",
    CommunicationEntryAttachment = "CommunicationEntryAttachment",
    CommunicationEntryChannel = "CommunicationEntryChannel",
    CommunicationEntryState = "CommunicationEntryState",
    CommunicationEntryType = "CommunicationEntryType",
    CommunicationType = "CommunicationType",
    Company = "Company",
    CompanyBankAccount = "CompanyBankAccount",
    CompanyBankAccountType = "CompanyBankAccountType",
    CompanyCommunicationAddress = "CompanyCommunicationAddress",
    CompanyCommunicationContact = "CompanyCommunicationContact",
    CompanyFixedExchangeRate = "CompanyFixedExchangeRate",
    CompanyLegalAddress = "CompanyLegalAddress",
    CompanyPermission = "CompanyPermission",
    CompanyRole = "CompanyRole",
    CompanyRolePermission = "CompanyRolePermission",
    CompanyRoleType = "CompanyRoleType",
    CompanySetting = "CompanySetting",
    CompanyState = "CompanyState",
    CompanyVatReducedCoefficient = "CompanyVatReducedCoefficient",
    CompanyVatStatus = "CompanyVatStatus",
    CompoundJournalEntry = "CompoundJournalEntry",
    Contact = "Contact",
    CorrectiveInvoiceIssued = "CorrectiveInvoiceIssued",
    CorrectiveInvoiceReceived = "CorrectiveInvoiceReceived",
    Country = "Country",
    CountryClassification = "CountryClassification",
    CountryOrUnion = "CountryOrUnion",
    Currency = "Currency",
    CurrencyUsedByCompany = "CurrencyUsedByCompany",
    CurrencyWithExchangeRateInfo = "CurrencyWithExchangeRateInfo",
    CustomerPortalPayment = "CustomerPortalPayment",
    CzNace = "CzNace",
    DashboardTask = "DashboardTask",
    DataBox = "DataBox",
    DataLimit = "DataLimit",
    DataLimitType = "DataLimitType",
    DateFormat = "DateFormat",
    DayOfWeek = "DayOfWeek",
    DefaultVariant = "DefaultVariant",
    DeferredDocument = "DeferredDocument",
    DeferredPlan = "DeferredPlan",
    DeferredPlanDocument = "DeferredPlanDocument",
    DeferredPlanDocumentItem = "DeferredPlanDocumentItem",
    DeferredPlanOriginalDocumentItem = "DeferredPlanOriginalDocumentItem",
    DeferredPlanPostingType = "DeferredPlanPostingType",
    DepreciationPolicy = "DepreciationPolicy",
    DepreciationPolicyItem = "DepreciationPolicyItem",
    DepreciationPolicyType = "DepreciationPolicyType",
    DepreciationType = "DepreciationType",
    DiscountType = "DiscountType",
    Document = "Document",
    DocumentAccountAssignment = "DocumentAccountAssignment",
    DocumentAccountAssignmentDraft = "DocumentAccountAssignmentDraft",
    DocumentAccountAssignmentSelection = "DocumentAccountAssignmentSelection",
    DocumentAccountAssignmentSelectionDraft = "DocumentAccountAssignmentSelectionDraft",
    DocumentAssetEntry = "DocumentAssetEntry",
    DocumentAttachment = "DocumentAttachment",
    DocumentAttachmentDeleted = "DocumentAttachmentDeleted",
    DocumentAttachmentDraft = "DocumentAttachmentDraft",
    DocumentBankAccount = "DocumentBankAccount",
    DocumentBankAccountDraft = "DocumentBankAccountDraft",
    DocumentBusinessPartner = "DocumentBusinessPartner",
    DocumentBusinessPartnerDraft = "DocumentBusinessPartnerDraft",
    DocumentCbaCategory = "DocumentCbaCategory",
    DocumentCbaCategoryDraft = "DocumentCbaCategoryDraft",
    DocumentDeleteLog = "DocumentDeleteLog",
    DocumentDraft = "DocumentDraft",
    DocumentImportType = "DocumentImportType",
    DocumentItem = "DocumentItem",
    DocumentItemAccountAssignment = "DocumentItemAccountAssignment",
    DocumentItemAccountAssignmentDraft = "DocumentItemAccountAssignmentDraft",
    DocumentItemAccountAssignmentSelection = "DocumentItemAccountAssignmentSelection",
    DocumentItemAccountAssignmentSelectionDraft = "DocumentItemAccountAssignmentSelectionDraft",
    DocumentItemCbaCategory = "DocumentItemCbaCategory",
    DocumentItemCbaCategoryDraft = "DocumentItemCbaCategoryDraft",
    DocumentItemDraft = "DocumentItemDraft",
    DocumentItemLabel = "DocumentItemLabel",
    DocumentItemLabelDraft = "DocumentItemLabelDraft",
    DocumentItemType = "DocumentItemType",
    DocumentItemVatClassification = "DocumentItemVatClassification",
    DocumentItemVatClassificationDraft = "DocumentItemVatClassificationDraft",
    DocumentItemVatClassificationSelection = "DocumentItemVatClassificationSelection",
    DocumentItemVatClassificationSelectionDraft = "DocumentItemVatClassificationSelectionDraft",
    DocumentItemView = "DocumentItemView",
    DocumentLabel = "DocumentLabel",
    DocumentLabelDraft = "DocumentLabelDraft",
    DocumentLink = "DocumentLink",
    DocumentLinkClearingType = "DocumentLinkClearingType",
    DocumentLinkDraft = "DocumentLinkDraft",
    DocumentLinkType = "DocumentLinkType",
    DocumentLock = "DocumentLock",
    DocumentLockDraft = "DocumentLockDraft",
    DocumentMinorAssetItem = "DocumentMinorAssetItem",
    DocumentStatus = "DocumentStatus",
    DocumentType = "DocumentType",
    DocumentVatClassification = "DocumentVatClassification",
    DocumentVatClassificationDraft = "DocumentVatClassificationDraft",
    DocumentVatClassificationSelection = "DocumentVatClassificationSelection",
    DocumentVatClassificationSelectionDraft = "DocumentVatClassificationSelectionDraft",
    ElectronicSubmission = "ElectronicSubmission",
    ElectronicSubmissionType = "ElectronicSubmissionType",
    Entity = "Entity",
    EntityAccountAssignment = "EntityAccountAssignment",
    EntityAccountAssignmentDraft = "EntityAccountAssignmentDraft",
    EntityAttachment = "EntityAttachment",
    EntityBankAccount = "EntityBankAccount",
    EntityBankAccountDraft = "EntityBankAccountDraft",
    EntityBusinessPartner = "EntityBusinessPartner",
    EntityBusinessPartnerDraft = "EntityBusinessPartnerDraft",
    EntityCbaCategory = "EntityCbaCategory",
    EntityCbaCategoryDraft = "EntityCbaCategoryDraft",
    EntityLabel = "EntityLabel",
    EntityLock = "EntityLock",
    EntityPermissionLevel = "EntityPermissionLevel",
    EntityType = "EntityType",
    EntityVatClassification = "EntityVatClassification",
    EntityVatClassificationDraft = "EntityVatClassificationDraft",
    ExchangeRate = "ExchangeRate",
    ExchangeRateType = "ExchangeRateType",
    ExpenseTaxRate = "ExpenseTaxRate",
    ExportLog = "ExportLog",
    ExportRange = "ExportRange",
    ExportResultType = "ExportResultType",
    ExportType = "ExportType",
    ExternalCorrectedDocumentType = "ExternalCorrectedDocumentType",
    Feature = "Feature",
    FileMetadata = "FileMetadata",
    FinancialReportLayout = "FinancialReportLayout",
    FinancialReportLayoutSection = "FinancialReportLayoutSection",
    FiscalPeriod = "FiscalPeriod",
    FiscalPeriodUsage = "FiscalPeriodUsage",
    FiscalYear = "FiscalYear",
    FiscalYearClose = "FiscalYearClose",
    FiscalYearCloseAdvanceTax = "FiscalYearCloseAdvanceTax",
    FiscalYearCloseAttachment = "FiscalYearCloseAttachment",
    FiscalYearCloseExchangeRate = "FiscalYearCloseExchangeRate",
    FiscalYearCloseInventory = "FiscalYearCloseInventory",
    FiscalYearCloseSection = "FiscalYearCloseSection",
    FiscalYearCloseSectionType = "FiscalYearCloseSectionType",
    FiscalYearStatus = "FiscalYearStatus",
    FiscalYearUsage = "FiscalYearUsage",
    FlattenAccount = "FlattenAccount",
    FulfilmentType = "FulfilmentType",
    GeneralPermission = "GeneralPermission",
    GeneralRole = "GeneralRole",
    GeneralRolePermission = "GeneralRolePermission",
    GovernmentInstitution = "GovernmentInstitution",
    GovernmentInstitutionBankAccount = "GovernmentInstitutionBankAccount",
    GovernmentInstitutionBankAccountType = "GovernmentInstitutionBankAccountType",
    GovernmentInstitutionType = "GovernmentInstitutionType",
    Hotspot = "Hotspot",
    ImportDocument = "ImportDocument",
    InboxCommunication = "InboxCommunication",
    InboxEntityType = "InboxEntityType",
    InboxFile = "InboxFile",
    InboxFileLog = "InboxFileLog",
    InboxFileSource = "InboxFileSource",
    InboxFileStatus = "InboxFileStatus",
    InboxItemLog = "InboxItemLog",
    InboxItemLogState = "InboxItemLogState",
    IncomeStatementLayout = "IncomeStatementLayout",
    IncomeStatementLayoutTemplate = "IncomeStatementLayoutTemplate",
    InitialAccountBalance = "InitialAccountBalance",
    InteractionType = "InteractionType",
    InternalDocument = "InternalDocument",
    InternalDocumentItem = "InternalDocumentItem",
    InventoryType = "InventoryType",
    InvoiceIssued = "InvoiceIssued",
    InvoiceReceived = "InvoiceReceived",
    IsdocImportDocument = "IsdocImportDocument",
    ItemTemplate = "ItemTemplate",
    ItemTemplateAccountAssignment = "ItemTemplateAccountAssignment",
    ItemTemplateAccountAssignmentSelection = "ItemTemplateAccountAssignmentSelection",
    ItemTemplateCbaTaxImpact = "ItemTemplateCbaTaxImpact",
    ItemTemplateVatClassification = "ItemTemplateVatClassification",
    ItemTemplateVatClassificationSelection = "ItemTemplateVatClassificationSelection",
    JournalEntry = "JournalEntry",
    JournalEntryLabel = "JournalEntryLabel",
    JournalEntryType = "JournalEntryType",
    Label = "Label",
    LabelHierarchy = "LabelHierarchy",
    LabelSelection = "LabelSelection",
    LabelSelectionDraft = "LabelSelectionDraft",
    Language = "Language",
    LegalEntityCategory = "LegalEntityCategory",
    LegislativeFixedExchangeRate = "LegislativeFixedExchangeRate",
    LockType = "LockType",
    MandatoryAccount = "MandatoryAccount",
    MandatoryChartOfAccounts = "MandatoryChartOfAccounts",
    MinorAsset = "MinorAsset",
    MinorAssetAttachment = "MinorAssetAttachment",
    MinorAssetHistoryEntry = "MinorAssetHistoryEntry",
    MinorAssetHistoryItem = "MinorAssetHistoryItem",
    MinorAssetItem = "MinorAssetItem",
    MinorAssetLabel = "MinorAssetLabel",
    MinorAssetStatus = "MinorAssetStatus",
    Module = "Module",
    MoneyS3ImportDocument = "MoneyS3ImportDocument",
    MonthOfYear = "MonthOfYear",
    MultiplePostingDateAccountingType = "MultiplePostingDateAccountingType",
    Notification = "Notification",
    NotificationSetting = "NotificationSetting",
    NotificationType = "NotificationType",
    NotificationTypeGroup = "NotificationTypeGroup",
    NotificationTypeSetting = "NotificationTypeSetting",
    NumberRange = "NumberRange",
    NumberRangeDefinition = "NumberRangeDefinition",
    NumberRangeDefinitionTemplate = "NumberRangeDefinitionTemplate",
    NumberRangeType = "NumberRangeType",
    NumberRangeUsage = "NumberRangeUsage",
    NumberRangeWildcard = "NumberRangeWildcard",
    OppositeDocumentDocumentLink = "OppositeDocumentDocumentLink",
    OppositeDocumentLink = "OppositeDocumentLink",
    OppositePaymentDocumentDocumentLink = "OppositePaymentDocumentDocumentLink",
    Organization = "Organization",
    OtherLiability = "OtherLiability",
    OtherReceivable = "OtherReceivable",
    PayableReceivableType = "PayableReceivableType",
    PayingDocument = "PayingDocument",
    PayingDocumentItemView = "PayingDocumentItemView",
    PaymentDocument = "PaymentDocument",
    PaymentDocumentAttachment = "PaymentDocumentAttachment",
    PaymentDocumentAttachmentDraft = "PaymentDocumentAttachmentDraft",
    PaymentDocumentDraft = "PaymentDocumentDraft",
    PaymentDocumentItem = "PaymentDocumentItem",
    PaymentDocumentItemAccountAssignment = "PaymentDocumentItemAccountAssignment",
    PaymentDocumentItemAccountAssignmentDraft = "PaymentDocumentItemAccountAssignmentDraft",
    PaymentDocumentItemAccountAssignmentSelection = "PaymentDocumentItemAccountAssignmentSelection",
    PaymentDocumentItemAccountAssignmentSelectionDraft = "PaymentDocumentItemAccountAssignmentSelectionDraft",
    PaymentDocumentItemCbaCategory = "PaymentDocumentItemCbaCategory",
    PaymentDocumentItemCbaCategoryDraft = "PaymentDocumentItemCbaCategoryDraft",
    PaymentDocumentItemDraft = "PaymentDocumentItemDraft",
    PaymentDocumentItemType = "PaymentDocumentItemType",
    PaymentDocumentLock = "PaymentDocumentLock",
    PaymentDocumentLockDraft = "PaymentDocumentLockDraft",
    BankAccountBalance = "BankAccountBalance",
    CashBoxBalance = "CashBoxBalance",
    PaymentMethod = "PaymentMethod",
    PaymentOrderFormat = "PaymentOrderFormat",
    PaymentOrderOutput = "PaymentOrderOutput",
    PaymentStatus = "PaymentStatus",
    PeriodDay = "PeriodDay",
    PohodaColumnPreviewValue = "PohodaColumnPreviewValue",
    PohodaDocument = "PohodaDocument",
    PohodaEnumMapping = "PohodaEnumMapping",
    PohodaImport = "PohodaImport",
    PohodaImportDocument = "PohodaImportDocument",
    PohodaImportProgress = "PohodaImportProgress",
    PohodaMapping = "PohodaMapping",
    PostedStatus = "PostedStatus",
    PostingRule = "PostingRule",
    PostingRuleDocumentType = "PostingRuleDocumentType",
    PrAbsenceCategory = "PrAbsenceCategory",
    PrAbsenceCategoryLegislativeType = "PrAbsenceCategoryLegislativeType",
    PrAbsenceCategoryTemporal = "PrAbsenceCategoryTemporal",
    PrAbsenceCategoryTemporalCurrent = "PrAbsenceCategoryTemporalCurrent",
    PrAttendance = "PrAttendance",
    PrAttendanceDay = "PrAttendanceDay",
    PrAttendanceDayInterval = "PrAttendanceDayInterval",
    PrAttendanceStatus = "PrAttendanceStatus",
    PrAttendanceWithWorkingPattern = "PrAttendanceWithWorkingPattern",
    PrBaseType = "PrBaseType",
    PrChildSupportDeductionType = "PrChildSupportDeductionType",
    PrCzechReductionLimit = "PrCzechReductionLimit",
    PrDeduction = "PrDeduction",
    PrDeductionBankAccount = "PrDeductionBankAccount",
    PrDeductionBusinessPartner = "PrDeductionBusinessPartner",
    PrDeductionCalculationType = "PrDeductionCalculationType",
    PrDeductionEntityType = "PrDeductionEntityType",
    PrDeductionStatus = "PrDeductionStatus",
    PrDeductionTemporal = "PrDeductionTemporal",
    PrDeductionTemporalCurrent = "PrDeductionTemporalCurrent",
    PrDeductionType = "PrDeductionType",
    PrDisabilityType = "PrDisabilityType",
    PrDistrainmentDeductionType = "PrDistrainmentDeductionType",
    PrEmployee = "PrEmployee",
    PrEmployeeBankAccount = "PrEmployeeBankAccount",
    PrEmployeeCar = "PrEmployeeCar",
    PrEmployeeContactAddress = "PrEmployeeContactAddress",
    PrEmployeeDependentPerson = "PrEmployeeDependentPerson",
    PrEmployeeForeignAddress = "PrEmployeeForeignAddress",
    PrEmployeeLabel = "PrEmployeeLabel",
    PrEmployeeLocalAddress = "PrEmployeeLocalAddress",
    PrEmployeeState = "PrEmployeeState",
    PrEmployeeTemporal = "PrEmployeeTemporal",
    PrEmployeeTemporalCurrent = "PrEmployeeTemporalCurrent",
    PrEmployment = "PrEmployment",
    PrEmploymentExtraSalary = "PrEmploymentExtraSalary",
    PrEmploymentExtraSalaryTemporal = "PrEmploymentExtraSalaryTemporal",
    PrEmploymentExtraSalaryTemporalCurrent = "PrEmploymentExtraSalaryTemporalCurrent",
    PrEmploymentSalaryComponent = "PrEmploymentSalaryComponent",
    PrEmploymentSalaryComponentTemporal = "PrEmploymentSalaryComponentTemporal",
    PrEmploymentSalaryComponentTemporalCurrent = "PrEmploymentSalaryComponentTemporalCurrent",
    PrEmploymentTemplate = "PrEmploymentTemplate",
    PrEmploymentTemplateExtraSalary = "PrEmploymentTemplateExtraSalary",
    PrEmploymentTemplateExtraSalaryTemporal = "PrEmploymentTemplateExtraSalaryTemporal",
    PrEmploymentTemplateExtraSalaryTemporalCurrent = "PrEmploymentTemplateExtraSalaryTemporalCurrent",
    PrEmploymentTemplateSalaryComponent = "PrEmploymentTemplateSalaryComponent",
    PrEmploymentTemplateSalaryComponentTemporal = "PrEmploymentTemplateSalaryComponentTemporal",
    PrEmploymentTemplateSalaryComponentTemporalCurrent = "PrEmploymentTemplateSalaryComponentTemporalCurrent",
    PrEmploymentTemplateTemporal = "PrEmploymentTemplateTemporal",
    PrEmploymentTemplateTemporalCurrent = "PrEmploymentTemplateTemporalCurrent",
    PrEmploymentTemporal = "PrEmploymentTemporal",
    PrEmploymentTemporalCurrent = "PrEmploymentTemporalCurrent",
    PrEmploymentType = "PrEmploymentType",
    PrEntityValueSource = "PrEntityValueSource",
    PrExtraSalary = "PrExtraSalary",
    PrExtraSalaryComputationType = "PrExtraSalaryComputationType",
    PrExtraSalaryLegislativeValue = "PrExtraSalaryLegislativeValue",
    PrExtraSalaryTemporal = "PrExtraSalaryTemporal",
    PrExtraSalaryTemporalCurrent = "PrExtraSalaryTemporalCurrent",
    PrExtraSalaryType = "PrExtraSalaryType",
    PrFormOfStudyType = "PrFormOfStudyType",
    PrGovernmentInstitutionBankAccountDetail = "PrGovernmentInstitutionBankAccountDetail",
    PrGovernmentInstitutionDetail = "PrGovernmentInstitutionDetail",
    PrGroupDeduction = "PrGroupDeduction",
    PrGroupDeductionEmployee = "PrGroupDeductionEmployee",
    PrGuaranteedSalaryDegree = "PrGuaranteedSalaryDegree",
    PrHealthInsuranceCompany = "PrHealthInsuranceCompany",
    PrIdentityDocumentType = "PrIdentityDocumentType",
    PrIndividualDeduction = "PrIndividualDeduction",
    PrIndividualDeductionDebtPayment = "PrIndividualDeductionDebtPayment",
    PrInsuranceBankAccountPredefined = "PrInsuranceBankAccountPredefined",
    PrInsuranceBankAccountType = "PrInsuranceBankAccountType",
    PrInsuranceCompany = "PrInsuranceCompany",
    PrInsuranceCompanyBankAccount = "PrInsuranceCompanyBankAccount",
    PrInsuranceCompanyPredefined = "PrInsuranceCompanyPredefined",
    PrInsuranceCompanyType = "PrInsuranceCompanyType",
    PrLegislativeAverageSalary = "PrLegislativeAverageSalary",
    PrLifeInsuranceCompany = "PrLifeInsuranceCompany",
    PrPayrollComponentCategoryType = "PrPayrollComponentCategoryType",
    PrPayrollComponentType = "PrPayrollComponentType",
    PrPayrollSetting = "PrPayrollSetting",
    PrPayrollSettingTemporal = "PrPayrollSettingTemporal",
    PrPayrollSettingTemporalCurrent = "PrPayrollSettingTemporalCurrent",
    PrPensionInsuranceCompany = "PrPensionInsuranceCompany",
    PrSalaryAdvance = "PrSalaryAdvance",
    PrSalaryComponent = "PrSalaryComponent",
    PrSalaryComponentTemporal = "PrSalaryComponentTemporal",
    PrSalaryComponentTemporalCurrent = "PrSalaryComponentTemporalCurrent",
    PrSalaryComponentType = "PrSalaryComponentType",
    PrSex = "PrSex",
    PrUnseizableAmount = "PrUnseizableAmount",
    PrWorkIntervalType = "PrWorkIntervalType",
    PrWorkingPattern = "PrWorkingPattern",
    PrWorkingPatternDay = "PrWorkingPatternDay",
    PrWorkingPatternDayInterval = "PrWorkingPatternDayInterval",
    PrWorkingPatternType = "PrWorkingPatternType",
    Product = "Product",
    ProductPriceType = "ProductPriceType",
    ProformaInvoiceIssued = "ProformaInvoiceIssued",
    ProformaInvoiceReceived = "ProformaInvoiceReceived",
    PurchaseStatus = "PurchaseStatus",
    ReceivableAdjustmentGroup = "ReceivableAdjustmentGroup",
    ReceivableAdjustmentType = "ReceivableAdjustmentType",
    RecurrentPaymentState = "RecurrentPaymentState",
    RecurringTask = "RecurringTask",
    RecurringTaskExecution = "RecurringTaskExecution",
    RecurringTaskInterval = "RecurringTaskInterval",
    RecurringTaskPeriod = "RecurringTaskPeriod",
    RecurringTaskState = "RecurringTaskState",
    RecurringTaskType = "RecurringTaskType",
    RegularDocumentItem = "RegularDocumentItem",
    ReportSectionType = "ReportSectionType",
    ReportType = "ReportType",
    RequiredAccount = "RequiredAccount",
    RequiredAccountTemplate = "RequiredAccountTemplate",
    RequiredAccountType = "RequiredAccountType",
    RossumJob = "RossumJob",
    RossumJobStatus = "RossumJobStatus",
    S5InvoiceState = "S5InvoiceState",
    Selection = "Selection",
    SignatoryPerson = "SignatoryPerson",
    SingleJournalEntry = "SingleJournalEntry",
    SplitAccountAssignment = "SplitAccountAssignment",
    SplitAccountAssignmentDraft = "SplitAccountAssignmentDraft",
    SubscriptionStatus = "SubscriptionStatus",
    SubscriptionType = "SubscriptionType",
    TaxApplicability = "TaxApplicability",
    TaxCalendar = "TaxCalendar",
    TaxCalendarTaxType = "TaxCalendarTaxType",
    TaxDepreciationCategory = "TaxDepreciationCategory",
    TaxDepreciationCoefficient = "TaxDepreciationCoefficient",
    TaxDepreciationPolicy = "TaxDepreciationPolicy",
    TaxDepreciationPolicyItem = "TaxDepreciationPolicyItem",
    TaxStatementSignatoryPerson = "TaxStatementSignatoryPerson",
    TaxStatementSubmitter = "TaxStatementSubmitter",
    TemporalEntity = "TemporalEntity",
    Ticket = "Ticket",
    TicketMessage = "TicketMessage",
    TicketMessageAttachment = "TicketMessageAttachment",
    TicketStatus = "TicketStatus",
    TimeFormat = "TimeFormat",
    TimeZone = "TimeZone",
    PropertyTranslation = "PropertyTranslation",
    TrustedSender = "TrustedSender",
    TypeOfBusiness = "TypeOfBusiness",
    UndirectedDocumentLink = "UndirectedDocumentLink",
    UndirectedPaymentDocumentLink = "UndirectedPaymentDocumentLink",
    UnitOfMeasure = "UnitOfMeasure",
    UnorganizedAsset = "UnorganizedAsset",
    User = "User",
    UserCompanyRole = "UserCompanyRole",
    UserGeneralRole = "UserGeneralRole",
    UserNotification = "UserNotification",
    UserStatus = "UserStatus",
    Variant = "Variant",
    VariantAccessType = "VariantAccessType",
    VariantType = "VariantType",
    Vat = "Vat",
    VatClassification = "VatClassification",
    VatClassificationSelection = "VatClassificationSelection",
    VatClassificationSelectionDraft = "VatClassificationSelectionDraft",
    VatControlStatement = "VatControlStatement",
    VatControlStatementSection = "VatControlStatementSection",
    VatDeductionType = "VatDeductionType",
    VatOss = "VatOss",
    VatRateType = "VatRateType",
    VatReverseCharge = "VatReverseCharge",
    VatStatement = "VatStatement",
    VatStatementDocument = "VatStatementDocument",
    VatStatementFrequency = "VatStatementFrequency",
    VatStatementSection = "VatStatementSection",
    VatStatementSectionCategory = "VatStatementSectionCategory",
    VatStatementStatus = "VatStatementStatus",
    VatStatus = "VatStatus",
    VatVies = "VatVies",
    WebSocketMessageType = "WebSocketMessageType",
    WriteOffType = "WriteOffType",
}

export enum EntitySetName {
    AccountCategories = "AccountCategories",
    Accountings = "Accountings",
    AccountRoles = "AccountRoles",
    AccountTypes = "AccountTypes",
    AccruedProcessingTypes = "AccruedProcessingTypes",
    ApprovalStatusTypes = "ApprovalStatusTypes",
    AssetDisposalTypes = "AssetDisposalTypes",
    AssetItemTypes = "AssetItemTypes",
    AssetStatuses = "AssetStatuses",
    AssetTypes = "AssetTypes",
    AssetValues = "AssetValues",
    BackgroundJobStatuses = "BackgroundJobStatuses",
    BackgroundJobTypes = "BackgroundJobTypes",
    Banks = "Banks",
    BankAccountNumberFormatTypes = "BankAccountNumberFormatTypes",
    BankApiStatementImportFrequencies = "BankApiStatementImportFrequencies",
    BankApiStatementImportRanges = "BankApiStatementImportRanges",
    BankExchangeRateFreshnesses = "BankExchangeRateFreshnesses",
    BankPaymentOrderStates = "BankPaymentOrderStates",
    BankTransactionDocumentNumberLocations = "BankTransactionDocumentNumberLocations",
    BankTransactionTypes = "BankTransactionTypes",
    BillingItemTypes = "BillingItemTypes",
    BillingPeriods = "BillingPeriods",
    Bookkeepings = "Bookkeepings",
    BusinessPartnerDocumentDefaultTypes = "BusinessPartnerDocumentDefaultTypes",
    CbaAssetDisposalTypes = "CbaAssetDisposalTypes",
    CbaCategoryTaxTypes = "CbaCategoryTaxTypes",
    CbaEntryLedgerDocumentTypes = "CbaEntryLedgerDocumentTypes",
    CbaEntrySpecialVatCalculations = "CbaEntrySpecialVatCalculations",
    CbaEntryTypes = "CbaEntryTypes",
    CBATransactionTypes = "CBATransactionTypes",
    ClearedStatuses = "ClearedStatuses",
    ColourThemes = "ColourThemes",
    CommunicationEntryChannels = "CommunicationEntryChannels",
    CommunicationEntryStates = "CommunicationEntryStates",
    CommunicationEntryTypes = "CommunicationEntryTypes",
    CommunicationTypes = "CommunicationTypes",
    CompanyBankAccountTypes = "CompanyBankAccountTypes",
    CompanyPermissions = "CompanyPermissions",
    CompanyRoleTypes = "CompanyRoleTypes",
    CompanyStates = "CompanyStates",
    Countries = "Countries",
    CountryClassifications = "CountryClassifications",
    CountriesAndUnions = "CountriesAndUnions",
    Currencies = "Currencies",
    CzNaces = "CzNaces",
    DataLimitTypes = "DataLimitTypes",
    DateFormats = "DateFormats",
    DaysOfWeek = "DaysOfWeek",
    DeferredPlanPostingTypes = "DeferredPlanPostingTypes",
    DepreciationPolicyTypes = "DepreciationPolicyTypes",
    DepreciationTypes = "DepreciationTypes",
    DiscountTypes = "DiscountTypes",
    DocumentImportTypes = "DocumentImportTypes",
    DocumentItemTypes = "DocumentItemTypes",
    DocumentLinkClearingTypes = "DocumentLinkClearingTypes",
    DocumentLinkTypes = "DocumentLinkTypes",
    DocumentStatuses = "DocumentStatuses",
    DocumentTypes = "DocumentTypes",
    ElectronicSubmissionTypes = "ElectronicSubmissionTypes",
    EntityPermissionLevels = "EntityPermissionLevels",
    EntityTypes = "EntityTypes",
    ExchangeRateTypes = "ExchangeRateTypes",
    ExpenseTaxRates = "ExpenseTaxRates",
    ExportRanges = "ExportRanges",
    ExportResultTypes = "ExportResultTypes",
    ExportTypes = "ExportTypes",
    ExternalCorrectedDocumentTypes = "ExternalCorrectedDocumentTypes",
    Features = "Features",
    FinancialReportLayouts = "FinancialReportLayouts",
    FinancialReportLayoutSections = "FinancialReportLayoutSections",
    FiscalYearCloseSectionTypes = "FiscalYearCloseSectionTypes",
    FiscalYearStatuses = "FiscalYearStatuses",
    FulfilmentTypes = "FulfilmentTypes",
    GeneralPermissions = "GeneralPermissions",
    GovernmentInstitutions = "GovernmentInstitutions",
    GovernmentInstitutionBankAccounts = "GovernmentInstitutionBankAccounts",
    GovernmentInstitutionBankAccountTypes = "GovernmentInstitutionBankAccountTypes",
    GovernmentInstitutionTypes = "GovernmentInstitutionTypes",
    InboxEntityTypes = "InboxEntityTypes",
    InboxFileSources = "InboxFileSources",
    InboxFileStatuses = "InboxFileStatuses",
    InboxItemLogStates = "InboxItemLogStates",
    InteractionTypes = "InteractionTypes",
    InventoryTypes = "InventoryTypes",
    JournalEntryTypes = "JournalEntryTypes",
    Languages = "Languages",
    LegalEntityCategories = "LegalEntityCategories",
    LegislativeFixedExchangeRates = "LegislativeFixedExchangeRates",
    DocumentLockTypes = "DocumentLockTypes",
    MandatoryAccounts = "MandatoryAccounts",
    MandatoryChartsOfAccounts = "MandatoryChartsOfAccounts",
    MinorAssetStatuses = "MinorAssetStatuses",
    Modules = "Modules",
    MonthsOfYear = "MonthsOfYear",
    MultiplePostingDateAccountingType = "MultiplePostingDateAccountingType",
    NotificationTypes = "NotificationTypes",
    NotificationTypeGroups = "NotificationTypeGroups",
    NumberRangeTypes = "NumberRangeTypes",
    NumberRangeWildcards = "NumberRangeWildcards",
    PayableReceivableTypes = "PayableReceivableTypes",
    PaymentDocumentItemTypes = "PaymentDocumentItemTypes",
    PaymentMethods = "PaymentMethods",
    PaymentOrderFormats = "PaymentOrderFormats",
    PaymentOrderOutput = "PaymentOrderOutput",
    PaymentStatuses = "PaymentStatuses",
    PrAbsenceCategoryLegislativeTypes = "PrAbsenceCategoryLegislativeTypes",
    PrAttendanceStatuses = "PrAttendanceStatuses",
    PrCzechReductionLimits = "PrCzechReductionLimits",
    PrDisabilityTypes = "PrDisabilityTypes",
    PrEmployeeStates = "PrEmployeeStates",
    PrEmploymentTypes = "PrEmploymentTypes",
    PrExtraSalaryComputationTypes = "PrExtraSalaryComputationTypes",
    PrExtraSalaryLegislativeValues = "PrExtraSalaryLegislativeValues",
    PrExtraSalaryTypes = "PrExtraSalaryTypes",
    PrFormOfStudyTypes = "PrFormOfStudyTypes",
    PrGuaranteedSalaryDegrees = "PrGuaranteedSalaryDegrees",
    PrIdentityDocumentTypes = "PrIdentityDocumentTypes",
    PrInsuranceBankAccountsPredefined = "PrInsuranceBankAccountsPredefined",
    PrInsuranceBankAccountTypes = "PrInsuranceBankAccountTypes",
    PrInsuranceCompaniesPredefined = "PrInsuranceCompaniesPredefined",
    PrInsuranceCompanyTypes = "PrInsuranceCompanyTypes",
    PrLegislativeAverageSalaries = "PrLegislativeAverageSalaries",
    PrPayrollComponentCategoryTypes = "PrPayrollComponentCategoryTypes",
    PrPayrollComponentTypes = "PrPayrollComponentTypes",
    PrSalaryComponentTypes = "PrSalaryComponentTypes",
    PrSex = "PrSex",
    PrUnseizableAmounts = "PrUnseizableAmounts",
    PrWorkingPatternTypes = "PrWorkingPatternTypes",
    PeriodDays = "PeriodDays",
    PostedStatuses = "PostedStatuses",
    PostingRuleDocumentTypes = "PostingRuleDocumentTypes",
    PrBaseTypes = "PrBaseTypes",
    PrChildSupportDeductionTypes = "PrChildSupportDeductionTypes",
    PrDeductionCalculationTypes = "PrDeductionCalculationTypes",
    PrDeductionEntityTypes = "PrDeductionEntityTypes",
    PrDeductionStatuses = "PrDeductionStatuses",
    PrDeductionTypes = "PrDeductionTypes",
    PrDistrainmentDeductionTypes = "PrDistrainmentDeductionTypes",
    PrEntityValueSources = "PrEntityValueSources",
    Products = "Products",
    ProductPriceTypes = "ProductPriceTypes",
    PrWorkIntervalTypes = "PrWorkIntervalTypes",
    PurchaseStatuses = "PurchaseStatuses",
    ReceivableAdjustmentGroups = "ReceivableAdjustmentGroups",
    ReceivableAdjustmentTypes = "ReceivableAdjustmentTypes",
    RecurrentPaymentStates = "RecurrentPaymentStates",
    RecurringTaskIntervals = "RecurringTaskIntervals",
    RecurringTaskPeriods = "RecurringTaskPeriods",
    RecurringTaskStates = "RecurringTaskStates",
    RecurringTaskTypes = "RecurringTaskTypes",
    ReportSectionTypes = "ReportSectionTypes",
    ReportTypes = "ReportTypes",
    RequiredAccountTypes = "RequiredAccountTypes",
    RosumJobStatuses = "RosumJobStatuses",
    S5InvoiceStates = "S5InvoiceStates",
    Selections = "Selections",
    SignatoryPersons = "SignatoryPersons",
    SubscriptionStatuses = "SubscriptionStatuses",
    SubscriptionTypes = "SubscriptionTypes",
    TaxApplicabilities = "TaxApplicabilities",
    TaxCalendarTaxTypes = "TaxCalendarTaxTypes",
    TaxDepreciationCategories = "TaxDepreciationCategories",
    TaxDepreciationCoefficients = "TaxDepreciationCoefficients",
    TaxStatementSubmitters = "TaxStatementSubmitters",
    TicketStatuses = "TicketStatuses",
    TimeFormats = "TimeFormats",
    TimeZones = "TimeZones",
    TypeOfBusinesses = "TypeOfBusinesses",
    UnitsOfMeasure = "UnitsOfMeasure",
    UserStatuses = "UserStatuses",
    VariantAccessTypes = "VariantAccessTypes",
    VariantTypes = "VariantTypes",
    Vats = "Vats",
    VatControlStatements = "VatControlStatements",
    VatControlStatementSections = "VatControlStatementSections",
    VatDeductionTypes = "VatDeductionTypes",
    VatOsses = "VatOsses",
    VatRateTypes = "VatRateTypes",
    VatReverseCharges = "VatReverseCharges",
    VatStatements = "VatStatements",
    VatStatementFrequencies = "VatStatementFrequencies",
    VatStatementSections = "VatStatementSections",
    VatStatementSectionCategories = "VatStatementSectionCategories",
    VatStatementStatuses = "VatStatementStatuses",
    VatStatuses = "VatStatuses",
    VatVieses = "VatVieses",
    WebSocketMessageTypes = "WebSocketMessageTypes",
    WriteOffTypes = "WriteOffTypes",
    Documents = "Documents",
    PrInsuranceCompanies = "PrInsuranceCompanies",
    CashReceipts = "CashReceipts",
    AccountAssignments = "AccountAssignments",
    CorrectiveInvoicesReceived = "CorrectiveInvoicesReceived",
    InvoicesReceived = "InvoicesReceived",
    OtherLiabilities = "OtherLiabilities",
    ProformaInvoicesReceived = "ProformaInvoicesReceived",
    CorrectiveInvoicesIssued = "CorrectiveInvoicesIssued",
    InvoicesIssued = "InvoicesIssued",
    OtherReceivables = "OtherReceivables",
    ProformaInvoicesIssued = "ProformaInvoicesIssued",
    DeferredDocuments = "DeferredDocuments",
    DeferredPlans = "DeferredPlans",
    ActionLogs = "ActionLogs",
    Assets = "Assets",
    DocumentAssetEntries = "DocumentAssetEntries",
    DocumentMinorAssetItems = "DocumentMinorAssetItems",
    MinorAssets = "MinorAssets",
    MinorAssetHistoryEntries = "MinorAssetHistoryEntries",
    UnorganizedAssets = "UnorganizedAssets",
    BackgroundJobs = "BackgroundJobs",
    BusinessPartners = "BusinessPartners",
    CbaCategories = "CbaCategories",
    CbaEntries = "CbaEntries",
    ChartedAccountAssignments = "ChartedAccountAssignments",
    ChartsOfAccounts = "ChartsOfAccounts",
    CommunicationEntries = "CommunicationEntries",
    Companies = "Companies",
    CompanyRoles = "CompanyRoles",
    CompanySettings = "CompanySettings",
    CurrenciesUsedByCompany = "CurrenciesUsedByCompany",
    CurrenciesWithExchangeRateInfo = "CurrenciesWithExchangeRateInfo",
    CustomerPortalPayments = "CustomerPortalPayments",
    DashboardTasks = "DashboardTasks",
    DataBoxes = "DataBoxes",
    IsdocImportDocuments = "IsdocImportDocuments",
    MoneyS3ImportDocuments = "MoneyS3ImportDocuments",
    PohodaImports = "PohodaImports",
    PohodaImportDocuments = "PohodaImportDocuments",
    PohodaMappings = "PohodaMappings",
    ClearingDocumentLinks = "ClearingDocumentLinks",
    DocumentDeleteLogs = "DocumentDeleteLogs",
    DocumentDrafts = "DocumentDrafts",
    PayingDocuments = "PayingDocuments",
    UndirectedDocumentLinks = "UndirectedDocumentLinks",
    VatStatementDocuments = "VatStatementDocuments",
    ElectronicSubmissions = "ElectronicSubmissions",
    ExchangeRates = "ExchangeRates",
    ExportLogs = "ExportLogs",
    FilesMetadata = "FilesMetadata",
    FiscalYears = "FiscalYears",
    FiscalYearCloses = "FiscalYearCloses",
    FlattenAccounts = "FlattenAccounts",
    GeneralRoles = "GeneralRoles",
    Hotspots = "Hotspots",
    InboxFiles = "InboxFiles",
    InboxFileLogs = "InboxFileLogs",
    InboxItemLogs = "InboxItemLogs",
    InternalDocuments = "InternalDocuments",
    ItemTemplates = "ItemTemplates",
    CompoundJournalEntries = "CompoundJournalEntries",
    JournalEntries = "JournalEntries",
    SingleJournalEntries = "SingleJournalEntries",
    LabelHierarchies = "LabelHierarchies",
    NotificationSettings = "NotificationSettings",
    Notifications = "Notifications",
    NumberRanges = "NumberRanges",
    NumberRangeDefinitions = "NumberRangeDefinitions",
    Organizations = "Organizations",
    BankStatements = "BankStatements",
    BankStatementDrafts = "BankStatementDrafts",
    BankTransactions = "BankTransactions",
    BankTransactionDuplicates = "BankTransactionDuplicates",
    CompanyBankAccounts = "CompanyBankAccounts",
    PostingRules = "PostingRules",
    CashBoxes = "CashBoxes",
    CashReceiptsIssued = "CashReceiptsIssued",
    CashReceiptsReceived = "CashReceiptsReceived",
    PaymentDocumentDrafts = "PaymentDocumentDrafts",
    UndirectedPaymentDocumentLinks = "UndirectedPaymentDocumentLinks",
    PrAbsenceCategories = "PrAbsenceCategories",
    PrAttendances = "PrAttendances",
    PrAttendancesWithWorkingPatterns = "PrAttendancesWithWorkingPatterns",
    PrEmployees = "PrEmployees",
    PrEmployments = "PrEmployments",
    PrEmploymentTemplates = "PrEmploymentTemplates",
    PrExtraSalaries = "PrExtraSalaries",
    PrGroupDeductions = "PrGroupDeductions",
    PrHealthInsuranceCompanies = "PrHealthInsuranceCompanies",
    PrIndividualDeductions = "PrIndividualDeductions",
    PrLifeInsuranceCompanies = "PrLifeInsuranceCompanies",
    PrPayrollSettings = "PrPayrollSettings",
    PrPensionInsuranceCompanies = "PrPensionInsuranceCompanies",
    PrSalaryAdvances = "PrSalaryAdvances",
    PrSalaryComponents = "PrSalaryComponents",
    PrWorkingPatterns = "PrWorkingPatterns",
    RecurringTasks = "RecurringTasks",
    BalanceSheetLayouts = "BalanceSheetLayouts",
    IncomeStatementLayouts = "IncomeStatementLayouts",
    RequiredAccounts = "RequiredAccounts",
    RossumJobs = "RossumJobs",
    TaxCalendars = "TaxCalendars",
    AccountAssignmentTemplates = "AccountAssignmentTemplates",
    BalanceSheetLayoutTemplates = "BalanceSheetLayoutTemplates",
    ChartOfAccountsTemplates = "ChartOfAccountsTemplates",
    IncomeStatementLayoutTemplates = "IncomeStatementLayoutTemplates",
    Tickets = "Tickets",
    Users = "Users",
    DefaultVariants = "DefaultVariants",
    Variants = "Variants",
    VatClassifications = "VatClassifications",
    PropertyTranslations = "PropertyTranslations",
    BillingAddresses = "BillingAddresses",
    BillingContacts = "BillingContacts",
    EnabledFeatures = "EnabledFeatures",
    Invitations = "Invitations",
    SubscriptionModules = "SubscriptionModules",
    ProductPrices = "ProductPrices",
    Subscriptions = "Subscriptions",
    Tenants = "Tenants",
}

export enum OdataActionName {
    BackgroundJobCancel = "BackgroundJobCancel",
    BackgroundJobMarkSeen = "BackgroundJobMarkSeen",
    BackgroundJobRollback = "BackgroundJobRollback",
    BankTransactionsConfirm = "BankTransactionsConfirm",
    BankTransactionsMatchMultiple = "BankTransactionsMatchMultiple",
    BankTransactionsPostMultiple = "BankTransactionsPostMultiple",
    DocumentDraftsPostMultiple = "DocumentDraftsPostMultiple",
    DocumentDraftCancelRossum = "DocumentDraftCancelRossum",
    CashBoxCreateReceipt = "CashBoxCreateReceipt",
    ElectronicSubmissionsLock = "ElectronicSubmissionsLock",
    ElectronicSubmissionUnlock = "ElectronicSubmissionUnlock",
    DocumentDraftExtractIsdoc = "DocumentDraftExtractIsdoc",
    DocumentDraftExtractRossum = "DocumentDraftExtractRossum",
    UserNotificationsMarkAllIsRead = "UserNotificationsMarkAllIsRead",
    PrEmploymentRemoveTemplate = "PrEmploymentRemoveTemplate",
    InboxFileRemoveInboxFileLeaveFileMetadata = "InboxFileRemoveInboxFileLeaveFileMetadata",
    UserNotificationSwitchIsRead = "UserNotificationSwitchIsRead",
}

export const ODataActionPath = {
    [OdataActionName.BackgroundJobCancel]: "Cancel",
    [OdataActionName.BackgroundJobMarkSeen]: "MarkSeen",
    [OdataActionName.BackgroundJobRollback]: "Rollback",
    [OdataActionName.BankTransactionsConfirm]: "Confirm",
    [OdataActionName.BankTransactionsMatchMultiple]: "MatchMultiple",
    [OdataActionName.BankTransactionsPostMultiple]: "PostMultiple",
    [OdataActionName.DocumentDraftsPostMultiple]: "PostMultiple",
    [OdataActionName.DocumentDraftCancelRossum]: "CancelRossum",
    [OdataActionName.CashBoxCreateReceipt]: "CreateReceipt",
    [OdataActionName.ElectronicSubmissionsLock]: "Lock",
    [OdataActionName.ElectronicSubmissionUnlock]: "Unlock",
    [OdataActionName.DocumentDraftExtractIsdoc]: "ExtractIsdoc",
    [OdataActionName.DocumentDraftExtractRossum]: "ExtractRossum",
    [OdataActionName.UserNotificationsMarkAllIsRead]: "MarkAllIsRead",
    [OdataActionName.PrEmploymentRemoveTemplate]: "RemoveTemplate",
    [OdataActionName.InboxFileRemoveInboxFileLeaveFileMetadata]: "RemoveInboxFileLeaveFileMetadata",
    [OdataActionName.UserNotificationSwitchIsRead]: "SwitchIsRead",
};

export interface IBankTransactionsConfirmParameters {
    BankTransactionIds: number[];
}

export interface IBankTransactionsMatchMultipleParameters {
    DocumentId: number;
    TransactionIds: number[];
}

export interface IBankTransactionsPostMultipleParameters {
    AccountId?: number;
    TransactionIds: number[];
}

export interface IDocumentDraftsPostMultipleParameters {
    DraftIds: number[];
    EntityTypeCode: string;
    DateAccountingTypeCode?: string;
    DateAccounting?: string;
    CreditAccountNumber?: string;
    DebitAccountNumber?: string;
    AccountAssignmentName?: string;
    IsAccountAssignmentCustom?: boolean;
    DocumentVatClassificationId?: number;
    CbaCategoryId?: number;
    CbaTaxImpactCode?: string;
    TaxPercentage?: number;
    IsAssetAcquisition?: boolean;
}

export interface IDocumentDraftCancelRossumParameters {
    BackgroundJobId: number;
}

export interface ICashBoxCreateReceiptParameters {
    Document: IDocumentEntity;
}

export interface IElectronicSubmissionsLockParameters {
    Company?: ICompanyEntity;
    DatePeriodEnd?: string;
    DatePeriodStart?: string;
    ElectronicSubmissionTypeCode?: string;
    ForceLock?: boolean;
}

export interface IDocumentDraftExtractIsdocParameters {
    FileMetadata: IFileMetadataEntity;
    WorkDate?: string;
}

export interface IDocumentDraftExtractRossumParameters {
    FileMetadata: IFileMetadataEntity;
    WorkDate?: string;
}

export interface IPrEmploymentRemoveTemplateParameters {
    TargetDate: string;
    SynchronizeWithDefaultValues: boolean;
}

export interface IOdataActionParameters {
    [OdataActionName.BackgroundJobCancel]: null;
    [OdataActionName.BackgroundJobMarkSeen]: null;
    [OdataActionName.BackgroundJobRollback]: null;
    [OdataActionName.BankTransactionsConfirm]: IBankTransactionsConfirmParameters;
    [OdataActionName.BankTransactionsMatchMultiple]: IBankTransactionsMatchMultipleParameters;
    [OdataActionName.BankTransactionsPostMultiple]: IBankTransactionsPostMultipleParameters;
    [OdataActionName.DocumentDraftsPostMultiple]: IDocumentDraftsPostMultipleParameters;
    [OdataActionName.DocumentDraftCancelRossum]: IDocumentDraftCancelRossumParameters;
    [OdataActionName.CashBoxCreateReceipt]: ICashBoxCreateReceiptParameters;
    [OdataActionName.ElectronicSubmissionsLock]: IElectronicSubmissionsLockParameters;
    [OdataActionName.ElectronicSubmissionUnlock]: null;
    [OdataActionName.DocumentDraftExtractIsdoc]: IDocumentDraftExtractIsdocParameters;
    [OdataActionName.DocumentDraftExtractRossum]: IDocumentDraftExtractRossumParameters;
    [OdataActionName.UserNotificationsMarkAllIsRead]: null;
    [OdataActionName.PrEmploymentRemoveTemplate]: IPrEmploymentRemoveTemplateParameters;
    [OdataActionName.InboxFileRemoveInboxFileLeaveFileMetadata]: null;
    [OdataActionName.UserNotificationSwitchIsRead]: null;
}